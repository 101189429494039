import { css } from 'styled-components';
import { breakpointLarge, breakpointMedium } from '../../design/responsive/breakpoints';

export const carouselStyles = css`
  .slick-slider div {
    transition: none;
  }

  .slick-slide {
    padding: 0 16px;
    ${breakpointMedium(css`
      padding: 0 24px;
    `)}
    ${breakpointLarge(css`
      padding: 0 40px;
    `)}
    position: relative;
    outline: none;
    > div {
      > div {
        outline: none;
      }
    }
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 48px;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: none;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    bottom: -20px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
    width: 20px;
    height: 20px;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: 0;
    border-radius: 100%;
    border: 1px solid #ffffff;
    cursor: pointer;
  }

  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }

  .slick-dots li.slick-active button {
    background: #caee10;
    border: 1px solid #caee10;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    z-index: 99;
  }

  .slick-next,
  .slick-prev {
    // todo: arrow right

    background-position: center;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    margin: 0;
    background-image: url('../../images/dark-right-arrow.svg');
  }

  .slick-prev {
    // todo: arrow left
    background-image: url('../../images/dark-left-arrow.svg');
  }

  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev {
    right: 30px;
  }

  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }

  .slick-prev:before {
    content: none;
  }

  .slick-next:before,
  [dir='rtl'] .slick-prev:before {
    content: none;
  }

  .slick-next {
    right: 0px;
  }
`;
