import React, { FC } from 'react';
import Link from '../../../../../components/link/Link';
import * as colors from '../../../../../design/colors/brand';
import { Background, isDarkBackground } from '../../../../../design/containers/panel/Panel';
import { BodyP } from '../../../../../design/typography/Typography';
import { PrismicImageData } from '../../../../../utilityTypes';
import { ArticleType } from '../../../types';
import CategoryTag from '../../Tags/CategoryTag';
import TopicTags, { TagTheme } from '../../Tags/TopicTags';
import { Card, CardImage, CardContent, TextContainer, Excerpt, CardTitle } from '../sharedCardStyles';

type CardProps = {
  date: string;
  title: string;
  author: string;
  excerpt: string;
  url: string;
  background: Background;
  image?: PrismicImageData;
  type: ArticleType;
  topics: string[];
  theme: TagTheme;
  truncateTitle?: boolean;
};
/**
 * Blog Card component: sits inside PostsGrid component on Blog Landing page.
 */
const BlogPostCard: FC<CardProps> = ({
  date,
  title,
  author,
  excerpt,
  url,
  image,
  background,
  type,
  topics,
  theme,
  truncateTitle,
}) => {
  const link = {
    link_type: 'Document',
    url,
  } as const;

  const darkMode = isDarkBackground(background);
  const textColor = darkMode ? colors.brand2Mid : colors.brand2;

  return (
    <Card borderRadius="24px" data-testid="content-hub-card" background={background}>
      {image?.gatsbyImageData && (
        <Link link={link}>
          <CardImage image={image} />
        </Link>
      )}
      <CardContent>
        <TextContainer>
          <CategoryTag type={type} isLight={darkMode} />
          <CardTitle link={link} truncateTitle={truncateTitle}>
            {title}
          </CardTitle>
          <TopicTags topics={topics} theme={theme} />
          {author && (
            <BodyP type="bodyMBold" color={textColor}>
              By {author}
            </BodyP>
          )}
          {excerpt && <Excerpt>{excerpt}</Excerpt>}
          {date && (
            <BodyP type="bodyMRegular" color={textColor}>
              {date}
            </BodyP>
          )}
        </TextContainer>
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
