import styled from 'styled-components';
import CloneChild from './CloneChild';

/**
 * Provides `grid-area` property equal to the provided `area` prop.
 * Allows easily adding grid areas to components without making them
 * styled components.
 *
 * @example
 * <GridAreaProvider area="some-area">
 *   <SomeComponent />
 * </GridAreaProvider>
 */
export const GridAreaProvider = styled(CloneChild)<{ area: string }>`
  grid-area: ${(props) => props.area};
`;
