import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RawImage } from '../../components/image/rawImage/RawImage';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { jump } from '../../design/animations/micro-interactions';
import { ButtonLink } from '../../design/components/button/Button';
import { Panel } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { Heading3, BodyP } from '../../design/typography/Typography';
import billApproved from '../../images/bill-approved.svg';
import cardIssued from '../../images/card-issued.svg';
import categoryConnect from '../../images/category-connect.svg';
import reimbursementSubmitted from '../../images/reimbursement-submitted.svg';
import statusUpdated from '../../images/status-updated.svg';
import subscriptionManagement from '../../images/subscription-management.svg';
import transactionsSynced from '../../images/transactions-synced.svg';
import xeroConnect from '../../images/xero-connect.svg';
import { WithClassName } from '../../utilityTypes';

const CardsContainer = styled.div`
  display: grid;
  gap: ${spacings['24px']};
  ${breakpointSmall(css`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  `)}
`;

const Card = styled(Panel).attrs({ background: 'grey200Solid', borderRadius: '24px', padding: '32px' })`
  ${jump}
  text-align: center;
`;

const TextContent = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'center' })``;

const selectableFeatures = {
  'Xero connected': xeroConnect,
  'Categories mapped': categoryConnect,
  'Transactions synced': statusUpdated,
  'Status updated': transactionsSynced,
  'Card issued': cardIssued,
  'Subscription management': subscriptionManagement,
  'Bill approved': billApproved,
  'Reimbursement submitted': reimbursementSubmitted,
};

type TwoColumnFourCardsProps = {
  heading: PrismicRichTextType;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  items: Queries.TwoColumnFourCardsFragment['items'];
} & WithClassName;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(ButtonLink)`
  width: fit-content;
`;
/**
 * Renders TwoColumnFourCards slice.
 */
const TwoColumnFourCards: FC<TwoColumnFourCardsProps> = ({ className, heading, items, primaryCta, secondaryCta }) => {
  return (
    <SliceContainer className={className} background="whiteSolid" padding="16px">
      <CenteredSliceContentContainer maxWidth="814px">
        <Heading2 textAlign="center">{heading}</Heading2>
        <Spacing top={{ base: '32px', breakpointSmall: '48px' }}>
          <CardsContainer>
            {items?.map((item, index) => (
              <Card key={`${item.heading}-${index}`} data-testid={`card-${index}`}>
                {item.feature && (
                  <Spacing bottom="12px">
                    <RawImage
                      src={selectableFeatures[item.feature as keyof typeof selectableFeatures]}
                      alt={item.feature}
                    />
                  </Spacing>
                )}
                <Heading3 textAlign="center">{item.heading}</Heading3>
                <Spacing top="12px">
                  <TextContent>{item.text}</TextContent>
                </Spacing>
                <Spacing top="32px">
                  <ButtonContainer>
                    <Button link={item.buttonLink}>{item.buttonText}</Button>
                  </ButtonContainer>
                </Spacing>
              </Card>
            ))}
          </CardsContainer>
        </Spacing>
        <Spacing top={{ base: '32px', breakpointSmall: '48px' }}>
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonLink styleType="secondary" link={secondaryCta.link}>
                {secondaryCta.text}
              </ButtonLink>
            }
          />
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default TwoColumnFourCards;
