import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../design/animations/display';
import { brand1 } from '../../../design/colors/brand';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { BodyP } from '../../../design/typography/Typography';
import InfoIcon from '../../../images/react-icons/InformationIcon.inline.svg';
import { WithTestId } from '../../../utilityTypes';
import PopUp, { popUpHoverTrigger } from '../popup/PopUp';
import { TooltipType } from '../types';

const TooltipText = styled.div`
  ${flex({ align: 'center', gap: '12px' })};
  color: ${brand1};
  cursor: pointer;
  ${breakpointSmall(css`
    cursor: auto;
  `)}
`;

const Container = styled.div`
  ${breakpointSmall(css`
    ${popUpHoverTrigger()}
  `)}
`;
type Props = { tooltip?: TooltipType } & WithTestId;

/**
 * Renders the tool tip trigger + popup.
 */
const Tooltip: FC<Props> = ({ tooltip }) => {
  const [showOnMobile, setShowMobile] = useState<boolean>(false);
  const onClose = () => {
    setShowMobile(false);
  };
  return (
    <Container>
      <TooltipText onClick={() => setShowMobile(true)} data-testid="tool-tip-trigger">
        <InfoIcon height={20} width={20} />
        <BodyP type="bodyLRegular">How we calculate price</BodyP>
      </TooltipText>
      <PopUp tooltip={tooltip} showOnMobile={showOnMobile} onClose={onClose} />
    </Container>
  );
};

export default Tooltip;
