import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { grid, padding } from '../../../design/animations/display';
import { brand1 } from '../../../design/colors/brand';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import Spacing from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import { EventState } from './EventHeader.helpers';

export const InfoKey = styled(BodyP)`
  color: ${brand1};
`;

export const Details = styled.div`
  ${grid({ gap: '16px' })};
  grid-template-columns: min-content 1fr;
  ${padding({ y: '24px' })};
`;

const pseudoElStyles = css`
  content: '';
  position: absolute;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
`;

const topBorder = css`
  ${pseudoElStyles};
  transition: width 1s ease;
  top: 0;
  left: 0;
  width: 0;
`;

const bottomBorder = css`
  ${pseudoElStyles}
  transition: left 1s ease;
  bottom: 0;
  left: 100%;
  width: 100%;
`;

type ContainerProps = { hideBottomLine: boolean };
export const DetailContainer = styled(OnVisible)<ContainerProps>`
  position: relative;
  &:before {
    ${topBorder};
  }
  &:after {
    ${({ hideBottomLine }) => (hideBottomLine ? 'display: none' : `${bottomBorder}`)}
  }
  &.visible {
    &:before {
      ${topBorder};
      width: 100%;
    }
    &:after {
      ${bottomBorder};
      left: 0;
    }
  }
`;

const EventDetail: FC<{ detail: string; value: string }> = ({ detail, value }) => (
  <>
    <InfoKey type={{ base: 'bodyMBold', breakpointXSmall: 'bodyLBold' }}>{detail}</InfoKey>
    <BodyP type={{ base: 'bodyMRegular', breakpointXSmall: 'bodyLRegular' }}>{value}</BodyP>
  </>
);
export interface EventDetailProps {
  info: {
    date: string | null | undefined;
    location: string | undefined;
    time: string | null | undefined;
  };
  detailState: EventState;
}
/**
 * Renders Event Details.
 */
const EventDetails: FC<EventDetailProps> = ({ info, detailState }) => {
  const eventHasPassed = detailState === 'eventHasPassed';
  const showThisEventHasPassed = eventHasPassed || detailState === 'Watch now';

  return (
    <DetailContainer hideBottomLine={eventHasPassed}>
      <>
        {showThisEventHasPassed ? (
          <Spacing top="24px" bottom="24px">
            <InfoKey type={{ base: 'bodyMBold', breakpointXSmall: 'bodyLBold' }}>This event has passed</InfoKey>
          </Spacing>
        ) : (
          <Details data-testid="event-header-details">
            {info.time && <EventDetail detail="Time" value={info.time} />}
            {info.date && <EventDetail detail="Date" value={info.date} />}
            {info.location && <EventDetail detail="Location" value={info.location} />}
          </Details>
        )}
      </>
    </DetailContainer>
  );
};

export default EventDetails;
