import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Image from '../Image';

type ImageSliceDataProps = SliceProps<
  'image',
  SliceVariation<'default', Queries.PrismicImageDefaultPrimary, Queries.PrismicImageDefaultItem>
> &
  WithClassName;

/**
 * Image data component: specifies the fragment and passes the appropriate data to the Image component.
 */
const ImageData: FC<ImageSliceDataProps> = ({ className, slice }) => {
  return <Image className={className} columns={slice?.primary?.columns || '1'} items={slice?.items} />;
};

export default ImageData;

export const query = graphql`
  fragment ImageSlice on PrismicImageDefault {
    id
    primary {
      columns
    }
    items {
      image {
        ...Image
      }
    }
  }
`;
