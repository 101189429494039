import { css, CSSProperties } from 'styled-components';

export const standardTransition = '200ms ease-in-out';

/**
 * Generates standard transition styles for provided property.
 */
export const standardTransitionStyles = (property: keyof CSSProperties) =>
  css`
    transition: ${property} ${standardTransition};
  `;
