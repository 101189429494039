import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { flex, padding } from '../../design/animations/display';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';

type TitleProps = {
  heading?: PrismicRichTextType;
  text?: string | '';
} & WithClassName;

const TitleContainer = styled.div`
  ${padding({ y: '40px' })}
  ${flex({ direction: 'column', justify: 'center' })}
${breakpointSmall(
    css`
      padding: 100px 0;
    `
  )}
`;

const Container = styled(SliceContainer)`
  overflow: hidden;
`;
Container.defaultProps = { background: 'brand4GradientMidnightLogoLeft', borderRadius: '32px' };
/**
 * Renders Quote slice.
 */
const Title: FC<TitleProps> = ({ className, heading, text }) => {
  return (
    <Container className={className}>
      <TitleContainer>
        {heading && <Heading1>{heading}</Heading1>}
        <Spacing top="16px">
          <BodyP textAlign="center" type="bodyLRegular">
            {text}
          </BodyP>
        </Spacing>
      </TitleContainer>
    </Container>
  );
};
export default Title;
