import React, { FC, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { grey300, white } from '../../../../design/colors/greyscale';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { WithClassName } from '../../../../utilityTypes';
import { LinkType } from '../../../link/types';
import { headerMenuZindex } from '../../../zindex';
import { headerHeight } from '../../data/measurements';
import { HeaderItem } from '../../types';
import { ctasHeight } from '../ctaSection/Ctas';
import { NavItem } from './NavItems';
import { slideoutTransition } from './styles/slideoutTransition';

type MenuContainerProps = { open?: boolean };
export const MenuContainer = styled.div<MenuContainerProps>`
  ${(props) => slideoutTransition(props.open)}
  top: ${headerHeight};
  z-index: ${headerMenuZindex};
  height: calc(100vh - ${headerHeight});
  width: 100%;
  padding: ${spacings['16px']} ${spacings['8px']} calc(${ctasHeight} - ${spacings['8px']}) ${spacings['8px']};
  background-color: ${white};

  ${breakpointMedium(css`
    display: flex;
    height: auto;
    width: auto;
    padding: 0;
  `)}
`;

const MenuInnerContainer = styled.div`
  height: 100%;
  border: 1px solid ${grey300};
  border-bottom: none;
  border-radius: ${spacings['24px']} ${spacings['24px']} 0 0;
  overflow: scroll;
  overscroll-behavior: none;

  ${breakpointMedium(css`
    display: flex;
    height: auto;
    border: none;
    overflow: visible;
  `)}
`;

const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

export type MenuProps = { items: HeaderItem[]; megaDropdownCta: LinkType };
type Props = MenuProps & MenuContainerProps & WithClassName;
/**
 * Renders nav menu.
 */
export const Menu: FC<Props> = ({ className, items, megaDropdownCta, open }) => (
  <MenuContainer className={className} open={open} data-testid="menu">
    <MenuInnerContainer onClick={stopPropagation}>
      {items.map((item) => (
        <NavItem key={item.heading} item={item} megaDropdownCta={megaDropdownCta} />
      ))}
    </MenuInnerContainer>
  </MenuContainer>
);
