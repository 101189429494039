import { css } from 'styled-components';
import { largeStyles } from '../large/large';

/**
 * Generates X-Large button styles.
 */
export const xLargeStyles = () => css`
  ${largeStyles()}
  width: 100%;
`;
