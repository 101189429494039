import React, { FC } from 'react';
import styled from 'styled-components';
import { brand1DarkAlt, brand3, brand1 } from '../../../design/colors/brand';
import { white } from '../../../design/colors/greyscale';
import { spacings } from '../../../design/spacing/Spacing';
import { Heading3, BodyP } from '../../../design/typography/Typography';
import { WithTestId } from '../../../utilityTypes';

const StepContainer = styled.div`
  display: flex;
  gap: ${spacings['24px']};
  padding-bottom: ${spacings['48px']};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: ${spacings['32px']};
    height: 100%;
    width: 1px;
    background: ${brand1DarkAlt};
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;

const StepTitle = styled(Heading3)`
  padding-bottom: ${spacings['8px']};
`;

const StepNumber = styled.div`
  background: ${brand3};
  border-radius: 100vh;
  width: ${spacings['64px']};
  height: ${spacings['64px']};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${brand1};
`;

type StepData = {
  Heading?: string | null;
  Text?: string | null;
  index: number;
};

type StepProps = StepData & WithTestId;

/**
 * Renders Step Comp.
 */
const Step: FC<StepProps> = ({ Heading, Text, index, 'data-testid': testId }) => {
  const formatNumber = index + 1 > 9 ? index : `0${index + 1}`;

  return (
    <StepContainer data-testid={testId}>
      <StepNumber>
        <Heading3>{formatNumber}</Heading3>
      </StepNumber>
      <div>
        <StepTitle textAlign="left">{Heading}</StepTitle>
        <BodyP textAlign="left" type="bodyLRegular">
          {Text}
        </BodyP>
      </div>
    </StepContainer>
  );
};

export default Step;
