import { css } from 'styled-components';
import { brand2Dark } from '../../../../../../colors/brand';
import { white } from '../../../../../../colors/greyscale';
import { secondaryDisabledStyles } from './secondary';

/**
 * Generates the secondary reverse button type style.
 */
export const secondaryReverseTypeStyle = () => css`
  background-color: transparent;
  border-color: ${white};
  color: ${white};

  &:hover {
    background-color: ${white};
    border-color: ${brand2Dark};
    color: ${brand2Dark};
  }

  ${secondaryDisabledStyles}
`;
