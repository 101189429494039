import { css } from 'styled-components';
import { brand2, brand2Light, brand4 } from '../../../../design/colors/brand';
import { mediumStyles } from '../../../../design/components/button/styles/size/medium/medium';
import { getTypographyStyles } from '../../../../design/typography/Typography';

/**
 * Generates nav button styles.
 */
export const navButtonStyles = () => css`
  ${mediumStyles()}
  ${getTypographyStyles('bodyLRegular')}
    border-color: transparent;
  color: ${brand4};
  transition: all 0.3s ease;
  &:hover {
    background: ${brand2Light};
    border-color: ${brand2Light};
    color: ${brand2};
  }
`;
