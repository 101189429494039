import React, { useState } from 'react';
import styled from 'styled-components';
import HubspotForm from '../../components/hubspotForm/HubspotForm';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { grey700 } from '../../design/colors/greyscale';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import Heading3 from '../../design/typography/Heading3';
import { WithClassName } from '../../utilityTypes';
import { customHubspotFormStyles } from './customHubspotFormStyles';

type EmailCaptureProps = {
  title?: PrismicRichTextType;
  successTitleText?: PrismicRichTextType;
  successSubText?: PrismicRichTextType;
  hubspotFormId?: string;
  trackingEventName?: string;
} & WithClassName;

type SuccessStateProps = Pick<EmailCaptureProps, 'successTitleText' | 'successSubText'>;
type EmailInputStateProps = Pick<EmailCaptureProps, 'title' | 'hubspotFormId' | 'trackingEventName'> & {
  handleSubmit: () => void;
};

const StyledRichText = styled(RichText)`
  color: ${grey700};
`;

const SuccessState = ({ successTitleText, successSubText }: SuccessStateProps) => (
  <>
    {successTitleText && (
      <Spacing bottom="24px">
        <Heading2 textAlign="center">{successTitleText}</Heading2>
      </Spacing>
    )}
    {successSubText && <StyledRichText textType="bodyLRegular" textAlign="center" field={successSubText} />}
  </>
);

const EmailInputState = ({ title, handleSubmit, hubspotFormId, trackingEventName }: EmailInputStateProps) => (
  <>
    {title && (
      <Spacing bottom="24px">
        <Heading3 textAlign="center">{title}</Heading3>
      </Spacing>
    )}
    <HubspotForm
      id={hubspotFormId || ''}
      customStyleOptions={customHubspotFormStyles}
      trackingEvent={trackingEventName}
      onFormSubmit={handleSubmit}
    />
  </>
);

/**
 * Renders Email Capture component.
 */
const EmailCapture = ({
  className,
  title,
  successTitleText,
  successSubText,
  hubspotFormId,
  trackingEventName,
}: EmailCaptureProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const handleSubmit = () => {
    setHasSubmitted(true);
  };

  return (
    <SliceContainer className={className} background="brand5Solid">
      <CenteredSliceContentContainer>
        {hasSubmitted ? (
          <SuccessState successTitleText={successTitleText} successSubText={successSubText} />
        ) : (
          <EmailInputState
            title={title}
            handleSubmit={handleSubmit}
            hubspotFormId={hubspotFormId || ''}
            trackingEventName={trackingEventName || ''}
          />
        )}
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};
export default EmailCapture;
