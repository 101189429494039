import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../components/link/types';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { TransparentVideo } from '../../components/video/TransparentVideo';
import { SquareVideoContainer } from '../../components/video/containers/VideoContainers';
import { flex } from '../../design/animations/display';
import { ButtonLink, ButtonToUse } from '../../design/components/button/Button';
import { ResponsiveButtonType } from '../../design/components/button/Button.page';
import { Background, isDarkBackground } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import {
  getSliceContentContainerVerticalPadding,
  sliceContentContainerMobilePadding,
} from '../../design/containers/sliceContainer/sliceContentContainerStyles';
import { breakpointMedium, breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodyP } from '../../design/typography/Typography';
import animationFallback from '../../images/videos/weel-homepage-animation-fallback.png';
import animationHevc from '../../images/videos/weel-homepage-animation.mp4';
import animationWebm from '../../images/videos/weel-homepage-animation.webm';
import { PrismicImageData, WithClassName } from '../../utilityTypes';

type HomepageHeroProps = {
  heading: PrismicRichTextType;
  text?: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  secondaryMobileCta: LinkType;
  backgroundType?: Background;
  imageDesktop: PrismicImageData;
  imageMobile: PrismicImageData;
} & WithClassName;

const TextContent = styled(BodyP).attrs({ type: 'bodyLRegular' })`
  width: 100%;
`;

const CtaContainer = styled.div`
  ${flex({ direction: 'column', gap: '16px' })}
  max-width: fit-content;
  margin: auto;
  padding-top: ${spacings['24px']};
  ${breakpointSmall(css`
    margin: 0;
    ${flex({ direction: 'row' })}
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpointSmall(css`
    flex-direction: row;
    align-items: flex-end;
  `)}
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${sliceContentContainerMobilePadding};

  ${breakpointSmall(css`
    padding-bottom: ${sliceContentContainerMobilePadding};
  `)}

  ${breakpointMedium(css`
    padding-top: ${getSliceContentContainerVerticalPadding('100px')};
    padding-bottom: ${getSliceContentContainerVerticalPadding('100px')};
  `)}
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 500px;
`;

const HomepageHeroHeading = styled(RichText)``;
HomepageHeroHeading.defaultProps = {
  textAlign: { base: 'center', breakpointSmall: 'left' },
  textType: { base: 'heading1', breakpointSmall: 'heading2', breakpointMedium: 'heading1' },
};

const HomePageAnimationContainer = styled(SquareVideoContainer)`
  position: relative;
  width: 100%;
  max-width: 500px;
  left: 21.5%;

  ${breakpointSmall(css`
    position: static;
  `)}
`;

const HomePageAnimation = () => (
  <HomePageAnimationContainer>
    <TransparentVideo webmSrc={animationWebm} hevcSrc={animationHevc} fallbackImageSrc={animationFallback} />
  </HomePageAnimationContainer>
);

/**
 * Get secondary styles for hero buttons.
 */
export const getBackgroundStyle = (background: Background): ResponsiveButtonType => {
  return isDarkBackground(background) ? 'reverseSecondary' : 'secondary';
};
/**
 * Homepage hero component.
 */
const HomepageHero: FC<HomepageHeroProps> = ({
  className,
  heading,
  text,
  primaryCta,
  secondaryCta,
  backgroundType = 'brand3Gradient',
  secondaryMobileCta,
}) => {
  return (
    <SliceContainer className={className} background={backgroundType}>
      <ContentContainer>
        <LeftSideContainer>
          <HomepageHeroHeading field={heading} />
          <Spacing top="24px">
            <TextContent textAlign={{ base: 'center', breakpointSmall: 'left' }}>{text}</TextContent>
          </Spacing>
          {
            <Spacing top="24px">
              <CtaContainer>
                {primaryCta && (
                  <ButtonLink styleType="primary" link={primaryCta.link}>
                    {primaryCta.text}
                  </ButtonLink>
                )}
                <ButtonToUse
                  desktopCta={secondaryCta}
                  mobileCta={secondaryMobileCta}
                  styleType={getBackgroundStyle(backgroundType)}
                />
              </CtaContainer>
            </Spacing>
          }
        </LeftSideContainer>
        <RightSideContainer>
          <HomePageAnimation />
        </RightSideContainer>
      </ContentContainer>
    </SliceContainer>
  );
};

export default HomepageHero;
