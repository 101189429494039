import styled, { css } from 'styled-components';
import { breakpointSmall, breakpointMedium } from '../responsive/breakpoints';
import CloneChild from './CloneChild';

/**
 * Provides styles to child to hide it at breakpointSmall and above.
 *
 * Useful where child cannot be directly styled, such as `StaticImage`.
 */
export const BelowBreakpointSmallStylesProvider = styled(CloneChild)`
  && {
    ${breakpointSmall(css`
      display: none;
    `)}
  }
`;

/**
 * Provides styles to child to only show it at breakpointSmall and above.
 *
 * Useful where child cannot be directly styled, such as `StaticImage`.
 */
export const BreakpointSmallStylesProvider = styled(CloneChild)`
  && {
    display: none;
    ${breakpointMedium(css`
      display: block;
    `)}
  }
`;

/**
 * Provides styles to child to only show it at breakpointMedium and above.
 *
 * Useful where child cannot be directly styled, such as `StaticImage`.
 */
export const BreakpointMediumStylesProvider = styled(CloneChild)`
  && {
    display: none;
    ${breakpointMedium(css`
      display: block;
    `)}
  }
`;
