import React, { useRef, FC, PropsWithChildren } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { carouselStyles } from './CarouselStyles';

const defaultSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const CarouselContainer = styled.section`
  max-width: 100%;
  ${carouselStyles}
`;

type CarouselProps = {
  sliderRef?: React.RefObject<HTMLHeadingElement>;
  settings?: Record<string, string>;
} & PropsWithChildren;
/**
 * Render Component component.
 */
export const Carousel: FC<CarouselProps> = ({ sliderRef, settings, children }) => {
  const settingsToUse = settings || defaultSettings;
  const sliderElement = useRef(null);

  return (
    <CarouselContainer>
      <Slider {...settingsToUse} ref={sliderRef ? sliderElement : null}>
        {children}
      </Slider>
    </CarouselContainer>
  );
};
