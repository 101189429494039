import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import EmailCapture from '../EmailCapture';

type EmailCaptureProps = SliceProps<
  'email_capture',
  SliceVariation<'default', Queries.EmailCaptureFragment['primary']>
> &
  WithClassName;

/**
 * Email Capture Data Component.
 */
const EmailCaptureData: FC<EmailCaptureProps> = ({ className, slice }) => (
  <EmailCapture
    className={className}
    title={slice.primary.title}
    successTitleText={slice.primary.successTitleText}
    successSubText={slice.primary.successSubText}
    hubspotFormId={stringData(slice.primary?.hubspotFormId)}
    trackingEventName={stringData(slice.primary.trackingEventName)}
  />
);

export default EmailCaptureData;

export const query = graphql`
  fragment EmailCapture on PrismicEmailCaptureDefault {
    primary {
      hubspotFormId
      title {
        ...RichText
      }
      successTitleText {
        ...RichText
      }
      successSubText {
        ...RichText
      }
      trackingEventName
    }
  }
`;
