import { css } from 'styled-components';
import { breakpointXLarge, breakpointLarge, breakpointSmall } from '../../design/responsive/breakpoints';

export const pagePadding = css`
  padding-left: 8px;
  padding-right: 8px;

  ${breakpointSmall(css`
    padding-left: 16px;
    padding-right: 16px;
  `)}

  ${breakpointLarge(css`
    padding-left: 24px;
    padding-right: 24px;
  `)}

  ${breakpointXLarge(css`
    padding-left: 32px;
    padding-right: 32px;
  `)}
`;
