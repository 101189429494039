import styled, { css } from 'styled-components';
import HubspotForm from '../../components/hubspotForm/HubspotForm';
import { breakpointMedium } from '../../design/responsive/breakpoints';

const DemoHubspotForm = styled(HubspotForm)`
  ${breakpointMedium(
    css`
      width: 100%;
    `
  )}
`;

export default DemoHubspotForm;
