import styled, { css } from 'styled-components';
import { breakpointSmall } from '../../responsive/breakpoints';

/**
 * Renders children vertically on mobile and horizontally on desktop.
 */
const BaseColumnSmallRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointSmall(css`
    flex-direction: row;
  `)}
`;

export default BaseColumnSmallRowContainer;
