import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { grid, flex } from '../../design/animations/display';
import { Background } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { Heading2 } from '../../design/typography/Typography';
import { nthBackgroundConstructer, nthTagConstructer } from '../../design/utils/nthBackgroundProvider';
import { WithClassName } from '../../utilityTypes';
import BlogPostCard from '../contentHubCards/components/Cards/BlogPostCard/BlogPostCard';
import EventCard from '../contentHubCards/components/Cards/EventCard/EventCard';
import GuideCard from '../contentHubCards/components/Cards/GuideCard/GuideCard';
import { TagTheme } from '../contentHubCards/components/Tags/TopicTags';
import { ResourceCard } from '../contentHubCards/types';

type RelatedResourcesProps = {
  title: string | null;
  cards: ResourceCard[];
} & WithClassName;

export const backgroundPattern: Background[] = ['brand2Solid', 'whiteSolid', 'brand3Solid'];

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacings['48px']} 0;
  max-width 1286px;
  width: 100%;
`;
const Container = styled.div`
  ${flex({ justify: 'center', gap: '24px', direction: 'column' })}
  ${breakpointSmall(css`
    ${grid({ columns: 3 })}
  `)}
`;

const themeCards: TagTheme[] = ['brand3Mid', 'brand5', 'brand2'];
/**
 * Related Resources presentation component.
 */
const RelatedResources: FC<RelatedResourcesProps> = ({ title, cards, className }) => {
  const displayedTitle = title ? title : 'Related resources';
  return (
    <SliceContainer className={className} background="brand5Solid">
      <MainContainer>
        <Spacing bottom="48px">
          <Heading2 textAlign="left">{displayedTitle}</Heading2>
        </Spacing>
        <Container>
          {cards.map((card, index) => {
            if (card.documentType === 'blog_post') {
              return (
                <BlogPostCard
                  truncateTitle
                  theme={nthTagConstructer(themeCards, index)}
                  background={nthBackgroundConstructer(backgroundPattern, index)}
                  {...card}
                />
              );
            }
            if (card.documentType === 'guides') {
              return (
                <GuideCard
                  truncateTitle
                  theme={nthTagConstructer(themeCards, index)}
                  background={nthBackgroundConstructer(backgroundPattern, index)}
                  {...card}
                />
              );
            }
            if (card.documentType === 'events') {
              return (
                <EventCard
                  truncateTitle
                  theme={nthTagConstructer(themeCards, index)}
                  background={nthBackgroundConstructer(backgroundPattern, index)}
                  {...card}
                />
              );
            }
          })}
        </Container>
      </MainContainer>
    </SliceContainer>
  ); // TODO: Type properly => <></>;
};

export default RelatedResources;
