import React, { FC, useCallback, useEffect } from 'react';
import ReactHubspotForm from 'react-hubspot-form';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Defined, WithClassName } from '../../utilityTypes';
import { objectData } from '../../utils/lodashHelpers';
import { LinkType } from '../link/types';
import { hubspotFormStyles } from './hubspotFormStyles';
import { HubspotFormData, HubspotFormSubmitEvent } from './types';

type FormOptions = { customStyleOptions?: FlattenSimpleInterpolation };
const FormStyleWrapper = styled.div<FormOptions>`
  ${({ customStyleOptions }) =>
    customStyleOptions
      ? css`
          ${customStyleOptions}
        `
      : css`
          ${hubspotFormStyles}
        `}
`;

const trackingReducer = (arr: HubspotFormData) => {
  if (!arr) return {};
  return arr.reduce((accumulator, current) => ({ ...accumulator, [current.name]: current.value }), {});
};

const formIsInvalid = (formData: HubspotFormData) => formData.some((data) => data.valid === false);

const hubspotProductionPortalId = '4814723';

type HandleFormSubmitOptions = {
  data?: HubspotFormData;
  trackingEvent?: string;
  link?: LinkType['link'];
  onComplete?: (formData: HubspotFormData) => void;
};
const handleFormSubmit = ({ data, trackingEvent, link, onComplete }: HandleFormSubmitOptions) => {
  if (!data || !data.length || formIsInvalid(data)) return;

  const analytics = window.analytics;
  const email = data.find(({ name }) => name === 'email')?.value;
  const isNotGmail = email && !email.includes('@gmail.com');
  const trackingData = trackingReducer(data);

  if (analytics) {
    analytics.identify({ email });
    analytics.track(`${trackingEvent}`, trackingData);
  }
  if (isNotGmail && link?.url) window.open(link.url || undefined, '_blank');

  onComplete && onComplete(data);
};

export type HubspotProps = {
  formType?: string;
  id: string;
  link?: LinkType['link'];
  trackingEvent?: string;
  referrer?: string;
};

type Props = {
  onFormSubmit?: (formData: HubspotFormData) => void;
  onFormSubmitted?: () => void;
  customStyleOptions?: FlattenSimpleInterpolation;
} & HubspotProps &
  WithClassName;
/**
 * Renders hubspot form.
 */
const HubspotForm: FC<Props> = ({
  className,
  id,
  link,
  trackingEvent,
  onFormSubmit,
  onFormSubmitted = () => {},
  customStyleOptions,
}) => {
  const handler = useCallback(
    (event: HubspotFormSubmitEvent) => {
      const { eventName, data } = objectData(event, 'data') as Defined<HubspotFormSubmitEvent['data']>;

      if (eventName === 'onFormSubmit')
        return handleFormSubmit({
          trackingEvent,
          link,
          onComplete: onFormSubmit,
          data,
        });

      if (eventName === 'onFormSubmitted') return onFormSubmitted();
    },

    [link, onFormSubmit, onFormSubmitted, trackingEvent]
  );

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [handler]);

  const hsPortalId = hubspotProductionPortalId;
  const hsFormId = id;

  return (
    <FormStyleWrapper
      className={className}
      data-testid="hubspot-form-container"
      customStyleOptions={customStyleOptions}
    >
      <ReactHubspotForm portalId={hsPortalId} formId={hsFormId} loading={<div>Loading...</div>} />
    </FormStyleWrapper>
  );
};

export default HubspotForm;
