import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { toggleShow } from '../../../../../design/animations/display';
import { arrowHover } from '../../../../../design/animations/micro-interactions';
import { brand1DarkAlt } from '../../../../../design/colors/brand';
import { grey300, white } from '../../../../../design/colors/greyscale';
import { mediumShadowStyles } from '../../../../../design/colors/shadows';
import { hyperlink } from '../../../../../design/colors/system';
import { breakpointLarge, breakpointMedium } from '../../../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../../../design/spacing/Spacing';
import { BodyP } from '../../../../../design/typography/Typography';
import RightArrow from '../../../../../images/react-icons/ArrowRightLime.inline.svg';
import { WithClassName, WithTestId } from '../../../../../utilityTypes';
import Link, { LinkProps } from '../../../../link/Link';
import { LinkType } from '../../../../link/types';
import { HeaderMegaDropdownSection } from '../../../types';
import { HiddenOnMobile } from '../../../utils/Hidden';
import { Section } from './section/Section';

type DropdownContainerProps = { hide?: boolean; maxHeight?: number };

const toggleStyles = (hide?: boolean, maxHeight?: number) => css`
  ${toggleShow(!hide)}
  ${hide ? 'padding: 0;' : `padding: ${spacings['12px']};`}
  ${hide ? 'max-height: 0' : `max-height: ${maxHeight}px`};
`;

export const DropdownContainer = styled.div<DropdownContainerProps & WithTestId & WithClassName>`
  ${({ hide, maxHeight }) => toggleStyles(hide, maxHeight)};
  position: relative;
  transition: opacity 0.7s ease, max-height 0.5s ease;
  ${breakpointMedium(css`
    left: -160px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 56px;
    border-radius: 24px;
    padding: 0;
    ${mediumShadowStyles}
    background-color: ${white};
    width: max-content;
    transition: top 0.3s ease, opacity 0.4s ease;
    max-height: unset;
    max-width: 95vw;
  `)}
`;
DropdownContainer.defaultProps = { 'data-testid': 'header-dropdown-container' };

const DropdownMainContentContainer = styled.div`
  ${breakpointMedium(css`
    display: flex;
    padding: ${spacings['32px']};
  `)}
`;

const DropdownSectionContainer = styled.div`
  ${breakpointMedium(css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const LeftDropdownContainer = styled(DropdownSectionContainer)`
  ${breakpointMedium(css`
    padding-right: ${spacings['24px']};
  `)}
  ${breakpointLarge(css`
    padding-right: ${spacings['48px']};
  `)}
`;
const RightDropdownContainer = styled(DropdownSectionContainer)`
  ${breakpointMedium(css`
    border-left: 1px solid ${grey300};
    padding-left: ${spacings['24px']};
  `)}
  ${breakpointLarge(css`
    padding-left: ${spacings['48px']};
  `)}
`;

const BottomCtaText = styled(BodyP)`
  color: ${hyperlink};
`;
BottomCtaText.defaultProps = { type: 'bodySBold' };

const StyledArrow = styled(RightArrow)`
  transition: all 0.3s ease;
`;

const BottomCtaLink = styled(Link)`
  ${breakpointMedium(css`
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${grey300};
    padding: ${spacings['12px']} ${spacings['32px']} ${spacings['12px']} 0;
    &:hover {
      ${StyledArrow} {
        ${arrowHover()};
      }
    }
  `)}
`;

type BottomCtaProps = { text: string; link: LinkProps['link'] } & WithClassName;
const BottomCta: FC<BottomCtaProps> = ({ className, text, link }) => (
  <HiddenOnMobile display="flex">
    <BottomCtaLink className={className} link={link}>
      <BottomCtaText>{text}</BottomCtaText>
      <Spacing left="8px">
        <StyledArrow width="24px" stroke={brand1DarkAlt} />
      </Spacing>
    </BottomCtaLink>
  </HiddenOnMobile>
);

type DropdownProps = {
  megaDropdownCta: LinkType;
  leftSection: HeaderMegaDropdownSection;
  rightSection: HeaderMegaDropdownSection;
} & DropdownContainerProps &
  WithClassName;
/**
 * Renders a dropdown for the dropdown nav item.
 */
export const Dropdown: FC<DropdownProps> = ({ megaDropdownCta, leftSection, rightSection, hide, className }) => {
  const numberOfLeftItems = leftSection?.items.length || 0;
  const numberOfRightItems = rightSection?.items.length || 0;
  const needTwoColumns = numberOfRightItems > numberOfLeftItems;
  const dropDownContainer = useRef<HTMLHeadingElement>(null);
  const scrollHeight = dropDownContainer?.current?.scrollHeight;

  return (
    <DropdownContainer hide={hide} maxHeight={scrollHeight} className={className} ref={dropDownContainer}>
      <DropdownMainContentContainer>
        <LeftDropdownContainer>
          <Section section={leftSection} />
        </LeftDropdownContainer>
        <RightDropdownContainer data-testid="right-section-container">
          <Section section={rightSection} sectionItemColumns={needTwoColumns ? '2' : '1'} />
        </RightDropdownContainer>
      </DropdownMainContentContainer>
      <BottomCta text={megaDropdownCta.text} link={megaDropdownCta.link} />
    </DropdownContainer>
  );
};
