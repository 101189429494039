import { css } from 'styled-components';
import { warningDark, warningLight, warningMid, warningMid2 } from '../../../../../../colors/system';
import { alertDisabledStyles } from '../alert';

/**
 * Generates the warning button type style.
 */
export const warningTypeStyle = () => css`
  background-color: ${warningLight};
  border-color: ${warningMid2};
  color: ${warningDark};

  &:hover {
    background-color: ${warningMid2};
    border-color: ${warningMid};
  }

  ${alertDisabledStyles}
`;
