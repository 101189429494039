import { createGlobalStyle } from 'styled-components';
import { getButtonStyles } from '../../../design/components/button/Button';
import { primaryHoverStyle } from '../../../design/components/button/styles/buttonType/brand/primary/primary';
import { getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';

/**
 * Global styles targetting hubspot leadin modal.
 */
export const HubspotLeadInModal = createGlobalStyle`
  .leadinModal-content.leadinModal-content.leadinModal-content {
    ${fontFamilyStyles}
    h4 {
      ${getTypographyStyles('heading4')}
    }

    p {
      ${getTypographyStyles('bodyMRegular')}
    }

    .leadin-button-primary.leadin-button-primary.leadin-button-primary {
      ${getButtonStyles('primary', 'large')}
      ${primaryHoverStyle({ important: true })}
    }
  }
`;
