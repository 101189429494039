import { css } from 'styled-components';
import { brand3, brand5Mid } from '../../../../../../colors/brand';
import { white } from '../../../../../../colors/greyscale';
import { primaryDisabledStyles } from './primary';

/**
 * Generates the primary reverse button type style.
 */
export const primaryReverseTypeStyle = () => css`
  background-color: ${white};
  border-color: ${white};
  color: ${brand3};

  &:hover {
    background-color: ${brand5Mid};
    border-color: ${brand5Mid};
  }

  ${primaryDisabledStyles}
`;
