import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../../components/link/types';
import { flex } from '../../../design/animations/display';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointMedium, breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { Heading3, Heading4 } from '../../../design/typography/Typography';
import { WithClassName, WithTestId } from '../../../utilityTypes';
import Price from '../columns/Price';
import { HalfWidthButton } from '../columns/PricingColumns';
import List from '../list/List';
import Tooltip from '../tooltip/Tooltip';
import { TooltipType } from '../types';

const Container = styled(Panel).attrs({ background: 'brand2LogoRight', borderRadius: '12px' })`
  width: 100%;
  display: flex;
  gap: ${spacings['32px']};
  padding: ${spacings['32px']};
  flex-direction: column;
  height: 735px;
  overflow: visible;
  ${breakpointMedium(css`
    gap: ${spacings['48px']};
  `)}
  ${breakpointSmall(css`
    gap: ${spacings['100px']};
    padding: ${spacings['48px']};
    flex-direction: row;
    height: 100%;
  `)};
`;

export type BottomPanelListItems = {
  firstItem?: string;
  secondItem?: string;
  thirdItem?: string;
  fourthItem?: string;
  fifthItem?: string;
};

export type BottomCardProps = {
  heading?: string;
  subHeading?: string;
  price?: string;
  pricingPeriod?: string;
  ctaButton?: LinkType;
  listItems: BottomPanelListItems;
} & WithClassName &
  WithTestId;

const LeftSection: FC<Omit<BottomCardProps, 'listItems'>> = ({
  subHeading,
  heading,
  price,
  pricingPeriod,
  ctaButton,
}) => (
  <div data-testid="pricing-bottom-card">
    <Spacing bottom="24px">
      <Heading4 textAlign="left" type="heading4Medium">
        {subHeading}
      </Heading4>
    </Spacing>
    <Heading3 textAlign="left" type="heading3Bold">
      {heading}
    </Heading3>
    <Price price={price || ''} pricingPeriod={pricingPeriod || ''} />
    <HalfWidthButton size="medium" styleType="primary" link={ctaButton?.link || null}>
      {ctaButton?.text}
    </HalfWidthButton>
  </div>
);

const RightSection = styled.div`
  ${flex({ direction: 'column', gap: '24px' })};
`;

type Props = { bottomCard: BottomCardProps; tooltip?: TooltipType } & WithTestId;
/**
 * Renders the bottom card for Pricing slice.
 */
const PricingBottomCard: FC<Props> = ({ bottomCard, tooltip }) => {
  const { heading, subHeading, price, pricingPeriod, ctaButton, listItems } = bottomCard;
  const items = Object.values(listItems);
  const hasTooltip = !!tooltip?.rows?.length;
  return (
    <OnVisible fadeUp style={{ width: '100%' }}>
      <Spacing bottom="48px">
        <Container data-testid="bottom-card-container">
          <LeftSection
            heading={heading}
            subHeading={subHeading}
            price={price}
            pricingPeriod={pricingPeriod}
            ctaButton={ctaButton}
          />
          <RightSection>
            <List items={items} />
            {hasTooltip && <Tooltip tooltip={tooltip} />}
          </RightSection>
        </Container>
      </Spacing>
    </OnVisible>
  );
};

export default PricingBottomCard;
