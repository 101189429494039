import { css } from 'styled-components';
import {
  form,
  heading1,
  heading2,
  label,
  button,
  listItem,
  inputWrapper,
  inputError,
  cookieResetContainer,
  errorMessage,
  errorMessageList,
  errorsSummary,
  submittedMessage,
} from '../../components/hubspotForm/hubspotFormStyles';
import { brand4 } from '../../design/colors/brand';
import { grey600, white } from '../../design/colors/greyscale';
import { error, successDark } from '../../design/colors/system';
import { primaryTypeStyle } from '../../design/components/button/styles/buttonType/brand/primary/primary';
import { largeStyles } from '../../design/components/button/styles/size/large/large';
import { breakpointSmall, breakpointXSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { getTypographyStyles } from '../../design/typography/Typography';
import { fontFamilyStyles } from '../../design/typography/base/Text';

export const customHubspotFormStyles = css`
  ${fontFamilyStyles}
  ${getTypographyStyles('bodyMRegular')}
  line-height: 100%;
  color: ${brand4};

  ${form} {
    display: flex;
    flex-direction: row;
  }

  ${heading1} {
    ${getTypographyStyles('heading1')}
  }

  ${heading2} {
    ${getTypographyStyles('heading2')}
  }

  ${heading1}, ${heading2} {
    margin-bottom: ${spacings['32px']};
  }

  ${label} {
    ${getTypographyStyles('bodyMRegular')}
    line-height: 100%;
    display: flex;
    margin-bottom: ${spacings['8px']};
  }

  ${cookieResetContainer} {
    ${getTypographyStyles('bodyXSRegular')}
    margin: 0;
    margin-bottom: ${spacings['16px']};
  }

  ${button} {
    ${primaryTypeStyle()}
    ${largeStyles()}
    margin-top: ${spacings['8px']};
    line-height: 100%;
    cursor: pointer;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: fit-content;
  }

  ${inputWrapper} {
    width: 210px;
    ${fontFamilyStyles}
    ${getTypographyStyles('bodyMRegular')};
    line-height: 2.25rem;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: ${spacings['8px']} ${spacings['8px']} 0 ${spacings['24px']};
    color: ${grey600};
    background-color: ${white};
    height: 54px;
    input {
      width: 100%;
      text-overflow: ellipsis;
      background-color: white !important;
    }
    ${breakpointXSmall(css`
      width: 260px;
    `)}
    ${breakpointSmall(css`
      width: 272px;
    `)}
  }

  ${inputError} {
    margin-bottom: ${spacings['12px']};
  }
  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  ${errorMessage} {
    width: 100%;
    margin-bottom: 0;
    margin-top: ${spacings['12px']};
    margin-left: ${spacings['12px']};
  }

  ${errorMessageList} {
    margin-bottom: -${spacings['24px']};
  }

  ${errorMessage} {
    color: ${error};
    font-size: 14px;
  }

  ${errorsSummary} {
    color: ${error};
  }

  ${listItem} {
    margin-bottom: 0;
  }

  ${submittedMessage} {
    color: ${successDark};
  }
`;
