import { css } from 'styled-components';
import { textStyles } from '../../../../../typography/base/Text';

/**
 * Generates large button text styles.
 */
export const largeButtonTextStyles = () => textStyles({ fontSize: '18pt', fontWeight: '500', lineHeight: '150%' });

/**
 * Generates large button styles.
 */
export const largeStyles = () => css`
  height: 54px;
  width: unset;
  border-radius: 100px;
  padding: 16px 24px;
  ${largeButtonTextStyles()}
`;
