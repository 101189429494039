import styled from 'styled-components';
import generateResponsiveStyles, { ResponsiveValue } from '../responsive/generateResponsiveStyles';
import CloneChild from '../utils/CloneChild';

export const spacings = {
  none: '0',
  '4px': '4px',
  '8px': '8px',
  '12px': '12px',
  '16px': '16px',
  '24px': '24px',
  '32px': '32px',
  '40px': '40px',
  '48px': '48px',
  '60px': '60px',
  '64px': '64px',
  '72px': '72px',
  '80px': '80px',
  '100px': '100px',
  '144px': '144px',
  '200px': '200px',
} as const;

export type SpacingType = keyof typeof spacings;
export type Direction = 'top' | 'right' | 'bottom' | 'left';

const getSizePx = (size: SpacingType) => spacings[size];
const generateMargin = (size: SpacingType, direction: Direction) => `margin-${direction}: ${getSizePx(size)};`;

export type SpacingProps = {
  top?: ResponsiveValue<SpacingType>;
  right?: ResponsiveValue<SpacingType>;
  bottom?: ResponsiveValue<SpacingType>;
  left?: ResponsiveValue<SpacingType>;
};
const Spacing = styled(CloneChild)<SpacingProps>`
  ${(props) => props.top && generateResponsiveStyles(props.top, (size) => generateMargin(size, 'top'))}
  ${(props) => props.right && generateResponsiveStyles(props.right, (size) => generateMargin(size, 'right'))}
  ${(props) => props.bottom && generateResponsiveStyles(props.bottom, (size) => generateMargin(size, 'bottom'))}
  ${(props) => props.left && generateResponsiveStyles(props.left, (size) => generateMargin(size, 'left'))}
`;

export default Spacing;
