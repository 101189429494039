import styled, { css } from 'styled-components';
import BaseColumnSmallRowContainer from '../../../../design/containers/responsive/BaseColumnSmallRowContainer';
import { breakpointLarge, breakpointSmall } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import CloneChild from '../../../../design/utils/CloneChild';

export const OneRowCardStylesProvider = styled(CloneChild)`
  ${breakpointSmall(css`
    flex: 1;
  `)}
`;

const gap = spacings['24px'];

export const OneRowCardsContainer = styled(BaseColumnSmallRowContainer)`
  flex-wrap: wrap;
  justify-content: center;
  gap: ${gap};
  width: 100%;

  ${OneRowCardStylesProvider} {
    ${breakpointSmall(css`
      max-width: calc(33% - ${gap});
      min-width: calc(33% - ${gap});
    `)}

    ${breakpointLarge(css`
      max-width: none;
      min-width: 0;
    `)}
  }
`;
