import React, { FC, useState } from 'react';
import styled from 'styled-components';
import HubspotForm from '../../components/hubspotForm/HubspotForm';
import { brand2 } from '../../design/colors/brand';
import { Button } from '../../design/components/button/Button';
import Modal from '../../design/components/modal/Modal';
import Cross from '../../images/react-icons/Cross.inline.svg';
import SuccessState from './SuccessState';
import { downloadGatedContentPopupStyles } from './downloadNowPopUpStyles';

const CloseButtonElement = styled(Button)`
  cursor: pointer;
  width: fit-content;
  background: transparent;
  border: none;
  position: absolute;
  top: 3%;
  right: 3%;
  &:hover {
    background-color: transparent;
  }
`;

type CloseButtonProps = { onClick?: () => void };
const CloseButton: FC<CloseButtonProps> = ({ onClick }) => (
  <CloseButtonElement onClick={onClick} data-testid="modal-close" size="small">
    <Cross color={brand2} height={14} width={14} />
  </CloseButtonElement>
);

export type PopUpProps = {
  active: boolean;
  hubspotFormID?: string;
  hubspotFormTrackingEventName?: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
  hubspotFormSuccessHeading?: string | null;
  hubspotFormSuccessText?: string | null;
  downloadLink?: string | null;
};

/**
 * Pop up form for gated downloadable content.
 */
const PopUpForm: FC<PopUpProps> = ({
  active,
  hubspotFormID,
  hubspotFormTrackingEventName,
  setIsModalOpen,
  hubspotFormSuccessHeading,
  hubspotFormSuccessText,
  downloadLink,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleClose = () => {
    if (hasSubmitted) setHasSubmitted(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal active={active}>
        <CloseButton onClick={() => handleClose()} />
        {hasSubmitted ? (
          <SuccessState
            setIsModalOpen={setIsModalOpen}
            setHasSubmitted={setHasSubmitted}
            hubspotFormSuccessHeading={hubspotFormSuccessHeading}
            hubspotFormSuccessText={hubspotFormSuccessText}
            downloadLink={downloadLink}
          />
        ) : (
          <>
            <HubspotForm
              id={hubspotFormID || ''}
              trackingEvent={hubspotFormTrackingEventName}
              customStyleOptions={downloadGatedContentPopupStyles}
              onFormSubmitted={() => setHasSubmitted(true)}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default PopUpForm;
