import React, { FC } from 'react';
import styled from 'styled-components';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { grey400 } from '../../design/colors/greyscale';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { spacings } from '../../design/spacing/Spacing';
import { getTypographyStyles } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';

const WysiwygText = styled(RichText)`
  width: 100%;
  padding: ${spacings['24px']} 0;
  * {
    text-align: left;
  }
  h4,
  h5,
  h6 {
    margin: 16px 0;
    ${getTypographyStyles('heading4')}
  }
  h1,
  h2,
  h3 {
    ${getTypographyStyles('heading3')}
    margin: 16px 0;
  }
  ol {
    margin-left: 16px;
  }
`;

const CenteredContainer = styled(CenteredSliceContentContainer)`
  width: 100%;
`;
const SliceContainerAlt = styled(SliceContainer)`
  & + .wysiwyg-slice {
    border-top: 1px solid ${grey400};
  }
`;
// @todo build these styles into the RichText comp?
type WysiwygProps = {
  content?: PrismicRichTextType;
} & WithClassName;

/**
 * Renders Wysiwyg slice.
 */
const Wysiwyg: FC<WysiwygProps> = ({ content }) => {
  if (!content?.richText) return null;
  return (
    <SliceContainerAlt className="wysiwyg-slice" borderRadius="0px">
      <CenteredContainer>
        <WysiwygText field={content} />
      </CenteredContainer>
    </SliceContainerAlt>
  );
};

export default Wysiwyg;
