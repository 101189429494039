import { PrismicLinkType } from '../../../components/link/data/Link.generateData';
import { Defined, PrismicImageData } from '../../../utilityTypes';
import { SocialProofField } from '../types';

export type IconLinkMapping = { icon: PrismicImageData; link: PrismicLinkType }[];
/**
 * Gets social proof icons given the queried prismic social proof document link field.
 */
export const getSocialProofIconsFromPrismicDocumentLinkField = (socialProofField: SocialProofField) => {
  const selectedIconsRepeatableGroup =
    (socialProofField?.document && 'data' in socialProofField.document && socialProofField.document.data.icons) || [];

  const selectedIcons = selectedIconsRepeatableGroup.map((iconData) => ({
    icon: iconData?.icon,
    link: iconData?.iconLink,
  })) as IconLinkMapping;
  return selectedIcons.filter((icon): icon is Defined<typeof icon> => !!icon);
};
