import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage, PrismicImageType } from '../../components/image/prismicImage/PrismicImage';
import { RawImage } from '../../components/image/rawImage/RawImage';
import { PrismicLinkType } from '../../components/link/data/Link.generateData';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { flex } from '../../design/animations/display';
import { brand1, brand2 } from '../../design/colors/brand';
import { ButtonLink } from '../../design/components/button/Button';
import { Background, Panel } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { createBreakpoint } from '../../design/responsive/breakpointUtils';
import { breakpointMedium } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import { nthBackgroundConstructer } from '../../design/utils/nthBackgroundProvider';
import icon from '../../images/brand/icon.png';
import { WithClassName } from '../../utilityTypes';

type TwoColumnProcessProps = {
  title?: PrismicRichTextType;
  description?: PrismicRichTextType;
  left_column_heading: PrismicRichTextType;
  left_column_image: PrismicImageType;
  left_column_subtitle: PrismicRichTextType;
  cta_text?: string;
  cta_link?: PrismicLinkType;
  items: {
    card_description: PrismicRichTextType;
    card_heading: PrismicRichTextType;
  }[];
} & WithClassName;

const HeaderContainer = styled.div`
  max-width: 995px;
  margin: 0 auto;
  padding-bottom: ${spacings['48px']};
  ${flex({ direction: 'column', gap: '24px' })}
  * {
    text-align: left;
    ${createBreakpoint(850)(css`
      text-align: center;
    `)}
  }
`;

const MaxWidthButton = styled(ButtonLink)`
  max-width: fit-content;
  align-self: baseline;
  margin-top: 0;
  ${createBreakpoint(850)(css`
    align-self: center;
  `)}
`;

const CenteredContainer = styled.div`
  max-width: 1285px;
  margin: 0 auto;
`;

const LeftColumn = styled(Panel).attrs({ background: 'brand4Gradient', borderRadius: '12px' })`
  ${flex({ direction: 'column', justify: 'space-between' })}
  width: 100%;
  max-height: 512px;
  ${createBreakpoint(850)(css`
    max-width: 411px;
    max-height: unset;
  `)};
`;

const RightColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  ${createBreakpoint(850)(css`
    gap: 12px;
  `)};
`;

const LeftColumnTopper = styled.div`
  margin: 24px;
  ${createBreakpoint(850)(css`
    margin: 32px;
  `)};
`;

const ColumnLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  ${createBreakpoint(850)(css`
    grid-template-columns: max-content auto;
  `)}
`;

const CardStep = styled(Panel).attrs({ borderRadius: '12px', padding: '32px' })`
  ${flex({ gap: '24px', direction: 'column' })}
  ${breakpointMedium(
    css`
      display: grid;
      gap: 8px;
      grid-template-columns: 80px 1fr;
    `
  )}
`;

const LogoMark = styled(RawImage).attrs({ src: icon, alt: 'logo-mark' })`
  max-height: 76px;
  max-width: 76px;
`;

const Image = styled(PrismicImage)`
  && {
    img {
      width: auto;
    }
  }
`;

/**
 * Renders TwoColumnProcess slice.
 */
const TwoColumnProcess: FC<TwoColumnProcessProps> = ({
  title,
  description,
  cta_link,
  cta_text,
  items,
  className,
  ...leftColumnProps
}) => {
  const backgroundPattern: Background[] = ['whiteSolid', 'brand2Solid', 'brand3Solid', 'brand4Gradient', 'whiteSolid'];

  return (
    <SliceContainer
      background="brand5Solid"
      className={className}
      padding={{ base: '24px', breakpointMedium: '96px' }}
      data-testid="two-column-process-slice"
    >
      <CenteredContainer>
        <HeaderContainer>
          {title && <Heading2>{title}</Heading2>}
          {description && <RichText field={description} />}
          {cta_link && cta_text && <MaxWidthButton link={cta_link}>{cta_text}</MaxWidthButton>}
        </HeaderContainer>
        <ColumnLayout>
          <LeftColumn data-testid="two-column-process-left-column">
            <LeftColumnTopper>
              <Heading2>{leftColumnProps.left_column_heading}</Heading2>
              <RichText field={leftColumnProps.left_column_subtitle} textType="heading4" />
            </LeftColumnTopper>
            <Image image={leftColumnProps.left_column_image} objectFit="contain" />
          </LeftColumn>
          <RightColumn data-testid="two-column-process-right-column">
            {items.map((item, index) => (
              <CardStep key={index} background={nthBackgroundConstructer(backgroundPattern, index)}>
                {index !== items.length - 1 ? (
                  <BodyP type="numbersLight" color={index === 0 ? brand2 : brand1}>
                    0{index + 1}
                  </BodyP>
                ) : (
                  <LogoMark />
                )}
                <div>
                  {item.card_heading && <RichText field={item.card_heading} textType="heading4Medium" />}
                  {item.card_description && <RichText field={item.card_description} />}
                </div>
              </CardStep>
            ))}
          </RightColumn>
        </ColumnLayout>
      </CenteredContainer>
    </SliceContainer>
  );
};

export default TwoColumnProcess;
