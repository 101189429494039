/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { flex, padding, borderRadius } from '../../../../design/animations/display';
import { white } from '../../../../design/colors/greyscale';
import { typesFilterShadow } from '../../../../design/colors/shadows';
import { ActiveFilterButton } from '../../../../design/components/button/styles/buttonType/blog/blogFilter';
import { breakpointLarge, breakpointSmall } from '../../../../design/responsive/breakpoints';
import { BodyP } from '../../../../design/typography/Typography';
import ArticleIcon from '../../../../images/react-icons/Article.inline.svg';
import EventIcon from '../../../../images/react-icons/Event.inline.svg';
import GuideIcon from '../../../../images/react-icons/Guide.inline.svg';
import SearchIcon from '../../../../images/react-icons/Search.inline.svg';
import CustomerIcon from '../../../../images/react-icons/SpeechBubble.inline.svg';
import AllIcon from '../../../../images/react-icons/Stack.inline.svg';
import WebinarIcon from '../../../../images/react-icons/Webinar.inline.svg';
import { Chevron } from '../TopicFilter/TopicFilter';
import { ButtonMap, ExpandCollapseContainer, FilterContainer } from './FiltersShared';

const DesktopButtonsContainer = styled.div`
  display: none;
  ${typesFilterShadow};
  ${breakpointLarge(css`
    ${flex({ gap: '8px', direction: 'row' })};
    ${borderRadius({ all: '40px' })};
    ${padding({ all: '8px' })};
    background: ${white};
    max-width: fit-content;
  `)}
`;

const TypesContainer = styled((props) => <FilterContainer {...props} />)`
  width: 100%;
  ${breakpointSmall(css`
    max-width: 310px;
  `)}
  ${breakpointLarge(css`
    max-width: fit-content;
  `)}
`;

const MobileButtons = styled((props) => <ButtonMap {...props} />)`
  ${breakpointLarge(css`
    display: none;
  `)}
`;
const DesktopButtons = styled((props) => <ButtonMap {...props} />)`
  display: none;
  ${breakpointLarge(css`
    display: static;
  `)}
`;

export const filterButtonIcons: Record<string, JSX.Element> = {
  Articles: <ArticleIcon height={24} />,
  Webinars: <WebinarIcon height={24} />,
  Events: <EventIcon height={24} />,
  Guides: <GuideIcon height={24} />,
  Research: <SearchIcon height={24} />,
  'Customer stories': <CustomerIcon height={24} />,
  'All posts': <AllIcon height={24} />,
} as const;

type TypeFilterProps = {
  types: string[];
  handleFilter: (type: string) => void;
  queryType?: string | null;
};
/**
 * Type Filter component: sits inside PostsGrid component on Blog Lannding page.
 */
const TypeFilter: FC<TypeFilterProps> = ({ types, handleFilter, queryType }) => {
  const [activeType, setActiveType] = useState<string>(types[0]);
  const filterContainer = useRef<HTMLHeadingElement>(null);
  const scrollHeight = filterContainer?.current?.scrollHeight;
  const [expandTopics, setExpandTopics] = useState<boolean>(false);

  const toggleMenu = () => {
    setExpandTopics(!expandTopics);
  };

  const setFilter = (type: string) => {
    handleFilter(type);
    setActiveType(type);
    setExpandTopics(false);
  };

  useEffect(() => {
    if (queryType) setFilter(queryType);
  }, [queryType]);

  return (
    <TypesContainer align="right">
      <ActiveFilterButton
        expand={expandTopics}
        onClick={toggleMenu}
        styleType="tertiary"
        data-testid="type-post-filter"
        hideOnDesktop={true}
      >
        <BodyP type="bodyMMedium">{activeType}</BodyP>
        <Chevron expand={expandTopics} />
      </ActiveFilterButton>
      <ExpandCollapseContainer
        expand={expandTopics}
        ref={filterContainer}
        maxHeight={scrollHeight}
        data-testid="type-filters-container"
        hideOnDesktop={true}
      >
        <MobileButtons values={types} isTypes activeValue={activeType} onClick={setFilter} excludeActive={true} />
      </ExpandCollapseContainer>
      <DesktopButtonsContainer>
        <DesktopButtons values={types} isTypes activeValue={activeType} onClick={setFilter} />
      </DesktopButtonsContainer>
    </TypesContainer>
  );
};

export default TypeFilter;
