import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import styled from 'styled-components';
import { extractBodyFromArticle, generateArticleSchema } from '../../components/SEO/structuredData/utils';
import { brand2Mid2 } from '../../design/colors/brand';
import { BodyP } from '../../design/typography/Typography';
import PageContainer from '../../pageContainers/PageContainer';
import ImageData from '../../slices/image/data/Image.queryData';
import RelatedResourcesData from '../../slices/relatedResources/RelatedResources.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import VideoEmbedData from '../../slices/videoEmbed/data/VideoEmbed.queryData';
import WysiwygData from '../../slices/wysiwyg/data/Wysiwyg.queryData';
import { objectData, prismicAuthor } from '../../utils/lodashHelpers';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import Header from '../components/Header/Header';

const components = {
  wysiwyg: withBottomSpacing(WysiwygData),
  image: withBottomSpacing(ImageData),
  video_embed: withBottomSpacing(VideoEmbedData),
  related_resources: withBottomSpacing(RelatedResourcesData),
};

const PostDetail = styled(BodyP)`
  color: ${brand2Mid2};
`;
PostDetail.defaultProps = { type: 'bodyMRegular' };

/**
 * Renders Prismic BlogPost document and maps slice data to be rendered by their components in order.
 */
const BlogPost: FC<PageProps<Queries.BlogPostQuery>> = ({ data }) => {
  const postData = data.prismicBlogPost?.data;
  const headerId = objectData(postData, 'header.id');
  const footerId = objectData(postData, 'footer.id');
  const author = prismicAuthor(postData?.author);
  const slices = postData?.slices?.map((slice) => ({ ...slice })) as Queries.PrismicSharedSliceType[];

  const body = extractBodyFromArticle(slices);

  const seoData = {
    title: objectData(postData, 'Title'),
    description: objectData(postData, 'Description'),
    ogImage: objectData(postData, 'Image.url'),
    exclude_from_search_engines: objectData(postData, 'exclude_from_search_engines', false),
    last_publication_date: data.prismicBlogPost?.last_publication_date,
  };

  const schema = JSON.stringify(
    generateArticleSchema({
      ...seoData,
      uid: `${objectData(data.prismicBlogPost, 'url', undefined)}`,
      body,
      author,
    })
  );

  const seo = {
    ...seoData,
    schema,
  };

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <Header title={postData?.title}>
        {postData?.date && <PostDetail>{postData?.date}</PostDetail>}
        {author && <PostDetail>By {author}</PostDetail>}
      </Header>
      <ContentWrapper>
        <SliceZone slices={slices} components={components} />
      </ContentWrapper>
    </PageContainer>
  );
};

export default withPrismicPreview(BlogPost);

export const query = graphql`
  fragment AuthorDocument on PrismicAuthor {
    data {
      name
      title
    }
  }

  query BlogPost($url: String!) {
    prismicBlogPost(url: { eq: $url }) {
      url
      _previewable
      last_publication_date
      data {
        ...BlogSEO
        ...BlogPostHeader
        ...BlogPostFooter
        author {
          document {
            ...AuthorDocument
          }
        }
        date(formatString: "MMMM D, YYYY")
        title {
          ...RichText
        }
        slices {
          ...Slice
          ...Wysiwyg
          ...ImageSlice
          ...VideoEmbed
          ...RelatedResources
        }
      }
    }
  }
`;
