import styled, { css } from 'styled-components';
import { grey300 } from '../../../../design/colors/greyscale';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { headerHeight } from '../../data/measurements';

export const ContentContainer = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  ${breakpointMedium(css`
    height: ${headerHeight};
    border: 1px solid ${grey300};
    border-radius: 54px;
    padding-right: ${spacings['4px']};
    padding-left: ${spacings['4px']};
  `)}
`;
