import { graphql, useStaticQuery } from 'gatsby';
import { objectData } from '../../../utils/lodashHelpers';
import { FAQItem } from './utils';

/**
 * Fetches data for all FAQs on '/faqs' page.
 */
export const FaqQueryResult = () => {
  const faqs: Queries.PageQuery = useStaticQuery(graphql`
    query {
      prismicPage(uid: { eq: "faqs" }) {
        id
        uid
        data {
          slices {
            ... on PrismicFaqsDefault {
              id
              items {
                heading
                text {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);
  return faqs;
};

interface FaqItemRaw {
  heading: string;
  text: { html: string };
}
/**
 * Fetches and formats FAQ data.
 */
export const faqData = (): FAQItem[] =>
  objectData(FaqQueryResult(), 'prismicPage.data.slices', [])
    ?.map((slice: FaqItemRaw[]) =>
      objectData(slice, 'items', []).map(({ heading, text: { html: answer } }: FaqItemRaw) => ({
        question: heading,
        answer,
      }))
    )
    .flat();
