import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Pricing from '../Pricing';
import { PricingItem } from '../types';

type PricingProps = SliceProps<'pricing', SliceVariation<'default', Queries.PricingFragment['primary'], PricingItem>> &
  WithClassName;
/**
 * Pricing data component: specifies the fragment and passes the appropriate data to the Pricing component.
 */
const PricingData: FC<PricingProps> = ({ className, slice }) => {
  const tooltipDocument = slice?.primary?.tool_tip?.document;
  const tooltipData = (tooltipDocument && 'data' in tooltipDocument && tooltipDocument.data) || undefined;

  const tooltipRows = tooltipData?.entries?.flatMap((entry) =>
    entry
      ? {
          left_column_entry: entry.left_column_entry || '',
          right_column_entry: entry.right_column_entry || '',
        }
      : []
  );

  return (
    <Pricing
      className={className}
      heading={slice.primary?.heading}
      text={slice.primary?.text || ''}
      terms={slice.primary?.termsAndConditions || ''}
      tooltip={{
        heading: tooltipData?.heading || null,
        description: tooltipData?.text || null,
        leftColTitle: tooltipData?.left_column_label || '',
        rightColTitle: tooltipData?.right_column_label || '',
        rows: tooltipRows || [],
      }}
      bottomCard={{
        heading: slice.primary?.bottomHeading || '',
        subHeading: slice.primary?.bottomSubheading || '',
        price: slice.primary?.bottomPrice || '',
        pricingPeriod: slice.primary?.bottomPricingPeriod || '',
        ctaButton: { text: slice.primary?.bottomCtaButtonText || '', link: slice.primary?.bottomCtaButtonLink },
        listItems: {
          firstItem: slice.primary?.bottomFirstListItem || '',
          secondItem: slice.primary?.bottomSecondListItem || '',
          thirdItem: slice.primary?.bottomThirdListItem || '',
          fourthItem: slice.primary?.bottomFourthListItem || '',
          fifthItem: slice.primary?.bottomFifthListItem || '',
        },
      }}
      items={slice.items || []}
    />
  );
};

export default PricingData;

export const query = graphql`
  fragment Pricing on PrismicPricingDefault {
    primary {
      heading {
        ...RichText
      }
      text
      bottomHeading
      bottomSubheading
      bottomPrice
      bottomPricingPeriod
      bottomCtaButtonText
      bottomCtaButtonLink {
        ...PrismicLink
      }
      bottomFirstListItem
      bottomSecondListItem
      bottomThirdListItem
      bottomFourthListItem
      bottomFifthListItem
      termsAndConditions
      tool_tip {
        document {
          ... on PrismicProgressivePricing {
            data {
              entries {
                left_column_entry
                right_column_entry
              }
              heading {
                ...RichText
              }
              left_column_label
              right_column_label
              text {
                ...RichText
              }
            }
          }
        }
      }
    }
    items {
      heading
      tagText
      price
      pricingPeriod
      pricingSubtext
      ctaButtonText
      ctaButtonLink {
        ...PrismicLink
      }
      itemHeading
      firstListItem
      secondListItem
      thirdListItem
      fourthListItem
      fifthListItem
    }
  }
`;
