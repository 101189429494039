import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import CompanyQuote from '../CompanyQuote';

type CompanyQuoteProps = SliceProps<
  'companyQuote',
  SliceVariation<'default', Queries.PrismicCompanyQuoteDefaultPrimary>
> &
  WithClassName;

/**
 * Company quote data component: specifies the fragment and passes the appropriate data to the CompanyQuote component.
 */
const CompanyQuoteData: FC<CompanyQuoteProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  return (
    <CompanyQuote
      className={className}
      heading={data.heading}
      subHeading={stringData(data.subHeading)}
      author={{
        name: stringData(data.authorName),
        position: stringData(data.authorPosition),
        company: stringData(data.authorCompany),
      }}
      quote={stringData(data.quote)}
      company={stringData(data.company)}
    />
  );
};

export default CompanyQuoteData;

export const query = graphql`
  fragment CompanyQuote on PrismicCompanyQuoteDefault {
    primary {
      heading {
        ...RichText
      }
      subHeading
      authorName
      authorPosition
      authorCompany
      quote
      company
    }
  }
`;
