import React, { FC } from 'react';
import styled from 'styled-components';
import { padding } from '../../../design/animations/display';
import { BodyP } from '../../../design/typography/Typography';
import { DetailContainer, InfoKey } from '../../events/EventHeader/EventDetails';

type KeyTextInfoProps = {
  location?: string;
  industry?: string;
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 4px 16px;
  ${padding({ y: '24px' })}
`;

/**
 * Renders Header Info to sit in the Header of the Customer Story Page.
 */
const KeyTextInfo: FC<KeyTextInfoProps> = ({ location, industry }) => {
  return (
    <DetailContainer hideBottomLine={false}>
      <Container>
        {location && (
          <>
            <InfoKey type={{ base: 'bodyMBold', breakpointXSmall: 'bodyLBold' }}>Location</InfoKey>
            <BodyP type={{ base: 'bodyMRegular', breakpointXSmall: 'bodyLRegular' }}>{location}</BodyP>
          </>
        )}
        {industry && (
          <>
            <InfoKey type={{ base: 'bodyMBold', breakpointXSmall: 'bodyLBold' }}>Industry</InfoKey>
            <BodyP type={{ base: 'bodyMRegular', breakpointXSmall: 'bodyLRegular' }}>{industry}</BodyP>
          </>
        )}
      </Container>
    </DetailContainer>
  );
};

export default KeyTextInfo;
