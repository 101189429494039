import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Sponsors from '../Sponsors';

type SponsorsDataProps = SliceProps<'sponsors', SliceVariation<'default', Queries.SponsorsFragment['primary']>> &
  WithClassName;

/**
 * Sponsors data component.
 */
export const SponsorsData: FC<SponsorsDataProps> = ({ className, slice }) => (
  <Sponsors className={className} title={slice?.primary?.title} items={slice?.items} />
);

export const query = graphql`
  fragment Sponsors on PrismicSponsorsDefault {
    items {
      sponsor {
        alt
        gatsbyImageData(height: 48)
      }
      sponsorText: text {
        ...RichText
      }
    }
    primary {
      title {
        ...RichText
      }
    }
  }
`;
