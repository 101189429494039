import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { PrismicLinkType } from '../../../components/link/data/Link.generateData';
import { PrismicRichTextType } from '../../../components/richText/RichText';
import { expandClosedStyles, flex, toggleExpand } from '../../../design/animations/display';
import { WithClassName } from '../../../utilityTypes';
import { FormattedFeatures } from '../FeaturePricingComparison';
import { FeatureHeadingToggle } from '../components/FeatureHeadingToggle';
import FeatureRow, { Tier } from '../components/FeatureRow';
import { DefinedFeaturePricingComparisonItem } from '../types';

export type FeaturePricingComparisonProps = {
  heading: PrismicRichTextType;
  links: { basic: PrismicLinkType; premium: PrismicLinkType; enterprise: PrismicLinkType };
  features: DefinedFeaturePricingComparisonItem[];
} & WithClassName;

type ToggleProps = { open: boolean; maxHeight?: number };
export const Collapsable = styled.div<ToggleProps>`
  ${expandClosedStyles}
  ${({ open, maxHeight }) => maxHeight && toggleExpand(open, maxHeight)}
`;

const TableContainer = styled.div`
  ${flex({ direction: 'column', gap: '40px' })};
  padding: 40px 0;
`;

export const CenteredContainer = styled.div`
  max-width: 1648px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
`;

/**
 * Renders FeaturePricingComparison slice.
 */
const FeatureList: FC<{ features: FormattedFeatures; activeMobileTier?: Tier }> = ({ features, activeMobileTier }) => {
  const [collapsedRows, setCollapsedRows] = useState<string[]>([]);
  const lineItems = useRef<HTMLHeadingElement>(null);
  const scrollHeight = 1000;

  const toggleExpand = (feautureIndex: string) => {
    const currentCollapsed = [...collapsedRows];
    const featureCollapsed = currentCollapsed.includes(feautureIndex);

    const collapse = () => {
      currentCollapsed.push(feautureIndex);
      return currentCollapsed;
    };

    const expand = () => currentCollapsed.filter((item) => item !== feautureIndex);
    const newValue = featureCollapsed ? expand() : collapse();
    setCollapsedRows(newValue);
  };

  return (
    <TableContainer>
      {features.map((featureItem, index) => {
        const { title, features } = featureItem;
        const open = !collapsedRows.includes(index.toFixed());
        if (!title) return null;
        return (
          <div key={`feature-${index}`}>
            <FeatureHeadingToggle title={title} open={open} onClick={() => toggleExpand(index.toFixed())} />
            <Collapsable open={open} ref={lineItems} maxHeight={scrollHeight}>
              {features &&
                features.map((feature) => {
                  return (
                    <FeatureRow
                      {...feature}
                      key={feature?.feature_title}
                      basic={feature?.starter}
                      mobileActiveTier={activeMobileTier}
                    />
                  );
                })}
            </Collapsable>
          </div>
        );
      })}
    </TableContainer>
  );
};

export default FeatureList;
