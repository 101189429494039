import classNames from 'classnames';
import { cloneElement, ReactElement } from 'react';

type CloneChildProps = { className?: string; children: ReactElement };
/**
 * Applies all props provided to it, to it's child.
 */
const CloneChild = ({ className, children }: CloneChildProps) =>
  cloneElement(children, { className: classNames(children.props.className, className) });

export default CloneChild;
