import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { WithClassName, WithTestId } from '../../../utilityTypes';
import { flex } from '../../animations/display';
import { breakpointSmall } from '../../responsive/breakpoints';
import BaseColumnSmallRowContainer from '../responsive/BaseColumnSmallRowContainer';

type Props = { cta1Placement: ReactElement; cta2Placement: ReactElement } & WithClassName & WithTestId;
const Container = styled(BaseColumnSmallRowContainer)`
  ${flex({ direction: 'column', gap: '16px' })}
  ${breakpointSmall(css`
    ${flex({ direction: 'row' })}
  `)}
`;
/**
 * Renders two CTA buttons vertically stacked on mobile, horizontally on desktop.
 */
const TwoCtaPlacementContainer: FC<Props> = ({
  className,
  cta1Placement,
  cta2Placement,
  'data-testid': testId = 'two-cta-placement-container',
}) => (
  <Container className={className} data-testid={testId}>
    {cta1Placement}
    {cta2Placement}
  </Container>
);

export default TwoCtaPlacementContainer;
