import { css } from 'styled-components';
import { standardTransition } from '../../design/animations/transitions';
import { brand1, brand1Dark, brand1DarkAlt, brand4 } from '../../design/colors/brand';
import { grey400, grey500 } from '../../design/colors/greyscale';
import { error, successDark } from '../../design/colors/system';
import { primaryTypeStyle } from '../../design/components/button/styles/buttonType/brand/primary/primary';
import { largeStyles } from '../../design/components/button/styles/size/large/large';
import { spacings } from '../../design/spacing/Spacing';
import { getTypographyStyles } from '../../design/typography/Typography';
import { fontFamilyStyles } from '../../design/typography/base/Text';

const highSpecificityClass = (className: string) => `.${className}.${className}.${className}`;

export const form = highSpecificityClass('hs-form');
export const heading1 = 'h1';
export const heading2 = 'h2';
export const heading3 = 'h3';
export const label = 'label';
export const button = highSpecificityClass('hs-button');
export const listItem = 'li';
export const inputWrapper = highSpecificityClass('input');
export const inputElement = highSpecificityClass('hs-input');
export const inputError = `${inputElement}${highSpecificityClass('error')}`;
export const checkboxAndLabelWrapper = highSpecificityClass('hs-form-booleancheckbox-display');
export const checkboxElement = `${inputElement}[type='checkbox']`;
export const formField = highSpecificityClass('hs-form-field');
export const submitButtonContainer = highSpecificityClass('hs-submit');
export const cookieResetContainer = highSpecificityClass('cookie-reset-container');
export const errorMessage = highSpecificityClass('hs-error-msg');
export const errorMessageList = highSpecificityClass('hs-error-msgs');
export const errorsSummary = highSpecificityClass('hs_error_rollup');
export const formColumns1 = highSpecificityClass('form-columns-1');
export const formColumns2 = highSpecificityClass('form-columns-2');
export const submittedMessage = highSpecificityClass('submitted-message');

const checkboxSize = 22;
const checkThickness = 3;
const checkboxBackground = `${checkboxElement}:before`;
const checkboxCheckedBackground = `${checkboxElement}:checked:before`;
const checkboxForeground = `${checkboxElement}:after`;
const checkboxCheckedForeground = `${checkboxElement}:checked:after`;
const checkboxStyles = css`
  ${checkboxAndLabelWrapper} {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: ${spacings['32px']};
    cursor: pointer;
  }

  ${checkboxElement} {
    position: relative;
    appearance: none;
    width: ${checkboxSize}px;
    height: ${checkboxSize}px;
    margin: 0;
    margin-right: ${spacings['8px']};
    border: none;
  }

  ${checkboxBackground} {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${grey400};
    border-radius: 4px;
    background-color: transparent;
    transition: background-color ${standardTransition}, border-color ${standardTransition};
  }

  ${checkboxCheckedBackground} {
    border-color: ${brand1DarkAlt};
    background-color: ${brand1};
  }

  ${checkboxForeground} {
    content: ' ';
    display: block;
    position: absolute;
    border: ${checkThickness}px solid ${brand4};
    border-right: none;
    border-top: none;
    height: 35%;
    width: 60%;
    left: 30%;
    top: 45%;
    transform: rotate(-45deg);
    transform-origin: left;
    opacity: 0;
    transition: opacity ${standardTransition};
  }

  ${checkboxCheckedForeground} {
    opacity: 1;
  }
`;

export const hubspotFormStyles = css`
  ${fontFamilyStyles}
  ${getTypographyStyles('bodyMRegular')}
  line-height: 100%;
  color: ${brand4};

  ${form} {
    display: flex;
    flex-direction: column;
  }

  ${heading1} {
    ${getTypographyStyles('heading1')}
  }

  ${heading2} {
    ${getTypographyStyles('heading2')}
  }

  ${heading1}, ${heading2} {
    margin-bottom: ${spacings['32px']};
  }

  ${label} {
    ${getTypographyStyles('bodyMRegular')}
    line-height: 100%;
    display: flex;
    margin-bottom: ${spacings['8px']};
  }

  ${cookieResetContainer} {
    ${getTypographyStyles('bodyXSRegular')}
    margin: 0;
    margin-bottom: ${spacings['16px']};
  }

  ${formColumns1} ${inputWrapper} {
    margin: 0;
  }

  ${formColumns2} ${inputWrapper} {
    margin: 0 ${spacings['8px']} 0 0;
  }

  ${formColumns2} ${formField}:last-child ${inputWrapper} {
    margin: 0;
  }

  ${formColumns1},
  ${formColumns2} {
    width: 100%;
  }

  ${button} {
    ${primaryTypeStyle()}
    ${largeStyles()}
    line-height: 100%;
    cursor: pointer;
  }

  ${inputElement} {
    ${getTypographyStyles('bodyMRegular')}
    line-height: 100%;
    height: 42px;
    width: 100%;
    margin-bottom: ${spacings['32px']};
    border: 1px solid ${grey500};
    border-radius: ${spacings['4px']};
    padding: ${spacings['12px']};
    background-color: inherit;

    &:focus {
      border: 1px solid ${brand1Dark};
    }
  }

  ${checkboxStyles}

  ${inputError} {
    border: 1px solid ${error};
    margin-bottom: ${spacings['12px']};
  }

  ${errorMessage} {
    width: 100%;
    margin-bottom: ${spacings['40px']};
    margin-top: 0;
  }

  ${errorMessageList} {
    margin-bottom: -${spacings['24px']};
  }

  ${errorMessage},
  ${errorsSummary} {
    color: ${error};
  }

  ${listItem} {
    margin-bottom: ${spacings['40px']};
  }

  ${submittedMessage} {
    color: ${successDark};
  }
`;
