import React, { FC } from 'react';
import styled from 'styled-components';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { spacings } from '../../../design/spacing/Spacing';

const Title = styled(RichText)`
  padding-bottom: ${spacings['24px']};
`;

const ColumnContainer = styled.div`
  max-width: 564px;
  width: 100%;
`;

type ColumnProps = {
  heading?: PrismicRichTextType;
  text?: PrismicRichTextType;
};
/**
 * Renders TextColumn component.
 */
const TextColumn: FC<ColumnProps> = ({ heading, text }) => {
  return (
    <ColumnContainer>
      {heading && <Title textAlign="left" textType="heading2" field={heading} />}
      {text && <RichText textType="bodyLRegular" field={text} />}
    </ColumnContainer>
  );
};
export default TextColumn;
