import styled, { css } from 'styled-components';
import Link, { LinkProps } from '../../components/link/Link';
import { breakpointSmall } from '../responsive/breakpoints';
import generateResponsiveStyles, { ResponsiveValue } from '../responsive/generateResponsiveStyles';
import Text, { textStyles } from './base/Text';

const Body = styled(Text)``;
const Heading = styled(Text)``;
Heading.defaultProps = { textAlign: 'center' };

const heading1StyleOptions = { fontWeight: '500', fontSize: '40pt', letterSpacing: '-1%', lineHeight: '110%' } as const;
const heading2StyleOptions = { fontWeight: '400', fontSize: '36pt', letterSpacing: '0', lineHeight: '110%' } as const;
const heading3StyleOptions = { fontWeight: '400', fontSize: '24pt', letterSpacing: '0', lineHeight: '110%' } as const;

export const headingStyles = {
  display: textStyles({ fontWeight: '400', fontSize: '100pt', letterSpacing: '-4%', lineHeight: '110%' }),
  heading1: css`
    ${textStyles(heading1StyleOptions)}

    ${breakpointSmall(css`
      ${textStyles({ ...heading1StyleOptions, fontSize: '64pt', lineHeight: '100%' })}
    `)}
  `,
  heading2: css`
    ${textStyles(heading2StyleOptions)}

    ${breakpointSmall(css`
      ${textStyles({ ...heading2StyleOptions, fontSize: '48pt' })}
    `)}
  `,
  heading1Mobile: css`
    ${textStyles(heading1StyleOptions)}

    ${breakpointSmall(css`
      ${textStyles({ ...heading1StyleOptions, fontSize: '40pt' })}
    `)}
  `,
  heading3: css`
    ${textStyles(heading3StyleOptions)}

    ${breakpointSmall(css`
      ${textStyles({ ...heading3StyleOptions, fontSize: '32pt' })}
    `)}
  `,
  heading3Bold: css`
    ${textStyles(heading3StyleOptions)}

    ${breakpointSmall(css`
      ${textStyles({ ...heading3StyleOptions, fontWeight: '500', fontSize: '32pt' })}
    `)}
  `,
  heading4: textStyles({ fontWeight: '400', fontSize: '24pt', letterSpacing: '0', lineHeight: '120%' }),
  heading4Bold: textStyles({ fontWeight: '600', fontSize: '24pt', letterSpacing: '0', lineHeight: '120%' }),
  heading4Medium: textStyles({ fontWeight: '500', fontSize: '24pt', letterSpacing: '0', lineHeight: '120%' }),
  heading5Medium: textStyles({ fontWeight: '500', fontSize: '22pt', letterSpacing: '0', lineHeight: '120%' }),
  numbersLight: textStyles({ fontWeight: '300', fontSize: '64pt', letterSpacing: '0', lineHeight: '100%' }),
  numbersMedium: textStyles({ fontWeight: '500', fontSize: '64pt', letterSpacing: '0', lineHeight: '100%' }),
};

export const bodyStyles = {
  bodyXLRegular: textStyles({ fontWeight: '400', fontSize: '22pt', letterSpacing: '0', lineHeight: '120%' }),
  bodyLBold: textStyles({ fontWeight: '600', fontSize: '18pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyLMedium: textStyles({ fontWeight: '500', fontSize: '18pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyLRegular: textStyles({ fontWeight: '400', fontSize: '18pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyMBold: textStyles({ fontWeight: '600', fontSize: '16pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyMMedium: textStyles({ fontWeight: '500', fontSize: '16pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyMRegular: textStyles({ fontWeight: '400', fontSize: '16pt', letterSpacing: '0', lineHeight: '140%' }),
  bodySBold: textStyles({ fontWeight: '600', fontSize: '14pt', letterSpacing: '0', lineHeight: '140%' }),
  bodySMedium: textStyles({ fontWeight: '500', fontSize: '14pt', letterSpacing: '0', lineHeight: '140%' }),
  bodySRegular: textStyles({ fontWeight: '400', fontSize: '14pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyXSBold: textStyles({ fontWeight: '600', fontSize: '12pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyXSMedium: textStyles({ fontWeight: '500', fontSize: '12pt', letterSpacing: '0', lineHeight: '140%' }),
  bodyXSRegular: textStyles({ fontWeight: '400', fontSize: '12pt', letterSpacing: '0', lineHeight: '140%' }),
  quotes: css`
    ${textStyles({ fontWeight: '400', fontSize: '22pt', letterSpacing: '0', lineHeight: '140%' })}

    ${breakpointSmall(css`
      ${textStyles({ fontWeight: '400', fontSize: '32pt', letterSpacing: '-1%', lineHeight: '140%' })}
    `)}
  `,
};

const typographyStyles = { ...headingStyles, ...bodyStyles };

export type HeadingStyleType = keyof typeof headingStyles;
export type BodytyleType = keyof typeof bodyStyles;
export type TypographyStyleType = keyof typeof typographyStyles;
export type TypographyType = ResponsiveValue<TypographyStyleType>;
export type TypographyProps = { type?: TypographyType; color?: string };

/**
 * Gets typography styles that can be mixed in to any styled component. Can be a responsive value.
 *
 * @example
 * // Standard
 * responsiveTextStyles('bodyMRegular')
 *
 * // Responsive
 * responsiveTextStyles({ base: 'bodyMRegular', breakpointSmall: 'heading4' })
 */
export const getTypographyStyles = (type?: TypographyType) => css`
  ${generateResponsiveStyles(type || 'bodyMRegular', (t) => typographyStyles[t])}
`;

export const BodySpan = styled(Body).attrs({ element: 'span' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;

export const BodyStrong = styled(Body).attrs({ element: 'strong' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;

export const BodyEm = styled(Body).attrs({ element: 'em' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;

export const BodyP = styled(Body).attrs({ element: 'p' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;

export const BodyLink = styled(Body).attrs({ element: Link })<TypographyProps & LinkProps>`
  ${(props) => getTypographyStyles(props.type)}
`;

export const Display = styled(Heading).attrs({ element: 'h1' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;
Display.defaultProps = { type: 'display' };

export const Heading1 = styled(Heading).attrs({ element: 'h1' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;
Heading1.defaultProps = { type: 'heading1' };

export const Heading2 = styled(Heading).attrs({ element: 'h2' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;
Heading2.defaultProps = { type: 'heading2' };

export const Heading3 = styled(Heading).attrs({ element: 'h3' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;
Heading3.defaultProps = { type: 'heading3' };

export const Heading4 = styled(Heading).attrs({ element: 'h4' })<TypographyProps>`
  ${(props) => getTypographyStyles(props.type)}
`;
Heading4.defaultProps = { type: 'heading4' };
