import { css } from 'styled-components';

export const mediumShadowStyles = css`
  box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.2);
`;

export const bigShadowStyles = css`
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
`;

export const blogHeaderShadow = css`
  box-shadow: 0 -48px 0px -15px rgb(6, 68, 78, 0.2);
`;

export const videoEmbedHeaderShadow = blogHeaderShadow;

export const blogWysiwygShadow = css`
  box-shadow: 0px 4px 12px rgba(120, 144, 156, 0.2), 0px 15px 20px rgba(120, 144, 156, 0.01);
`;

export const cardShadowsSubtle = css`
  box-shadow: 1px 1px 10px rgba(51, 49, 49, 0.06);
`;
export const typesFilterShadow = css`
  box-shadow: 0px 4px 10px rgba(38, 44, 94, 0.07);
`;

export const stickyHeaderShadow = css`
  box-shadow: 0px 1.8518518209457397px 3.1481480598449707px 0px #00000004,
    0px 8.148148536682129px 6.518518447875977px 0px #00000007, 0px 20px 13px 0px #00000009,
    0px 38.51852035522461px 25.481481552124023px 0px #0000000b,
    0px 64.81481170654297px 46.85185241699219px 0px #0000000e, 0px 100px 80px 0px #00000012;
`;

export const planDropDownShadow = css`
  box-shadow: 0px 15px 20px 0px #78909c03, 0px 4px 12px 0px #78909c33;
`;
