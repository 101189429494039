import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../../design/animations/display';
import { grey400, grey100, grey300, white } from '../../../../design/colors/greyscale';
import { spacings } from '../../../../design/spacing/Spacing';
import { getTypographyStyles, BodyP } from '../../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../../design/typography/base/Text';
import GreyChevron from '../../../../images/react-icons/GreyChevron.inline.svg';
import { RawImage } from '../../../image/rawImage/RawImage';
import { Currency } from '../types';
import { currencyAssets } from '../utils';

type CalculatorDropdownProps = {
  currency: Currency;
  onChangeCurrency?: (currency: Currency) => void;
};

const inputStyles = () => css`
  padding: ${spacings['16px']};
  background-color: ${grey100};
  ${fontFamilyStyles};
  ${getTypographyStyles('bodyLMedium')}
  position: relative;
`;

const DropDownButton = styled.button`
  ${flex({ align: 'center', gap: '12px' })};
  ${inputStyles()};
  border-left: 1px solid ${grey400};
  border-radius: 0 ${spacings['4px']} ${spacings['4px']} 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const FlagImage = styled(RawImage)`
  height: ${spacings['24px']};
  width: ${spacings['32px']};
`;

const Dropdown = styled.ul`
  position: absolute;
  width: max-content;
  right: 0;
  top: 100%;
  border-radius: ${spacings['4px']};
  border: 1px solid ${grey400};
  overflow: hidden;
  z-index: 9;
`;

const Option = styled.li<{ active: boolean }>`
  ${flex({ align: 'center', gap: '12px' })};
  ${inputStyles()};
  white-space: nowrap;
  width: 100%;
  border-bottom: ${grey400} 1px solid;
  transition: background-color 0.2s ease;
  cursor: pointer;
  ${({ active }) => (active ? `background-color: ${grey300}` : `background-color: ${white};`)};
  &:hover {
    background: ${grey300};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
`;

/**
 * Render CalculatorDropdown component.
 */
export const CalculatorDropdown: FC<CalculatorDropdownProps> = ({ onChangeCurrency, currency }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const currencies = Object.entries(currencyAssets).filter(([key]) => key !== 'AUD'); // hard coded for v1;

  const onChange = (currency: Currency) => {
    onChangeCurrency && onChangeCurrency(currency);
    setDropdownOpen(false);
  };

  const onClick = () => setDropdownOpen(onChangeCurrency ? !dropdownOpen : false);

  const handleClickOutside = (event: MouseEvent) =>
    !ref?.current?.contains(event.target as Node) && setDropdownOpen(false);

  useEffect(() => {
    addEventListener('mousedown', handleClickOutside);
    return () => removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Container data-testid="currency-dropdown" ref={ref}>
      <DropDownButton
        data-testid="currency-dropdown-button"
        onClick={onClick}
        disabled={onChangeCurrency === undefined}
      >
        <FlagImage src={currencyAssets[currency].flag} alt={currency} />
        <BodyP type="bodyLMedium">{currency}</BodyP>
        {onChangeCurrency ? <GreyChevron /> : <div style={{ width: '16px' }} />}
      </DropDownButton>
      {dropdownOpen && (
        <Dropdown data-testid="currency-dropdown-options">
          {currencies.map(([key, { flag, label }]) => (
            <Option key={key} onClick={() => onChange(key as Currency)} active={key === currency}>
              <FlagImage src={flag} alt={key} />
              <BodyP>{label}</BodyP>
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};
