export const hyperlink = '#266ABF';

export const error = '#E5460B';
export const errorDark = '#D13B15';
export const errorMid = '#FFBEA6';
export const errorMid2 = '#FFDCCF';
export const errorLight = '#FFECE6';

export const warning = '#FFA812';
export const warningDark = '#F58300';
export const warningMid = '#FFD080';
export const warningMid2 = '#FFE8BF';
export const warningLight = '#FFF4E0';

export const success = '#50BE3D';
export const successDark = '#0D9B0D';
export const successMid = '#AFE5A5';
export const successMid2 = '#CBEBC5';
export const successLight = '#E7F7E7';
