import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Carousel } from '../../components/carousel/Carousel';
import { flex } from '../../design/animations/display';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import { Heading2 } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
import Speaker, { SpeakerProps } from './speaker/Speaker';

type SpeakersProps = {
  speakersTitle: string | null;
  speakers: SpeakerProps[];
} & WithClassName;

const SpeakersContainer = styled.div`
  ${flex({ justify: 'center', direction: 'column', align: 'center', gap: '40px' })}
  max-width: 1280px;
  width: 100%;
  ${breakpointSmall(css`
    ${flex({ direction: 'row', gap: '64px' })}
    flex-wrap: wrap;
  `)}
`;

const getDefaultTitle = (speakers: SpeakerProps[]) =>
  speakers.length === 1 ? 'Meet your speaker' : 'Meet your speakers';

const Container = styled(SliceContainer)`
  overflow: hidden;
`;

Container.defaultProps = { background: 'brand4GradientMidnightCardLeft', borderRadius: '32px' };
/**
 * Speakers presentation component.
 */
const Speakers: FC<SpeakersProps> = ({ className, speakersTitle, speakers }) => {
  const title = speakersTitle ? speakersTitle : getDefaultTitle(speakers);
  const useCarousel = speakers.length > 3;

  return (
    <Container className={className}>
      <CenteredSliceContentContainer>
        <Spacing bottom="48px">
          <Heading2>{title}</Heading2>
        </Spacing>
        {useCarousel ? (
          <Carousel>
            {speakers.map((speaker, index) => (
              <Speaker key={index} {...speaker} inCarousel />
            ))}
          </Carousel>
        ) : (
          <SpeakersContainer>
            {speakers.map((speaker, index) => (
              <Speaker key={index} {...speaker} />
            ))}
          </SpeakersContainer>
        )}
      </CenteredSliceContentContainer>
    </Container>
  );
};

export default Speakers;
