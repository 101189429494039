import { LinkProps as PrismicLinkProps, PrismicLink } from '@prismicio/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import { WithClassName, WithTestId } from '../../utilityTypes';

/**
 * Gatsby Link component.
 */
export const InternalLink: FC<PrismicLinkProps> = ({ href, ...props }) => <GatsbyLink to={href} {...props} />;

export type LinkProps = PropsWithChildren<{ link?: Queries.PrismicLinkFragment | null }> & WithClassName & WithTestId;
/**
 * Renders a link field from prismic with link_type="Web".
 */
const Link: FC<LinkProps> = ({ className, link, children, 'data-testid': testId }) =>
  link?.link_type ? (
    <PrismicLink
      className={className}
      field={{ ...link, link_type: link.link_type }}
      internalComponent={InternalLink}
      data-testid={testId}
    >
      {children}
    </PrismicLink>
  ) : null;

export default Link;
