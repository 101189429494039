import { css } from 'styled-components';
import { brand1, brand2, brand4 } from '../colors/brand';
import { breakpointXSmall } from '../responsive/breakpoints';

/**
 * Changes color of browser text background when highlighted.
 */
export const textSelect = ({ color = brand4, background = brand1 }) => css`
  * {
    ::selection {
      background: ${background};
      color: ${color};
    }
  }
`;
/**
 * Element does gentle jump on hover.
 */
export const jump = ({ position = 'relative', height = '8px' }) => css`
  ${breakpointXSmall(
    css`
      position: ${position};
      top: 0;
      transition: 0.3s ease top;
      &:hover {
        top: -${height};
      }
    `
  )};
`;
/**
 * Element gently grows on hover.
 */
export const grow = ({ amount = 1.045 }) => css`
  ${breakpointXSmall(
    css`
      transition: transform 0.2s ease;
      &:hover {
        transform: scale(${amount});
      }
    `
  )};
`;

/**
 * Gently fills arrow and slides right.
 */
export const arrowHover = () => css`
  transform: translateX(5px);
  * {
    stroke: ${brand2};
  }
`;
/**
 * Generates gentle fade-up-down style.
 */
export const toggleFadeUp = (fadeUp?: boolean) => css`
  transition: opacity 0.5s ease, transform 0.3s ease;
  ${fadeUp
    ? css`
        transform: translateY(0);
        opacity: 1;
      `
    : css`
        transform: translateY(4px);
        opacity: 0;
      `}
`;
