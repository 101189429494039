import React, { FC } from 'react';
import Icon from '../../icons/Icon';

/**
 * Renders 5 brand1 stars.
 */
const FiveStars: FC = () => {
  return (
    <div>
      <Icon type="Star" />
      <Icon type="Star" />
      <Icon type="Star" />
      <Icon type="Star" />
      <Icon type="Star" />
    </div>
  );
};

export default FiveStars;
