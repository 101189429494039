import approvals from '../../../images/icons/Approvals.svg';
import approvalsReverse from '../../../images/icons/ApprovalsReverse.svg';
import approve from '../../../images/icons/Approve.svg';
import approveReverse from '../../../images/icons/ApproveReverse.svg';
import auditTrailsReverse from '../../../images/icons/Audit Trails Reverse.svg';
import auditTrails from '../../../images/icons/Audit Trails.svg';
import billPayments from '../../../images/icons/Bill Payments.svg';
import bulletsReverse from '../../../images/icons/BulletsReverse.svg';
import cardControlsReverse from '../../../images/icons/Card Controls Reverse.svg';
import cardControls from '../../../images/icons/Card Controls.svg';
import cards from '../../../images/icons/Cards.svg';
import cashFlow from '../../../images/icons/Cash Flow.svg';
import check from '../../../images/icons/Check.svg';
import checkReverse from '../../../images/icons/CheckReverse.svg';
import code from '../../../images/icons/Code.svg';
import codeReverse from '../../../images/icons/CodeReverse.svg';
import connect from '../../../images/icons/Connect.svg';
import connectReverse from '../../../images/icons/ConnectReverse.svg';
import creditCardReverse from '../../../images/icons/CreditCardReverse.svg';
import dollar from '../../../images/icons/Dollar.svg';
import feeds from '../../../images/icons/Feeds.svg';
import integrations from '../../../images/icons/Integrations.svg';
import learn from '../../../images/icons/Learn.svg';
import learnReverse from '../../../images/icons/LearnReverse.svg';
import listCheckReverse from '../../../images/icons/ListChecksReverse.svg';
import phone from '../../../images/icons/Phone.svg';
import receiptReverse from '../../../images/icons/ReceiptReverse.svg';
import reconcile from '../../../images/icons/Reconcile.svg';
import reimbursements from '../../../images/icons/Reimbursements.svg';
import sheild from '../../../images/icons/Sheild.svg';
import spendingPolicy from '../../../images/icons/Spending Policy.svg';
import star from '../../../images/icons/Star.svg';
import subscriptions from '../../../images/icons/Subscriptions.svg';
import toggleReverse from '../../../images/icons/ToggleRightReverse.svg';
import upload from '../../../images/icons/Upload.svg';
import uploadReverse from '../../../images/icons/UploadReverse.svg';
import user from '../../../images/icons/User.svg';
import workflows from '../../../images/icons/Workflows.svg';

export const marketingIcons = {
  Approvals: approvals,
  Approve: approve,
  'Audit Trails': auditTrails,
  'Bill Payments': billPayments,
  Bullets: bulletsReverse,
  'Card Controls': cardControls,
  Cards: cards,
  'Cash Flow': cashFlow,
  Check: check,
  Code: code,
  Connect: connect,
  'Credit Card': creditCardReverse,
  Dollar: dollar,
  Feeds: feeds,
  Integrations: integrations,
  Learn: learn,
  'List Check': listCheckReverse,
  Phone: phone,
  Reconcile: reconcile,
  Receipt: receiptReverse,
  Reimbursements: reimbursements,
  Sheild: sheild,
  'Spending Policy': spendingPolicy,
  Star: star,
  Subscriptions: subscriptions,
  Toggle: toggleReverse,
  Upload: upload,
  User: user,
  Workflows: workflows,
};

export const marketingIconsReverse = {
  Approvals: approvalsReverse,
  Approve: approveReverse,
  'Audit Trails': auditTrailsReverse,
  'Bill Payments': billPayments,
  Bullets: bulletsReverse,
  'Card Controls': cardControlsReverse,
  Cards: cards,
  'Cash Flow': cashFlow,
  Check: checkReverse,
  Code: codeReverse,
  Connect: connectReverse,
  'Credit Card': creditCardReverse,
  Dollar: dollar,
  Feeds: feeds,
  Integrations: integrations,
  Learn: learnReverse,
  'List Check': listCheckReverse,
  Phone: phone,
  Reconcile: reconcile,
  Receipt: receiptReverse,
  Reimbursements: reimbursements,
  Sheild: sheild,
  'Spending Policy': spendingPolicy,
  Star: star,
  Subscriptions: subscriptions,
  Toggle: toggleReverse,
  Upload: uploadReverse,
  User: user,
  Workflows: workflows,
};
