import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { flex, grid, padding } from '../../../design/animations/display';
import { brand1 } from '../../../design/colors/brand';
import { grey400 } from '../../../design/colors/greyscale';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import Check from '../../../images/react-icons/Check.inline.svg';
import { PrismicImageData } from '../../../utilityTypes';

export const PricingRowContainer = styled.div`
  ${padding({ y: '12px' })}
  border-bottom: 1px solid ${grey400};
  ${flex({ justify: 'space-between', gap: '16px' })}
  ${breakpointSmall(
    css`
      ${grid({ columns: 4 })}
    `
  )};
`;

export const ComparisonCell = styled.div<{ showOnMobile?: boolean }>`
  ${({ showOnMobile }) => (showOnMobile ? flex({ justify: 'space-between', align: 'center' }) : 'display: none;')}
  ${breakpointSmall(css`
    ${flex({ justify: 'center', align: 'center' })}
  `)};
`;

const Icon = styled(PrismicImage)`
  max-height: 24px;
  max-width: 24px;
`;
export type Tier = 'basic' | 'premium' | 'enterprise';
export type FeatureRowProps = {
  enterprise?: boolean | null;
  feature_description?: string | null;
  feature_title?: string | null;
  premium?: boolean | null;
  basic?: boolean | null;
  icon?: PrismicImageData | null;
  mobileActiveTier?: Tier;
};

const Flex = styled.div`
  ${flex({ gap: '8px' })}
`;

export type DetailsColumnProps = Omit<FeatureRowProps, 'basic' | 'premium' | 'enterprise' | 'mobileActiveTier'>;
/**
 * Details column for feature pricing comparison.
 * + includes icon, title and description.
 */
export const DetailsColumn: FC<DetailsColumnProps> = ({
  feature_description: description,
  feature_title: title,
  icon,
}) => (
  <div data-testid="detail-column">
    <Spacing bottom="8px">
      <Flex>
        {icon && <Icon image={icon} objectFit="contain" />}
        <BodyP type="bodyLMedium" color={brand1}>
          {title}
        </BodyP>
      </Flex>
    </Spacing>
    {description && <BodyP type="bodySRegular">{description}</BodyP>}
  </div>
);

const CheckboxColumn: FC<{ checked: boolean; testId: string; showOnMobile?: boolean }> = ({
  checked,
  testId,
  showOnMobile,
}) => (
  <ComparisonCell showOnMobile={showOnMobile} data-testid={testId}>
    {checked && <Check data-testid="check-mark" />}
  </ComparisonCell>
);

/**
 * Renders FaqItem slice.
 */
const FeatureRow: FC<FeatureRowProps> = ({ enterprise, premium, basic, mobileActiveTier, ...details }) => {
  const tiers = { basic, premium, enterprise };
  return (
    <OnVisible fadeUp>
      <PricingRowContainer data-testid="pricing-feature-row">
        <DetailsColumn {...details} />
        {Object.entries(tiers).map(([tier, checked]) => (
          <CheckboxColumn
            key={tier}
            testId={`${tier}-column`}
            checked={!!checked}
            showOnMobile={mobileActiveTier === tier}
          />
        ))}
      </PricingRowContainer>
    </OnVisible>
  );
};

export default FeatureRow;
