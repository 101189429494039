import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import TwoColFloatingContent from '../TwoColFloatingContent';

type TwoColFloatingContentDataProps = SliceProps<
  'twoColFloatingContent',
  SliceVariation<'default', Queries.TwoColFloatingContentFragment['primary']>
> &
  WithClassName;
/**
 * Two Col Floating Content data.
 */
const TwoColFloatingContentData: FC<TwoColFloatingContentDataProps> = ({ className, slice }) => (
  <TwoColFloatingContent
    className={className}
    leftImage={slice.primary.leftImage}
    leftImageMobile={slice.primary.leftImageMobile}
    leftCardText={slice.primary.leftCardText || ''}
    leftCardTitle={slice.primary.leftCardTitle || ''}
    rightCardQuote={slice.primary.rightCardQuote || ''}
    rightCardAuthor={slice.primary.rightCardAuthor || ''}
    rightCardJobTitle={slice.primary.rightCardJobTitle || ''}
    rightCardCompany={slice.primary.rightCardCompany || ''}
    belowRightCardImage={slice.primary.belowRightCardImage}
    belowRightCardImageMobile={slice.primary.belowRightCardImageMobile}
  />
);

export default TwoColFloatingContentData;

export const query = graphql`
  fragment TwoColFloatingContent on PrismicTwoColFloatingContentDefault {
    id
    primary {
      leftCardText
      leftCardTitle
      rightCardQuote
      rightCardAuthor
      rightCardJobTitle
      rightCardCompany
      leftImage {
        ...Image
      }
      leftImageMobile {
        ...Image
      }
      belowRightCardImage {
        ...Image
      }
      belowRightCardImageMobile {
        ...Image
      }
    }
  }
`;
