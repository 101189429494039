import { FilledLinkToDocumentField } from '@prismicio/types';

export type EventsDoc = FilledLinkToDocumentField<
  string,
  string,
  Omit<Queries.PrismicEventsDataType, 'slices' | 'topics'>
>;
const getArticleTypePrefix = (isWebinar: boolean | undefined | null) =>
  isWebinar ? '/resources/the-weelhouse/webinars' : '/resources/the-weelhouse/events';

/**
 * Resolves links to guides.
 */
export const eventsLinkResolver = (doc: EventsDoc, url: string) =>
  `${getArticleTypePrefix(doc?.data?.is_webinar)}${url}`;
