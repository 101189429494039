import React, { FC } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { flex } from '../../../../design/animations/display';
import { brand2, brand3Light, brand3Mid, brand5 } from '../../../../design/colors/brand';
import { BodySpan } from '../../../../design/typography/Typography';

const brand2Theme = css`
  background: ${brand2};
  color: ${brand3Light};
`;
const brand3MidTheme = css`
  background: ${brand3Mid};
  color: ${brand3Light};
`;
const brand5Theme = css`
  background: ${brand5};
  color: ${brand2};
`;
export type TagTheme = 'brand2' | 'brand3Mid' | 'brand5';

const themes: Record<string, FlattenSimpleInterpolation> = {
  brand2: brand2Theme,
  brand3Mid: brand3MidTheme,
  brand5: brand5Theme,
};

const Tag = styled.div<Pick<TagProps, 'theme'>>`
  ${flex({ gap: '4px', align: 'center' })}
  ${({ theme }) => theme && themes[theme]};
  padding: 2px 6px;
  border-radius: 6px;
  max-width: fit-content;
`;

const TagsContainer = styled.div`
  ${flex({ gap: '4px', wrap: 'wrap' })}
`;

export const TagText = styled(BodySpan)``;
type TagProps = { topics: string[]; theme?: TagTheme };
/**
 * Topic tag for blog card.
 */
const TopicTags: FC<TagProps> = ({ topics, theme }) => {
  if (topics[0] === '' || topics.length === 0) return null;
  return (
    <TagsContainer>
      {topics.map((topic) => (
        <Tag key={topic} theme={theme} data-testid="card-topic">
          <TagText type="bodySRegular">{topic}</TagText>
        </Tag>
      ))}
    </TagsContainer>
  );
};

export default TopicTags;
