import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { WithClassName } from '../../utilityTypes';

const FallbackImage = styled.img`
  object-fit: cover;
`;

type VideoProps = {
  /**
   * The webm video source. Import the video as a file and provide that.
   */
  webmSrc: string;
  /**
   * The hevc video source. Import the video as a file and provide that.
   */
  hevcSrc: string;
  /**
   * The fallback image source. This will show if the browser is not capable of showing the videos.
   * Import it as a file and provide it. This MUST be the same dimensions as the videos or it will look funny.
   */
  fallbackImageSrc?: string;
} & WithClassName;
/**
 * Renders video with transparency. There is no one format that supports video with alpha
 * that is supported by all browsers. As a result, we need two videos, one for Chrome, one for Safari.
 *
 * Safari requires a HEVC encoded .mp4 file.
 * Chrome requires a VP9 encoded .webm file.
 *
 * This component requires both those files to work.
 */
export const TransparentVideo: FC<VideoProps> = ({ className, webmSrc, hevcSrc, fallbackImageSrc }) => {
  const [noCodecSupported, setNoCodecSupported] = useState(false);

  return noCodecSupported ? (
    <FallbackImage className={className} src={fallbackImageSrc} data-testid="fallback-image" />
  ) : (
    <video
      className={className}
      autoPlay
      muted
      loop
      disableRemotePlayback
      disablePictureInPicture
      playsInline
      data-testid="video"
    >
      <source src={hevcSrc} type='video/mp4; codecs="hvc1"' />
      <source src={webmSrc} type="video/webm" onError={() => setNoCodecSupported(true)} />
    </video>
  );
};
