import styled, { css, CSSProperties } from 'styled-components';
import { breakpointMedium } from '../../../design/responsive/breakpoints';
import CloneChild from '../../../design/utils/CloneChild';

type HiddenOnMobileProps = { display?: CSSProperties['display'] };
export const HiddenOnMobile = styled(CloneChild)<HiddenOnMobileProps>`
  display: none;

  ${({ display = 'block' }) =>
    breakpointMedium(css`
      display: ${display};
    `)}
`;

export const HiddenOnDesktop = styled(CloneChild)`
  ${breakpointMedium(css`
    display: none;
  `)}
`;
