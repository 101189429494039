import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../../components/image/prismicImage/PrismicImage';
import { brand1 } from '../../../../design/colors/brand';
import { Background, Panel } from '../../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../../design/spacing/Spacing';
import { BodyP, BodySpan } from '../../../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../../../utilityTypes';

const Card = styled(Panel)`
  grid-column: span 2;
  padding: ${spacings['24px']};
  ${breakpointSmall(
    css`
      grid-column: span 1;
      padding: ${spacings['48px']};
    `
  )};
`;
Card.defaultProps = { borderRadius: `${spacings['12px']}` };

const QuoteImage = styled(PrismicImage)``;
QuoteImage.defaultProps = { objectFit: 'contain', objectPosition: 'left' };

const Quote = styled(BodyP)`
  padding-bottom: ${spacings['32px']};
`;
Quote.defaultProps = { type: 'quotes', textAlign: 'left' };

const Name = styled(BodySpan)`
  color: ${brand1};
`;

type QuoteProps = {
  companyImage?: PrismicImageData;
  author?: string;
  company?: string;
  quote?: string;
  role?: string;
  background?: Background;
} & WithClassName;
/**
 * Renders QuoteCard Comp.
 */
const QuoteCard: FC<QuoteProps> = ({ className, companyImage, quote, author, role, company, background }) => (
  <Card className={className} background={background} data-testid="quote-card">
    {companyImage && (
      <Spacing bottom="24px">
        <QuoteImage image={companyImage} />
      </Spacing>
    )}
    {quote && <Quote>&quot;{quote}&quot;</Quote>}
    {author && <Name type="bodyLBold">{author}</Name>}
    {role && <BodySpan type="bodyLRegular"> | {role}</BodySpan>}
    {company && <BodySpan type="bodyLRegular">, {company}</BodySpan>}
  </Card>
);

export default QuoteCard;
