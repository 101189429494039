import { css } from 'styled-components';
import { fontFamilyStyles } from '../..//design/typography/base/Text';
import {
  form,
  inputWrapper,
  label,
  submittedMessage,
  button,
  errorMessage,
  errorMessageList,
  errorsSummary,
  listItem,
  heading3,
  formField,
} from '../../components/hubspotForm/hubspotFormStyles';
import { brand4 } from '../../design/colors/brand';
import { grey500, grey800, white } from '../../design/colors/greyscale';
import { error, hyperlink, successDark } from '../../design/colors/system';
import { primaryTypeStyle } from '../../design/components/button/styles/buttonType/brand/primary/primary';
import { largeStyles } from '../../design/components/button/styles/size/large/large';
import { spacings } from '../../design/spacing/Spacing';
import { getTypographyStyles } from '../../design/typography/Typography';

export const downloadGatedContentPopupStyles = css`
  ${heading3} {
    ${getTypographyStyles('heading3')}
    font-size: 32px;
    margin-bottom: ${spacings['24px']};
  }
  ${fontFamilyStyles}
  ${getTypographyStyles('bodyMRegular')}
  line-height: 100%;

  ${form} {
    display: flex;
    flex-direction: column;
  }

  ${formField} {
    margin-bottom: 20px;
  }

  p {
    ${getTypographyStyles('bodySRegular')};
    color: ${brand4};
    a,
    a:link,
    a:visited {
      color: ${hyperlink} !important;
    }
  }

  ${label} {
    ${getTypographyStyles('bodyMRegular')}
    line-height: 100%;
    display: flex;
    margin-bottom: 10px;
  }

  ${button} {
    ${primaryTypeStyle()}
    ${largeStyles()}
    line-height: 100%;
    cursor: pointer;
    width: fit-content;
    margin-top: ${spacings['24px']};
  }

  ${inputWrapper} {
    height: 40px;
    padding: 8px 10px;
    ${fontFamilyStyles} ${getTypographyStyles('bodyMRegular')};
    color: ${grey800};
    background-color: ${white};
    border: 1px solid ${grey500};
    border-radius: 4px;
    input {
      text-overflow: ellipsis;
      background-color: white !important;
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  ${errorMessage} {
    width: 100%;
    margin-top: 4px;
    margin-bottom: -2px;
  }

  ${errorMessageList} {
  }

  ${errorMessage} {
    color: ${error};
    font-size: 14px;
  }

  ${errorsSummary} {
    display: none;
  }

  ${listItem} {
  }

  ${submittedMessage} {
    color: ${successDark};
  }
`;
