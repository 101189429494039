import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink, ButtonToUse } from '../../design/components/button/Button';
import { Background } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall, breakpointMedium, breakpointLarge } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP, Heading4 } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';
import Card from './Card/Card';
import Panel from './Panel/Panel';

const FiveColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['24px']};
  ${breakpointSmall(
    css`
      grid-template-columns: repeat(2, 1fr);
    `
  )};
  ${breakpointMedium(
    css`
      grid-template-columns: repeat(3, 1fr);
    `
  )};
  ${breakpointLarge(
    css`
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: 1fr;
    `
  )};
`;

const HeadingContainer = styled.div`
  max-width: 814px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading: FC<HeadingData> = ({ heading, text, primaryCta, secondaryCta, secondaryCtaMobile }) => (
  <HeadingContainer>
    <Spacing bottom={text ? 'none' : '48px'}>
      <div>
        <Heading2>{heading}</Heading2>
        {text && (
          <Spacing top={text ? '16px' : 'none'}>
            <BodyP type="bodyLRegular" textAlign="center">
              {text}
            </BodyP>
          </Spacing>
        )}
      </div>
    </Spacing>
    {primaryCta && secondaryCta && secondaryCtaMobile && (
      <Spacing top="24px" bottom={{ base: '32px', breakpointSmall: '48px' }}>
        <TwoCtaPlacementContainer
          cta1Placement={
            <ButtonLink styleType="primary" link={primaryCta.link}>
              {primaryCta.text}
            </ButtonLink>
          }
          cta2Placement={<ButtonToUse styleType="secondary" desktopCta={secondaryCta} mobileCta={secondaryCtaMobile} />}
        />
      </Spacing>
    )}
  </HeadingContainer>
);

type CardData = {
  heading: string;
  text?: string;
  icon?: string | null;
};

type PanelData = {
  text?: PrismicRichTextType;
  cta?: LinkType;
  image?: PrismicImageData;
};

type HeadingData = {
  heading: PrismicRichTextType;
  text?: string | null;
  primaryCta?: LinkType | null;
  secondaryCta?: LinkType | null;
  secondaryCtaMobile?: LinkType | null;
};

type FiveColumnsPanelProps = {
  headingContent: HeadingData;
  cardOne: CardData;
  cardTwo: CardData;
  cardThree: CardData;
  cardFour: CardData;
  cardFive: CardData;
  panel?: PanelData;
  backgroundType?: Background;
} & WithClassName;
/**
 * Renders FiveColumnsPanel slice.
 */
const FiveColumnsPanel: FC<FiveColumnsPanelProps> = ({
  headingContent,
  cardOne,
  cardTwo,
  cardThree,
  cardFour,
  cardFive,
  panel,
  className,
  backgroundType,
}) => {
  return (
    <SliceContainer className={className} background={backgroundType}>
      <CenteredSliceContentContainer>
        <Heading
          heading={headingContent.heading}
          text={headingContent.text}
          primaryCta={headingContent.primaryCta}
          secondaryCta={headingContent.secondaryCta}
          secondaryCtaMobile={headingContent.secondaryCtaMobile}
        />
        <FiveColumnsWrapper>
          <Card {...cardOne} background={cardOne.icon ? 'whiteSolid' : 'brand1SolidWeelLogo'} />
          <Card {...cardTwo} background="brand2Solid" />
          <Card {...cardThree} background="whiteSolid" />
          <Card {...cardFour} background="brand3Solid" />
          <Card {...cardFive} background={cardFive.icon ? 'whiteSolid' : 'brand1SolidWeelLogo'} />
        </FiveColumnsWrapper>
        {panel && (
          <>
            <Spacing top="48px" bottom="48px">
              <Heading4>- or -</Heading4>
            </Spacing>
            <Panel {...panel} />
          </>
        )}
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default FiveColumnsPanel;
