import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utilityTypes';
import ContentCard from './ContentCard/ContentCard';
import ImageCard from './ImageCard/ImageCard';
import QuoteCard from './QuoteCard/QuoteCard';
import { Card } from './types';

export const TwoColumnsWrapper = styled.div`
  display: block;
  gap: ${spacings['24px']};
  display: flex;
  flex-direction: column;
`;

type RowProps = { reverse?: boolean };
const RowWrapper = styled.div<RowProps>`
  display: flex;
  gap: ${spacings['24px']};
  flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  ${breakpointSmall(
    css`
      flex-direction: row;
    `
  )};
`;

type GridAreaCardsProps = {
  cards?: Card[][];
} & WithClassName;
/**
 * Renders GridAreaCards slice.
 */
const GridAreaCards: FC<GridAreaCardsProps> = ({ cards, className }) => {
  return (
    <SliceContainer className={className} data-testid="cards-container">
      <CenteredSliceContentContainer>
        <TwoColumnsWrapper>
          {cards?.map((row, index) => {
            return (
              <RowWrapper reverse={index % 2 === 0} key={`grid-card-${index}`}>
                {row.map((item) => {
                  const { type } = item;
                  return (
                    <Fragment key={`grid-card-${type}-${index}`}>
                      {type === 'quote' && <QuoteCard {...item} data-testid="grid-area-card quote-card" />}
                      {type === 'image' && <ImageCard {...item} data-testid="grid-area-card image-card" />}
                      {type === 'content' && <ContentCard {...item} data-testid="grid-area-card content-card" />}
                    </Fragment>
                  );
                })}
              </RowWrapper>
            );
          })}
        </TwoColumnsWrapper>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default GridAreaCards;
