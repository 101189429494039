import React, { FC } from 'react';
import styled from 'styled-components';
import { PrismicLinkType } from '../../../components/link/data/Link.generateData';
import { PrismicIconType } from '../../../components/prismicIcon/types';
import { WithClassName } from '../../../utilityTypes';
import { flex } from '../../animations/display';
import { Panel } from '../../containers/panel/Panel';
import { spacings } from '../../spacing/Spacing';
import { BodyP, Heading3 } from '../../typography/Typography';
import { ButtonLink } from '../button/Button';
import CardTopper from './cardTopper/CardTopper';
import { CardBackground, CardType } from './types';

const CardContainer = styled(Panel).attrs({
  padding: '32px',
  borderRadius: `${spacings['12px']}`,
})<CardProps>`
  ${flex({ direction: 'column', gap: '24px' })}
`;

const TextContainer = styled.div`
  ${flex({ direction: 'column', gap: '4px' })}
`;

const CardContent: FC<Pick<CardProps, 'title' | 'content'>> = ({ title, content }) => {
  return (
    <TextContainer>
      {title && <Heading3 textAlign="left">{title}</Heading3>}
      {content && <BodyP type="bodyMRegular">{content}</BodyP>}
    </TextContainer>
  );
};

const Cta = styled(ButtonLink)`
  max-width: fit-content;
`;

export type CardProps = {
  title?: string;
  content?: string;
  cta?: PrismicLinkType;
  ctaText?: string;
  icon?: PrismicIconType;
  index?: number;
  cardType?: CardType;
  background?: CardBackground;
} & WithClassName;
/**
 * Renders Card comp.
 */
export const Card: FC<CardProps> = ({
  className,
  title,
  content,
  index = 0,
  icon,
  cardType,
  cta,
  ctaText = 'Read more',
  background,
}) => {
  const hasTop = !!cardType;
  const cardBackground = background || 'brandGlass';

  return (
    <CardContainer className={className} data-testid="card" index={index} background={cardBackground}>
      {hasTop && (
        <CardTopper data-testid={cardType || 'icon'} {...{ index, icon, cardType, background: cardBackground }} />
      )}
      {(title || content) && <CardContent title={title} content={content} />}
      {cta && (
        <Cta styleType="primary" size="medium" link={cta}>
          {ctaText}
        </Cta>
      )}
    </CardContainer>
  );
};
