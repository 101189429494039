import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { PrismicIconType } from '../../../components/prismicIcon/types';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import ThreeColumnCards from '../ThreeColumnCards';
import {
  DefaultThreeColumnCardsPrimary,
  DefaultThreeColumnCardsItem,
  NumberedThreeColumnCardsPrimary,
  NumberedThreeColumnCardsItem,
  AltNumberedThreeColumnCardsPrimary,
  AltNumberedThreeColumnCardsItem,
  StripedCurveThreeColumnCardsPrimary,
  StripedCurveThreeColumnCardsItem,
} from '../types';

type ThreeColumnCardsDataProps = SliceProps<
  'threeColumnCards',
  | SliceVariation<'default', DefaultThreeColumnCardsPrimary, DefaultThreeColumnCardsItem>
  | SliceVariation<'numberedCards', NumberedThreeColumnCardsPrimary, NumberedThreeColumnCardsItem>
  | SliceVariation<'numberedCardsAltBackground', AltNumberedThreeColumnCardsPrimary, AltNumberedThreeColumnCardsItem>
  | SliceVariation<'brand2GradientStripedCurve', StripedCurveThreeColumnCardsPrimary, StripedCurveThreeColumnCardsItem>
> &
  WithClassName;
/**
 * ThreeColumnCards data component: specifies the fragment and passes the appropriate data to the ThreeColumnCards component.
 */
const ThreeColumnCardsData: FC<ThreeColumnCardsDataProps> = ({ className, slice }) => {
  const items =
    slice.items?.map(({ heading, text, ctaButtonText, ctaLink, cardImage, ...others }) => ({
      heading: heading || '',
      text: text || '',
      cta: { text: ctaButtonText || '', link: ctaLink },
      icon: ('icon' in others && others.icon) || undefined,
      linked_icon: (('linked_icon' in others && others.linked_icon) as PrismicIconType) || undefined,
      cardImage,
    })) || [];

  return (
    <ThreeColumnCards
      className={className}
      heading={slice.primary.heading}
      text={slice.primary.text || ''}
      items={items}
      variation={slice.variation || 'default'}
    />
  );
};

export default ThreeColumnCardsData;

export const query = graphql`
  fragment ThreeColumnCardsDefault on PrismicThreeColumnCardsDefault {
    primary {
      heading {
        ...RichText
      }
      text
    }
    items {
      cardImage {
        alt
        gatsbyImageData
      }
      icon
      linked_icon {
        ...Icon
      }
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment ThreeColumnCardsNumberedCards on PrismicThreeColumnCardsNumberedCards {
    primary {
      heading {
        ...RichText
      }
      text
    }
    items {
      cardImage {
        ...Image
      }
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment ThreeColumnCardsNumberedCardsAltBackground on PrismicThreeColumnCardsNumberedCardsAltBackground {
    primary {
      heading {
        ...RichText
      }
      text
    }
    items {
      cardImage {
        ...Image
      }
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment ThreeColumnCardsBrand2GradientStripedCurve on PrismicThreeColumnCardsBrand2GradientStripedCurve {
    primary {
      heading {
        ...RichText
      }
      text
    }
    items {
      cardImage {
        ...Image
      }
      icon
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment ThreeColumnCards on PrismicPageDataSlicesSlicesType {
    ...ThreeColumnCardsDefault
    ...ThreeColumnCardsNumberedCards
    ...ThreeColumnCardsNumberedCardsAltBackground
    ...ThreeColumnCardsBrand2GradientStripedCurve
  }
`;
