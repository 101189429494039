import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { videoEmbedHeaderShadow } from '../../design/colors/shadows';
import { Panel } from '../../design/containers/panel/Panel';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import ContentWrapper from '../../templates/components/ContentWrapper/ContentWrapper';
import { WithClassName } from '../../utilityTypes';

type VideoEmbedProps = {
  videoEmbed: string;
  title?: PrismicRichTextType;
} & WithClassName;

const videoEmbedContainerBorderRadius = 12;

type VideoEmbedContainerProps = { width?: number; height?: number };
const VideoEmbedContainer = styled.div<VideoEmbedContainerProps>`
  && {
    position: relative;
    overflow: hidden;
    padding-top: calc(9 / 16 * 100%);
    width: 100%;
    border-radius: ${videoEmbedContainerBorderRadius}px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

export const shadowPaddingAllowance = 'padding-top: 12px;';

const VideoShadowContainer = styled(SliceContainer)`
  ${shadowPaddingAllowance}
  padding-left: 0;
  padding-right: 0;
  margin-bottom: ${spacings['24px']};
  width: 100%;
`;

const HeaderContainer = styled(Panel)`
  ${videoEmbedHeaderShadow}
  width: 100%;
  min-height: 300px;
  z-index: 2;
  position: relative;
  margin: ${spacings['24px']} auto 0;
`;
HeaderContainer.defaultProps = { background: 'brand3WeelLogoAzul', borderRadius: `${spacings['32px']}` };

const CenteredContainer = styled(CenteredSliceContentContainer)`
  width: 100%;
  margin: 0 auto;
  align-items: baseline;
  padding: ${spacings['100px']} ${spacings['24px']} ${108}px ${spacings['24px']};
`;

const BasicVideoEmbed = ({ videoEmbed }: VideoEmbedProps) => (
  <VideoEmbedContainer data-testid="video-embed-container">
    <ReactPlayer data-testid="video-embed" width="100%" height="100%" url={videoEmbed} controls allowFullScreen />
  </VideoEmbedContainer>
);

const VideoEmbedDefault = ({ videoEmbed, className }: VideoEmbedProps) => (
  <SliceContainer className={className}>
    <BasicVideoEmbed videoEmbed={videoEmbed} />
  </SliceContainer>
);

const containerPadding = css`
  padding: ${spacings['16px']};
  ${breakpointSmall(css`
    padding: ${spacings['32px']};
  `)}
`;
const containerBorderRadius = 25;
const contentWrapperProps = {
  containerPadding: containerPadding,
  containerBorderRadius: containerBorderRadius,
};
const VideoEmbedWithHeader = ({ className, title, videoEmbed }: VideoEmbedProps) => (
  <>
    <VideoShadowContainer>
      <HeaderContainer data-testid="video-embed-header">
        <CenteredContainer>
          {title && <Heading1 textAlign={{ base: 'center', breakpointSmall: 'left' }}>{title}</Heading1>}
        </CenteredContainer>
      </HeaderContainer>
    </VideoShadowContainer>
    <ContentWrapper {...contentWrapperProps}>
      <BasicVideoEmbed videoEmbed={videoEmbed} className={className} />
    </ContentWrapper>
  </>
);

/**
 * Video embed component.
 */
const VideoEmbed: FC<VideoEmbedProps> = ({ className, videoEmbed, title }) =>
  title ? (
    <VideoEmbedWithHeader title={title} videoEmbed={videoEmbed} className={className} />
  ) : (
    <VideoEmbedDefault videoEmbed={videoEmbed} className={className} />
  );

export default VideoEmbed;
