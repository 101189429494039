import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { jump } from '../../../../design/animations/micro-interactions';
import { ButtonLink, DualButtonLink, ButtonToUseOptions } from '../../../../design/components/button/Button';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { WithClassName } from '../../../../utilityTypes';
import { navButtonStyles } from '../buttons/NavButton';

const CtaButtonLink = styled(ButtonLink).attrs({
  styleType: { base: 'reverseSecondary', breakpointMedium: 'transparent' },
})`
  ${jump}
  &:hover {
    transition: all 0.3s ease;
    top: -4px;
  }
  ${breakpointMedium(css`
    ${navButtonStyles()}
    &:hover {
      top: 0;
    }
  `)}
`;

const DualCtaButtonLink = styled(DualButtonLink).attrs({
  styleType: { base: 'reverseSecondary', breakpointMedium: 'transparent' },
})`
  ${jump}
  &:hover {
    transition: all 0.3s ease;
    top: -4px;
  }
  ${breakpointMedium(css`
    ${navButtonStyles()}
    &:hover {
      top: 0;
    }
  `)}
`;

const CtaButtonPrimaryLink = styled(ButtonLink).attrs({ styleType: 'primary', size: 'large' })``;

type ButtonLinkProps = ComponentProps<typeof ButtonLink>;
type CtaButtonType = 'secondaryTransparent' | 'primary';
type CtaButtonProps = {
  styleType: CtaButtonType;
  link: ButtonLinkProps['link'];
  text: ButtonLinkProps['children'];
} & WithClassName;
type CtaToUseOptions = Pick<ButtonToUseOptions, 'mobileCta' | 'desktopCta'> & { styleType: CtaButtonType };

/**
 * Button to use for CTAs on the right side of the nav.
 */
export const CtaButton: FC<CtaButtonProps> = ({ className, styleType, link, text }) =>
  styleType === 'primary' ? (
    <CtaButtonPrimaryLink className={className} link={link}>
      {text}
    </CtaButtonPrimaryLink>
  ) : (
    <CtaButtonLink className={className} link={link}>
      {text}
    </CtaButtonLink>
  );

/**
 * Return Dual or Single CTA.
 */
export const CtaToUse: FC<CtaToUseOptions> = ({ desktopCta, mobileCta, styleType }) => {
  const hasCtaOne = !!(desktopCta.link && desktopCta.text);
  const hasCtaTwo = !!(mobileCta.link && mobileCta.text);

  return (
    <>
      {hasCtaOne && hasCtaTwo ? (
        <DualCtaButtonLink
          desktopLink={desktopCta.link}
          desktopText={desktopCta.text}
          mobileLink={mobileCta?.link}
          mobileText={mobileCta?.text}
        />
      ) : (
        <CtaButton link={desktopCta.link} styleType={styleType} text={desktopCta.text} />
      )}
    </>
  );
};
