import { breakpointWidthValues, createBreakpoint } from './breakpointUtils';

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpointXSmall(css`
 *   background-color: green;
 * `);
 */
export const breakpointXSmall = createBreakpoint(breakpointWidthValues.breakpointXSmall);

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpointSmall(css`
 *   background-color: green;
 * `);
 */
export const breakpointSmall = createBreakpoint(breakpointWidthValues.breakpointSmall);

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpointMedium(css`
 *   background-color: green;
 * `);
 */
export const breakpointMedium = createBreakpoint(breakpointWidthValues.breakpointMedium);

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpointLarge(css`
 *   background-color: green;
 * `);
 */
export const breakpointLarge = createBreakpoint(breakpointWidthValues.breakpointLarge);

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpointXLarge(css`
 *   background-color: green;
 * `);
 */
export const breakpointXLarge = createBreakpoint(breakpointWidthValues.breakpointXLarge);

/**
 * Generates breakpoint. Styles must be passed in using the `css` helper.
 *
 * @example
 * breakpoint2XLarge(css`
 *   background-color: green;
 * `);
 */
export const breakpoint2XLarge = createBreakpoint(breakpointWidthValues.breakpoint2XLarge);
