import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1, brand1Dark } from '../../../design/colors/brand';
import { grey200, grey400 } from '../../../design/colors/greyscale';
import { createBreakpoint } from '../../../design/responsive/breakpointUtils';
import { breakpointLarge, breakpointMedium, breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading3 } from '../../../design/typography/Typography';

const StepText = styled(BodyP)`
  max-width: 300px;
`;
StepText.defaultProps = { type: 'bodyLRegular', textAlign: 'center' };

const divider = css`
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background: ${grey400};
  z-index: 1;
  width: 50%;
`;
const leftDivider = css`
  ${divider}
  right: 50%;
  left: auto;
  width: 100%;
`;
const rightDivider = css`
  ${divider}
  left: 50%;
  right: auto;
  width: 100%;
`;

const NumberContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 0 ${spacings['24px']} 0;
  &.num-1 {
    > * {
      background: ${brand1};
      border-color: ${brand1Dark};
    }
  }
  ${breakpointSmall(
    css`
      margin: ${spacings['64px']} 0 ${spacings['24px']} 0;
      &.num-2 {
        &:after {
          ${rightDivider}
        }
        &:before {
          ${leftDivider}
        }
      }
    `
  )};
  ${breakpointMedium(
    css`
      &.num-2 {
        &:after {
          width: 110%;
        }
        &:before {
          width: 110%;
        }
      }
    `
  )};
  ${createBreakpoint(1220)(
    css`
      &.num-2 {
        &:after {
          width: 120%;
        }
        &:before {
          width: 120%;
        }
      }
    `
  )};
  ${breakpointLarge(
    css`
      &.num-2 {
        &:after {
          width: 130%;
        }
        &:before {
          width: 130%;
        }
      }
    `
  )};
    
}
`;

const NumberCircle = styled(Heading3)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${grey200};
  padding: 12px;
  height: 64px;
  width: 64px;
  flex-shrink: 0;
  border-radius: 50vw;
  border: 2px solid ${grey400};
  z-index: 2;
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: ${spacings['32px']};
  ${breakpointSmall(
    css`
      padding-bottom: 0;
    `
  )};
`;

type StepsProps = {
  step?: string;
  index?: number;
};
/**
 * Renders TextColumn component.
 */
const ProductCaption: FC<StepsProps> = ({ step, index }) => {
  return (
    <CaptionContainer>
      <NumberContainer className={`num-${index}`}>
        <NumberCircle>{index}</NumberCircle>
      </NumberContainer>
      <StepText>{step}</StepText>
    </CaptionContainer>
  );
};
export default ProductCaption;
