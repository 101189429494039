import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { HubspotProps } from '../../components/hubspotForm/HubspotForm';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { white } from '../../design/colors/greyscale';
import { Panel } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { IconType } from '../../design/icons/Icon';
import { breakpointMedium, breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodyP } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';
import { objectData } from '../../utils/lodashHelpers';
import DemoHubspotForm from './DemoHubspotForm';
import ChiliPiperForm from './chiliPiperForm/ChiliPiperForm';
import InformationPanel from './informationPanel/InformationPanel';

const HeadingContainer = styled.div`
  padding-top: ${spacings['64px']};
  max-width: 814px;

  ${breakpointSmall(css`
    padding-top: ${spacings['100px']};
  `)}
`;

const PanelContainer = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: ${spacings['24px']};
  align-items: center;
  max-width: 1286px;
  padding-bottom: ${spacings['32px']};
  margin-top: ${spacings['48px']};

  ${breakpointMedium(css`
    flex-direction: row-reverse;
    padding: ${spacings['32px']};
    align-items: center;
    justify-content: center;
    margin-top: ${spacings['12px']};

    border-top-left-radius: ${spacings['24px']};
    border-top-right-radius: ${spacings['24px']};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `)}
`;

const FormContainer = styled(Panel)`
  background: ${white};
  width: 100%;
  display: flex;
  justify-content: center;

  ${breakpointMedium(css`
    height: 100%;
    width: 50%;
    max-width: 550px;
  `)}
`;
FormContainer.defaultProps = { borderRadius: '24px', padding: '48px' };

export type IconProps = { image: IconType | string; text: string };
export type FormProps = {
  subHeading?: string;
  icons?: IconProps[];
  partnershipHeading?: string;
  hubspot?: HubspotProps;
  logos?: Queries.PrismicDemoDefaultItem[];
  postLogoImage?: PrismicImageData;
};
export type DemoProps = {
  heading: PrismicRichTextType;
  text?: string;
  form: FormProps;
} & WithClassName;

const Heading: FC<Pick<DemoProps, 'heading' | 'text'>> = ({ heading, text }) => (
  <HeadingContainer>
    <Heading1 textAlign="center">{heading}</Heading1>
    <Spacing top={{ base: '24px' }} bottom={{ base: 'none', breakpointMedium: '48px' }}>
      <BodyP type="bodyLRegular" textAlign="center">
        {text}
      </BodyP>
    </Spacing>
  </HeadingContainer>
);

/**
 * Renders Demo slice.
 */
const Demo: FC<DemoProps> = ({ className, heading, text, form }) => {
  const { trackingEvent = '', id = '', link = null, referrer, formType } = objectData(form, 'hubspot') as HubspotProps;

  const isChiliPiperForm = formType === 'Chili Piper Form';
  const Form = isChiliPiperForm ? ChiliPiperForm : DemoHubspotForm;

  return (
    <SliceContainer className={className} background="brand5ExpressiveWaveLime">
      <Heading heading={heading} text={text} />
      <PanelContainer background={{ base: 'transparent', breakpointMedium: 'brand3Gradient' }}>
        <FormContainer>
          <Form id={id} link={link} trackingEvent={trackingEvent} referrer={referrer} />
        </FormContainer>
        <InformationPanel {...form} isChiliPiperForm={isChiliPiperForm} />
      </PanelContainer>
    </SliceContainer>
  );
};

export default Demo;
