import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RawImage } from '../../../components/image/rawImage/RawImage';
import previouslyDivipayMark from '../../../images/brand/previously-divipay-mark.svg';
import wordmark from '../../../images/brand/wordmark.svg';
import { WithClassName, WithTestId } from '../../../utilityTypes';
import Spacing from '../../spacing/Spacing';
import { BodyP } from '../../typography/Typography';
import CloneChild from '../../utils/CloneChild';

type BrandMarkImageSize = '40px' | '84px';
type BrandMarkImageStylesProviderProps = { size: BrandMarkImageSize };
const BrandMarkImageStylesProvider = styled(CloneChild)<BrandMarkImageStylesProviderProps>`
  ${(props) => css`
    width: ${props.size};
    height: ${props.size};
  `}
`;

const WordMarkContaienr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const WordMark: FC<WithClassName> = ({ className }) => (
  <WordMarkContaienr>
    <RawImage className={className} src={wordmark} alt="weel" />
    <Spacing top="4px">
      <RawImage className={className} src={previouslyDivipayMark} alt="previously divipay" />
    </Spacing>
  </WordMarkContaienr>
);

type BrandMarkProps = BrandMarkImageStylesProviderProps & WithClassName;
const BrandMark: FC<BrandMarkProps> = ({ className, size }) => (
  <BrandMarkImageStylesProvider className={className} size={size}>
    <StaticImage src="../../../images/brand/icon.png" alt="weel icon" />
  </BrandMarkImageStylesProvider>
);

const brandMarkRotateStart = css`
  transition-duration: 0.8s;
  transition-property: transform;
  animation-fill-mode: forwards;
  transform: rotate(0deg);
`;

const brandMarkRotateRotated = css`
  transform: rotate(360deg);
`;

const BrandMarkWithWordMarkLeftContainer = styled.div`
  display: flex;
  align-items: center;
  ${BrandMarkImageStylesProvider} {
    ${brandMarkRotateStart}
  }
  &:hover {
    ${BrandMarkImageStylesProvider} {
      ${brandMarkRotateRotated}
    }
  }
`;
/**
 * Renders brand mark with word mark on the left.
 */
export const BrandMarkWithWordMarkLeft: FC<WithClassName & WithTestId> = ({ className }) => (
  <BrandMarkWithWordMarkLeftContainer className={className}>
    <Spacing right="8px">
      <WordMark />
    </Spacing>
    <BrandMark size="40px" />
  </BrandMarkWithWordMarkLeftContainer>
);

const BrandMarkWithSloganBelowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
/**
 * Renders brand mark with "Everybody Wins" slogan below.
 */
export const BrandMarkWithSloganBelow: FC<WithClassName> = ({ className }) => (
  <BrandMarkWithSloganBelowContainer className={className}>
    <BrandMark size="84px" />
    <Spacing top="12px">
      <BodyP type="bodyXSRegular" textAlign="center">
        Everybody Wins
      </BodyP>
    </Spacing>
  </BrandMarkWithSloganBelowContainer>
);
