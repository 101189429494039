import { css } from 'styled-components';
import { brand2, brand3Dark } from '../../../../../../colors/brand';
import { grey500 } from '../../../../../../colors/greyscale';
import { disabledStyle } from '../../../../../../utils/style';

export const secondaryDisabledStyles = disabledStyle(css`
  border-color: ${grey500};
  color: ${grey500};
`);

/**
 * Generates the secondary button type style.
 */
export const secondaryTypeStyle = () => css`
  border-color: ${brand2};
  color: ${brand2};

  &:hover {
    border-color: ${brand3Dark};
    color: ${brand3Dark};
  }

  ${secondaryDisabledStyles}
`;
