import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../../design/icons/Icon';
import { breakpointMedium, breakpointLarge } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodySpan } from '../../../design/typography/Typography';
import { WithClassName, WithTestId } from '../../../utilityTypes';
import { PricingItem } from '../types';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings['8px']};
  ${breakpointMedium(
    css`
      gap: ${spacings['16px']};
    `
  )};
`;

const ListItem = styled.div`
  display: flex;
  gap: ${spacings['12px']};
  ${breakpointMedium(css`
    gap: ${spacings['8px']};
  `)}
  ${breakpointLarge(css`
    gap: ${spacings['12px']};
  `)}
`;

const ListIcon = styled(Icon)`
  width: 16px;
  height: auto;
  ${breakpointMedium(css`
    width: 24px;
  `)}
`;

const ListText = styled(BodySpan).attrs({
  type: { base: 'bodyLRegular', breakpointSmall: 'bodyMRegular', breakpointMedium: 'bodyLRegular' },
  textAlign: 'left',
})``;

const ListHeading = styled(ListText).attrs({
  type: { base: 'bodyLMedium', breakpointSmall: 'bodyMMedium', breakpointMedium: 'bodyLMedium' },
})`
  padding-bottom: ${spacings['8px']};
`;

type ListProps = { heading?: PricingItem['itemHeading']; items: string[]; reverse?: boolean } & WithClassName &
  WithTestId;
/**
 * Renders check list items for Price slice.
 */
const List: FC<ListProps> = ({ heading, reverse, items, className }) => {
  return (
    <ListContainer className={className} data-testid="pricing-bottom-card-features">
      {heading && <ListHeading>{heading}</ListHeading>}
      {items.map((item) => (
        <ListItem key={item}>
          {item && <ListIcon type="Check" reverse={reverse} />}
          <ListText>{item}</ListText>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default List;
