import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import ProductCaption from './ProductCaption';

const ThreeColumns = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  ${breakpointSmall(
    css`
      grid-template-columns: repeat(3, 1fr);
      > * {
        &:first-child {
          justify-self: start;
        }
        &:last-child {
          justify-self: end;
        }
      }
    `
  )};
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  padding-bottom: ${spacings['32px']};
  ${breakpointSmall(
    css`
      flex-direction: column;
      padding-bottom: ${spacings['none']};
    `
  )};
`;

const StepsContainer = styled.div`
  width: 100%;
`;

const imgStyles = {
  maxWidth: '332px',
  maxHeight: '648px',
  flexShrink: '0',
};

type StepsProps = {
  step1?: string;
  step2?: string;
  step3?: string;
};
// @todo refactor wet comp
/**
 * Renders TextColumn component.
 */
const Steps: FC<StepsProps> = ({ step1, step2, step3 }) => {
  return (
    <StepsContainer>
      <ThreeColumns>
        <OnVisible fadeUp threshold={0.2}>
          <ProductContainer>
            <StaticImage
              src="../../../images/product/apple-pay-1.png"
              alt="phone with option to add Weel card to Apple wallet"
              style={{ ...imgStyles }}
              objectFit="contain"
            />
            <ProductCaption step={step1} index={1} />
          </ProductContainer>
        </OnVisible>
        <OnVisible fadeUp threshold={0.2} style={{ transitionDelay: `${0.3}s` }}>
          <ProductContainer>
            <StaticImage
              src="../../../images/product/apple-pay-2.png"
              alt="phone with Weel card in Apple Wallet"
              style={{ ...imgStyles }}
              objectFit="contain"
            />
            <ProductCaption step={step2} index={2} />
          </ProductContainer>
        </OnVisible>
        <OnVisible fadeUp threshold={0.2} style={{ transitionDelay: `${0.6}s` }}>
          <ProductContainer>
            <StaticImage
              src="../../../images/product/apple-pay-3.png"
              alt="phone with manual card add screen on Apple"
              style={{ ...imgStyles }}
              objectFit="contain"
            />
            <ProductCaption step={step3} index={3} />
          </ProductContainer>
        </OnVisible>
      </ThreeColumns>
    </StepsContainer>
  );
};
export default Steps;
