import React, { FC } from 'react';
import styled from 'styled-components';
import Link from '../../../../../components/link/Link';
import { grid, padding } from '../../../../../design/animations/display';
import * as colors from '../../../../../design/colors/brand';
import { grey400 } from '../../../../../design/colors/greyscale';
import { Background, isDarkBackground } from '../../../../../design/containers/panel/Panel';
import { BodyP } from '../../../../../design/typography/Typography';
import { PrismicImageData } from '../../../../../utilityTypes';
import { ArticleType } from '../../../types';
import CategoryTag from '../../Tags/CategoryTag';
import TopicTags, { TagTheme } from '../../Tags/TopicTags';
import { Card, CardImage, CardContent, TextContainer, CardTitle } from '../sharedCardStyles';

export const DetailsContainer = styled.div<{ darkMode: boolean }>`
  ${grid({})};
  gap: 4px 8px;
  grid-template-columns: min-content 1fr;
  ${({ darkMode }) =>
    darkMode ? 'border-top: solid 1px rgba(255, 255, 255, 0.4);' : `border-top: solid 1px ${grey400};`}
  ${padding({ top: '16px' })};
`;

type CardProps = {
  date: string;
  title: string;
  time: string;
  location: string;
  hubspotFormID?: string | null;
  recording?: string;
  excerpt: string;
  url: string;
  background: Background;
  image?: PrismicImageData;
  type: ArticleType;
  topics?: string[] | null;
  theme: TagTheme;
  truncateTitle?: boolean;
};
/**
 * Blog Card component: sits inside PostsGrid component on Blog Lannding page.
 */
const EventCard: FC<CardProps> = ({
  date,
  title,
  time,
  location,
  url,
  image,
  background,
  type,
  topics,
  theme,
  truncateTitle,
}) => {
  const link = {
    link_type: 'Document',
    url,
  } as const;

  const formattedTopics = topics === [''] ? undefined : topics;

  const darkMode = isDarkBackground(background);
  const textColor = darkMode ? colors.brand1 : colors.brand2;

  return (
    <Card borderRadius="24px" data-testid="content-hub-card" background={background}>
      {image?.gatsbyImageData && (
        <Link link={link}>
          <CardImage image={image} />
        </Link>
      )}
      <CardContent>
        <TextContainer>
          <CategoryTag type={type} isLight={darkMode} />
          <CardTitle link={link} truncateTitle={truncateTitle}>
            {title}
          </CardTitle>
          {formattedTopics && <TopicTags topics={formattedTopics} theme={theme} />}
          <DetailsContainer darkMode={darkMode}>
            {time && (
              <>
                <BodyP type="bodyMBold" color={textColor}>
                  Time
                </BodyP>
                <BodyP type="bodyMRegular">{time}</BodyP>
              </>
            )}
            {date && (
              <>
                <BodyP type="bodyMBold" color={textColor}>
                  Date
                </BodyP>
                <BodyP type="bodyMRegular">{date}</BodyP>
              </>
            )}
            {location && (
              <>
                <BodyP type="bodyMBold" color={textColor}>
                  Location
                </BodyP>
                <BodyP type="bodyMRegular">{location}</BodyP>
              </>
            )}
          </DetailsContainer>
        </TextContainer>
      </CardContent>
    </Card>
  );
};

export default EventCard;
