import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { arrowHover } from '../../../../../../design/animations/micro-interactions';
import { standardTransitionStyles } from '../../../../../../design/animations/transitions';
import { brand1DarkAlt, brand2Dark } from '../../../../../../design/colors/brand';
import { breakpointLarge, breakpointMedium } from '../../../../../../design/responsive/breakpoints';
import { spacings } from '../../../../../../design/spacing/Spacing';
import { BodyP } from '../../../../../../design/typography/Typography';
import RightArrow from '../../../../../../images/react-icons/ArrowRightLime.inline.svg';
import Link from '../../../../../link/Link';
import { HeaderMegaDropdownSectionItem } from '../../../../types';
import { HiddenOnMobile } from '../../../../utils/Hidden';
import { SectionSubText } from '../SectionSubText';

const StyledArrow = styled(RightArrow)`
  ${standardTransitionStyles('transform')};
  * {
    ${standardTransitionStyles('stroke')};
  }
`;

const HeadingText = styled(BodyP)`
  ${standardTransitionStyles('color')};
`;

const SubText = styled(SectionSubText)`
  max-width: 250px;
`;
SubText.defaultProps = { type: 'bodySRegular' };

const SectionItemButton = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacings['12px']} ${spacings['12px']} ${spacings['12px']} ${spacings['24px']};
  &:hover {
    ${StyledArrow} {
      ${arrowHover}
    }
  }
  ${breakpointMedium(css`
    align-items: flex-start;
    padding: 0;
  `)}
`;

const SectionItemContentContainer = styled.div`
  width: 100%;
  &:hover {
    p:first-of-type {
      color: ${brand2Dark};
    }
  }
  ${breakpointMedium(css`
    flex-direction: column;
    align-items: flex-start;
    padding-right: ${spacings['16px']};
  `)}
  ${breakpointLarge(css`
    padding-right: ${spacings['32px']};
  `)}
`;

type SectionItemProps = { item: HeaderMegaDropdownSectionItem };
/**
 * Renders a nav dropdown section item.
 */
export const SectionItem: FC<SectionItemProps> = ({ item }) => (
  <SectionItemButton link={item.link}>
    <SectionItemContentContainer>
      <HeadingText type={{ base: 'bodyMRegular', breakpointMedium: 'bodyMMedium' }}>{item.heading}</HeadingText>
      <HiddenOnMobile>
        <SubText>{item.text}</SubText>
      </HiddenOnMobile>
    </SectionItemContentContainer>
    <StyledArrow width="24px" stroke={brand1DarkAlt} />
  </SectionItemButton>
);
