import { css } from 'styled-components';
import { breakpointMedium } from '../../../../../design/responsive/breakpoints';

/**
 * Renders open or closed slideout styles.
 */
export const slideUpTransition = (open?: boolean) => css`
  position: fixed;
  bottom: ${open ? 0 : '-100%'};
  left: 0;
  transition: 0.3s ease bottom;

  ${breakpointMedium(css`
    && {
      position: static;
      transition: none;
      transform: none;
    }
  `)}
`;
