import { graphql } from 'gatsby';
import _ from 'lodash';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps } from '../../types';
import TwoColumnContentImage from '../TwoColumnContentImage';
import {
  WhiteSolidVariation,
  Grey200SolidVariation,
  Brand2SolidVariation,
  Brand2SolidStripedCurveLeftVariation,
  Brand2SolidStripedCurveRightVariation,
  Brand3SolidVariation,
  Brand3GradientVariation,
  Brand3GradientStripedCurveVariation,
  Brand4GradientVariation,
  Brand4GradientWeelLogoVariation,
  Brand4GradientWeelLogoLeftVariation,
  Brand5SolidVariation,
  Brand5SolidCardsLeftVariation,
  WhiteSolidWithQuoteCardVariation,
  LinkedDocumentCta,
} from './TwoColumnContentImage.queryType';
const getBackgroundForVariation = (variation: TwoColumnContentImageDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    whiteSolid: 'whiteSolid',
    grey200Solid: 'grey200Solid',
    brand2Solid: 'brand2Solid',
    brand2SolidStripedCurveLeft: 'brand2SolidStripedCurveLeft',
    brand2SolidStripedCurveRight: 'brand2SolidStripedCurveRight',
    brand3Solid: 'brand3Solid',
    brand3Gradient: 'brand3Gradient',
    brand3GradientStripedCurve: 'brand3GradientStripedCurve',
    brand4Gradient: 'brand4Gradient',
    brand4GradientWeelLogo: 'brand4GradientWeelLogo',
    brand4GradientWeelLogoLeft: 'brand4GradientWeelLogoLeft',
    brand5Solid: 'brand5Solid',
    brand5SolidCardsLeft: 'brand5SolidCardsLeft',
    whiteSolidWithQuoteCard: 'whiteSolid',
  };
  return variationBackgroundMap[variation];
};

type TwoColumnContentImageDataProps = SliceProps<
  'two_column_content_image',
  | WhiteSolidVariation
  | Grey200SolidVariation
  | Brand2SolidVariation
  | Brand2SolidStripedCurveLeftVariation
  | Brand2SolidStripedCurveRightVariation
  | Brand3SolidVariation
  | Brand3GradientVariation
  | Brand3GradientStripedCurveVariation
  | Brand4GradientVariation
  | Brand4GradientWeelLogoVariation
  | Brand4GradientWeelLogoLeftVariation
  | Brand5SolidVariation
  | Brand5SolidCardsLeftVariation
  | WhiteSolidWithQuoteCardVariation
  | LinkedDocumentCta
> &
  WithClassName;

/**
 * TwoColumnContentImage data component: specifies the fragment and passes the appropriate data to the TwoColumnContentImage component.
 */
const TwoColumnContentImageData: FC<TwoColumnContentImageDataProps> = ({ className, slice }) => (
  <TwoColumnContentImage
    className={className}
    heading={slice?.primary?.heading}
    text={slice.primary?.text || ''}
    primaryCta={{
      text: _.get(slice, 'primary.primaryCtaButtonText', ''),
      link: _.get(slice, 'primary.primaryCtaLink'),
    }}
    secondaryCta={{
      text: _.get(slice, 'primary.secondaryCtaButtonText', ''),
      link: _.get(slice, 'primary.secondaryCtaLink'),
    }}
    desktopImage={slice.primary?.imageDesktop}
    mobileImage={slice.primary?.imageMobile}
    linkedDocumentCta={_.get(slice, 'primary.linkedDocumentCta')}
    contentAlignment={slice.primary?.layout === 'Image Left - Content Right' ? 'right' : 'left'}
    backgroundType={getBackgroundForVariation(slice.variation)}
    animation={slice.primary?.animation || undefined}
    quoteCard={{
      companyImage: _.get(slice, 'primary.quoteCardCompanyImage', null),
      quote: _.get(slice, 'primary.quoteCardQuote', ''),
      author: _.get(slice, 'primary.quoteCardAuthor', ''),
      jobTitle: _.get(slice, 'primary.quoteCardJobTitle', ''),
    }}
  />
);

export default TwoColumnContentImageData;

export const query = graphql`
  fragment Brand3GradientStripedCurve on PrismicTwoColumnContentImageBrand3GradientStripedCurve {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand3Gradient on PrismicTwoColumnContentImageBrand3Gradient {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand4GradientWeelLogo on PrismicTwoColumnContentImageBrand4GradientWeelLogo {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand2Solid on PrismicTwoColumnContentImageBrand2Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand4Gradient on PrismicTwoColumnContentImageBrand4Gradient {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment WhiteSolid on PrismicTwoColumnContentImageWhiteSolid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand3Solid on PrismicTwoColumnContentImageBrand3Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand5Solid on PrismicTwoColumnContentImageBrand5Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand5SolidCardsLeft on PrismicTwoColumnContentImageBrand5SolidCardsLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand4GradientWeelLogoLeft on PrismicTwoColumnContentImageBrand4GradientWeelLogoLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand2SolidStripedCurve on PrismicTwoColumnContentImageBrand2SolidStripedCurveLeft {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Grey200Solid on PrismicTwoColumnContentImageGrey200Solid {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment Brand2SolidStripedCurveRight on PrismicTwoColumnContentImageBrand2SolidStripedCurveRight {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
      quoteCardCompanyImage {
        alt
        gatsbyImageData(height: 48)
      }
      quoteCardQuote
      quoteCardJobTitle
      quoteCardAuthor
    }
  }

  fragment WhiteSolidWithQuoteCard on PrismicTwoColumnContentImageWhiteSolidWithQuoteCard {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
      quoteCardCompanyImage {
        alt
        gatsbyImageData(height: 48)
      }
      quoteCardQuote
      quoteCardJobTitle
      quoteCardAuthor
    }
  }

  fragment LinkedDocumentCta on PrismicTwoColumnContentImageLinkedDocumentCta {
    primary {
      layout
      heading {
        ...RichText
      }
      text
      linkedDocumentCta {
        document {
          ...AllResources
        }
      }
      imageMobile {
        ...Image
      }
      imageDesktop {
        ...Image
      }
      animation {
        document {
          ...LottieAnimation
        }
      }
    }
  }

  fragment TwoColumnContentImage on PrismicSliceType {
    ...Brand3GradientStripedCurve
    ...Brand3Gradient
    ...Brand4GradientWeelLogo
    ...Brand2Solid
    ...Brand4Gradient
    ...WhiteSolid
    ...Brand3Solid
    ...Brand5Solid
    ...Brand5SolidCardsLeft
    ...Brand4GradientWeelLogoLeft
    ...Brand2SolidStripedCurve
    ...Grey200Solid
    ...Brand2SolidStripedCurveRight
    ...WhiteSolidWithQuoteCard
    ...LinkedDocumentCta
  }
`;
