import { css } from 'styled-components';
import { breakpointMedium } from '../../../../../design/responsive/breakpoints';

/**
 * Renders open or closed slideout styles.
 */
export const slideoutTransition = (open?: boolean) => css`
  position: fixed;
  left: 100%;
  transition: transform 250ms ease-in-out;
  transform: translateX(${open ? '-100%' : '0%'});

  ${breakpointMedium(css`
    && {
      position: static;
      transition: none;
      transform: none;
    }
  `)}
`;
