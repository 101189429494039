import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import Hero from '../Hero';
import HomepageHero from '../HomepageHero';

const getBackgroundForVariation = (variation: HeroDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    brand5GlassSpinnerLime: 'brand5GlassSpinnerLime',
    brand2GlassSpinnerTeal: 'brand2GlassSpinnerTeal',
    brand5WaveLime: 'brand5WaveLime',
    brand3FlexSpinnerAzul: 'brand3FlexSpinnerAzul',
    brand5CardTwistTeal: 'brand5CardTwistTeal',
    brand2WaveTeal: 'brand2WaveTeal',
    brand4WaveMidnight: 'brand4WaveMidnight',
    brand3WeelLogoAzul: 'brand3WeelLogoAzul',
    brand5FlexSpinnerBase: 'brand5FlexSpinnerBase',
    brand5WeelLogoBase: 'brand5WeelLogoBase',
    brand5FlexSpinnerLime: 'brand5FlexSpinnerLime',
    brand5GlassSpinnerTeal: 'brand5GlassSpinnerTeal',
    brand4CardStackMidnight: 'brand4CardStackMidnight',
    brand3CardStackAzul: 'brand3CardStackAzul',
    brand3HomepageHero: 'brand3Gradient',
  };
  return variationBackgroundMap[variation];
};

type HeroDataProps = SliceProps<
  'hero',
  | SliceVariation<'brand5GlassSpinnerLime', Queries.HeroBrand5GlassSpinnerLimeFragment['primary']>
  | SliceVariation<'brand2GlassSpinnerTeal', Queries.HeroBrand2GlassSpinnerTealFragment['primary']>
  | SliceVariation<'brand5WaveLime', Queries.HeroBrand5WaveLimeFragment['primary']>
  | SliceVariation<'brand3FlexSpinnerAzul', Queries.HeroBrand3FlexSpinnerAzulFragment['primary']>
  | SliceVariation<'brand5CardTwistTeal', Queries.HeroBrand5CardTwistTealFragment['primary']>
  | SliceVariation<'brand2WaveTeal', Queries.HeroBrand2WaveTealFragment['primary']>
  | SliceVariation<'brand4WaveMidnight', Queries.HeroBrand4WaveMidnightFragment['primary']>
  | SliceVariation<'brand3WeelLogoAzul', Queries.HeroBrand3WeelLogoAzulFragment['primary']>
  | SliceVariation<'brand5FlexSpinnerBase', Queries.HeroBrand5FlexSpinnerBaseFragment['primary']>
  | SliceVariation<'brand5WeelLogoBase', Queries.HeroBrand5WeelLogoBaseFragment['primary']>
  | SliceVariation<'brand5FlexSpinnerLime', Queries.HeroBrand5FlexSpinnerLimeFragment['primary']>
  | SliceVariation<'brand5GlassSpinnerTeal', Queries.HeroBrand5GlassSpinnerTealFragment['primary']>
  | SliceVariation<'brand4CardStackMidnight', Queries.HeroBrand4CardStackMidnightFragment['primary']>
  | SliceVariation<'brand3CardStackAzul', Queries.HeroBrand3CardStackAzulFragment['primary']>
  | SliceVariation<'brand3HomepageHero', Queries.HeroBrand3HomepageHeroFragment['primary']>
> &
  WithClassName;
/**
 * Hero data component: specifies the fragment and passes the appropriate data to the Hero component.
 */
const HeroData: FC<HeroDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };

  return slice.variation === 'brand3HomepageHero' ? (
    <HomepageHero
      className={className}
      heading={data.heading}
      text={stringData(slice.primary.text)}
      primaryCta={{ text: stringData(data.primaryCtaButtonText), link: data.primaryCtaLink }}
      secondaryCta={{ text: stringData(data.secondaryCtaButtonText), link: data.secondaryCtaLink }}
      secondaryMobileCta={{ text: stringData(data.secondaryCtaMobileText), link: data.secondaryCtaMobileLink }}
      backgroundType={getBackgroundForVariation(slice.variation)}
      imageDesktop={slice.primary.imageDesktop}
      imageMobile={slice.primary.imageMobile}
    />
  ) : (
    <Hero
      className={className}
      heading={data.heading}
      text={slice.primary.textField}
      primaryCta={{ text: stringData(data.primaryCtaButtonText), link: data.primaryCtaLink }}
      secondaryCta={{ text: stringData(data.secondaryCtaButtonText), link: data.secondaryCtaLink }}
      secondaryMobileCta={{ text: stringData(data.secondaryCtaMobileText), link: data.secondaryCtaMobileLink }}
      backgroundType={getBackgroundForVariation(slice.variation)}
    />
  );
};

export default HeroData;
export const query = graphql`
  fragment HeroBrand5GlassSpinnerLime on PrismicHeroBrand5GlassSpinnerLime {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand2GlassSpinnerTeal on PrismicHeroBrand2GlassSpinnerTeal {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5WaveLime on PrismicHeroBrand5WaveLime {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3FlexSpinnerAzul on PrismicHeroBrand3FlexSpinnerAzul {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5CardTwistTeal on PrismicHeroBrand5CardTwistTeal {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand2WaveTeal on PrismicHeroBrand2WaveTeal {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand4WaveMidnight on PrismicHeroBrand4WaveMidnight {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3WeelLogoAzul on PrismicHeroBrand3WeelLogoAzul {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5FlexSpinnerBase on PrismicHeroBrand5FlexSpinnerBase {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5WeelLogoBase on PrismicHeroBrand5WeelLogoBase {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5FlexSpinnerLime on PrismicHeroBrand5FlexSpinnerLime {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand5GlassSpinnerTeal on PrismicHeroBrand5GlassSpinnerTeal {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand4CardStackMidnight on PrismicHeroBrand4CardStackMidnight {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3CardStackAzul on PrismicHeroBrand3CardStackAzul {
    primary {
      heading {
        ...RichText
      }
      textField: text {
        ...RichText
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
  }

  fragment HeroBrand3HomepageHero on PrismicHeroBrand3HomepageHero {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
      imageDesktop {
        ...Image
      }
      imageMobile {
        ...Image
      }
    }
  }

  fragment Hero on PrismicSliceType {
    ...HeroBrand5GlassSpinnerLime
    ...HeroBrand2GlassSpinnerTeal
    ...HeroBrand5WaveLime
    ...HeroBrand3FlexSpinnerAzul
    ...HeroBrand5CardTwistTeal
    ...HeroBrand2WaveTeal
    ...HeroBrand4WaveMidnight
    ...HeroBrand3WeelLogoAzul
    ...HeroBrand5FlexSpinnerBase
    ...HeroBrand5WeelLogoBase
    ...HeroBrand5FlexSpinnerLime
    ...HeroBrand5GlassSpinnerTeal
    ...HeroBrand4CardStackMidnight
    ...HeroBrand3CardStackAzul
    ...HeroBrand3HomepageHero
  }
`;
