import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../components/link/types';
import { jump } from '../../design/animations/micro-interactions';
import { brand3Mid } from '../../design/colors/brand';
import { ButtonLink } from '../../design/components/button/Button';
import { Background, isDarkBackground, Panel } from '../../design/containers/panel/Panel';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Icon from '../../design/icons/Icon';
import { breakpointMedium, breakpointSmall, breakpointLarge } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodyP, Heading2, Heading3 } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';

type MainCardData = {
  title: string;
  text: string;
  desktopImage: PrismicImageData;
  mobileImage: PrismicImageData;
};

type BasicCardData = {
  title: string;
  text: string;
  icon?: string | null;
  cta: LinkType;
};

type ThreeColumnFiveCardProps = {
  leftCard: MainCardData;
  middleTopCard: BasicCardData;
  middleBottomCard: BasicCardData;
  topRightCard: BasicCardData;
  bottomRightCard: BasicCardData;
  backgroundName?: Background;
} & WithClassName;

const DesktopImage = styled(PrismicImage)`
  && {
    border-radius: 10px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    width: 85%;
    position: absolute;
    bottom: 0;
  }
`;
DesktopImage.defaultProps = { visibility: { base: 'hide', breakpointMedium: 'show' } };

const MobileImage = styled(PrismicImage)`
  && {
    align-self: center;
    border-radius: 20px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    width: 100%;
    max-width: 450px;
  }
`;

MobileImage.defaultProps = { visibility: { base: 'show', breakpointMedium: 'hide' } };

const MainGridWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'maincard'
    'middleTopCard'
    'topRightCard'
    'bottomRightCard'
    'middleBottomCard';
  gap: ${spacings['16px']};
  ${breakpointMedium(
    css`
      grid-template-columns: 1fr 1fr 1fr;
      gap: ${spacings['24px']};
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'maincard middleTopCard topRightCard'
        'maincard middleBottomCard bottomRightCard';
      grid-auto-rows: 1fr;
    `
  )};
`;

const Card = styled(Panel)`
  ${jump}
  padding: ${spacings['24px']};
  ${breakpointLarge(
    css`
      height: 100%;
    `
  )};
`;

const MainCardContainer = styled(Card)`
  padding: ${spacings['8px']};
  grid-area: maincard;
  ${breakpointSmall(
    css`
      border: 1px solid ${brand3Mid};
      padding: ${spacings['32px']};
      grid-area: maincard;
    `
  )};
`;

const StyledButton = styled(ButtonLink)`
  max-width: 150px;
  ${breakpointSmall(
    css`
      max-width: 120px;
    `
  )};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MainCard: FC<MainCardData & { backgroundName: Background }> = ({
  backgroundName,
  title,
  text,
  desktopImage,
  mobileImage,
}) => (
  <MainCardContainer background={backgroundName}>
    {title && (
      <Spacing bottom={spacings['24px']}>
        <Heading2 textAlign="left">{title}</Heading2>
      </Spacing>
    )}
    {text && <BodyP type="bodyLRegular">{text}</BodyP>}
    {desktopImage && (
      <ImageWrapper>
        <Spacing top={spacings['24px']} bottom={spacings['32px']}>
          <DesktopImage image={desktopImage} />
        </Spacing>
      </ImageWrapper>
    )}
    {mobileImage && (
      <ImageWrapper>
        <Spacing top="24px">
          <MobileImage image={mobileImage} />
        </Spacing>
      </ImageWrapper>
    )}
  </MainCardContainer>
);

const BasicCard: FC<BasicCardData & { backgroundName: Background }> = ({ backgroundName, title, text, icon, cta }) => (
  <Card background={backgroundName}>
    {icon && (
      <Spacing bottom={spacings['24px']}>
        <Icon type={icon as ComponentProps<typeof Icon>['type']} reverse={isDarkBackground(backgroundName)} />
      </Spacing>
    )}
    {title && (
      <Spacing bottom={spacings['24px']}>
        <Heading3 textAlign="left">{title}</Heading3>
      </Spacing>
    )}
    {text && <BodyP type="bodyMRegular">{text}</BodyP>}
    <Spacing top={spacings['24px']} bottom={spacings['8px']}>
      <StyledButton styleType="primary" link={cta.link} size="medium">
        {cta.text}
      </StyledButton>
    </Spacing>
  </Card>
);

type NamedCardProps = {
  gridArea: 'middleTopCard' | 'topRightCard' | 'bottomRightCard' | 'middleBottomCard';
};

const GridAreaCardWrapper = styled.div<NamedCardProps>`
  grid-area: ${(props) => props.gridArea};
`;

/**
 * Renders three column five card component.
 */
const ThreeColumnFiveCards: FC<ThreeColumnFiveCardProps> = ({
  className,
  leftCard,
  middleTopCard,
  topRightCard,
  middleBottomCard,
  bottomRightCard,
  backgroundName = 'brand3GradientStripedCurveLeft',
}) => (
  <SliceContainer className={className} background={backgroundName}>
    <CenteredSliceContentContainer maxWidth="1286px">
      <MainGridWrapper>
        <MainCard {...leftCard} backgroundName="transparent"></MainCard>
        <GridAreaCardWrapper gridArea="middleTopCard">
          <BasicCard {...middleTopCard} backgroundName="brand5Solid" />
        </GridAreaCardWrapper>
        <GridAreaCardWrapper gridArea="topRightCard">
          <BasicCard {...topRightCard} backgroundName="brand2Solid" />
        </GridAreaCardWrapper>
        <GridAreaCardWrapper gridArea="bottomRightCard">
          <BasicCard {...bottomRightCard} backgroundName="brand5Solid" />
        </GridAreaCardWrapper>
        <GridAreaCardWrapper gridArea="middleBottomCard">
          <BasicCard {...middleBottomCard} backgroundName="brand2Solid" />
        </GridAreaCardWrapper>
      </MainGridWrapper>
    </CenteredSliceContentContainer>
  </SliceContainer>
);

export default ThreeColumnFiveCards;
