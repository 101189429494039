import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import FeaturePricingComparison from '../FeaturePricingComparison';
import { DefinedFeaturePricingComparisonItem, FeaturePricingComparisonPrimary } from '../types';

type FeaturePricingComparisonDataProps = SliceProps<
  'feature_pricing_comparison',
  SliceVariation<'default', FeaturePricingComparisonPrimary, DefinedFeaturePricingComparisonItem>
> &
  WithClassName;

/**
 * FeaturePricingComparison data component: specifies the fragment and passes the appropriate data to the FeaturePricingComparison component.
 */
const FeaturePricingComparisonData: FC<FeaturePricingComparisonDataProps> = ({ className, slice }) => {
  const calculatorData = {
    payments: {
      title: slice.primary.calculator_payments_title,
      subtitle: slice.primary.calculator_payments_subtitle,
      icon: slice.primary.calculator_payments_icon,
      enterprise: slice.primary.enterprise_payment_limit,
      premium: slice.primary.premium_payment_limit,
      basic: slice.primary.starter_payment_limit,
    },
    users: {
      title: slice.primary.calculator_users_title,
      subtitle: slice.primary.calculator_users_subtitle,
      icon: slice.primary.calculator_users_icon,
      enterprise: slice.primary.enterprise_user_limit,
      premium: slice.primary.premium_user_limit,
      basic: slice.primary.starter_user_limit,
    },
  };

  return (
    <FeaturePricingComparison
      className={className}
      heading={slice.primary.title}
      features={slice.items || []}
      links={{
        basic: slice.primary.starter_cta_link,
        premium: slice.primary.premium_cta_link,
        enterprise: slice.primary.enterprise_cta_link,
      }}
      calculatorData={slice.primary.show_calculator ? calculatorData : undefined}
    />
  );
};

export default FeaturePricingComparisonData;

export const query = graphql`
  fragment FeaturePricingComparison on PrismicFeaturePricingComparisonDefault {
    primary {
      title {
        richText
      }
      enterprise_cta_link {
        ...PrismicLink
      }
      premium_cta_link {
        ...PrismicLink
      }
      starter_cta_link {
        ...PrismicLink
      }
      calculator_payments_icon {
        ...Image
      }
      calculator_users_icon {
        ...Image
      }
      calculator_payments_title
      calculator_users_title
      calculator_payments_subtitle
      calculator_users_subtitle
      enterprise_payment_limit
      enterprise_user_limit
      premium_payment_limit
      premium_user_limit
      starter_payment_limit
      starter_user_limit
      show_calculator
    }
    items {
      feature_set {
        document {
          ... on PrismicFeatureComparison {
            ...FeatureComparisonSet
          }
        }
      }
    }
  }
  fragment FeatureComparisonSet on PrismicFeatureComparison {
    data {
      title {
        richText
      }
      features {
        enterprise
        feature_description
        feature_title
        premium
        starter
        icon {
          ...Image
        }
      }
    }
  }
`;
