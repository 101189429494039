import styled, { css } from 'styled-components';
import { white } from '../../../../design/colors/greyscale';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { pagePadding } from '../../../../templates/page/styles';
import { headerZindex } from '../../../zindex';
import { desktopHeaderContainerPadding, headerHeight } from '../../data/measurements';

export const HeaderContainer = styled.header<{ offset: number }>`
  position: fixed;
  top: ${({ offset }) => `${offset}px`};
  left: 0;
  z-index: ${headerZindex};
  height: ${headerHeight};
  width: 100%;
  ${pagePadding};
  background-color: ${white};
  ${breakpointMedium(css`
    height: auto;
    padding-top: ${desktopHeaderContainerPadding};
    padding-bottom: ${desktopHeaderContainerPadding};
  `)}
`;
