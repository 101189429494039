import styled, { css } from 'styled-components';
import { grey100, grey200, grey600 } from '../../../../design/colors/greyscale';
import { spacings } from '../../../../design/spacing/Spacing';
import { getTypographyStyles } from '../../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../../design/typography/base/Text';

const inputStyles = () => css`
  padding: 0 ${spacings['16px']};
  background-color: ${grey100};
  ${fontFamilyStyles};
  ${getTypographyStyles('heading4Medium')}
  position: relative;
`;

export const CalculatorTextInput = styled.input.attrs({
  type: 'text',
  pattern: '([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]',
  'data-testid': 'calculator-input',
})`
  ${inputStyles()};
  border-radius: ${spacings['4px']} 0 0 ${spacings['4px']};
  border-right: none;
  width: 100%;
  &:disabled {
    background-color: ${grey200};
    color: ${grey600};
  }
`;
