import { ArticleType } from '../../../slices/contentHubCards/types';

type Props = {
  date?: string | null;
  hubspotFormID?: string | null;
  recording?: string | null;
};

export type EventState = 'Register' | 'Watch now' | 'eventHasPassed' | undefined;
/**
 * Returns an event state based on these three properties provided.
 */
export const getEventState = ({ date, hubspotFormID, recording }: Props): EventState => {
  const eventDate = date && new Date(date).toDateString();
  const todayDate = new Date().toDateString();
  const isToday = eventDate === todayDate;

  const hasPassed = !isToday && date && new Date(date) < new Date();

  const hasSignUp = !!hubspotFormID;
  const recordingAvailable = !!recording;

  if (!hasPassed && hasSignUp) return 'Register';
  if (hasPassed && hasSignUp && recordingAvailable) return 'Watch now';
  if (hasPassed && !recordingAvailable) return 'eventHasPassed';
};
/**
 * Returns a boolean as to whether to render the gated button or not.
 */
export const shouldRenderButton = (detailState: EventState) => detailState && detailState !== 'eventHasPassed';

const getEventOrWebinarText = (type: ArticleType) => (type === 'event' ? 'View event' : 'View webinar');

/**
 * Gets the button text of an Event Card based on its state and/or type.
 */
export const getEventCardButtonText = (eventState: EventState, articleType: ArticleType) =>
  shouldRenderButton(eventState) ? eventState : getEventOrWebinarText(articleType);
