/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ActiveFilterButton } from '../../../../design/components/button/styles/buttonType/blog/blogFilter';
import { breakpointSmall } from '../../../../design/responsive/breakpoints';
import { BodyP } from '../../../../design/typography/Typography';
import ChevronArrow from '../../../../images/react-icons/Chevron.inline.svg';
import { ButtonMap, ExpandCollapseContainer, FilterContainer, FilterOptions } from '../TypeFilter/FiltersShared';

const TopicsContainer = styled((props) => <FilterContainer {...props} />)`
  width: 100%;
  ${breakpointSmall(css`
    max-width: 310px;
  `)}
`;

const TopicsActiveButton = styled((props) => <ActiveFilterButton {...props} />)`
  justify-content: space-between;
`;

export const Chevron = styled(ChevronArrow)<FilterOptions>`
  ${({ expand }) => (expand ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')}
  transition: transform 0.3s ease;
`;

type FilterProps = {
  topics: string[];
  handleFilter: (topic: string) => void;
  queryType?: string | null;
};
/**
 * Topic Filter component: sits inside PostsGrid component on Blog Lannding page.
 */
const PostFilter: FC<FilterProps> = ({ topics, handleFilter, queryType }) => {
  const filterContainer = useRef<HTMLHeadingElement>(null);
  const scrollHeight = filterContainer?.current?.scrollHeight;
  const [expandTopics, setExpandTopics] = useState<boolean>(false);
  const [activeTopic, setActiveTopic] = useState<string>(topics[0]);

  const toggleMenu = () => {
    setExpandTopics(!expandTopics);
  };

  const setFilter = (topic: string) => {
    handleFilter(topic);
    setActiveTopic(topic);
    setExpandTopics(false);
  };

  useEffect(() => {
    if (queryType) setFilter(queryType);
  }, [queryType]);

  return (
    <TopicsContainer align="left">
      <TopicsActiveButton
        expand={expandTopics}
        onClick={toggleMenu}
        styleType="tertiary"
        data-testid="topic-post-filter"
      >
        <BodyP type="bodyMMedium">{activeTopic}</BodyP>
        <Chevron expand={expandTopics} />
      </TopicsActiveButton>
      <ExpandCollapseContainer
        expand={expandTopics}
        ref={filterContainer}
        maxHeight={scrollHeight}
        data-testid="topic-filters-container"
      >
        <ButtonMap values={topics} isTopics onClick={setFilter} activeValue={activeTopic} excludeActive />
      </ExpandCollapseContainer>
    </TopicsContainer>
  );
};

export default PostFilter;
