import { JSXMapSerializer } from '@prismicio/react';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { isBreakpointMap } from '../../design/responsive/generateResponsiveStyles';
import { TypographyStyleType, TypographyType } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
import RichText, { PrismicRichTextType, RichTextProps } from './RichText';

export type RichTextComponentType = keyof JSXMapSerializer;
export type ConfigurableTextType = TypographyType | Partial<Record<RichTextComponentType, TypographyType>>;
const isTextType = (configurableTextType: ConfigurableTextType): configurableTextType is TypographyType =>
  typeof configurableTextType === 'string' || isBreakpointMap(configurableTextType as TypographyType);
/**
 * Gets the text type given a configurable text type and a rich text component type.
 */
export const getConcreteTextType = (richTextComponentType: RichTextComponentType, textType?: ConfigurableTextType) =>
  textType && (isTextType(textType) ? textType : textType[richTextComponentType]);

type Props = { children: PrismicRichTextType } & Partial<Omit<RichTextProps, 'field' | 'textType'>> & WithClassName;
/**
 * Creates a RichText component with baked in provided textType.
 *
 * The returned component has a children prop that is a rich text field type so the
 * component can be used as a drop in replacement for existing typography components.
 */
export const createRichTextComponent = (textType: TypographyStyleType) => {
  const componentName = upperFirst(textType);
  const componentNameProvider = {
    [componentName]: ({ className, children, ...richTextprops }: Props) => (
      <RichText className={className} textType={textType} field={children} {...richTextprops} />
    ),
  };
  return componentNameProvider[componentName];
};

/**
 * Given a rich text field, extracts the text out of it and returns it.
 */
export const getRichTextFieldText = (richTextField: PrismicRichTextType) =>
  richTextField?.richText.map(({ text }: { text: string }) => text).join('');
