import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { margin } from '../../../design/animations/display';
import { brand2 } from '../../../design/colors/brand';
import { breakpointMedium } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';

const s24 = spacings['24px'];
const StyledItalicText = styled(BodyP)`
  font-style: italic;
  border-radius: 0;
  padding: 0 ${s24};
  ${margin({ left: '24px', top: '24px' })};
  border-left: 2px solid ${brand2};
  ${breakpointMedium(css`
    ${margin({ left: '24px', top: 'none' })};
  `)}
`;

type CustomerQuoteProps = {
  excerpt?: string | null;
};

/**
 * Info Box component for Customer Story Page.
 */
const CustomerQuote: FC<CustomerQuoteProps> = ({ excerpt }) => {
  return <>{excerpt && <StyledItalicText type="bodyLRegular">{excerpt} </StyledItalicText>}</>;
};

export default CustomerQuote;
