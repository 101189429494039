import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { IconType } from '../../../design/icons/Icon';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnThreeCards from '../TwoColumnThreeCards';

type TwoColumnThreeCardsDataProps = SliceProps<
  'threeColumnCards',
  SliceVariation<'default', Queries.PrismicTwoColumnThreeCardsDefaultPrimary>
> &
  WithClassName;

/**
 * TwoColumnThreeCards data component: specifies the fragment and passes the appropriate data to the TwoColumnThreeCards component.
 */
const TwoColumnThreeCardsData: FC<TwoColumnThreeCardsDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  return (
    <TwoColumnThreeCards
      className={className}
      heading={data.heading}
      text={stringData(data.text)}
      leftCard={{
        heading: stringData(data.leftCardHeading),
        text: stringData(data.leftCardText),
        icon: data.leftCardIcon as IconType,
        bronze: {
          heading: stringData(data.leftCardBronzeHeading),
          text: stringData(data.leftCardBronzeText),
          featureText: stringData(data.leftCardBronzeFeatureText),
          subText: stringData(data.leftCardBronzeSubText),
        },
        silver: {
          heading: stringData(data.leftCardSilverHeading),
          text: stringData(data.leftCardSilverText),
          featureText: stringData(data.leftCardSilverFeatureText),
          subText: stringData(data.leftCardSilverSubText),
        },
        gold: {
          heading: stringData(data.leftCardGoldHeading),
          text: stringData(data.leftCardGoldText),
          featureText: stringData(data.leftCardGoldFeatureText),
          subText: stringData(data.leftCardGoldSubText),
        },
      }}
      topRightCard={{
        heading: stringData(data.topRightCardHeading),
        text: stringData(data.topRightCardText),
        icon: data.topRightCardIcon as IconType,
      }}
      bottomRightCard={{
        heading: stringData(data.bottomRightCardHeading),
        text: stringData(data.bottomRightCardText),
        icon: data.bottomRightCardIcon as IconType,
      }}
    />
  );
};

export default TwoColumnThreeCardsData;

/**
 * @todo Convert card headings to rich text.
 */
export const query = graphql`
  fragment TwoColumnThreeCards on PrismicTwoColumnThreeCardsDefault {
    primary {
      heading {
        ...RichText
      }
      text
      leftCardIcon
      leftCardHeading
      leftCardText
      leftCardBronzeHeading
      leftCardBronzeText
      leftCardBronzeFeatureText
      leftCardBronzeSubText
      leftCardSilverHeading
      leftCardSilverText
      leftCardSilverFeatureText
      leftCardSilverSubText
      leftCardGoldHeading
      leftCardGoldText
      leftCardGoldFeatureText
      leftCardGoldSubText
      topRightCardIcon
      topRightCardHeading
      topRightCardText
      bottomRightCardIcon
      bottomRightCardHeading
      bottomRightCardText
    }
  }
`;
