import pick from 'lodash/pick';
import { css } from 'styled-components';
import { breakpointSmall } from '../../responsive/breakpoints';
import { spacings } from '../../spacing/Spacing';

const verticalPaddings = pick(spacings, ['none', '100px', '144px', '200px']);
export type VerticalPadding = keyof typeof verticalPaddings;

export const sliceContentContainerMobilePadding = spacings['48px'];
/**
 * Gets vertical padding amount for slice content.
 */
export const getSliceContentContainerVerticalPadding = (verticalPadding: VerticalPadding) =>
  verticalPaddings[verticalPadding];

/**
 * Adds vertical padding for slice content.
 */
export const sliceContentContainerVerticalPaddingStyles = (verticalPadding: VerticalPadding = '100px') => {
  const padding = getSliceContentContainerVerticalPadding(verticalPadding);
  return css`
    padding-top: ${sliceContentContainerMobilePadding};
    padding-bottom: ${sliceContentContainerMobilePadding};

    ${breakpointSmall(css`
      padding-top: ${padding};
      padding-bottom: ${padding};
    `)}
  `;
};

const maxWidths = ['814px', '1286px'] as const;
export type MaxWidth = typeof maxWidths[number];
/**
 * Adds max width for slice content.
 */
export const sliceContentContainerMaxWidthStyles = (maxWidth: MaxWidth = '1286px') => css`
  max-width: ${maxWidth};
`;
