import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { LinkType } from '../../components/link/types';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink } from '../../design/components/button/Button';
import { Background, isDarkBackground, Panel } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import { BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
import { SocialProofIcons } from '../socialProof/SocialProof';
import { IconLinkMapping } from '../socialProof/data/utils';

const SocialProofPanel = styled(Panel)`
  width: 100%;
`;
const verticalPadding = '32px';
const horizontalPadding = '48px';
SocialProofPanel.defaultProps = {
  background: 'brandGlass',
  padding: { top: verticalPadding, bottom: verticalPadding, left: horizontalPadding, right: horizontalPadding },
  borderRadius: '12px',
};

type SocialProofSectionProps = { socialProofIcons?: IconLinkMapping } & WithClassName;
const SocialProofSection: FC<SocialProofSectionProps> = ({ className, socialProofIcons }) => (
  <SocialProofPanel className={className} data-testid="social-proof-section">
    <SocialProofIcons icons={socialProofIcons} justify="space-between" />
  </SocialProofPanel>
);

const SpacingExceptFirst = styled(Spacing)`
  &:first-child {
    margin: 0;
  }
`;

type CardsProps = {
  background: Background;
  heading: PrismicRichTextType;
  text?: string | null;
  socialProofIcons?: IconLinkMapping;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  cardsPlacement?: ReactElement;
} & WithClassName;
/**
 * Renders Cards slice.
 */
const Cards: FC<CardsProps> = ({
  className,
  background,
  heading,
  text,
  socialProofIcons,
  primaryCta,
  secondaryCta,
  cardsPlacement,
}) => {
  const secondaryButtonType = isDarkBackground(background) ? 'reverseSecondary' : 'secondary';
  const headingPresent = !!(heading && heading.richText[0] && heading.richText[0].text);

  return (
    <SliceContainer className={className} background={background}>
      <CenteredSliceContentContainer>
        {headingPresent && <RichText textType="heading2" field={heading} data-testid="heading" />}
        {text && (
          <SpacingExceptFirst top="24px">
            <BodyP type="bodyLRegular">{text}</BodyP>
          </SpacingExceptFirst>
        )}
        {!!socialProofIcons?.length && (
          <SpacingExceptFirst top="48px">
            <SocialProofSection socialProofIcons={socialProofIcons} />
          </SpacingExceptFirst>
        )}
        {cardsPlacement && <SpacingExceptFirst top="24px">{cardsPlacement}</SpacingExceptFirst>}
        <SpacingExceptFirst top="48px">
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonLink styleType={secondaryButtonType} link={secondaryCta.link}>
                {secondaryCta.text}
              </ButtonLink>
            }
          />
        </SpacingExceptFirst>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Cards;
