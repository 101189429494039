import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { flex, padding } from '../../../../design/animations/display';
import { Button } from '../../../../design/components/button/Button';
import { breakpointXSmall } from '../../../../design/responsive/breakpoints';
import { nthBackgroundConstructer, nthTagConstructer } from '../../../../design/utils/nthBackgroundProvider';
import { backgroundPattern, tagThemes } from '../../ContentHubCards.helpers';
import { HubContent, HubEvent, HubGuide, HubPost, HubStory } from '../../types';
import BlogPostCard from '../Cards/BlogPostCard/BlogPostCard';
import EventCard from '../Cards/EventCard/EventCard';
import GuideCard from '../Cards/GuideCard/GuideCard';
import StoryCard from '../Cards/StoryCard/StoryCard';

export const PaginationButton = styled(Button)`
  max-width: fit-content;
`;

export const PageCountContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  ${padding({ y: '40px' })}
  ${flex({ direction: 'column', gap: '16px', align: 'center' })}
  ${breakpointXSmall(css`
    ${flex({ justify: 'space-between', align: 'center', direction: 'row' })}
  `)}
`;

/**
 * Posts to render for Paginated Items.
 */
export const Posts: FC<{ currentItems: HubContent[] }> = ({ currentItems }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((post: HubPost | HubEvent | HubGuide | HubStory | undefined, index: number) => {
          const isPost: HubPost | undefined = post && 'author' in post ? post : undefined;
          const isEvent: HubEvent | undefined = post && 'time' in post ? post : undefined;
          const isStory: HubStory | undefined = post && 'industry' in post ? post : undefined;
          const isGuide: HubGuide | undefined = post && 'isResearch' in post ? post : undefined;
          const background = nthBackgroundConstructer(backgroundPattern, index);
          const theme = nthTagConstructer(tagThemes, index);

          return (
            <Fragment key={post?.url || ''}>
              {isPost && <BlogPostCard {...isPost} background={background} theme={theme} />}
              {isEvent && <EventCard {...isEvent} background={background} theme={theme} />}
              {isGuide && <GuideCard {...isGuide} background={background} theme={theme} />}
              {isStory && <StoryCard {...isStory} background={background} theme={theme} />}
            </Fragment>
          );
        })}
    </>
  );
};
