import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../design/animations/display';
import { jump } from '../../../design/animations/micro-interactions';
import { brand1, brand3, brand4 } from '../../../design/colors/brand';
import { white } from '../../../design/colors/greyscale';
import { ButtonLink } from '../../../design/components/button/Button';
import { Panel } from '../../../design/containers/panel/Panel';
import BaseColumnSmallRowContainer from '../../../design/containers/responsive/BaseColumnSmallRowContainer';
import { breakpointMedium, breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodySpan, Heading3 } from '../../../design/typography/Typography';
import { Defined, WithClassName, WithTestId } from '../../../utilityTypes';
import List from '../list/List';
import { PricingItem } from '../types';
import Price from './Price';

const CardsContainer = styled(BaseColumnSmallRowContainer)`
  flex-direction: column;
  justify-content: center;
  gap: ${spacings['24px']};
  width: 100%;
  ${breakpointSmall(css`
    flex-direction: row;
    align-items: flex-end;
    gap: ${spacings['16px']};
  `)};
  ${breakpointMedium(css`
    gap: ${spacings['24px']};
  `)}
`;

const getCardPanelStyle = (index: number) => {
  const style = [
    { color: white, background: `${brand3}d6` },
    { color: brand4, background: white, 'border-top': `8px solid ${brand1}` },
    { color: white, background: 'linear-gradient(180deg,#2b3d58fa 0%,#151f2e 100%)' },
  ];
  return style[index];
};

type CardProps = { index: number; 'data-testid': string };
const CardPanel = styled(Panel)<CardProps>`
  ${jump({ height: '8px' })};
  flex: 1;
  align-self: center;
  max-width: 416px;
  min-width: 224px;
  ${({ index }) => getCardPanelStyle(index)};
  ${breakpointMedium(css`
    align-items: flex-end;
    align-self: end;
  `)};
`;
CardPanel.defaultProps = {
  shadow: 'bigShadowStyles',
  padding: { base: '32px', breakpointSmall: '24px', breakpointMedium: '32px', breakpointLarge: '48px' },
  borderRadius: '12px',
};

const PillTag = styled(Panel).attrs({ background: 'brand3Solid' })`
  width: fit-content;
  min-width: 110px;
  padding: ${spacings['12px']};
  height: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const PriceRow = styled.div`
  ${flex({ gap: '8px', align: 'center', wrap: 'wrap-reverse' })}
  ${breakpointMedium(css`
    ${flex({ gap: '16px', align: 'center', wrap: 'wrap-reverse' })}
  `)}
`;

export const HalfWidthButton = styled(ButtonLink)`
  width: fit-content;
`;

const Heading: FC<Pick<PricingItem, 'heading' | 'tagText'>> = ({ heading, tagText }) => (
  <PriceRow>
    <Spacing right="12px">
      <Heading3 textAlign="left" type={{ base: 'heading4', breakpointMedium: 'heading3' }}>
        {heading}
      </Heading3>
    </Spacing>
    {tagText && (
      <PillTag>
        <BodySpan type="bodyXSBold">{tagText}</BodySpan>
      </PillTag>
    )}
  </PriceRow>
);

export type ItemPricingProps = { items: PricingItem[] } & WithClassName & WithTestId;
/**
 * Renders the three pricing columns for Pricing slice.
 */
const PricingColumns: FC<ItemPricingProps> = ({ items, className }) => {
  return (
    <Spacing bottom={{ base: '32px', breakpointSmall: '64px' }}>
      <CardsContainer className={className}>
        {items?.map((item, index) => {
          const {
            heading,
            price,
            pricingPeriod,
            ctaButtonText,
            ctaButtonLink,
            pricingSubtext,
            itemHeading,
            tagText,
            ...listItems
          } = item;
          const listedItems = Object.values(listItems).filter((item): item is Defined<typeof item> => !!item);
          return (
            <CardPanel key={heading} index={index} data-testid="repeatable-card">
              <Heading heading={heading} tagText={tagText} />
              <Price price={price} pricingPeriod={pricingPeriod} pricingSubtext={pricingSubtext} />
              <Spacing top={{ base: '16px', breakpointMedium: '24px' }}>
                <HalfWidthButton size="medium" styleType="primary" link={ctaButtonLink} data-testid="pricing-card-cta">
                  {ctaButtonText}
                </HalfWidthButton>
              </Spacing>
              <Spacing top={{ base: '16px', breakpointMedium: '24px' }}>
                <List className={className} heading={itemHeading} items={listedItems} reverse={index % 2 !== 0} />
              </Spacing>
            </CardPanel>
          );
        })}
      </CardsContainer>
    </Spacing>
  );
};

export default PricingColumns;
