import { css } from 'styled-components';
import { textStyles } from '../../../../../typography/base/Text';

/**
 * Generates small button text styles.
 */
export const smallButtonTextStyles = () => textStyles({ fontSize: '14pt', fontWeight: '500', lineHeight: '150%' });

/**
 * Generates small button styles.
 */
export const smallStyles = () => css`
  height: 32px;
  width: unset;
  border-radius: 50px;
  padding: 6px 12px;
  ${smallButtonTextStyles()}
`;
