import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../design/animations/display';
import { brand1Dark } from '../../../design/colors/brand';
import { grey100, grey200, grey400 } from '../../../design/colors/greyscale';
import { errorDark } from '../../../design/colors/system';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';
import { maxAllowedMoneyAmount } from '../utils';
import { CalculatorDropdown } from './components/CalculatorDropdown';
import { CalculatorTextInput } from './components/CalculatorTextInput';
import { Currency } from './types';
import { currencyAssets, inputErrors } from './utils';

export type CalculatorInputProps = {
  currency: Currency;
  label: string;
  testId?: string;
  value?: number;
  onChange: (value?: number) => void;
  onChangeCurrency?: (currency: Currency) => void;
  disabled?: boolean;
};

const getBorderStyles = (hasError: boolean) => css`
  border: ${hasError ? errorDark : grey400} 1px solid;
  &:focus-within {
    border: ${hasError ? errorDark : brand1Dark} 1px solid;
  }
`;

const InputContainer = styled.div<{ hasError: boolean; disabled: boolean }>`
  ${({ hasError }) => getBorderStyles(hasError)};
  ${flex({ align: 'center' })};
  width: 100%;
  border-radius: ${spacings['4px']};
  background-color: ${({ disabled }) => (disabled ? grey200 : grey100)};
`;

const ErrorMessage = styled(BodyP)<{ hasError: boolean }>`
  color: ${errorDark};
  margin-top: 4px;
  opacity: ${({ hasError }) => (hasError ? 1 : 0)};
`;

const Label = styled.label`
  ${fontFamilyStyles};
  ${getTypographyStyles('bodyLMedium')};
  display: block;
  padding-bottom: 12px;
`;
/**
 * Render CalculatorInput component.
 */
export const CalculatorInput: FC<CalculatorInputProps> = ({
  currency,
  label,
  testId,
  onChange,
  onChangeCurrency,
  value,
  disabled,
}) => {
  const [error, setError] = useState('');

  const currencySymbol = currencyAssets[currency].symbol;
  const displayValue = `${currencySymbol}${value || value === 0 ? value.toLocaleString() : ''}`;

  const setErrors = (number: number) => {
    if (number > maxAllowedMoneyAmount) return setError(inputErrors.isGreaterThanMillion);
    if (number === 0) return setError(inputErrors.isZero);
    return setError('');
  };

  const reset = () => {
    setError('');
    onChange();
  };

  const update = (number: number) => {
    setErrors(number);
    onChange(number);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(event.target.value.replaceAll(',', '').replace(currencySymbol, ''));

    if (event.target.value === currencySymbol || !number) reset();
    if (number || number === 0) update(number);
  };

  useEffect(() => {
    value && setErrors(value);
  }, [value]);

  return (
    <div data-testid={testId || 'calculator-input-container'}>
      <Label data-testid="calculator-input-label">{label}</Label>
      <InputContainer hasError={!!error} disabled={!!disabled}>
        <CalculatorTextInput value={displayValue} onChange={onInputChange} disabled={disabled} />
        <CalculatorDropdown onChangeCurrency={onChangeCurrency} currency={currency} />
      </InputContainer>
      <ErrorMessage hasError={!!error}>{error || '.'}</ErrorMessage>
    </div>
  );
};
