import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1 } from '../../../design/colors/brand';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, BodySpan } from '../../../design/typography/Typography';
import { WithTestId } from '../../../utilityTypes';

const Card = styled(Panel)`
  ${breakpointSmall(
    css`
      min-height: 396px;
      flex: 1;
      flex-basis: 50%;
    `
  )};
`;
Card.defaultProps = {
  borderRadius: `${spacings['12px']}`,
  background: 'lightOpaqueCards',
  padding: { base: '24px', breakpointSmall: '48px' },
};

const Quote = styled(BodyP)`
  padding-bottom: ${spacings['32px']};
`;

const Name = styled(BodySpan)`
  color: ${brand1};
`;

type QuoteData = {
  name?: string;
  company?: string;
  quote?: string;
  role?: string;
};

type QuoteProps = QuoteData & WithTestId;
/**
 * Renders QuoteCard Comp.
 */
const QuoteCard: FC<QuoteProps> = ({ quote, name, role, company, 'data-testid': testId }) => {
  return (
    <Card data-testid={testId}>
      {quote && (
        <Quote type="quotes" textAlign="left">
          &quot;{quote}&quot;
        </Quote>
      )}
      {name && <Name type="bodyLBold">{name}</Name>}
      {role && <BodySpan type="bodyLRegular"> | {role}</BodySpan>}
      {company && <BodySpan type="bodyLRegular">, {company}</BodySpan>}
    </Card>
  );
};

export default QuoteCard;
