import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../../components/image/prismicImage/PrismicImage';
import Link from '../../../../components/link/Link';
import { PrismicLinkType } from '../../../../components/link/data/Link.generateData';
import { flex, onHover } from '../../../../design/animations/display';
import { jump } from '../../../../design/animations/micro-interactions';
import { blogWysiwygShadow } from '../../../../design/colors/shadows';
import { ButtonLink } from '../../../../design/components/button/Button';
import { Panel } from '../../../../design/containers/panel/Panel';
import { breakpointXLarge } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { BodyP, Heading3 } from '../../../../design/typography/Typography';
import { multiLineEllipsis } from '../../../../design/typography/base/utils';

export const space24 = `${spacings['24px']}`;
export const space40 = `${spacings['40px']}`;

export const Excerpt = styled(BodyP)`
  ${multiLineEllipsis(4)};
`;

type CardTitleProps = {
  truncateTitle?: boolean;
  link: PrismicLinkType;
} & PropsWithChildren;

const StyledHeading3 = styled(Heading3)<{ truncateTitle?: boolean }>`
  ${({ truncateTitle }) => truncateTitle && multiLineEllipsis(2)}
`;

/**
 * Title for content hub cards.
 */
export const CardTitle: FC<CardTitleProps> = ({ truncateTitle, children, link }) => (
  <Link link={link}>
    <StyledHeading3
      truncateTitle={truncateTitle}
      textAlign="left"
      type={{ base: 'heading3', breakpointSmall: 'heading4', breakpointXLarge: 'heading3' }}
    >
      {children}
    </StyledHeading3>
  </Link>
);

export const Cta = styled(ButtonLink)`
  max-width: fit-content;
  margin-top: ${space24};
`;

export const Card = styled(Panel)`
  ${jump}
  ${blogWysiwygShadow}
  ${flex({ direction: 'column' })}
  ${onHover(css`
    h3 {
      text-decoration: underline;
      text-underline-offset: 6px;
      text-decoration-thickness: 0px;
    }
  `)}
`;

export const TextContainer = styled.div`
  ${flex({ direction: 'column', gap: '16px' })}
`;
export const CardContent = styled.div`
  ${flex({ direction: 'column', justify: 'space-between' })}
  flex: 1;
  padding: ${space24};
  ${breakpointXLarge(
    css`
      padding: ${space40};
    `
  )};
`;

export const CardImage = styled(PrismicImage)`
  && {
    border-radius: ${space24} ${space24} 0 0;
    max-height: 320px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
`;
