import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import {
  breakpointLarge,
  breakpointMedium,
  breakpointSmall,
  breakpointXSmall,
} from '../../../design/responsive/breakpoints';
import { WithClassName } from '../../../utilityTypes';
import SvgLogoMask from './logoMask.inline.svg';

const desktopAlign = css`
  ${breakpointMedium(css`
    display: block;
    transform: scale(0.8) translate(66%, -27%);
  `)}
  ${breakpointLarge(css`
    transform: scale(0.8) translate(66%, -27%);
  `)}
`;

const mobileAlign = css`
  transform: scale(0.4);
  top: -30%;
  left: -10%;
  ${breakpointXSmall(css`
    transform: scale(0.5);
    top: -25%;
    left: 0;
  `)}
`;

const MaskedImageContainer = styled.div`
  position: relative;
  ${mobileAlign}
  ${breakpointSmall(css`
    display: none;
  `)}
  ${desktopAlign};
`;

const StyledPrismicImage = styled(PrismicImage)`
  && {
    position: absolute;
    clip-path: url(#quote-image-mask);
    top: 0;
    ${breakpointSmall(css`
      left: 0;
      height: 100%;
    `)}
  }
`;

type MaskedImageProps = {
  image: ComponentProps<typeof PrismicImage>['image'] | null;
} & WithClassName;
/**
 * Masked Image Component.
 */
const MaskedImage: FC<MaskedImageProps> = ({ image, className }) => (
  <MaskedImageContainer className={className || ''}>
    <SvgLogoMask />
    <StyledPrismicImage image={image} />
  </MaskedImageContainer>
);

export default MaskedImage;
