import React, { FC } from 'react';
import styled from 'styled-components';
import { PrismicIcon } from '../../../../components/prismicIcon/PrismicIcon';
import { PrismicIconType } from '../../../../components/prismicIcon/types';
import { WithTestId } from '../../../../utilityTypes';
import { toggleAlignment } from '../../../animations/display';
import { Background, isDarkBackground } from '../../../containers/panel/Panel';
import { BodyP } from '../../../typography/Typography';
import { CardType } from '../types';

type CardTopContainerProps = { align?: 'left' | 'right' };
const CardTopContainer = styled.div<CardTopContainerProps>`
  ${({ align }) => (align ? toggleAlignment(align) : toggleAlignment('left'))}
`;
const CardNumber = styled(BodyP).attrs({ type: 'numbersLight' })``;

type CardTopProps = {
  index?: number;
  icon?: PrismicIconType;
  cardType?: CardType;
  background: Background;
} & WithTestId;
/**
 * Renders Card comp.
 */
const CardTopper: FC<CardTopProps> = ({ index, 'data-testid': testId, cardType, icon, background }) => {
  const hasIndex = !!index || index === 0;
  const isNumberedVariant = cardType === 'numbered' && hasIndex;
  const isIconVariant = (cardType === 'iconLeft' || 'iconRight') && icon;
  const align = cardType === 'iconRight' ? 'right' : 'left';
  const reverseIcon = isDarkBackground(background);
  return (
    <CardTopContainer data-testid={`${testId}-card-top`} align={align}>
      {isIconVariant && <PrismicIcon data-testid="card-icon" icon={icon} reverse={reverseIcon} />}
      {isNumberedVariant && <CardNumber data-testid="card-number">0{index + 1}</CardNumber>}
    </CardTopContainer>
  );
};

export default CardTopper;
