import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FxCalculator } from '../../components/fx-calculator/FxCalculator';
import { RawImage } from '../../components/image/rawImage/RawImage';
import { PrismicLinkType } from '../../components/link/data/Link.generateData';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { flex, grid } from '../../design/animations/display';
import { cardShadowsSubtle } from '../../design/colors/shadows';
import { ButtonLink } from '../../design/components/button/Button';
import { Panel } from '../../design/containers/panel/Panel';
import { breakpointMedium, breakpointXSmall } from '../../design/responsive/breakpoints';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import cardStack from '../../images/product/visa-card-stack.png';
import { fxContainerTestId } from './fxCalculatorSlice.testIds';

type FxCalculatorSliceProps = {
  title: PrismicRichTextType;
  primaryCtaText: string;
  primaryCtaLink: PrismicLinkType | null;
  secondaryCtaText: string;
  secondaryCtaLink: PrismicLinkType | null;
};

const CenteredContainer = styled(Panel).attrs({ borderRadius: '0px' })`
  margin: 0 auto;
  position: relative;
  max-width: 1285px;
  z-index: 9;
`;
const Grid = styled.div`
  border-radius: 12px;
  ${breakpointMedium(css`
    ${grid({ columns: 2 })};
    ${cardShadowsSubtle}
  `)}
`;
const Background = styled(Panel).attrs({
  background: 'brand5WeelLogoBase',
  padding: { base: 'none', breakpointSmall: '60px', breakpointXSmall: '32px' },
  borderRadius: '12px',
})``;

const LeftColumn = styled(Panel).attrs({
  background: 'brand3SolidStripedCurve',
  padding: { base: '32px', breakpointSmall: '48px' },
})`
  ${flex({ direction: 'column', justify: 'center', align: 'center', gap: '24px' })}
  border-radius: 12px 12px 0 0;
  ${breakpointMedium(css`
    border-radius: 12px 0 0 12px;
  `)}
`;

const RightColumn = styled(Panel).attrs({
  background: 'whiteSolid',
  padding: { base: '24px', breakpointSmall: '60px', breakpointXSmall: '32px' },
})`
  position: relative;
  display: flex;
  overflow: visible;
  top: -24px;
  border-radius: 0 0 12px 12px;
  ${breakpointMedium(css`
    border-radius: 0 12px 12px 0;
  `)}
  ${breakpointXSmall(css`
    top: 0;
  `)}
`;

const Image = styled(RawImage)`
  max-height: 300px;
  width: auto;
`;

const FooterContainer = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center', gap: '24px' })};
  padding: 0 24px 24px;
  max-width: 665px;
  margin: 0 auto;
  ${breakpointXSmall(css`
    padding: 24px;
  `)}
`;

const Title = styled(Heading2)`
  white-space: break-spaces;
`;

const Cta = styled(ButtonLink)`
  white-space: nowrap;
  text-align: center;
`;

const CtaContainer = styled.div`
  ${flex({ direction: 'column', gap: '16px' })}
  ${breakpointXSmall(css`
    ${flex({ justify: 'center', align: 'center', gap: '16px', direction: 'row' })}
  `)}
`;

/**
 * Render FxCalculatorSlice slice.
 */
export const FxCalculatorSlice: FC<FxCalculatorSliceProps> = ({
  title,
  primaryCtaText,
  primaryCtaLink,
  secondaryCtaText,
  secondaryCtaLink,
}) => {
  return (
    <Background data-testid={fxContainerTestId}>
      <CenteredContainer>
        <Grid>
          <LeftColumn>
            <Title>{title}</Title>
            <Image src={cardStack} alt="Visa card stack" />
          </LeftColumn>
          <RightColumn>
            <FxCalculator />
          </RightColumn>
        </Grid>
        <FooterContainer>
          <CtaContainer>
            <Cta link={primaryCtaLink}>{primaryCtaText}</Cta>
            <Cta link={secondaryCtaLink} styleType="reverseTertiary">
              {secondaryCtaText}
            </Cta>
          </CtaContainer>
          <BodyP textAlign="center">
            The amounts above are calculated using the Visa exchange rate. Please note that the rate available at the
            time you make an international card payment on the Weel Visa Business Debit Card may be different and the
            foreign exchange rate applied will be the rate available at the time of processing the transaction.
          </BodyP>
        </FooterContainer>
      </CenteredContainer>
    </Background>
  );
};
