import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { IconType } from '../../../design/icons/Icon';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import Demo, { IconProps } from '../Demo';

type DemoProps = SliceProps<
  'demo',
  SliceVariation<'default', Queries.PrismicDemoDefaultPrimary, Queries.PrismicDemoDefaultItem>
> &
  WithClassName;

/**
 * Demo data component: specifies the fragment and passes the appropriate data to the Demo component.
 */
const DemoData: FC<DemoProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  const icons: IconProps[] = [
    { image: data?.topLeftIcon as IconType, text: stringData(data?.topLeftText) },
    { image: data?.bottomLeftIcon as IconType, text: stringData(data?.bottomLeftText) },
    { image: data?.topRightIcon as IconType, text: stringData(data?.topRightText) },
    { image: data?.bottomRightIcon as IconType, text: stringData(data?.bottomRightText) },
  ];
  return (
    <Demo
      className={className}
      heading={data.heading}
      text={stringData(data?.text)}
      form={{
        subHeading: stringData(data?.subHeading),
        icons: icons || [],
        partnershipHeading: stringData(data?.partnershipHeading),
        hubspot: {
          formType: data?.formType || 'Hubspot Form',
          id: stringData(data?.hubspotFormId),
          link: data?.hubspotMeetingLink,
          trackingEvent: stringData(data?.trackingEventName),
          referrer: stringData(data?.tracking_referrer),
        },
        logos: slice.items,
        postLogoImage: slice.primary.post_logo_mark,
      }}
    />
  );
};

export default DemoData;

export const query = graphql`
  fragment Demo on PrismicDemoDefault {
    primary {
      heading {
        ...RichText
      }
      post_logo_mark {
        alt
        gatsbyImageData(layout: CONSTRAINED, height: 36)
      }
      text
      subHeading
      topLeftIcon
      topLeftText
      bottomLeftIcon
      bottomLeftText
      topRightIcon
      topRightText
      bottomRightIcon
      bottomRightText
      partnershipHeading
      formType
      hubspotFormId
      hubspotMeetingLink {
        ...PrismicLink
      }
      trackingEventName
      tracking_referrer
    }
    items {
      logo {
        alt
        gatsbyImageData(height: 36)
      }
    }
  }
`;
