import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage, PrismicImageType } from '../../components/image/prismicImage/PrismicImage';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { grow } from '../../design/animations/micro-interactions';
import { grey300, grey700 } from '../../design/colors/greyscale';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utilityTypes';

type ColorProps = { color: string };
const StyledRichText = styled(RichText)<ColorProps>`
  color: ${grey700};
`;

const SponsorContainer = styled.div`
  border-top: 1px solid ${grey300};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 450px;
  ${breakpointSmall(css`
    border-top: none;
    border-left: 1px solid ${grey300};
    padding-left: ${spacings['24px']};
    max-width: none;
  `)}
`;

type SponsorProps = {
  sponsor?: PrismicImageType;
  sponsorText?: PrismicRichTextType;
};

type SponsorsProps = {
  title?: PrismicRichTextType;
  items?: SponsorProps[];
} & WithClassName;

const Sponsor: FC<SponsorProps> = ({ sponsor, sponsorText }) => (
  <SponsorContainer>
    {sponsor && (
      <Spacing left="16px" top="32px" bottom="16px">
        <ImageIcon image={sponsor} />
      </Spacing>
    )}
    {sponsorText && (
      <StyledRichText
        field={sponsorText}
        textType="bodySRegular"
        textAlign={{ base: 'center', breakpointSmall: 'left' }}
        color={grey700}
      />
    )}
  </SponsorContainer>
);

const ContentContainer = styled.div`
  gap: ${spacings['24px']};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${spacings['24px']} ${spacings['24px']} ${spacings['24px']} 0;
  max-width: 1286px;
  ${breakpointSmall(css`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr;
    padding: 0;
    gap: ${spacings['24px']};
    margin-bottom: ${spacings['24px']};
  `)}
`;

const ImageIcon = styled(PrismicImage)`
  && {
    ${grow}
    margin-right: auto;
    margin-left: auto;
    max-height: 25px;
    width: auto;
    max-width: 200px;
    ${breakpointSmall(css`
      margin-left: 0;
      img {
        width: auto;
      }
    `)}
  }
`;
ImageIcon.defaultProps = { objectFit: 'contain' };

type ItemsProps = {
  numItems?: number;
};

const GridContainer = styled.div<ItemsProps>`
  display: grid;
  grid-row-gap: ${spacings['24px']};
  ${(props) =>
    breakpointSmall(css`
      width: 100%;
      grid-column-gap: ${spacings['24px']};
      grid-template-columns: ${props.numItems && props.numItems % 2 === 0 ? '1fr 1fr' : '1fr 1fr 1fr'};
    `)}}
`;

/**
 * Sponsors presentation component.
 */
const Sponsors: FC<SponsorsProps> = ({ className, title, items }) => {
  return (
    <SliceContainer className={className}>
      <ContentContainer>
        {title && <StyledRichText textType="heading4Medium" textAlign="center" color={grey700} field={title} />}
        <GridContainer numItems={items?.length}>
          {items?.map((item, index) => (
            <Sponsor key={index} sponsor={item?.sponsor} sponsorText={item?.sponsorText} />
          ))}
        </GridContainer>
      </ContentContainer>
    </SliceContainer>
  );
};

export default Sponsors;
