import React, { FC } from 'react';
import styled from 'styled-components';
import { flex } from '../../../design/animations/display';
import { grey400, grey700, white } from '../../../design/colors/greyscale';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import DollarSign from '../../../images/react-icons/DollarSign.inline.svg';
import LongArrow from '../../../images/react-icons/LongArrow.inline.svg';
import Refresh from '../../../images/react-icons/Refresh.inline.svg';
import { Currency } from '../input/types';

const IconContainer = styled.figure`
  ${flex({ justify: 'center', align: 'center' })};
  position: relative;
  z-index: 2;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 1px solid ${grey400};
  background-color: ${white};
`;

const StyledArrow = styled(LongArrow)`
  z-index: 1;
`;

const TrailContainer = styled.div`
  ${flex({ direction: 'column', gap: '8px' })}
  position: relative;
  padding: ${spacings['24px']} 0 28px 0;
  margin-bottom: ${spacings['24px']};
  ${StyledArrow} {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(-50%, -50%);
  }
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 30px max-content max-content;
  align-items: center;
  gap: ${spacings['12px']};
`;

type RowProps = { icon: React.ReactNode; metric: string; value: string; testId: string };
const Row = ({ icon, metric, value, testId }: RowProps) => (
  <RowContainer data-testid={testId}>
    <IconContainer>{icon}</IconContainer>
    <BodyP color={grey700} type="bodySRegular">
      {metric}
    </BodyP>
    <BodyP type="bodySMedium">{value}</BodyP>
  </RowContainer>
);

const VisaExchangeRate = styled(Row).attrs({
  icon: <Refresh />,
  metric: 'Visa exchange rate',
  testId: 'exchange-rate',
})``;

const FxMarkup = styled(Row).attrs({
  icon: <DollarSign />,
  metric: 'FX markup',
  value: '0.95%',
  testId: 'fx-markup',
})``;

type InfoTrailProps = {
  exchangeRate: string;
  currency: Currency;
};
/**
 * Render InfoTrail component.
 */
export const InfoTrail: FC<InfoTrailProps> = ({ exchangeRate, currency }) => {
  const formattedRate = exchangeRate ? `1 AUD = ${exchangeRate} ${currency}` : '';
  return (
    <TrailContainer data-testid="info-trail-container">
      <StyledArrow />
      <VisaExchangeRate value={formattedRate} />
      <FxMarkup />
    </TrailContainer>
  );
};
