import { useLocation } from '@reach/router';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import * as site from '../../../config/site';
import { SharedData, generateMasterSchema, generateWebpageSchema } from './structuredData/utils';

export type SEOProps = {
  title?: string;
  description?: string;
  ogImage?: string;
  exclude_from_search_engines?: boolean;
  last_publication_date?: string;
  schema?: string;
};

/**
 * Exports SEO comp.
 */
export const SEO: FC<SEOProps> = ({
  title,
  description,
  ogImage,
  exclude_from_search_engines,
  schema,
  last_publication_date,
}) => {
  const { pathname } = useLocation();
  const url = `${site.siteUrl}${pathname || ''}`;

  const metaTitle = title || site.title;
  const metaImage = ogImage || site.ogImage;
  const metaDesc = description || site.description;

  const pageSchemaData: SharedData = {
    headline: title,
    description,
    image: ogImage,
    uid: pathname,
    datePublished: last_publication_date,
  };

  const graphSchema = JSON.stringify(generateMasterSchema(generateWebpageSchema(pageSchemaData)));

  return (
    <Helmet>
      {/* standard metadata */}
      <html lang="en" />
      <title>{metaTitle}</title>
      <meta name="image" content={metaImage} />
      <meta name="description" content={metaDesc} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* social sharing metadata */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={metaImage} />
      {/* search engine crawlability */}
      {exclude_from_search_engines && <meta name="robots" content="noindex" />}
      {/* schema markup / structured data  */}
      {schema && <script type="application/ld+json">{schema}</script>}
      {graphSchema && <script type="application/ld+json">{graphSchema}</script>}
      {/* links */}
      <link rel="icon" type="image/png" href="/images/brand/icon.png" />
    </Helmet>
  );
};
