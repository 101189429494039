const calculateColumnLength = <T>(column: T[], getItemSize: SortItemsIntoColumnsOptions<T>['getItemSize']) =>
  column.reduce((acc, items) => getItemSize(items) + acc, 0);

type SortItemsIntoColumnsOptions<T> = {
  items: T[];
  getItemSize: (items: T) => number;
  numberOfColumns: number;
};
/**
 * Sorts items into columns so that the columns are evenly filled based
 * on the size of each item.
 */
const sortItemsIntoColumns = <T>({ items, getItemSize, numberOfColumns }: SortItemsIntoColumnsOptions<T>): T[][] => {
  const columns = new Array(numberOfColumns).fill(undefined).map(() => [] as T[]);
  const balancedSections = items.reduce((acc, items) => {
    const columnLengths = acc.map((column) => calculateColumnLength(column, getItemSize));
    const smallestColumnIndex = columnLengths.findIndex((colLength) => colLength === Math.min(...columnLengths));
    const newAcc = [...acc];
    newAcc[smallestColumnIndex] = [...newAcc[smallestColumnIndex], items];
    return newAcc;
  }, columns);
  return balancedSections.filter((items) => items.length);
};

export default sortItemsIntoColumns;
