import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand2, brand2Mid } from '../../design/colors/brand';
import { white } from '../../design/colors/greyscale';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { BrandMarkWithSloganBelow } from '../../design/icons/brandMark/BrandMark';
import { breakpointLarge, breakpointMedium, breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodyLink, BodyP } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
import sortItemsIntoColumns from '../../utils/sortSectionsIntoColumns';
import { LinkType } from '../link/types';
import RichText, { PrismicRichTextType } from '../richText/RichText';

const sectionHeadingSize = 2;
const getSectionLength = (section: Section) => section.links.length + sectionHeadingSize;
const sortSectionsIntoColumns = (sections: Section[], numberOfColumns: number) =>
  sortItemsIntoColumns({ items: sections, getItemSize: getSectionLength, numberOfColumns });

const FooterSectionText = styled(BodyP).attrs({ type: 'bodySMedium' })``;

const FooterSectionHeading = styled(FooterSectionText)`
  color: ${white};
`;

const FooterSectionItem = styled(BodyLink).attrs({
  type: { base: 'bodyXSRegular', breakpointSmall: 'bodySMedium' },
})`
  display: block;
  color: ${brand2Mid};
`;

const FooterSectionContainer = styled.div`
  padding-bottom: ${spacings['32px']};
`;

type FooterSectionProps = { section: Section } & WithClassName;
const FooterSection: FC<FooterSectionProps> = ({ className, section }) => (
  <FooterSectionContainer className={className} data-testid="footer-section">
    <Spacing bottom="16px">
      <FooterSectionHeading>{section.heading}</FooterSectionHeading>
    </Spacing>
    {section.links.map(({ text, link }) => (
      <Spacing key={text} top="12px">
        <FooterSectionItem link={link}>{text}</FooterSectionItem>
      </Spacing>
    ))}
  </FooterSectionContainer>
);

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const VerticalDivider = styled.div`
  border-right: 1px solid ${brand2};
  opacity: 0.2;
`;

const MobileFooterContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: ${spacings['16px']};
  width: 100%;

  ${breakpointSmall(
    css`
      display: none;
    `
  )}
`;

type MobileFooterSectionProps = { section: Section };
const MobileFooterSection: FC<MobileFooterSectionProps> = ({ section }) =>
  section?.heading === 'brandMark' ? (
    <Spacing bottom="32px">
      <BrandMarkWithSloganBelow key={section.heading} />
    </Spacing>
  ) : section ? (
    <FooterSection key={section.heading} section={section} />
  ) : null;

type MobileContentProps = { sections: Section[] };
const MobileContent: FC<MobileContentProps> = ({ sections }) => {
  const placeholderLink: LinkType = { text: '', link: null };
  const balancedSections = sortSectionsIntoColumns(
    [
      {
        heading: 'brandMark',
        links: [placeholderLink, placeholderLink, placeholderLink],
      },
      ...sections,
    ],
    2
  );
  return (
    <MobileFooterContentContainer data-testid="mobile-footer-content">
      <ColumnContainer>
        {balancedSections[0].map((section) => (
          <MobileFooterSection key={section.heading} section={section} />
        ))}
      </ColumnContainer>
      <VerticalDivider />
      <ColumnContainer>
        {balancedSections[1].map((section) => (
          <MobileFooterSection key={section.heading} section={section} />
        ))}
      </ColumnContainer>
    </MobileFooterContentContainer>
  );
};

const DesktopFooterContentContainer = styled.div`
  display: none;

  ${breakpointSmall(
    css`
      display: grid;
      grid-template-columns: repeat(6, auto);
      gap: ${spacings['32px']};
      width: 100%;
    `
  )}

  ${breakpointMedium(
    css`
      gap: ${spacings['64px']};
    `
  )}
`;

type DesktopContentProps = { sections: Section[] };
const DesktopContent: FC<DesktopContentProps> = ({ sections }) => {
  const [firstSection, ...otherSections] = sections;
  const balancedSections = sortSectionsIntoColumns(otherSections, 4);
  return (
    <DesktopFooterContentContainer data-testid="desktop-footer-content">
      <BrandMarkWithSloganBelow />
      <FooterSection section={firstSection} />
      {balancedSections.map((columnSections) => (
        <ColumnContainer key={columnSections.map((c) => c.heading).join('-')}>
          {columnSections.map((section) => (
            <FooterSection key={section.heading} section={section} />
          ))}
        </ColumnContainer>
      ))}
    </DesktopFooterContentContainer>
  );
};

const DeclarationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpointSmall(css`
    flex-direction: row;
  `)}
`;

const ContentLinesContainer = styled.div`
  ${breakpointSmall(css`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  `)}

  ${breakpointLarge(css`
    flex-direction: row;
    gap: ${spacings['24px']};
  `)}
`;

const ContentParagraphContainer = styled.div`
  ${breakpointSmall(css``)}
`;

type Section = { heading: string; links: LinkType[] };
type Content = { contentLine1?: string | null; contentLine2?: string | null; contentParagraph?: PrismicRichTextType };
type FooterProps = { sections: Section[]; content: Content };
/**
 * Renders the page footer.
 */
const Footer: FC<FooterProps> = ({ sections, content }) => {
  return (
    <SliceContainer background="brand3SolidStripedCurve">
      <CenteredSliceContentContainer>
        <MobileContent sections={sections} />
        <DesktopContent sections={sections} />
        <Spacing top="32px">
          <DeclarationsContainer>
            <ContentLinesContainer>
              <BodyP type="bodySRegular">{content.contentLine1}</BodyP>
              <BodyP type="bodySRegular">{content.contentLine2}</BodyP>
            </ContentLinesContainer>
            <Spacing top={{ base: '32px', breakpointSmall: 'none' }} left={{ base: 'none', breakpointSmall: '64px' }}>
              <ContentParagraphContainer>
                {content.contentParagraph && <RichText textType="bodyXSRegular" field={content.contentParagraph} />}
              </ContentParagraphContainer>
            </Spacing>
          </DeclarationsContainer>
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Footer;
