export const siteUrl = 'https://letsweel.com';
export const title = 'All-in-One Virtual Cards & Business Expense Management | Weel';
export const description =
  "Weel's All-in-One virtual cards and business expense tracker app gives you real-time visibility and control of spending. Book a demo or try FREE for 14 days.";
export const ogImage = `${siteUrl}/static/og-image.png`;
export const alternateName = 'Divipay';
export const email = 'help@letsweel.com';
export const tagline = 'All-in-One Virtual Cards & Business Expense Management';
export const logo = `${siteUrl}/static/icon-512x512.png`;
export const name = 'Weel';
export const currentYear = new Date().getFullYear();
export const socials = [
  'https://www.instagram.com/letsweel/',
  'https://www.linkedin.com/company/letsweel',
  'https://twitter.com/letsweel',
  'https://www.facebook.com/letsweel',
  'https://www.youtube.com/channel/UCtnnUnxTZvKhtwQBOfhFlXg',
  'https://www.tiktok.com/@letsweel',
];
export const addressCountry = 'ISO 3166-2:AU';
export const streetAddress = '320 Pitt St';
export const postalCode = '2000';
export const addressRegion = 'NSW';
export const addressLocality = 'Sydney';
export const address = `${streetAddress}, ${addressLocality} ${addressRegion} ${postalCode}`;
export const legalName = 'Weel Holdings Pty Ltd';
export const language = 'en';
