import aud from '../../../images/flags/aud.png';
import eur from '../../../images/flags/eur.png';
import gbp from '../../../images/flags/gbp.png';
import nzd from '../../../images/flags/nzd.png';
import usd from '../../../images/flags/usa.png';

export const currencyAssets: Record<string, { symbol: string; flag: string; label: string }> = {
  AUD: { symbol: '$', flag: aud, label: 'Australian dollar (AUD)' },
  USD: { symbol: '$', flag: usd, label: 'US dollar (USD)' },
  EUR: { symbol: '€', flag: eur, label: 'Euro (EUR)' },
  GBP: { symbol: '£', flag: gbp, label: 'British pound (GBP)' },
  NZD: { symbol: '$', flag: nzd, label: 'New Zealand dollar (NZD)' },
};

export const inputErrors = {
  isZero: "Please type in a number that's larger than 0",
  isGreaterThanMillion: 'The amount cannot exceed 1 million',
};
