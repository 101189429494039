import { css } from 'styled-components';
import { textStyles } from '../../../../../typography/base/Text';

/**
 * Generates medium button text styles.
 */
export const mediumButtonTextStyles = () => textStyles({ fontSize: '16pt', fontWeight: '500', lineHeight: '150%' });

/**
 * Generates medium button styles.
 */
export const mediumStyles = () => css`
  height: 38px;
  width: unset;
  border-radius: 100px;
  padding: 8px 16px;
  ${mediumButtonTextStyles()}
`;
