import { createContext } from 'react';

export type OpenDropdowns = Record<string, boolean>;
export type ChangeDropdownStatus = (headerName: string, open?: boolean) => void;
type NavItemsContextState = {
  dropdownsStatus: OpenDropdowns;
  changeDropdownStatus: ChangeDropdownStatus;
};

export const NavItemsContext = createContext<NavItemsContextState>({
  dropdownsStatus: {},
  changeDropdownStatus: () => {},
});
