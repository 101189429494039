import React, { FC } from 'react';
import { WithTestId } from '../../utilityTypes';
import { PrismicIconType, IconType } from './types';

const formatData = (icon: PrismicIconType): IconType => {
  const iconDocument = icon?.document;
  const data = (iconDocument && 'data' in iconDocument && iconDocument.data) || undefined;

  const lightIcon = data?.icon_light?.text || '';
  const darkIcon = data?.icon_dark?.text || '';
  const altText = data?.alt || '';

  const iconData: IconType = {
    icon_light: lightIcon,
    icon_dark: darkIcon,
    alt: altText,
  } as const;

  return iconData;
};

/**
 * Formats an icon string into a html element.
 */
const formatIcon = (svg: string, alt?: string) => {
  const withTestId = svg.replace(/<svg([^>]*)>/, '<svg$1 data-testid="prismic-icon-svg">');
  const withDesc = !alt ? withTestId : withTestId.replace(/<\/svg>/, `<desc>${alt}</desc></svg>`);
  return withDesc;
};

export type PrismicIconProps = {
  icon: PrismicIconType;
  reverse?: boolean;
} & WithTestId;
/**
 * Render Component component.
 */
export const PrismicIcon: FC<PrismicIconProps> = ({ icon, reverse, 'data-testid': testId }) => {
  const formattedIconData = formatData(icon);

  if (!icon?.document?.data) return null;
  const dataToUse = reverse ? formattedIconData.icon_light : formattedIconData.icon_dark;
  const iconElement = formatIcon(dataToUse, formattedIconData?.alt);

  return <figure data-testid={`prismic-icon-component ${testId}`} dangerouslySetInnerHTML={{ __html: iconElement }} />;
};
