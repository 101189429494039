import styled from 'styled-components';
import {
  VerticalPadding,
  MaxWidth,
  sliceContentContainerVerticalPaddingStyles,
  sliceContentContainerMaxWidthStyles,
} from './sliceContentContainerStyles';

type SliceContentContainerProps = { verticalPadding?: VerticalPadding; maxWidth?: MaxWidth };
/**
 * Container for centered slice content.
 */
export const CenteredSliceContentContainer = styled.div<SliceContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => sliceContentContainerVerticalPaddingStyles(props.verticalPadding || '100px')}
  ${(props) => sliceContentContainerMaxWidthStyles(props.maxWidth || '1286px')}
  width: 100%;
`;
