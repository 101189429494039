import React, { FC } from 'react';
import styled from 'styled-components';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { flex } from '../../../design/animations/display';
import { grey400 } from '../../../design/colors/greyscale';
import { spacings } from '../../../design/spacing/Spacing';
import Chevron from '../../../images/react-icons/ChevronDown.inline.svg';

type ToggleProps = { open: boolean; maxHeight?: number };
const ToggleContainer = styled.div<ToggleProps>`
  position: relative;
  transition: transform ease 0.3s;
  ${flex({ align: 'center', justify: 'center' })}
  ${(props) => (props.open ? 'transform: rotate(0deg);' : 'transform: rotate(-180deg);')};
`;

const HeadingContainer = styled.div`
  ${flex({ gap: '24px' })}
  cursor: pointer;
  padding-bottom: ${spacings['16px']};
  border-bottom: 1px solid ${grey400};
`;
/**
 * Feature Heading Toggle bar.
 */
export const FeatureHeadingToggle: FC<{
  title: PrismicRichTextType;
  onClick: () => void;
  open: boolean;
}> = ({ title, onClick, open }) => {
  return (
    <HeadingContainer onClick={onClick}>
      <ToggleContainer open={open}>
        <Chevron />
      </ToggleContainer>
      <RichText textAlign="left" field={title} textType="heading4" />
    </HeadingContainer>
  );
};
