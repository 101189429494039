import React from 'react';
import styled from 'styled-components';
import { Button } from '../../design/components/button/Button';
import Spacing from '../../design/spacing/Spacing';
import { BodySpan, Heading3 } from '../../design/typography/Typography';
import Rocket from '../../images/react-icons/Rocket.inline.svg';
import { PopUpProps } from './PopUpForm';

type SuccessStateProps = Pick<PopUpProps, 'setIsModalOpen' | 'hubspotFormSuccessHeading' | 'hubspotFormSuccessText'> & {
  setHasSubmitted: (hasSubmitted: boolean) => void;
  downloadLink?: string | null;
};

const defaultSuccess = {
  heading: 'Thank you',
  text: "Content is en route to your inbox. If can't see an email, please check your promotional or spam folders.",
};

const StyledButton = styled(Button)`
  width: fit-content;
  margin-left: 0;
`;
/**
 * Success state component upon submission of the hubspot form.
 */
const SuccessState = ({
  setIsModalOpen,
  setHasSubmitted,
  hubspotFormSuccessHeading,
  hubspotFormSuccessText,
  downloadLink,
}: SuccessStateProps) => {
  const handleCloseOnSuccess = () => {
    downloadLink && window.open(downloadLink, '_blank');
    setIsModalOpen(false);
    setHasSubmitted(false);
  };
  const successHeading = hubspotFormSuccessHeading || defaultSuccess.heading;
  const successText = hubspotFormSuccessText || defaultSuccess.text;
  const closeButtonText = downloadLink ? 'View Content' : 'Close';
  return (
    <>
      <Rocket height={50} width={50} />
      <Spacing top="24px" bottom="24px">
        <Heading3 textAlign="left">{successHeading}</Heading3>
      </Spacing>
      <Spacing bottom="24px">
        <BodySpan type="bodyMRegular" textAlign="left">
          {successText}
        </BodySpan>
      </Spacing>
      <StyledButton onClick={handleCloseOnSuccess}>{closeButtonText}</StyledButton>
    </>
  );
};

export default SuccessState;
