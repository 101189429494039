import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName, Mutable, ExtractFromArray } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import GridAreaCards from '../GridAreaCards';
import { Card, GridAreaCardItem } from '../types';

type GridAreaCardsDataProps = SliceProps<
  'GridAreaCards',
  SliceVariation<'default', never, ExtractFromArray<Mutable<Queries.GridAreaCardsFragment['items']>>>
> &
  WithClassName;

const getCardOne = (item: GridAreaCardItem) => {
  const isFullWidthImageType = item?.imageFullWidth === true;
  const isContentType = item?.title?.text;

  if (isFullWidthImageType)
    return { image: item?.image, imageFullWidth: !!item.imageFullWidth, type: 'image' as const };

  if (isContentType)
    return { title: stringData(item?.title?.text), content: stringData(item?.content), type: 'content' as const };
  return null;
};

const getCardTwo = (item: GridAreaCardItem) => {
  const isFullWidthImageType = item?.imageFullWidth === true;
  const isImageType = item?.image?.gatsbyImageData;
  const quoteType = item?.quote?.text;

  if (isImageType && !isFullWidthImageType)
    return { image: item?.image, imageFullWidth: !!item.imageFullWidth, type: 'image' as const };
  if (quoteType)
    return {
      quote: stringData(item?.quote?.text),
      name: stringData(item?.name),
      role: stringData(item?.role),
      company: stringData(item?.company),
      type: 'quote' as const,
    };
  return null;
};

const getRow = (item: GridAreaCardItem, index: number) => {
  const rowReverse = index % 2 === 0;

  const cardOne = getCardOne(item);
  const cardTwo = getCardTwo(item);

  if (cardOne && !cardTwo) return [cardOne];
  if (cardOne && cardTwo) return rowReverse ? [cardOne, cardTwo] : [cardTwo, cardOne];
  return [];
};
/**
 * GridAreaCards data component: specifies the fragment and passes the appropriate data to the GridAreaCards component.
 */
const GridAreaCardsData: FC<GridAreaCardsDataProps> = ({ className, slice }) => {
  const sanitisedCards: Card[][] = slice.items.map((item, index) => getRow(item, index));

  return <GridAreaCards className={className} cards={sanitisedCards} />;
};

export default GridAreaCardsData;

export const query = graphql`
  fragment GridAreaCards on PrismicGridAreaCardsDefault {
    items {
      company
      content
      imageFullWidth
      name
      quote {
        text
      }
      role
      title {
        text
      }
      image {
        ...Image
      }
    }
  }
`;
