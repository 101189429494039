import { css } from 'styled-components';
import { brand2, brand3Mid } from '../../../../../../colors/brand';
import { grey500, grey600 } from '../../../../../../colors/greyscale';
import { disabledStyle } from '../../../../../../utils/style';

/**
 * Generates the tertiary reverse button type style.
 */
export const tertiaryReverseTypeStyle = () => css`
  border-color: ${brand2};
  color: ${brand2};

  &:hover {
    border-color: ${brand3Mid};
    color: ${brand3Mid};
  }

  ${disabledStyle(css`
    border-color: ${grey600};
    color: ${grey500};
  `)}
`;
