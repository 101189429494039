import React, { ComponentProps, FC } from 'react';
import { Card } from '../../../../design/components/card/Card';
import { Mutable, WithClassName } from '../../../../utilityTypes';
import { OneRowCardsContainer, OneRowCardStylesProvider } from './oneRowCards';

type OneRowCardsLeftNumberCardsProps = {
  cards: Mutable<Queries.OneRowCardsLeftNumberFragment['items']>;
} & WithClassName;
/**
 * Renders cards for OneRowLeftNumberCards variation.
 */
export const OneRowCardsLeftNumberCards: FC<OneRowCardsLeftNumberCardsProps> = ({ className, cards }) => {
  const backgrounds: ComponentProps<typeof Card>['background'][] = ['brand2Solid', 'whiteSolid', 'brandGlass'];

  return (
    <OneRowCardsContainer className={className}>
      {cards.map((card, index) => {
        const background = backgrounds[index % 3];

        return (
          <OneRowCardStylesProvider key={card.heading}>
            <Card
              background={background}
              index={index}
              title={card.heading || undefined}
              content={card.text || undefined}
              cardType="numbered"
              cta={card.ctaLink || undefined}
              ctaText={card.ctaButtonText || undefined}
            />
          </OneRowCardStylesProvider>
        );
      })}
    </OneRowCardsContainer>
  );
};
