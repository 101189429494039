export const brand1 = '#CAEE10';
export const brand1Dark = '#C1E700';
export const brand1DarkAlt = '#B2D600';
export const brand1Mid = '#E5F78C';
export const brand1Mid2 = '#ECFAA6';
export const brand1Light = '#f8fde1';

export const brand2 = '#407A7D';
export const brand2Dark = '#296366';
export const brand2Mid = '#9AC6CD';
export const brand2Mid2 = '#CFEDF2';
export const brand2Light = '#E3F7FA';

export const brand3 = '#06444E';
export const brand3Dark = '#033740';
export const brand3Mid = '#569B9E';
export const brand3Mid2 = '#C9EBED';
export const brand3Light = '#DCF5F5';

export const brand4 = '#131F2E';
export const brand4Dark = '#04080D';
export const brand4Mid = '#334A66';
export const brand4Mid2 = '#DAE5F2';
export const brand4Light = '#F0F3F7';

export const brand5 = '#F1F5F0';
export const brand5Dark = '#BCC2BA';
export const brand5Mid = '#D6DBD5';
export const brand5Mid2 = '#E7EBE6';
export const brand5Light = '#F5F7F5';
