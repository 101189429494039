/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { borderRadius, flex } from '../../../design/animations/display';
import { brand1, brand3Light } from '../../../design/colors/brand';
import { grey600 } from '../../../design/colors/greyscale';
import { BodyP, getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';

const Subtract = styled.div<{ disabled?: boolean }>`
  width: 16px;
  height: 2px;
  background-color: ${({ disabled }) => (disabled ? grey600 : brand1)};
`;
const Plus = styled.div`
  width: 16px;
  height: 2px;
  background-color: ${brand1};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 16px;
    width: 2px;
    background-color: ${brand1};
    left: 7px;
    top: -7px;
  }
`;

const Button = styled.button`
  padding: 16px;
  margin: -16px;
  cursor: pointer;
`;

const ToggleContainer = styled.div`
  ${flex({ justify: 'center', gap: '8px' })}
  ${borderRadius({ all: '100px' })};
  padding: 6px 8px;
  border: 1px solid ${grey600};
  box-shadow: none;
  transition: border 0.3s ease;
  &:hover {
    border: 1px solid ${brand3Light};
  }
`;

const Input = styled.input`
  ${getTypographyStyles('bodyXLRegular')}
  ${fontFamilyStyles}
  width: -webkit-fill-available;
  text-align: center;
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const InputContainer = styled.div<{ maxWidth: number }>`
  display: flex;
  justify-content: center;
  width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}ch` : '30px')};
`;

/**
 * Component with '+' and '-' buttons to adjust the limit in the center.
 * Used with <Calculator/> in <FeaturePricingComparison/>.
 */
export const LimitToggle = ({
  value,
  updateValue,
  disableSubtraction,
}: {
  value: number;
  updateValue: (newValue: number) => void;
  disableSubtraction: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(value ? value.toString() : '');

  const onTextInputChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = el.target.value;
    setInputValue(newValue);
  };

  const onBlur = () => {
    const invalidValue = inputValue === '' || inputValue === '0' || !!disableSubtraction;
    if (invalidValue) setInputValue(value.toString());
  };

  useEffect(() => {
    if (value && value.toString() !== inputValue) updateValue(parseInt(inputValue));
  }, [inputValue]);
  // diasabled exhaustive deps as this causes infinite re-renders

  if (!value)
    return (
      <BodyP type="bodyLMedium" color={brand1}>
        Unlimited
      </BodyP>
    );

  const dynamicInputSize = Math.min(Math.max(inputValue.length, 1), 20) + 3.5;
  const plusOneToLimit = () => setInputValue((value + 1).toString());
  const minusOneFromLimit = () => setInputValue((value - 1).toString());
  return (
    <ToggleContainer data-testid="limit-toggle">
      <Button
        data-testid="subtract"
        role="button"
        aria-label="minus-1-button"
        onClick={minusOneFromLimit}
        disabled={!!disableSubtraction}
      >
        <Subtract disabled={!!disableSubtraction} />
      </Button>
      <InputContainer maxWidth={dynamicInputSize}>
        <Input
          data-testid="limit-value"
          value={inputValue}
          onChange={onTextInputChange}
          onBlur={onBlur}
          type="number"
        />
      </InputContainer>
      <Button data-testid="add" role="button" aria-label="plus-1-button" onClick={plusOneToLimit}>
        <Plus />
      </Button>
    </ToggleContainer>
  );
};
