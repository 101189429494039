import React, { FC } from 'react';
import styled from 'styled-components';
import { ButtonLink } from '../../../design/components/button/Button';
import TwoCtaPlacementContainer from '../../../design/containers/placements/TwoCtaPlacementContainer';
import Spacing from '../../../design/spacing/Spacing';
import { QuoteProps } from '../Quote';
/**
 * Render CtaButtons component for Quote slice.
 */

const NoWrapButton = styled(ButtonLink)`
  white-space: nowrap;
`;
/**
 * Render CtaButtons component for Quote slice.
 */
export const CtaButtons: FC<Pick<QuoteProps, 'primaryCta' | 'secondaryCta'>> = ({ primaryCta, secondaryCta }) => {
  return (
    <Spacing top="24px">
      <TwoCtaPlacementContainer
        cta1Placement={
          <NoWrapButton styleType="primary" link={primaryCta.link}>
            {primaryCta.text}
          </NoWrapButton>
        }
        cta2Placement={
          <NoWrapButton styleType="secondary" link={secondaryCta.link}>
            {secondaryCta.text}
          </NoWrapButton>
        }
      />
    </Spacing>
  );
};
