import { TagTheme } from '../../slices/contentHubCards/components/Tags/TopicTags';
import { Background } from '../containers/panel/Panel';
/**
 * Accepts array of backgrounds positioned as desired
 * e.g ['whiteSolid', 'brand2Solid', 'brand3Solid', 'whiteSolid'];
 * and index + returns the matching background for the index.
 */
export const nthBackgroundConstructer = (pattern: Background[], index: number) => {
  return pattern[index % pattern.length];
};
/**
 * Accepts array of tag backgrounds positioned as desired
 * e.g ['whiteSolid', 'brand2Solid', 'brand3Solid', 'whiteSolid'];
 * and index + returns the matching background for the index.
 */
export const nthTagConstructer = (pattern: TagTheme[], index: number) => {
  return pattern[index % pattern.length];
};
