import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * Sets provided style normally and for hover state.
 */
export const disabledStyle = (style: FlattenSimpleInterpolation) => css`
  &:disabled {
    ${style}
  }
`;
