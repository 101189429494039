import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RawImage } from '../../../components/image/rawImage/RawImage';
import { jump } from '../../../design/animations/micro-interactions';
import { Background, Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall, breakpointMedium, breakpointLarge } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading3 } from '../../../design/typography/Typography';
import { WithTestId } from '../../../utilityTypes';

const Card = styled(Panel)`
  ${jump}
  padding: ${spacings['24px']};
  display: flex;
  flex-direction: row-reverse;
  gap: ${spacings['8px']};
  align-items: normal;
  ${breakpointSmall(
    css`
      display: grid;
      grid-template-rows: 146px auto;
    `
  )};
  &:last-of-type {
    ${breakpointSmall(
      css`
        grid-column: span 2;
        display: block;
        grid-auto-rows: auto;
      `
    )};
    ${breakpointMedium(
      css`
        display: grid;
        grid-template-columns: 1fr;
      `
    )};
    ${breakpointLarge(
      css`
        grid-column: span 1;
      `
    )};
  }
`;
Card.defaultProps = { borderRadius: `${spacings['12px']}` };

const Icon = styled(RawImage)`
  max-height: 56px;
  max-width: ${spacings['64px']};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-basis: 56px;
  min-height: 65px;
  ${breakpointLarge(
    css`
      margin-bottom: ${spacings['64px']};
      width: 100%;
    `
  )};
`;

const TextContainer = styled.div`
  display: block;
  width: 100%;
  z-index: 9;
  height: 100%;
  ${breakpointMedium(
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `
  )};
`;

const Heading = styled(Heading3)`
  padding-bottom: ${spacings['8px']};
`;

type CardData = {
  heading?: string;
  text?: string;
  icon?: string | null;
  background?: Background;
};
type ContentProps = CardData & WithTestId;

/**
 * Renders ContentCard comp.
 */
const ContentCard: FC<ContentProps> = ({ heading, text, icon, background, 'data-testid': testId }) => (
  <Card background={background} data-testid={testId}>
    <IconContainer>{icon && <Icon src={icon} alt="card-icon" />}</IconContainer>
    <TextContainer>
      {heading && <Heading textAlign="left">{heading}</Heading>}
      {text && <BodyP type="bodyLRegular">{text}</BodyP>}
    </TextContainer>
  </Card>
);

export default ContentCard;
