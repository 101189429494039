import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';

type RawImageProps = { src: string; alt: string } & WithClassName;
/**
 * Renders image directly. Only use with image source files that are small, such as icons or svg.
 */
export const RawImage: FC<RawImageProps> = ({ className, src, alt }) => (
  <img className={className} src={src} alt={alt} />
);
