import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import Stats from '../Stats';

type StatsDataProps = SliceProps<'stats', SliceVariation<'default', Queries.StatsFragment['primary']>> & WithClassName;

/**
 * Stats data component.
 */
const StatsData: FC<StatsDataProps> = ({ className, slice }: StatsDataProps) => (
  <Stats
    className={className}
    stat1={{ text: slice?.primary?.stat1Text, number: slice?.primary?.stat1Number || undefined }}
    stat2={{ text: slice?.primary?.stat2Text, number: slice?.primary?.stat2Number || undefined }}
    stat3={{ text: slice?.primary?.stat3Text, number: slice?.primary?.stat3Number || undefined }}
  />
);

export default StatsData;

export const query = graphql`
  fragment Stats on PrismicStatsDefault {
    primary {
      stat1Number
      stat2Number
      stat3Number
      stat3Text {
        ...RichText
      }
      stat2Text {
        ...RichText
      }
      stat1Text {
        ...RichText
      }
    }
  }
`;
