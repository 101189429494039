import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { PrismicImageData, WithTestId } from '../../../utilityTypes';

type CardProps = { fullWidth?: boolean };

const Card = styled(Panel)<CardProps>`
  width: 100%;
  ${(props) => css`
    ${breakpointSmall(
      css`
        flex: 1;
        ${props.fullWidth ? 'flex-basis: 100%;' : 'flex-basis: 50%;'};
        height: ${props.fullWidth ? '177px' : 'unset'};
      `
    )};
  `}
`;
Card.defaultProps = { borderRadius: `${spacings['12px']}` };

const Image = styled(PrismicImage)`
  height: 100%;
  border-radius: ${spacings['12px']};
`;
export type ImageData = {
  image?: PrismicImageData;
  imageFullWidth?: boolean;
};

type ImageProps = ImageData & WithTestId;
/**
 * Renders ImageCard Comp.
 */
const ImageCard: FC<ImageProps> = ({ image, 'data-testid': testId, imageFullWidth }) => {
  return (
    <Card fullWidth={imageFullWidth} data-testid={testId}>
      {image && <Image image={image} />}
    </Card>
  );
};

export default ImageCard;
