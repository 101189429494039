/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { modalZIndex } from '../../../components/zindex';
import { brand5 } from '../../colors/brand';
import { spacings } from '../../spacing/Spacing';

type ModalProps = {
  active: boolean;
  closeModal?: (e: Event) => void;
} & PropsWithChildren;

const ModalContainer = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: ${modalZIndex};
  height: 100vh;
  width: 100%;
  background-color: rgba(19, 31, 46, 0.6);
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: ${spacings['40px']} ${spacings['48px']} ${spacings['32px']} ${spacings['48px']};
  border: ${spacings['24px']};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  max-width: 550px;
  max-height: 90%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: ${brand5};
  border-radius: ${spacings['24px']};
`;

/**
 * Modal component.
 */
const Modal: FC<ModalProps> = (props) => {
  const appRoot = typeof window !== 'undefined' ? document.getElementById('___gatsby') : null;
  const { active, children } = props;

  if (!active) return null;

  return appRoot
    ? createPortal(
        <ModalContainer data-testid="modal" role="dialog">
          <ModalContent>{children}</ModalContent>
        </ModalContainer>,
        appRoot
      )
    : null;
};

export default Modal;
