import { css } from 'styled-components';
import { brand1, brand1Dark, brand4 } from '../../../../../../colors/brand';
import { grey400, grey700 } from '../../../../../../colors/greyscale';
import { disabledStyle } from '../../../../../../utils/style';

export const primaryDisabledStyles = disabledStyle(css`
  background-color: ${grey400};
  border-color: ${grey400};
  color: ${grey700};
`);

type PrimaryHoverStyleOptions = { important?: boolean };
/**
 * Generates primary hover style with option to add important to all properties.
 *
 * This is needed to override some third party styles.
 */
export const primaryHoverStyle = ({ important }: PrimaryHoverStyleOptions = {}) => css`
  &:hover {
    background-color: ${brand1Dark}${important ? ' !important' : ''};
  }
`;

/**
 * Generates the primary button type style.
 */
export const primaryTypeStyle = () => css`
  background-color: ${brand1};
  border-color: ${brand1Dark};
  color: ${brand4};

  ${primaryHoverStyle()}
  ${primaryDisabledStyles}
`;
