import React, { FC } from 'react';
import styled from 'styled-components';
import { brand1 } from '../../../design/colors/brand';
import FiveStars from '../../../design/components/fiveStars/FiveStars';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP, BodySpan } from '../../../design/typography/Typography';
import { CompanyQuoteProps } from '../CompanyQuote';

const Highlight = styled(BodySpan).attrs({ textAlign: 'left' })`
  color: ${brand1};
`;

const QuoteContainer = styled.div`
  display: grid;
  gap: ${spacings['16px']};
`;

const FooterContainer = styled.div`
  display: flex;
  gap: ${spacings['16px']};
  align-items: center;
`;

/**
 * Renders the quote section for CompanyQuote slice.
 */
const Quote: FC<Omit<CompanyQuoteProps, 'heading'>> = ({ author, quote, company }) => (
  <QuoteContainer>
    <div>
      <Highlight type="bodyLBold">{author?.name}</Highlight>,{' '}
      <BodySpan type="bodyLRegular">{author?.position}</BodySpan>,{' '}
      <BodySpan type="bodyLRegular">{author?.company}</BodySpan>
    </div>
    <BodyP type="quotes">{`“${quote}”`}</BodyP>
    <FooterContainer>
      <FiveStars />
      <BodyP type="bodyLBold">{company}</BodyP>
    </FooterContainer>
  </QuoteContainer>
);

export default Quote;
