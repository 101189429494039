import {
  siteUrl as url,
  description,
  ogImage,
  alternateName,
  email,
  tagline,
  logo as logoUrl,
  name,
  currentYear,
  socials,
  addressCountry,
  postalCode,
  streetAddress,
  addressRegion,
  legalName,
  language as inLanguage,
} from '../../../../config/site';

/**
 * Generate formatted ID for schema
 * https://developer.yoast.com/features/schema/technology-approach/#constructing-id-parameters.
 */
export const generateSchemaId = (type: string, id: string) => `${url}/#/schema/${type}${id}`;

const ids = { website: generateSchemaId('WebSite', '1'), organization: generateSchemaId('Organization', '1') };
const copyright = { copyrightHolder: { '@id': ids.organization }, copyrightYear: currentYear };

/**
 * T H I N G   S C H E M A S.
 * + https://schema.org/Thing.
 */

// https://schema.org/PostalAddress
const address = {
  '@type': 'PostalAddress',
  addressCountry,
  streetAddress,
  postalCode,
  addressRegion,
};

// https://schema.org/subOrganization
const subOrganization = {
  '@type': 'Organization',
  name: 'Australian CFO Community',
  url: 'https://letsweel.com/resources/the-weelhouse/cfo-community',
  parentOrganization: ids.organization,
};

// https://schema.org/logo
const logo = {
  '@type': 'ImageObject',
  caption: 'Logo',
  url: logoUrl,
  ...copyright,
};

// https://schema.org/ImageObject
const image = {
  '@type': 'ImageObject',
  caption: 'Social Image',
  url: ogImage,
  ...copyright,
};

// https://schema.org/Person
const person = {
  '@type': 'Person',
  affiliation: { '@id': ids.organization },
};

/**
 * P R I M A R Y  E N T I T Y  S C H E M A S.
 * + https://developer.yoast.com/features/schema/technology-approach/#primary-entities.
 */

// https://schema.org/Organization
const organization = {
  '@type': 'Organization',
  '@id': ids.organization,
  name,
  legalName,
  address,
  email,
  alternateName,
  url,
  logo,
  image,
  sameAs: socials,
  subOrganization,
  person,
};

// https://schema.org/WebSite
const website = {
  '@type': 'WebSite',
  '@id': ids.website,
  url,
  name,
  description: tagline,
  about: description,
  datePublished: '2019-01-18T10:30:00+01:00',
  mainEntityOfPage: url,
  sameAs: socials,
  image,
  inLanguage,
  ...copyright,
};

// https://schema.org/WebPage
const webpage = {
  '@type': 'WebPage',
  author: { '@id': ids.organization },
  isPartOf: { '@id': ids.website },
  inLanguage,
  ...copyright,
};

/**
 * W E B P A G E  E N T I T Y  S C H E M A S.
 */

// https://schema.org/FAQPage
const faqPage = {
  '@type': 'FAQPage',
  relatedLink: 'https://letsweel.com/resources/the-weelhouse',
  reviewedBy: { '@id': ids.organization },
  author: { '@id': ids.organization },
  inLanguage,
};

// https://schema.org/Article
const article = {
  '@type': 'Article',
  inLanguage,
  ...copyright,
};

// https://schema.org/Event
const event = {
  '@type': 'Event',
  inLanguage,
  organizer: { '@id': ids.organization },
};

export const websiteSchema = {
  '@context': 'https://schema.org',
  ...website,
};

export default {
  address,
  subOrganization,
  logo,
  image,
  organization,
  website,
  webpage,
  faqPage,
  article,
  event,
  person,
} as const;
