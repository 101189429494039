import { blogPostLinkResolver } from '../../src/templates/blog_post/linkResolver/blogPostLinkResolver';
import { customerStoryLinkResolver } from '../../src/templates/customer_story/linkResolver/customerStoryLinkResolver';
import { EventsDoc, eventsLinkResolver } from '../../src/templates/events/linkResolver/eventLinkResolver';
import { GuidesDoc, guidesLinkResolver } from '../../src/templates/guides/linkResolver/guideLinkResolver';
import { Doc } from './types';

const replaceDotsWithSlashes = (input: string) => input.replace(/\./g, '/');
const addStartingSlash = (url: string) => (url.startsWith('/') ? url : `/${url}`);

const generatePathFromUid = (uid: string) => addStartingSlash(replaceDotsWithSlashes(uid));

/**
 * Gatsby link resolver that allows us to customize the url for each document.
 *
 * Docs can be found: https://prismic.io/docs/technologies/link-resolver-gatsby.
 */
const linkResolver = (doc: Doc) => {
  if (!doc.uid) return '/';
  const url = generatePathFromUid(doc.uid);
  if (doc.type === 'blog_post') return blogPostLinkResolver(url);
  if (doc.type === 'guides') return guidesLinkResolver(doc as GuidesDoc, url);
  if (doc.type === 'events') return eventsLinkResolver(doc as EventsDoc, url);
  if (doc.type === 'customer_story') return customerStoryLinkResolver(url);
  return url;
};

export default linkResolver;
