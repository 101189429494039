import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../components/link/types';
import { PrismicIcon } from '../../components/prismicIcon/PrismicIcon';
import { PrismicIconType } from '../../components/prismicIcon/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { jump } from '../../design/animations/micro-interactions';
import { brand1 } from '../../design/colors/brand';
import { ButtonLink } from '../../design/components/button/Button';
import { Background, isDarkBackground, Panel } from '../../design/containers/panel/Panel';
import BaseColumnSmallRowContainer from '../../design/containers/responsive/BaseColumnSmallRowContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Icon from '../../design/icons/Icon';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodySpan, BodyP } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';

const CardsContainer = styled(BaseColumnSmallRowContainer)`
  flex-wrap: wrap;
  justify-content: center;
  gap: ${spacings['24px']};
  width: 100%;
`;

type CardItem = {
  icon?: string | null;
  linked_icon?: PrismicIconType;
  heading: string | null;
  text: string | null;
  cta: LinkType;
  cardImage: PrismicImageData;
};

const CardPanel = styled(Panel)`
  ${jump}
  position: relative;
  flex: 1;
  align-self: center;
  max-width: 416px;
  min-width: 224px;
  ${breakpointSmall(
    css`
      align-self: stretch;
    `
  )};
`;
CardPanel.defaultProps = { shadow: 'bigShadowStyles', padding: '32px' };

const ImageCardPanel = styled(CardPanel)`
  width: 100%;
  min-height: 304px;
  padding: 0;
`;
const ImageCardImage = styled(PrismicImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

type ImageCardProps = { image: CardItem['cardImage'] };
const ImageCard: FC<ImageCardProps> = ({ image }) => (
  <ImageCardPanel background="whiteSolid" data-testid="card">
    <ImageCardImage image={image} />
  </ImageCardPanel>
);

const ButtonReadMe = styled(ButtonLink)`
  max-width: 140px;
`;

type NumberedHeadingProps = { color?: string };
const NumberedHeading = styled(BodySpan).attrs({ type: 'heading1' })<NumberedHeadingProps>`
  ${(props) => props.color && `color: ${props.color};`}
`;

type CardBackgroundOptions = { backgroundType: Background; numberedHeadingColor?: string };
const getBackgroundOptionsForCardIndex = (index: number, variation?: string): CardBackgroundOptions => {
  switch (index) {
    case 1:
      return { backgroundType: 'brand3Solid', numberedHeadingColor: brand1 };
    case 2:
      return { backgroundType: 'brand2Solid' };
    default:
      return { backgroundType: variation === 'numberedCardsAltBackground' ? 'whiteSolid' : 'brand5Solid' };
  }
};
type IconContainerProps = Pick<CardItem, 'icon' | 'linked_icon'> & { reverse: boolean };
const IconContainer = ({ icon, linked_icon, reverse }: IconContainerProps) => {
  if (!icon && !linked_icon?.document?.data) return null;
  return linked_icon?.document?.data ? (
    <PrismicIcon icon={linked_icon} reverse={reverse} />
  ) : (
    <Icon type={icon as ComponentProps<typeof Icon>['type']} reverse={reverse} />
  );
};

type ContentCardProps = { index: number; variation: string; item: CardItem };
const ContentCard: FC<ContentCardProps> = ({ index, variation, item }) => {
  const { backgroundType, numberedHeadingColor } = getBackgroundOptionsForCardIndex(index, variation);
  return (
    <CardPanel background={backgroundType} data-testid="card">
      {item.icon || item.linked_icon?.document?.data ? (
        <IconContainer icon={item.icon} linked_icon={item.linked_icon} reverse={isDarkBackground(backgroundType)} />
      ) : (
        <NumberedHeading color={numberedHeadingColor} textAlign="left">
          {`0${index + 1}`}
        </NumberedHeading>
      )}
      <Spacing top={{ base: '24px' }} bottom={{ base: '8px' }}>
        <BodyP textAlign="left" type={{ breakpointSmall: 'heading2', base: 'heading3' }}>
          {item.heading}
        </BodyP>
      </Spacing>
      <BodySpan type="bodyMRegular" textAlign="left">
        {item.text}
      </BodySpan>
      <Spacing top={{ base: '24px' }}>
        <ButtonReadMe styleType="primary" size="medium" link={item.cta.link}>
          {item.cta.text}
        </ButtonReadMe>
      </Spacing>
    </CardPanel>
  );
};

type CardProps = ContentCardProps;
const Card: FC<CardProps> = ({ index, variation, item }) =>
  item.cardImage?.gatsbyImageData ? (
    <ImageCard key={item.cardImage.alt} image={item.cardImage} />
  ) : (
    <ContentCard key={item.heading} variation={variation} index={index} item={item} />
  );

const getVariationBackground = (variation: string) => {
  const variationBackgroundMap: Record<string, Background> = {
    default: 'whiteSolid',
    numberedCards: 'whiteSolid',
    numberedCardsAltBackground: 'brand5Solid',
    brand2GradientStripedCurve: 'brand2GradientStripedCurve',
  };
  return variationBackgroundMap[variation];
};

type ThreeColumnCardsProps = {
  variation: string;
  heading: PrismicRichTextType;
  text?: string;
  items?: CardItem[];
} & WithClassName;

/**
 * Renders ThreeColumnCards slice.
 */
const ThreeColumnCards: FC<ThreeColumnCardsProps> = ({ className, heading, text, items, variation }) => {
  return (
    <SliceContainer className={className} background={getVariationBackground(variation)}>
      <CenteredSliceContentContainer>
        <Heading2 textAlign="center">{heading}</Heading2>
        {text && (
          <Spacing top={{ base: '24px' }}>
            <BodySpan type="bodyLRegular" textAlign="center">
              {text}
            </BodySpan>
          </Spacing>
        )}
        <Spacing top={{ base: '24px', breakpointSmall: '48px' }}>
          <CardsContainer>
            {items?.map((item, index) => (
              <Card key={item.cardImage?.alt || item.heading} variation={variation} index={index} item={item} />
            ))}
          </CardsContainer>
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default ThreeColumnCards;
