import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * Given a width number generates the media query for a min-width breakpoint.
 */
export const createBreakpoint = (width: number) => (styles: FlattenSimpleInterpolation) =>
  css`
    @media (min-width: ${width}px) {
      ${styles}
    }
  `;

export const breakpointWidthValues = {
  breakpointXSmall: 480,
  breakpointSmall: 768,
  breakpointMedium: 1024,
  breakpointLarge: 1280,
  breakpointXLarge: 1920,
  breakpoint2XLarge: 2560,
};
