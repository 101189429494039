/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { WithClassName, WithTestId } from '../../utilityTypes';
import Link from '../link/Link';

type LinkFragment = Queries.PrismicLinkFragment | null | undefined;

export type DualLinkProps = {
  desktopLink: LinkFragment;
  mobileLink: LinkFragment;
  desktopText: string;
  mobileText: string;
} & WithClassName &
  WithTestId;

/**
 * Render DualLink component.
 * Desktop link shows on devices > 800px
 * Mobile link shows on devices <= 800px
 * Pass links AND text (no children).
 */
const DualLink: FC<DualLinkProps> = ({ desktopLink, mobileLink, desktopText, mobileText, className }) => {
  type LinkToUse = { link: LinkFragment; text: string };
  const [linkToUse, setLinkToUse] = useState<LinkToUse | null>(null);

  const getScreenWidth = () => {
    return window.innerWidth || document.body.clientWidth;
  };

  const setMobile = () => setLinkToUse({ link: mobileLink, text: mobileText });
  const setDesktop = () => setLinkToUse({ link: desktopLink, text: desktopText });

  const resizeListener = () => {
    const width = getScreenWidth();
    width < 800 ? setMobile() : setDesktop();
  };

  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <Link data-testid="dual-link" link={linkToUse?.link} className={className}>
      {linkToUse?.text}
    </Link>
  );
};

export default DualLink;
