/* eslint-disable react-hooks/exhaustive-deps */

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { flex, padding } from '../../../design/animations/display';
import { brand2Mid2 } from '../../../design/colors/brand';
import { blogHeaderShadow } from '../../../design/colors/shadows';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointMedium } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import CategoryTag from '../../../slices/contentHubCards/components/Tags/CategoryTag';

const s48 = spacings['48px'];
const s24 = spacings['24px'];

const ArticleHeaderContainer = styled.div`
  padding-top: ${s48};
  margin-bottom: ${s24};
`;

const WithClientAtCompanyText = styled(BodyP)`
  color: ${brand2Mid2};
  margin: 0;
`;

const HeaderContainer = styled(Panel)`
  ${breakpointMedium(css`
    padding-bottom: ${s48};
  `)}
  ${blogHeaderShadow}
  padding-bottom: 0;
`;

export const TextContainer = styled.article`
  padding: 0;
  overflow: hidden;
  ${flex({ direction: 'column', gap: '24px', justify: 'center' })}
`;

export const InnerContainer = styled.div`
  ${flex({ gap: '24px', direction: 'column' })}
  ${padding({ x: '24px', y: '48px' })};
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

type CustomerStoryHeaderProps = {
  name?: string;
  role?: string;
  company?: string;
  title?: PrismicRichTextType;
};
/**
 * Renders Events Header.
 */
const CustomerStoryHeader: FC<CustomerStoryHeaderProps> = ({ name, role, company, title }) => {
  return (
    <ArticleHeaderContainer>
      <HeaderContainer background="brand3WeelLogoAzul" data-testid="event-research-header">
        <InnerContainer>
          <TextContainer>
            <CategoryTag type="customerStory" isLight />
            {title && (
              <RichText
                field={title}
                textType={{ base: 'heading3', breakpointSmall: 'heading3', breakpointLarge: 'heading2' }}
                textAlign="left"
              />
            )}
            {name && role && company && (
              <WithClientAtCompanyText type="bodyMBold">
                With {name}, {role} at {company}
              </WithClientAtCompanyText>
            )}
          </TextContainer>
        </InnerContainer>
      </HeaderContainer>
    </ArticleHeaderContainer>
  );
};

export default CustomerStoryHeader;
