import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { LinkType, Link } from '../../../components/link/types';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import FiveColumnsPanel from '../FiveColumnsPanel';
stringData;

const getBackgroundForVariation = (variation: FiveColumnsPanelDataProps['slice']['variation']) => {
  const variationBackgroundMap: Record<typeof variation, Background> = {
    default: 'brand4WaveMidnightRight',
    brand5ExpressiveWave: 'brand5WaveLimeRight',
  };
  return variationBackgroundMap[variation];
};

type FiveColumnsPanelDataProps = SliceProps<
  'FiveColumnsPanel',
  | SliceVariation<'default', Queries.PrismicFiveColumnsPanelDefaultPrimary>
  | SliceVariation<'brand5ExpressiveWave', Queries.PrismicFiveColumnsPanelBrand5ExpressiveWavePrimary>
> &
  WithClassName;

/**
 * FiveColumnsPanel data component: specifies the fragment and passes the appropriate data to the FiveColumnsPanel component.
 */
const FiveColumnsPanelData: FC<FiveColumnsPanelDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  const defaultVariation = slice?.variation === 'default';
  const brand5ExpressiveWaveVariation = slice?.variation === 'brand5ExpressiveWave';
  const createLink = (text: string | null, link: Link): LinkType => ({ text: stringData(text), link });

  return (
    <FiveColumnsPanel
      className={className}
      cardOne={{
        heading: stringData(data.cardOneHeading),
        text: stringData(data.cardOneText),
        icon: defaultVariation ? slice?.primary?.cardOneIcon?.url : '',
      }}
      cardTwo={{
        heading: stringData(data.cardTwoHeading),
        text: stringData(data.cardTwoText),
        icon: stringData(data.cardTwoIcon?.url),
      }}
      cardThree={{
        heading: stringData(data.cardThreeHeading),
        text: stringData(data.cardThreeText),
        icon: stringData(data.cardThreeIcon?.url),
      }}
      cardFour={{
        heading: stringData(data.cardFourHeading),
        text: stringData(data.cardFourText),
        icon: stringData(data?.cardFourIcon?.url),
      }}
      cardFive={{
        heading: stringData(data.cardFiveHeading),
        text: stringData(data.cardFiveText),
        icon: brand5ExpressiveWaveVariation ? slice?.primary?.cardFiveIcon?.url : '',
      }}
      panel={
        defaultVariation
          ? {
              text: slice?.primary?.panelText,
              cta: { text: stringData(slice.primary.ctaText) || '', link: slice.primary?.ctaLink },
              image: defaultVariation ? slice.primary.panelImage : null,
            }
          : undefined
      }
      headingContent={{
        heading: data.heading,
        text: brand5ExpressiveWaveVariation ? slice?.primary?.text : '',
        primaryCta: brand5ExpressiveWaveVariation
          ? createLink(slice.primary.primaryCtaButtonText, slice.primary.primaryCtaButtonLink)
          : null,
        secondaryCta: brand5ExpressiveWaveVariation
          ? createLink(slice.primary.secondaryCtaButtonText, slice.primary.secondaryCtaButtonLink)
          : null,
        secondaryCtaMobile: brand5ExpressiveWaveVariation
          ? createLink(slice.primary.secondaryCtaMobileText, slice.primary.secondaryCtaMobileLink)
          : null,
      }}
      backgroundType={getBackgroundForVariation(slice.variation)}
    />
  );
};

export default FiveColumnsPanelData;

export const query = graphql`
  fragment FiveColumnsPanelDefault on PrismicFiveColumnsPanelDefault {
    primary {
      cardOneIcon {
        url
      }
      cardTwoIcon {
        url
      }
      cardThreeIcon {
        url
      }
      cardFourIcon {
        url
      }
      cardFiveHeading
      cardFourHeading
      cardOneHeading
      cardThreeHeading
      cardTwoHeading
      cardFiveText
      cardFourText
      cardOneText
      cardThreeText
      cardTwoText
      heading {
        ...RichText
      }
      panelText {
        ...RichText
      }
      panelImage {
        ...Image
      }
      ctaText
      ctaLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment FiveColumnsPanelBrand5ExpressiveWave on PrismicFiveColumnsPanelBrand5ExpressiveWave {
    primary {
      cardTwoIcon {
        url
      }
      cardThreeIcon {
        url
      }
      cardFourIcon {
        url
      }
      cardFiveIcon {
        url
      }
      cardFiveHeading
      cardFourHeading
      cardOneHeading
      cardThreeHeading
      cardTwoHeading
      cardFiveText
      cardFourText
      cardOneText
      cardThreeText
      cardTwoText
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaButtonLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaButtonLink {
        ...PrismicLink
      }
      secondaryCtaMobileText
      secondaryCtaMobileLink {
        ...PrismicLink
      }
    }
    variation
  }

  fragment FiveColumnsPanel on PrismicPageDataSlicesSlicesType {
    ...FiveColumnsPanelDefault
    ...FiveColumnsPanelBrand5ExpressiveWave
  }
`;
