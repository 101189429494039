import React, { PropsWithChildren, FC } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { blogContentZindex } from '../../../components/zindex';
import { brand5 } from '../../../design/colors/brand';
import { blogWysiwygShadow } from '../../../design/colors/shadows';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointMedium } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';

export const topOffsetAmount = 70;

const baseContainerPaddingStyles = css`
  padding: ${spacings['16px']} 0;
  > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
  ${breakpointMedium(
    css`
      padding: ${spacings['32px']} 24px;
      max-width: calc(100% - 48px);
    `
  )};
`;

type ContainerWrapperProps = { containerBorderRadius?: number; containerPadding?: FlattenSimpleInterpolation };
const Container = styled(Panel)<ContainerWrapperProps>`
  position: relative;
  margin: 0 auto;
  position: relative;
  margin-bottom: ${spacings['24px']};
  border-radius: ${({ containerBorderRadius }) => containerBorderRadius && `${containerBorderRadius}px`};
  ${({ containerPadding }) => (containerPadding ? containerPadding : baseContainerPaddingStyles)}
  ${blogContentZindex};
  ${blogWysiwygShadow};
  ${breakpointMedium(
    css`
      max-width: 900px;
      top: -${topOffsetAmount}px;
    `
  )};
`;

Container.defaultProps = { background: 'whiteSolid', borderRadius: '24px' };

const Background = styled(Panel)`
  background: ${brand5};
  overflow: visible;
`;

type ContentWrapperProps = {
  containerBorderRadius?: number;
  containerPadding?: FlattenSimpleInterpolation;
} & PropsWithChildren;
/**
 * Renders Container for blog content.
 */
const ContentWrapper: FC<ContentWrapperProps> = ({ children, containerBorderRadius, containerPadding }) => {
  return (
    <Background>
      <OnVisible fadeUp threshold={0.01}>
        <Container
          containerBorderRadius={containerBorderRadius}
          background="whiteSolid"
          containerPadding={containerPadding}
        >
          {children}
        </Container>
      </OnVisible>
    </Background>
  );
};

export default ContentWrapper;
