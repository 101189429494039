import { Script } from 'gatsby';
import React from 'react';

/**
 * Loads and configures the Google Tag Manager script.
 */
const GoogleTagManager = () => {
  return (
    <>
      <Script id="google-tag-manager-script" src="https://www.googletagmanager.com/gtag/js?id=AW-947916348" />
      <Script id="google-tag-manager-config">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-947916348');
        `}
      </Script>
    </>
  );
};

export default GoogleTagManager;
