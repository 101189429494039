import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import React, { ComponentProps, FC } from 'react';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { WithClassName } from '../../../utilityTypes';
import {
  getArticleType,
  getDocumentTopics,
  objectData,
  prismicAuthor,
  prismicData,
  prismicDocData,
} from '../../../utils/lodashHelpers';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import ContentHubCards from '../ContentHubCards';
import { ArticleType } from '../types';

type BlogPostCardsDataProps = SliceProps<
  'content_hub_cards',
  SliceVariation<'default', Queries.ContentHubCardsFragment['primary']>
> &
  WithClassName;

/**
 * BlogPostCards data component: specifies the fragment and passes the appropriate data to the BlogPostCards component.
 */
const BlogPostCardsData: FC<BlogPostCardsDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  const { active_topic: activeTopic, active_type: activeType, no_topics: noTopics, no_types: noTypes } = data;
  const topic = prismicDocData(activeTopic, 'topic') as string;
  const type = objectData(activeType, 'type') ? getArticleType(activeType) : null;

  const preFilters = { topic, type, noTopics, noTypes };

  const postsData: Queries.AllPostsQuery = useStaticQuery(graphql`
    query AllPosts {
      blogposts: allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
        nodes {
          url
          type
          data {
            article_type
            feature_image {
              ...Image
            }
            excerpt
            topics {
              topic {
                document {
                  ... on PrismicTopic {
                    data {
                      topic
                    }
                  }
                }
              }
            }
            author {
              document {
                ... on PrismicAuthor {
                  data {
                    name
                    title
                  }
                }
              }
            }
            title {
              text
            }
            date(formatString: "MMMM D, YYYY")
          }
        }
      }
      events: allPrismicEvents(sort: { fields: data___date, order: DESC }) {
        nodes {
          url
          type
          data {
            card_image {
              ...Image
            }
            date(formatString: "MMMM D, YYYY")
            excerpt
            is_webinar
            location
            time
            title {
              text
            }
            topics {
              topic {
                document {
                  ... on PrismicTopic {
                    data {
                      topic
                    }
                  }
                }
              }
            }
            recording_page {
              url
            }
            hubspotFormID: hubspot_form_id
          }
        }
      }
      guides: allPrismicGuides(sort: { fields: data___date, order: DESC }) {
        nodes {
          url
          type
          data {
            card_image {
              ...Image
            }
            date(formatString: "MMMM D, YYYY")
            excerpt
            is_research
            title {
              text
            }
            topics {
              topic {
                document {
                  ... on PrismicTopic {
                    data {
                      topic
                    }
                  }
                }
              }
            }
          }
        }
      }
      customerStories: allPrismicCustomerStory(sort: { fields: data___date, order: DESC }) {
        nodes {
          url
          type
          data {
            title {
              text
            }
            location
            industry
            excerpt
            date(formatString: "MMMM D, YYYY")
            card_image {
              ...Image
            }
          }
        }
      }
    }
  `);
  const {
    blogposts: { nodes: blogPosts },
    events: { nodes: eventPosts },
    guides: { nodes: guidePosts },
    customerStories: { nodes: customerPosts },
  }: Queries.AllPostsQuery = postsData;

  // @todo: remove this legacy code handler once live with new customer stories type
  const postsWithoutStories = blogPosts.filter((post) => !prismicData(post, 'article_type').includes('Customer'));
  const formattedBlogPosts = postsWithoutStories.map((post) => {
    const author = prismicAuthor(post.data.author);
    const topics: string[] = getDocumentTopics(prismicData(post, 'topics', []));
    const type: ArticleType = getArticleType(post);

    return {
      url: stringData(post.url),
      date: stringData(post.data.date),
      author,
      title: stringData(post.data.title?.text),
      excerpt: stringData(post.data.excerpt),
      image: post.data.feature_image || null,
      topics,
      type,
    };
  });

  const formattedCustomerStories = customerPosts.map((post) => {
    const type: ArticleType = getArticleType(post);

    return {
      url: stringData(post.url),
      date: prismicData(post, 'date') as string,
      title: prismicData(post, 'title.text') as string,
      excerpt: prismicData(post, 'excerpt') as string,
      image: prismicData(post, 'card_image', null) as ComponentProps<typeof PrismicImage>['image'],
      location: prismicData(post, 'location') as string,
      industry: prismicData(post, 'industry') as string,
      type,
    };
  });

  const formattedEvents = eventPosts.map((post) => {
    const topics: string[] = getDocumentTopics(prismicData(post, 'topics', []));
    const type: ArticleType = getArticleType(post);

    return {
      url: stringData(post.url),
      title: stringData(post.data.title?.text),
      topics,
      excerpt: stringData(post.data?.excerpt),
      image: post?.data.card_image,
      date: stringData(post.data?.date),
      time: stringData(post.data?.time),
      location: stringData(post.data?.location),
      isWebinar: post.data?.is_webinar,
      type,
      hubspotFormID: _.get(post, 'data.hubspotFormID', ''),
      recording: _.get(post, 'data.recording_page.url', ''),
    };
  });

  const formattedGuides = guidePosts.map((post) => {
    const topics: string[] = getDocumentTopics(prismicData(post, 'topics', []));
    const type: ArticleType = getArticleType(post);

    return {
      url: stringData(post.url),
      title: stringData(post.data.title?.text),
      topics,
      excerpt: stringData(post.data?.excerpt),
      image: post?.data.card_image,
      date: stringData(post.data?.date),
      isResearch: post.data?.is_research,
      type,
    };
  });

  return (
    <ContentHubCards
      posts={formattedBlogPosts}
      events={formattedEvents}
      guides={formattedGuides}
      stories={formattedCustomerStories}
      preFilters={preFilters}
      className={className}
    />
  );
};

export default BlogPostCardsData;

export const query = graphql`
  fragment ContentHubCards on PrismicContentHubCardsDefault {
    primary {
      active_topic {
        document {
          ... on PrismicTopic {
            id
            data {
              topic
            }
          }
        }
      }
      active_type {
        type
      }
      no_topics
      no_types
    }
  }
`;
