import { css } from 'styled-components';
import { FontWeight } from './Text';

/**
 * Strips invalid characters from the text.
 */
export const stripInvalidCharacters = (text: string) => text.replace('\u2028', '');

type ColorBoldProps = {
  weight?: FontWeight;
  color?: string;
};
/**
 * Target STRONG in richtext and define colour/weight .
 */
export const colorBoldText = ({ weight = '400', color = 'inherit' }: ColorBoldProps) =>
  css`
    strong {
      color: ${color};
      font-weight: ${weight};
    }
  `;
/**
 * Truncates paragraphs by x amount of lines and append ellipses.
 */
export const multiLineEllipsis = (clamp: number) =>
  css`
    display: -webkit-box;
    -webkit-line-clamp: ${clamp};
    overflow: hidden;
    -webkit-box-orient: vertical;
  `;

/**
 * Truncates sentence overflow with ellipses.
 */
export const singleLineEllipsis = () =>
  css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
