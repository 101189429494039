import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import styled, { css, keyframes } from 'styled-components';
import { brand2, brand4 } from '../../design/colors/brand';
import { grey400, grey700 } from '../../design/colors/greyscale';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import { BodyP } from '../../design/typography/Typography';
import Loading from '../../images/react-icons/Loading.inline.svg';
import { fxLoadingSpinnerTestId, fxSenderInputTestId, fxVendorInputTestId } from './fxCalculator.testIds';
import { InfoTrail } from './info-trail/InfoTrail';
import { CalculatorInput } from './input/CalculatorInput';
import { Currency } from './input/types';
import { RateResponsePayload, InputType, RateRequestParams } from './types';
import { maxAllowedMoneyAmount, fetchExchangeRates } from './utils';

const SenderInput = styled(CalculatorInput).attrs({ label: 'You pay', testId: fxSenderInputTestId })``;
const VendorInput = styled(CalculatorInput).attrs({ label: 'They receive', testId: fxVendorInputTestId })``;
const InlineP = styled(BodyP)`
  display: inline;
`;

const SavingsP = styled(InlineP).attrs({ type: 'heading3Bold', color: brand2 })`
  font-weight: 700;
`;

const SavingsCounter = ({ number }: { number: number }) => {
  const formattingFn = (value: number) => `$${value.toLocaleString()} AUD`;
  const ref = useRef<number>(number);

  useEffect(() => {
    ref.current !== number && (ref.current = number);
  }, [number, ref]);

  return (
    <SavingsP>
      <CountUp start={ref.current} end={number} useEasing duration={0.6} formattingFn={formattingFn} />
    </SavingsP>
  );
};

const borderBase = css`
  content: '';
  position: absolute;
  height: 1px;
  background-color: ${grey400};
  transition: all 1s ease;
`;

const borderStart = css`
  &:after {
    ${borderBase}
    bottom: 0;
    left: 50%;
    width: 0;
  }
`;
const borderEnd = css`
  &:after {
    ${borderBase}
    left: 0;
    width: 100%;
  }
`;

export const DetailContainer = styled(OnVisible)`
  position: relative;
  padding-bottom: 24px;
  ${breakpointSmall(css`
    padding-bottom: 90px;
  `)}
  margin-bottom: 24px;
  ${borderStart}
  &.visible {
    ${borderEnd}
  }
`;
const infiniteRotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const LoadingSpinner = styled(Loading)`
  animation: ${infiniteRotation} 600ms infinite linear;
  margin: auto;
`;

const waitForUserInput = 300;

/**
 * FxCalculator component.
 */
export const FxCalculator = () => {
  const [vendorCurrency, setVendorCurrency] = useState<Currency>('USD');
  const [senderAmount, setSenderAmount] = useState<number>(500);
  const [senderInputDisabled, setSenderInputDisabled] = useState<boolean>(false);
  const [vendorAmount, setVendorAmount] = useState<number>(0);
  const [vendorInputDisabled, setVendorInputDisabled] = useState<boolean>(false);
  const [vendorCurrencyDisabled, setVendorCurrencyDisabled] = useState<boolean>(false);
  const [calculation, setCalculation] = useState<RateResponsePayload | undefined>();
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
  const senderCurrency = 'AUD'; // hard coded for v1;
  const params: RateRequestParams = {
    source_currency: senderCurrency,
    destination_currency: vendorCurrency,
  };
  if (senderAmount) params.source_amount = senderAmount.toString();
  if (vendorAmount) params.destination_amount = vendorAmount.toString();

  useEffect(() => {
    fetchExchangeRates(params).then((res) => {
      setCalculation(res);
      setVendorAmount(res.destination_amount);
    });
  }, []);

  const onAmountChange = async (input: InputType, amount: number | undefined) => {
    if (amount === undefined) {
      amount = 0;
    }
    const handleUserInput = async () => {
      if (amount === undefined || amount === 0 || amount > maxAllowedMoneyAmount) return;
      if (input === 'sender') {
        delete params.destination_amount;
        params.source_amount = amount?.toString();
      } else {
        delete params.source_amount;
        params.destination_amount = amount?.toString();
      }
      await fetchExchangeRates(params).then((res) => {
        setCalculation(res);
        if (input === 'sender') {
          setVendorAmount(res.destination_amount);
          setVendorInputDisabled(false);
        } else {
          setSenderAmount(res.source_amount);
          setSenderInputDisabled(false);
        }
        setVendorCurrencyDisabled(false);
      });
    };
    if (input === 'sender') {
      setSenderAmount(amount);
      setVendorInputDisabled(true);
    } else {
      setVendorAmount(amount);
      setSenderInputDisabled(true);
    }
    // in both cases, we want to disable the vendor currency input until the calculation is done
    setVendorCurrencyDisabled(true);
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(handleUserInput, waitForUserInput));
  };

  const onVendorCurrencyChange = (destination_currency: Currency) => {
    setVendorCurrency(destination_currency);
    setVendorInputDisabled(true);
    setSenderInputDisabled(true);
    setVendorCurrencyDisabled(true);
    params.destination_currency = destination_currency;
    delete params.destination_amount;
    fetchExchangeRates(params).then((res) => {
      setCalculation(res);
      setVendorAmount(res.destination_amount);
      setVendorInputDisabled(false);
      setSenderInputDisabled(false);
      setVendorCurrencyDisabled(false);
    });
  };

  if (!calculation?.rate) return <LoadingSpinner data-testid={fxLoadingSpinnerTestId} />;

  return (
    <div data-testid="fx-calculator">
      <DetailContainer>
        <>
          <SenderInput
            value={senderAmount}
            currency={senderCurrency}
            onChange={(value?: number) => onAmountChange('sender', value)}
            disabled={senderInputDisabled}
          />
          <InfoTrail exchangeRate={calculation.rate.toString()} currency={vendorCurrency} />
          <VendorInput
            currency={vendorCurrency}
            value={vendorAmount}
            // no onVendorCurrencyChange will disable the dropdown
            onChangeCurrency={vendorCurrencyDisabled ? undefined : onVendorCurrencyChange}
            onChange={(value?: number) => onAmountChange('vendor', value)}
            disabled={vendorInputDisabled}
          />
        </>
      </DetailContainer>
      <Spacing right="4px">
        <SavingsP>→</SavingsP>
      </Spacing>
      <InlineP type="heading4" color={brand4}>
        You save{' '}
        <InlineP type="heading4Medium">
          <SavingsCounter number={calculation.estimated_saving} />
        </InlineP>{' '}
        on this transaction
      </InlineP>
      <Spacing top="16px">
        <BodyP type="bodySRegular" color={grey700}>
          When compared to the standard 3% foreign transaction fee offered by the big 4 banks in Australia.
        </BodyP>
      </Spacing>
    </div>
  );
};
