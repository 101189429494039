import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import { extractSpeakersFromEvent, generateEventSchema } from '../../components/SEO/structuredData/utils';
import PageContainer from '../../pageContainers/PageContainer';
import EmailCaptureData from '../../slices/emailCapture/data/EmailCapture.queryData';
import RelatedResourcesData from '../../slices/relatedResources/RelatedResources.queryData';
import SocialProofData from '../../slices/socialProof/data/SocialProof.queryData';
import SpeakersData from '../../slices/speakers/Speakers.queryData';
import { SponsorsData } from '../../slices/sponsors/data/Sponsors.queryData';
import ThreeColumnCardsData from '../../slices/threeColumnCards/data/ThreeColumnCards.queryData';
import TwoColumnContentImageData from '../../slices/twoColumnContentImage/data/TwoColumnContentImage.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import { objectData } from '../../utils/lodashHelpers';
import { stringData } from '../../utils/normaliseData';
import EventHeader from './EventHeader/EventHeader';

const components = {
  two_column_content_image: withBottomSpacing(TwoColumnContentImageData),
  sponsors: withBottomSpacing(SponsorsData),
  three_column_cards: withBottomSpacing(ThreeColumnCardsData),
  email_capture: withBottomSpacing(EmailCaptureData),
  social_proof: withBottomSpacing(SocialProofData),
  speakers: withBottomSpacing(SpeakersData),
  related_resources: withBottomSpacing(RelatedResourcesData),
};
/**
 * Renders Prismic Page document and maps slice data to be rendered by their components in order.
 */
const Event: FC<PageProps<Queries.EventQuery>> = ({ data }) => {
  const pageData = data.prismicEvents?.data;
  const headerId = pageData?.header?.id || undefined;
  const footerId = pageData?.footer?.id || undefined;
  const slices = pageData?.slices?.map((slice) => ({ ...slice })) as Queries.PrismicSharedSliceType[];
  const uid = data.prismicEvents?.url || '';

  const maybeTopics = pageData?.topics?.length ? pageData.topics : [];

  const topics = maybeTopics.map((topicItem) => {
    const maybeTopicDocument = topicItem?.topic?.document;
    const topicDocument = maybeTopicDocument && 'data' in maybeTopicDocument ? maybeTopicDocument : undefined;
    const topic = stringData(topicDocument?.data.topic);
    return topic;
  });

  const maybeRecordingDocument = pageData?.recording_page;
  const recordingLink = maybeRecordingDocument && 'url' in maybeRecordingDocument ? maybeRecordingDocument : undefined;

  const headerData = {
    title: pageData?.title,
    topics,
    excerpt: stringData(pageData?.excerpt),
    image: pageData?.header_image,
    date: stringData(pageData?.date),
    time: stringData(pageData?.time),
    location: stringData(pageData?.location),
    recording: recordingLink?.url,
    isWebinar: pageData?.is_webinar,
    hubspotFormID: stringData(pageData?.hubspot_form_id),
    hubspotFormTrackingEventName: stringData(pageData?.hubspot_form_tracking_event_name),
    hubspotFormSuccessHeading: stringData(pageData?.hubspot_form_success_heading),
    hubspotFormSuccessText: stringData(pageData?.hubspot_form_success_text),
  };

  const seoData = {
    title: stringData(pageData?.Title),
    description: stringData(pageData?.Description),
    ogImage: stringData(pageData?.Image?.url),
    exclude_from_search_engines: !!pageData?.no_index,
    last_publication_date: data?.prismicEvents?.last_publication_date,
  } as const;

  const startDate = stringData(pageData?.schemaDate);

  const performers = extractSpeakersFromEvent(slices);
  const schema = JSON.stringify(
    generateEventSchema({
      uid,
      performers,
      startDate,
      location: stringData(pageData?.location),
      headline: objectData(pageData, 'title.text'),
      image: stringData(pageData?.header_image?.url),
      description: stringData(pageData?.excerpt),
    })
  );

  const seo = {
    ...seoData,
    schema,
  } as const;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <EventHeader {...headerData} />
      <SliceZone slices={pageData?.slices} components={components} />
    </PageContainer>
  );
};

export default withPrismicPreview(Event);

export const query = graphql`
  query Event($url: String!) {
    prismicEvents(url: { eq: $url }) {
      _previewable
      last_publication_date
      url
      data {
        ...EventsHeader
        ...EventsFooter
        ...EventsSeo
        slices {
          ...Slice
          ...TwoColumnContentImage
          ...ThreeColumnCards
          ...Sponsors
          ...EmailCapture
          ...SocialProof
          ...Speakers
          ...RelatedResources
        }
        card_image {
          ...Image
        }
        date(formatString: "dddd, D MMMM YYYY")
        schemaDate: date(formatString: "yyyy-MM-DD")
        excerpt
        header_image {
          ...Image
          url
        }
        location
        time
        title {
          ...RichText
          text
        }
        topics {
          topic {
            document {
              ... on PrismicTopic {
                id
                data {
                  topic
                }
              }
            }
          }
        }
        recording_page {
          url
        }
        hubspot_form_id
        hubspot_form_tracking_event_name
        hubspot_form_success_heading
        hubspot_form_success_text
        is_webinar
      }
    }
  }
`;
