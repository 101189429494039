import React, { FC } from 'react';
import { SEO, SEOProps } from '../components/SEO/SEO';
import GoogleTagManager from '../components/analytics/GoogleTagManager';
import { GlobalStyles } from '../global/styles/GlobalStyles';

type Props = { seoProps?: SEOProps };
/**
 * Adds \<head\> data.
 */
export const PageHead: FC<Props> = ({ seoProps }) => (
  <>
    <GlobalStyles />
    <SEO {...seoProps} />
    <GoogleTagManager />
  </>
);
