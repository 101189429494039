import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { objectData } from '../../../utils/lodashHelpers';
import { SliceProps, SliceVariation } from '../../types';
import TwoColumnProcess from '../TwoColumnProcess';

type TwoColumnProcessProps = SliceProps<
  'two_column_process',
  SliceVariation<'default', Queries.PrismicTwoColumnProcessDefaultPrimary, Queries.PrismicTwoColumnProcessDefaultItem>
> &
  WithClassName;

/**
 * Logos data component: specifies the fragment and passes the appropriate data to the Logos component.
 */
const TwoColumnProcessData: FC<TwoColumnProcessProps> = ({ className, slice }) => {
  return (
    <TwoColumnProcess
      title={slice.primary.title}
      description={slice.primary.description}
      cta_link={objectData(slice, 'primary.cta_link')}
      cta_text={objectData(slice, 'primary.cta_text')}
      left_column_heading={slice.primary.left_column_heading}
      left_column_image={objectData(slice, 'primary.left_column_image')}
      left_column_subtitle={slice.primary.left_column_subtitle}
      items={slice.items}
      className={className}
    />
  );
};

export default TwoColumnProcessData;

export const query = graphql`
  fragment TwoColumnProcess on PrismicTwoColumnProcessDefault {
    primary {
      cta_text
      cta_link {
        ...PrismicLink
      }
      description {
        ...RichText
      }
      left_column_heading {
        ...RichText
      }
      left_column_image {
        ...Image
      }
      left_column_subtitle {
        ...RichText
      }
      title {
        ...RichText
      }
    }
    items {
      card_description {
        ...RichText
      }
      card_heading {
        ...RichText
      }
    }
  }
`;
