import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { GatedButton } from '../../components/gatedContent/GatedContent';
import { ButtonLink } from '../../design/components/button/Button';
import { getEventState, shouldRenderButton } from '../../templates/events/EventHeader/EventHeader.helpers';
import { ResourceCard } from '../contentHubCards/types';

const Cta = styled(ButtonLink)`
  max-width: fit-content;
`;

type LinkedDocOptions = {
  linkedDocumentCta: ResourceCard;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};
/**
 * Renders a Linked Document Cta / Gated Content.
 */
const LinkedDocumentCta = ({ linkedDocumentCta, isModalOpen, setIsModalOpen }: LinkedDocOptions) => {
  if (linkedDocumentCta) {
    const type = _.get(linkedDocumentCta, 'document.type');
    const hubspotFormID = _.get(linkedDocumentCta, 'document.data.hubspot_form_id', '');
    const recording = _.get(linkedDocumentCta, 'document.data.recording_page.url', '');

    if (type === 'events') {
      const gatedContentProps = {
        hubspotFormID: hubspotFormID,
        hubspotFormTrackingEventName: _.get(linkedDocumentCta, 'document.data.hubspot_form_tracking_event_name'),
        hubspotFormSuccessHeading: _.get(linkedDocumentCta, 'document.data.hubspot_form_success_heading'),
        hubspotFormSuccessText: _.get(linkedDocumentCta, 'document.data.hubspot_form_success_text'),
        downloadLink: recording,
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
      } as const;

      const date = _.get(linkedDocumentCta, 'document.data.date', '');
      const detailState = getEventState({ date, hubspotFormID, recording });
      return detailState && shouldRenderButton(detailState) ? (
        <GatedButton text={detailState} {...gatedContentProps} />
      ) : null;
    }

    if (type === 'guides') {
      const downloadLink = _.get(linkedDocumentCta, 'document.data.download_file');
      const hubspotFormID = _.get(linkedDocumentCta, 'document.data.hubspotFormID', '');
      const gatedContentProps = {
        hubspotFormID: hubspotFormID,
        hubspotFormTrackingEventName: _.get(linkedDocumentCta, 'document.data.hubspotFormTrackingEventName'),
        hubspotFormSuccessHeading: _.get(linkedDocumentCta, 'document.data.hubspotFormSuccessHeading'),
        hubspotFormSuccessText: _.get(linkedDocumentCta, 'document.data.hubspotFormSuccessText'),
        downloadLink: downloadLink.url,
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
      } as const;

      return downloadLink && hubspotFormID ? (
        <GatedButton text="Download Now" data-testid="guide-download-gated-button" {...gatedContentProps} />
      ) : (
        downloadLink && (
          <Cta data-testid="guide-download-cta" link={recording}>
            Download now
          </Cta>
        )
      );
    }
  }
  return null;
};

export default LinkedDocumentCta;
