import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../components/link/types';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink, ButtonToUse } from '../../design/components/button/Button';
import { Background } from '../../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import Spacing from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import applePayLogo from '../../images/apple-pay.svg';
import { WithClassName } from '../../utilityTypes';
import { getBackgroundStyle } from './HomepageHero';

const TextContentContainer = styled.div`
  max-width: 564px;
`;

const applePaySvgWidth = 48.6;
const ApplePayLogoSpan = css`
  .applepay-logo-prefix {
    position: relative;
    &:before {
      content: url(${applePayLogo});
      position: absolute;
      height: auto;
      width: ${applePaySvgWidth}px;
      left: calc(-${applePaySvgWidth}px - 6px);
    }
    margin-left: ${applePaySvgWidth}px;
  }
`;

const StyledRichText = styled(RichText)`
  ${ApplePayLogoSpan}
`;

type HeroProps = {
  heading: PrismicRichTextType;
  text: PrismicRichTextType;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  secondaryMobileCta: LinkType;
  backgroundType?: Background;
} & WithClassName;
/**
 * Renders Hero slice.
 */
const Hero: FC<HeroProps> = ({
  className,
  heading,
  text,
  primaryCta,
  secondaryCta,
  secondaryMobileCta,
  backgroundType = 'brand5GlassSpinnerLime',
}) => {
  return (
    <SliceContainer className={className} background={backgroundType}>
      <CenteredSliceContentContainer verticalPadding="100px" maxWidth="814px">
        <Heading1>{heading}</Heading1>
        <Spacing top="24px">
          <TextContentContainer>
            {text && <StyledRichText textType="bodyLRegular" textAlign="center" field={text} />}
          </TextContentContainer>
        </Spacing>
        <Spacing top="24px" bottom={{ base: '144px', breakpointSmall: 'none' }}>
          <TwoCtaPlacementContainer
            cta1Placement={
              <ButtonLink styleType="primary" link={primaryCta.link}>
                {primaryCta.text}
              </ButtonLink>
            }
            cta2Placement={
              <ButtonToUse
                desktopCta={secondaryCta}
                mobileCta={secondaryMobileCta}
                styleType={getBackgroundStyle(backgroundType)}
              />
            }
          />
        </Spacing>
      </CenteredSliceContentContainer>
    </SliceContainer>
  );
};

export default Hero;
