import React, { FC } from 'react';
import Link from '../../../../../components/link/Link';
import * as colors from '../../../../../design/colors/brand';
import { Background, isDarkBackground } from '../../../../../design/containers/panel/Panel';
import { BodyP } from '../../../../../design/typography/Typography';
import { PrismicImageData } from '../../../../../utilityTypes';
import { ArticleType } from '../../../types';
import CategoryTag from '../../Tags/CategoryTag';
import { TagTheme } from '../../Tags/TopicTags';
import { DetailsContainer } from '../EventCard/EventCard';
import { Card, CardImage, CardContent, TextContainer, Excerpt, CardTitle } from '../sharedCardStyles';

type CardProps = {
  url: string;
  date: string;
  title: string;
  excerpt: string;
  image?: PrismicImageData;
  location: string;
  industry: string;
  type: ArticleType;
  theme: TagTheme;
  truncateTitle?: boolean;
  background: Background;
};
/**
 * Blog Card component: sits inside PostsGrid component on Blog Landing page.
 */
const StoryCard: FC<CardProps> = ({
  title,
  excerpt,
  url,
  image,
  type,
  industry,
  location,
  truncateTitle,
  background,
}) => {
  const link = {
    link_type: 'Document',
    url,
  } as const;

  const darkMode = isDarkBackground(background);
  const textColor = darkMode ? colors.brand2Mid : colors.brand2;
  return (
    <Card borderRadius="24px" data-testid="content-hub-card" background={background}>
      {image?.gatsbyImageData && (
        <Link link={link}>
          <CardImage image={image} />
        </Link>
      )}
      <CardContent>
        <TextContainer>
          <CategoryTag type={type} isLight={darkMode} />
          <CardTitle link={link} truncateTitle={truncateTitle}>
            {title}
          </CardTitle>
          {excerpt && <Excerpt>{excerpt}</Excerpt>}
          <DetailsContainer darkMode={darkMode}>
            {location && (
              <>
                <BodyP type="bodyMBold" color={textColor}>
                  Location
                </BodyP>
                <BodyP type="bodyMRegular">{location}</BodyP>
              </>
            )}
            {location && (
              <>
                <BodyP type="bodyMBold" color={textColor}>
                  Industry
                </BodyP>
                <BodyP type="bodyMRegular">{industry}</BodyP>
              </>
            )}
          </DetailsContainer>
        </TextContainer>
      </CardContent>
    </Card>
  );
};

export default StoryCard;
