import { useLottie, useLottieInteractivity } from 'lottie-react';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getJsonResponse } from '../../utils/apiHelpers/getJsonResponse';
import { AnimationField } from './types';

const AnimationContainer = styled.figure`
  > div {
    display: flex;
  }
`;

export type LottieProps = { animation: AnimationField };
/**
 * Animated Lottie component.
 */
const Lottie: FC<LottieProps> = ({ animation }) => {
  const fileUrl = animation?.document?.data?.lottie_file?.url;
  const [jsonFile, setJsonFile] = useState<Record<string, number>>();

  useEffect(() => {
    if (!fileUrl) return;
    getJsonResponse(fileUrl).then(setJsonFile);
  }, [fileUrl]);

  const firstFrame = jsonFile?.ip || 0;
  const totalFrames = jsonFile?.op || 0;

  const renderedLottieAnimation = useLottieInteractivity({
    lottieObj: useLottie({ animationData: jsonFile, loop: false }),
    mode: 'scroll',
    actions: [
      {
        visibility: [0.05, 0.8],
        type: 'seek',
        frames: [firstFrame, totalFrames],
      },
    ],
  });

  const showAnimation = !!(fileUrl && jsonFile) || null;

  return (
    showAnimation && <AnimationContainer data-testid="lottie-animation">{renderedLottieAnimation}</AnimationContainer>
  );
};

export default Lottie;
