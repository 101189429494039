import React from 'react';
import styled from 'styled-components';
import { Button } from '../../design/components/button/Button';
import PopUpForm from './PopUpForm';

const StyledGatedButton = styled(Button)`
  width: fit-content;
`;

type GatedButtonProps = {
  text: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
  hubspotFormID: string | null;
  hubspotFormTrackingEventName: string | null;
  hubspotFormSuccessHeading: string | null;
  hubspotFormSuccessText: string | null;
  downloadLink?: string | null;
};

/**
 * Gated Button to Download Content on the Guides/Research Header. Given a Hubspot Form ID, renders a modal for the gated content.
 */
export const GatedButton = ({
  text,
  setIsModalOpen,
  isModalOpen,
  hubspotFormID,
  hubspotFormTrackingEventName,
  hubspotFormSuccessHeading,
  hubspotFormSuccessText,
  downloadLink,
}: GatedButtonProps) => (
  <>
    <StyledGatedButton onClick={() => setIsModalOpen(!isModalOpen)}>{text}</StyledGatedButton>
    <PopUpForm
      setIsModalOpen={setIsModalOpen}
      active={isModalOpen}
      hubspotFormID={hubspotFormID || ''}
      hubspotFormTrackingEventName={hubspotFormTrackingEventName || ''}
      hubspotFormSuccessHeading={hubspotFormSuccessHeading}
      hubspotFormSuccessText={hubspotFormSuccessText}
      downloadLink={downloadLink}
    />
  </>
);
