import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { ExtractFromArray, Mutable, WithClassName } from '../../utilityTypes';
import { stringData } from '../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../types';
import Speakers from './Speakers';

export type SpeakersItem = ExtractFromArray<Mutable<Queries.SpeakersFragment['items']>>;
type SpeakersDataProps = SliceProps<
  'speakers',
  SliceVariation<'default', Queries.SpeakersFragment['primary'], SpeakersItem>
> &
  WithClassName;

/**
 * Speakers data component.
 */
const SpeakersData: FC<SpeakersDataProps> = ({ className, slice }) => {
  const maybeItems = slice.items?.length > 0 ? slice.items : [];
  const formattedSpeakers = maybeItems.map((maybeItem) => {
    const doc = maybeItem.speakers?.document;
    const bmSpeakers = doc && 'data' in doc ? doc : undefined;
    return {
      company: stringData(bmSpeakers?.data?.company),
      name: stringData(bmSpeakers?.data?.name),
      role: stringData(bmSpeakers?.data?.role),
      image: bmSpeakers?.data?.image,
      linkedin: stringData(bmSpeakers?.data.linkedin?.url),
    };
  });
  return (
    <Speakers
      className={className}
      speakersTitle={stringData(slice.primary.speakersTitle)}
      speakers={formattedSpeakers}
    />
  );
};

export default SpeakersData;

export const query = graphql`
  fragment Speakers on PrismicSpeakersDefault {
    primary {
      speakersTitle: title
    }
    items {
      speakers {
        document {
          ... on PrismicSpeaker {
            id
            data {
              image {
                ...Image
              }
              linkedin {
                url
              }
              company
              name
              role
            }
          }
        }
      }
    }
  }
`;
