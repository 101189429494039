/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { OnVisible } from '../../../design/components/onVisible/OnVisible';
import { WithTestId } from '../../../utilityTypes';
import { CalculatorRowProps } from '../FeaturePricingComparison';
import { PlanLimitData, getCalculatorData } from '../utils';
import { FeatureHeadingToggle } from './FeatureHeadingToggle';
import { Collapsable } from './FeatureList';
import { ComparisonCell, DetailsColumn, DetailsColumnProps, PricingRowContainer, Tier } from './FeatureRow';
import { LimitToggle } from './LimitToggle';

export type PlanLimits = { basic?: number; premium?: number; enterprise?: number };

const CalculatorCell = ({
  tier,
  'data-testid': testId,
  value,
  updateValue,
  originalLimits,
  showOnMobile,
}: WithTestId & {
  tier: Tier;
  value: number;
  updateValue: (value: number) => void;
  originalLimits: PlanLimits;
  showOnMobile: boolean;
}) => {
  const disableSubtraction = value <= (originalLimits[tier as Tier] || 0);

  const updateLimits = (newValue: number) => {
    const originalTierPrice = originalLimits[tier as Tier];
    if (originalTierPrice) newValue > originalTierPrice ? updateValue(newValue) : updateValue(originalTierPrice);
  };

  return (
    <ComparisonCell showOnMobile={showOnMobile} key={tier} data-testid={`${tier}-${testId}`}>
      <LimitToggle value={value} updateValue={updateLimits} disableSubtraction={disableSubtraction} />
    </ComparisonCell>
  );
};

type CalculatorDataProps = DetailsColumnProps &
  PlanLimits & { metric: 'users' | 'payments' } & { calculator: (limits: PlanLimits) => void };
/**
 * Render Component component.
 */
export const CalculatorRow: FC<CalculatorDataProps & { mobileActiveTier?: Tier }> = ({
  metric,
  basic,
  premium,
  enterprise,
  calculator,
  mobileActiveTier,
  ...detailProps
}) => {
  const originalLimits = { basic, premium, enterprise };
  const [limits, setLimits] = useState<PlanLimits>(originalLimits);

  useEffect(() => {
    if (limits !== originalLimits) calculator(limits);
  }, [limits]);

  return (
    <OnVisible fadeUp>
      <PricingRowContainer data-testid={`calculator-row-${metric}`}>
        <DetailsColumn {...detailProps} />
        {Object.entries(limits).map(([tier, limit]) => {
          return (
            <CalculatorCell
              key={tier}
              value={limit}
              tier={tier as Tier}
              updateValue={(value) => setLimits({ ...limits, [tier]: value })}
              originalLimits={originalLimits}
              data-testid={metric}
              showOnMobile={mobileActiveTier === tier}
            />
          );
        })}
      </PricingRowContainer>
    </OnVisible>
  );
};

type PriceProps = { updatePrices: (prices: Record<Tier, number>) => void; currentPrices: Record<Tier, number> };
/**
 * Calculator for feature pricing comparison.
 */
export const Calculator: FC<CalculatorRowProps & PriceProps & { mobileActiveTier: Tier }> = ({
  users,
  payments,
  updatePrices,
  mobileActiveTier,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [userLimits, setUserLimits] = useState<PlanLimitData | undefined>(undefined);
  const [paymentLimits, setPaymentLimits] = useState<PlanLimitData | undefined>(undefined);

  const collapseContainer = useRef<HTMLDivElement>(null);
  const scrollHeight = 1000;

  const toggleExpand = () => setOpen(!open);

  const richText = [{ type: 'heading3', text: 'Pricing calculator', spans: [] }];
  const initialLimits = { users, payments };

  const updateNewPrices = (newLimits: PlanLimits, metric: 'users' | 'payments') => {
    const calculatorData = getCalculatorData({ metric, newLimits, initialLimits });
    metric === 'users' ? setUserLimits(calculatorData) : setPaymentLimits(calculatorData);
  };

  useEffect(() => {
    const startingPrices = { basic: 119, premium: 340, enterprise: 2025 } as const;
    const newPrices: Record<Tier, number> = { ...startingPrices };

    if (userLimits)
      Object.entries(userLimits).forEach(
        ([tier, { cost }]) => (newPrices[tier as Tier] = newPrices[tier as Tier] + cost.additional)
      );
    if (paymentLimits)
      Object.entries(paymentLimits).forEach(([tier, { cost }]) => (newPrices[tier as Tier] += cost.additional));

    updatePrices(newPrices);
  }, [userLimits, paymentLimits]);

  return (
    <div data-testid="pricing-calculator">
      <FeatureHeadingToggle title={{ richText }} onClick={toggleExpand} open={!!open} />
      <Collapsable open={!!open} ref={collapseContainer} maxHeight={scrollHeight}>
        <CalculatorRow
          metric="users"
          feature_title={users.title}
          feature_description={users.subtitle}
          calculator={(limits: PlanLimits) => updateNewPrices(limits, 'users')}
          mobileActiveTier={mobileActiveTier}
          {...users}
        />
        <CalculatorRow
          metric="payments"
          feature_title={payments.title}
          feature_description={payments.subtitle}
          calculator={(limits: PlanLimits) => updateNewPrices(limits, 'payments')}
          mobileActiveTier={mobileActiveTier}
          {...payments}
        />
      </Collapsable>
    </div>
  );
};
