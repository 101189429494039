import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import PageContainer from '../../pageContainers/PageContainer';
import RelatedResourcesData from '../../slices/relatedResources/RelatedResources.queryData';
import StatsData from '../../slices/stats/data/Stats.queryData';
import ThreeColumnCardsData from '../../slices/threeColumnCards/data/ThreeColumnCards.queryData';
import TwoColumnContentImageData from '../../slices/twoColumnContentImage/data/TwoColumnContentImage.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import { stringData } from '../../utils/normaliseData';
import GuideHeader from './GuideHeader/GuideHeader';

const components = {
  two_column_content_image: withBottomSpacing(TwoColumnContentImageData),
  stats: withBottomSpacing(StatsData),
  three_column_cards: withBottomSpacing(ThreeColumnCardsData),
  related_resources: withBottomSpacing(RelatedResourcesData),
};
/**
 * Renders Prismic Page document and maps slice data to be rendered by their components in order.
 */
const Guide: FC<PageProps<Queries.GuideQuery>> = ({ data }) => {
  const pageData = data.prismicGuides?.data;
  const headerId = pageData?.header?.id || undefined;
  const footerId = pageData?.footer?.id || undefined;

  const maybeTopics = pageData?.topics?.length ? pageData.topics : [];

  const topics = maybeTopics.map((topicItem) => {
    const maybeTopicDocument = topicItem?.topic?.document;
    const topicDocument = maybeTopicDocument && 'data' in maybeTopicDocument ? maybeTopicDocument : undefined;
    const topic = stringData(topicDocument?.data.topic);
    return topic;
  });

  const headerData = {
    researchType: pageData?.is_research,
    title: pageData?.title,
    topics,
    excerpt: pageData?.excerpt,
    image: pageData?.header_image,
    downloadLink: pageData?.download_file,
    hubspotFormID: pageData?.hubspotFormID || '',
    hubspotFormTrackingEventName: pageData?.hubspotFormTrackingEventName || '',
    hubspotFormSuccessHeading: pageData?.hubspotFormSuccessHeading || '',
    hubspotFormSuccessText: pageData?.hubspotFormSuccessText || '',
  };

  const seo = {
    title: stringData(pageData?.Title),
    description: stringData(pageData?.description),
    ogImage: stringData(pageData?.image?.url),
    exclude_from_search_engines: !!pageData?.no_index,
    last_publication_date: data.prismicGuides?.last_publication_date,
  } as const;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <GuideHeader {...headerData} />
      <SliceZone slices={pageData?.slices} components={components} />
    </PageContainer>
  );
};

export default withPrismicPreview(Guide);

export const query = graphql`
  query Guide($url: String!) {
    prismicGuides(url: { eq: $url }) {
      _previewable
      last_publication_date
      data {
        ...GuidesHeader
        ...GuidesFooter
        ...GuidesSeo
        slices {
          ...Slice
          ...TwoColumnContentImage
          ...ThreeColumnCards
          ...Stats
          ...RelatedResources
        }
        is_research
        hubspotFormID
        hubspotFormTrackingEventName
        hubspotFormSuccessHeading
        hubspotFormSuccessText
        title {
          ...RichText
        }
        topics {
          topic {
            document {
              ... on PrismicTopic {
                id
                data {
                  topic
                }
              }
            }
          }
        }
        header_image {
          ...Image
        }
        card_image {
          ...Image
        }
        download_file {
          ...PrismicLink
        }
        excerpt
      }
    }
  }
`;
