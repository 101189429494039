import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink } from '../../design/components/button/Button';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import Heading1 from '../../design/typography/Heading1';
import { BodyP } from '../../design/typography/Typography';

const HeadingContainer = styled(CenteredSliceContentContainer)`
  padding-top: ${spacings['48px']};
  padding-bottom: ${spacings['none']};
  max-width: 814px;

  ${breakpointSmall(css`
    padding-top: ${spacings['100px']};
  `)};
`;

const TextContent = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'center' })`
  max-width: 564px;
`;

export type TopContentProps = {
  heading: PrismicRichTextType;
  text?: string;
  primaryCta?: LinkType;
  secondaryCta?: LinkType;
};

/**
 * Renders top content for ContentInfoBlock.
 */
const TopInfoBlock: FC<TopContentProps> = ({ heading, text, primaryCta, secondaryCta }) => (
  <HeadingContainer>
    <Heading1>{heading}</Heading1>
    <Spacing top="24px">
      <TextContent>{text}</TextContent>
    </Spacing>
    <Spacing top="24px" bottom={{ base: '64px', breakpointSmall: '48px' }}>
      <TwoCtaPlacementContainer
        cta1Placement={
          <ButtonLink styleType="primary" link={primaryCta?.link || null}>
            {primaryCta?.text || ''}
          </ButtonLink>
        }
        cta2Placement={
          <ButtonLink styleType="secondary" link={secondaryCta?.link || null}>
            {secondaryCta?.text || ''}
          </ButtonLink>
        }
      />
    </Spacing>
  </HeadingContainer>
);

export default TopInfoBlock;
