import React, { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import CloneChild from '../../design/utils/CloneChild';
import { WithClassName } from '../../utilityTypes';

const SliceBottomSpacingProvider = styled(CloneChild)`
  margin-bottom: ${spacings['12px']};

  ${breakpointSmall(css`
    margin-bottom: ${spacings['24px']};
  `)}
`;
/**
 * Provided with a component, this will add slice bottom spacing to the component.
 *
 * The component must accept a `className` prop for it to work.
 */
const withBottomSpacing = <TProps extends WithClassName>(Component: ComponentType<TProps>): ComponentType<TProps> => {
  const WithBottomSpacing = (props: TProps) => (
    <SliceBottomSpacingProvider>
      <Component {...props} />
    </SliceBottomSpacingProvider>
  );
  const componentName = `${Component.name}WithBottomSpacing`;
  const componentNameProvider = { [componentName]: WithBottomSpacing };
  return componentNameProvider[componentName];
};

export default withBottomSpacing;
