import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import { FxCalculatorSlice } from '../FxCalculatorSlice';

type FxCalculatorDataProps = SliceProps<
  'fx_calculator',
  SliceVariation<'default', Queries.FxCalculatorFragment['primary']>
> &
  WithClassName;

/**
 * FxCalculator slice data component.
 */
const FxCalculatorData: FC<FxCalculatorDataProps> = ({ slice }: FxCalculatorDataProps) => (
  <FxCalculatorSlice
    title={slice.primary.title}
    primaryCtaText={stringData(slice.primary.primary_cta_text)}
    primaryCtaLink={slice.primary.primary_cta_link}
    secondaryCtaText={stringData(slice.primary.secondary_cta_text)}
    secondaryCtaLink={slice.primary.secondary_cta_link}
  />
);

export default FxCalculatorData;

export const query = graphql`
  fragment FxCalculator on PrismicFxCalculatorDefault {
    primary {
      primary_cta_text
      secondary_cta_text
      title {
        ...RichText
      }
      secondary_cta_link {
        ...PrismicLink
      }
      primary_cta_link {
        ...PrismicLink
      }
    }
  }
`;
