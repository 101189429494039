import qs from 'query-string';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { flex, padding } from '../../design/animations/display';
import { Background } from '../../design/containers/panel/Panel';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import { BodyP, Heading2 } from '../../design/typography/Typography';
import DownStateCactus from '../../images/react-icons/DownstateCactus.inline.svg';
import { TagTheme } from './components/Tags/TopicTags';
import { HubContent } from './types';
/**
 * Check is content hub article is in the past.
 */
export const isPast = (post: HubContent) => new Date() >= new Date(post.date);

/**
 * Check is content hub article is in the future.
 */
export const isFuture = (post: HubContent) => new Date() < new Date(post.date);
/**
 * Sorts content hub articles by date descending.
 */
export const sortByDate = (posts: HubContent[]) => {
  const sortDateDesc = (a: Date, b: Date) => b.getTime() - a.getTime();
  return posts.sort((a, b) => sortDateDesc(new Date(a.date), new Date(b.date)));
};
export const backgroundPattern: Background[] = ['whiteSolid', 'brand2Solid', 'brand3Solid', 'whiteSolid'];

export const tagThemes: TagTheme[] = ['brand5', 'brand3Mid', 'brand2', 'brand5'];

export const formatTypeFilterText: Record<string, string> = {
  blogPost: 'Articles',
  event: 'Events',
  webinar: 'Webinars',
  customerStory: 'Customer stories',
  guide: 'Guides',
  research: 'Research',
  allPosts: 'All posts',
} as const;

/**
 * Gets all content topics.
 */
export const getTopics = (posts: HubContent[]) =>
  new Set(
    posts
      .map((post) => (post && 'topics' in post ? post?.topics : ''))
      .flat(2)
      .filter((e) => e)
  );

/**
 * Gets all content types.
 */
export const getTypes = (posts: HubContent[]) => {
  const types = posts.map(({ type }) => type).map((type) => formatTypeFilterText[type]);
  return types.length > 0 ? [...new Set(types)] : [];
};

/**
 * Formats the type between data and state.
 */
export const findActiveType = (activeFilter: string) =>
  Object.keys(formatTypeFilterText).find((key) => formatTypeFilterText[key] === activeFilter);

/**
 * Gets posts macthing topics.
 */
export const getPostsMatchingTopic = (posts: HubContent[], activeTopic: string) =>
  posts.filter((post) => post && 'topics' in post && post.topics.length && post.topics.includes(activeTopic));

/**
 * Gets posts macthing type.
 */
export const getPostsMatchingType = (posts: HubContent[], activeType: string) =>
  posts.filter(({ type }) => type === findActiveType(activeType));

export type QueryStringType = string | (string | null)[] | null;
/**
 * Removes array + null types from Query strings.
 */
export const formatQueryString = (queryString: QueryStringType): string =>
  queryString && !Array.isArray(queryString) ? queryString : '';
/**
 * Sets query params without refreshing page.
 */
export const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue;
  window.history.pushState({ path: newurl }, '', newurl);
};
/**
 * Gets query params.
 */
export const getQueryStringValue = (key: string, queryString = window.location.search): string => {
  const values = qs.parse(queryString);
  const formattedQueryString = formatQueryString(values[key]);
  return formattedQueryString;
};

/**
 * Returns topics formatted as queryStrings.
 */
export const queryStringTopics = (topics: string[]) => {
  return topics.map((topic) =>
    topic
      .toLowerCase()
      .split(' ')
      .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
      .replace(/[&\s]/g, '')
  );
};

const DownStateContainer = styled.section`
  width: 100%;
  ${flex({ direction: 'column', align: 'center', gap: '24px' })};
  ${padding({ top: '64px' })}
`;

const DownStateCactusContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 85%;
  ${breakpointSmall(
    css`
      width: 100%;
    `
  )}
`;

/**
 * DownState Component for Content Hub.
 */
export const DownState: FC = () => {
  return (
    <DownStateContainer>
      <Heading2 textAlign="center">It’s a bit quiet right now</Heading2>
      <BodyP type="bodyLRegular" textAlign="center">
        Try some other filter options or come back another time.
      </BodyP>
      <Spacing top={{ base: '24px', breakpointSmall: '48px' }} bottom="100px">
        <DownStateCactusContainer>
          <DownStateCactus />
        </DownStateCactusContainer>
      </Spacing>
    </DownStateContainer>
  );
};
