import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { brand1 } from '../../design/colors/brand';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointMedium, breakpointSmall, breakpointXSmall } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { Heading3 } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
import Quote from './quote/Quote';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacings['4px']};
  gap: ${spacings['48px']};

  ${breakpointXSmall(css`
    padding: ${spacings['32px']} 0;
  `)};

  ${breakpointSmall(css`
    padding: ${spacings['100px']} 0;
  `)};

  ${breakpointMedium(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  `)};
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacings['16px']};
`;

const Subheading = styled(Heading3)`
  color: ${brand1};
`;

const Heading: FC<CompanyQuoteProps> = ({ heading, subHeading }) => (
  <HeadingContainer>
    <Heading2 textAlign="left">{heading}</Heading2>
    <Subheading>{subHeading}</Subheading>
  </HeadingContainer>
);

type AuthorProps = {
  name?: string;
  position?: string;
  company?: string;
};
export type CompanyQuoteProps = {
  heading: PrismicRichTextType;
  subHeading?: string;
  author?: AuthorProps;
  quote?: string;
  company?: string;
} & WithClassName;
/**
 * Renders CompanyQuote slice.
 */
const CompanyQuote: FC<CompanyQuoteProps> = ({ className, heading, subHeading, author, quote, company }) => {
  return (
    <OnVisible threshold={0.01}>
      <SliceContainer className={className} background="brand4GradientMidnightCardLeft">
        <CenteredSliceContentContainer verticalPadding="none" maxWidth="1286px" className={className}>
          <Container>
            <Heading heading={heading} subHeading={subHeading} />
            <Quote author={author} quote={quote} company={company} />
          </Container>
        </CenteredSliceContentContainer>
      </SliceContainer>
    </OnVisible>
  );
};

export default CompanyQuote;
