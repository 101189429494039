import { graphql } from 'gatsby';
import _ from 'lodash';
import React, { FC } from 'react';
import { WithClassName } from '../../utilityTypes';
import {
  getArticleType,
  getCardImage,
  getDocumentTopics,
  prismicAuthorRef,
  prismicDoc,
  prismicDocData,
} from '../../utils/lodashHelpers';
import { ResourceCard } from '../contentHubCards/types';
import { SliceProps, SliceVariation } from '../types';
import RelatedResources from './RelatedResources';

export type Resource =
  | Queries.RelatedResourcesFragment['primary']['related_resource_1']
  | Queries.RelatedResourcesFragment['primary']['related_resource_2']
  | Queries.RelatedResourcesFragment['primary']['related_resource_3'];

type RelatedResourcesDataProps = SliceProps<
  'related_resources',
  SliceVariation<'default', Queries.RelatedResourcesFragment['primary']>
> &
  WithClassName;

/**
 * Related Resources data component.
 */
const RelatedResourcesData: FC<RelatedResourcesDataProps> = ({ className, slice }) => {
  const title = _.get(slice, 'primary.relatedResourcesTitle', '');
  const resourceOne: Resource = slice.primary.related_resource_1;
  const resourceTwo: Resource = slice.primary.related_resource_2;
  const resourceThree: Resource = slice.primary.related_resource_3;

  const resources: Resource[] = [resourceOne, resourceTwo, resourceThree];
  const cards: ResourceCard[] = resources
    .filter((resource: Resource) => resource !== null)
    .map((resource: Resource) => {
      return {
        url: prismicDoc(resource, 'url'),
        date: prismicDocData(resource, 'date'),
        time: prismicDocData(resource, 'time'),
        location: prismicDocData(resource, 'location'),
        author: prismicAuthorRef(resource),
        title: prismicDocData(resource, 'title.text'),
        excerpt: prismicDocData(resource, 'excerpt'),
        image: getCardImage(resource?.document as Resource),
        topics: getDocumentTopics(prismicDocData(resource, 'topics', [])),
        type: getArticleType(resource?.document as Resource),
        documentType: prismicDoc(resource, 'type'),
        hubspotFormID: prismicDocData(resource, 'hubspotFormID'),
        recording: prismicDocData(resource, 'recording_page.url'),
      };
    });

  return <RelatedResources className={className} title={title} cards={cards} />;
};

export default RelatedResourcesData;

export const query = graphql`
  fragment RelatedBlogPosts on PrismicBlogPost {
    data {
      article_type
      feature_image {
        ...Image
      }
      excerpt
      topics {
        topic {
          document {
            ... on PrismicTopic {
              data {
                topic
              }
            }
          }
        }
      }
      author {
        document {
          ... on PrismicAuthor {
            data {
              name
              title
            }
          }
        }
      }
      title {
        text
      }
      date(formatString: "MMMM D, YYYY")
    }
    type
    url
  }

  fragment RelatedGuides on PrismicGuides {
    data {
      card_image {
        ...Image
      }
      date(formatString: "MMMM D, YYYY")
      excerpt
      is_research
      title {
        text
      }
      topics {
        topic {
          document {
            ... on PrismicTopic {
              data {
                topic
              }
            }
          }
        }
      }
      download_file {
        ...PrismicLink
      }
      hubspotFormID
      hubspotFormTrackingEventName
      hubspotFormSuccessHeading
      hubspotFormSuccessText
    }
    type
    url
  }

  fragment RelatedEvents on PrismicEvents {
    data {
      card_image {
        ...Image
      }
      date(formatString: "MMMM D, YYYY")
      excerpt
      is_webinar
      location
      time
      title {
        text
      }
      topics {
        topic {
          document {
            ... on PrismicTopic {
              data {
                topic
              }
            }
          }
        }
      }
      recording_page {
        url
      }
      hubspotFormID: hubspot_form_id
      hubspot_form_tracking_event_name
      hubspot_form_success_heading
      hubspot_form_success_text
    }
    type
    url
  }

  fragment AllResources on PrismicAllDocumentTypes {
    ... on PrismicBlogPost {
      ...RelatedBlogPosts
    }
    ... on PrismicGuides {
      ...RelatedGuides
    }
    ... on PrismicEvents {
      ...RelatedEvents
    }
  }

  fragment RelatedResources on PrismicRelatedResourcesDefault {
    primary {
      relatedResourcesTitle: title
      related_resource_1 {
        document {
          ...AllResources
        }
      }
      related_resource_2 {
        document {
          ...AllResources
        }
      }
      related_resource_3 {
        document {
          ...AllResources
        }
      }
    }
  }
`;
