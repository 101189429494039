import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../../design/animations/display';
import { brand5, brand2 } from '../../../../design/colors/brand';
import { BodySpan } from '../../../../design/typography/Typography';
import ArticleIcon from '../../../../images/react-icons/Article.inline.svg';
import EventIcon from '../../../../images/react-icons/Event.inline.svg';
import GuideIcon from '../../../../images/react-icons/Guide.inline.svg';
import SearchIcon from '../../../../images/react-icons/Search.inline.svg';
import CustomerIcon from '../../../../images/react-icons/SpeechBubble.inline.svg';
import WebinarIcon from '../../../../images/react-icons/Webinar.inline.svg';

const toggleTheme = (isLight: boolean) => css`
    background: ${isLight ? brand5 : brand2};
    color: ${isLight ? brand2 : brand5};
    * {
      stroke: ${isLight ? brand2 : brand5};
    }
  }
`;

export const Tag = styled.div<Pick<TagProps, 'isLight'>>`
  ${flex({ gap: '4px', align: 'center' })}
  ${({ isLight }) => toggleTheme(isLight)};
  padding: 4px 6px;
  border-radius: 6px;
  max-width: fit-content;
`;

const typeTagsFormat: Record<string, Record<string, string | JSX.Element>> = {
  blogPost: { text: 'Article', icon: <ArticleIcon height={16} /> },
  webinar: { text: 'Webinar', icon: <WebinarIcon height={16} /> },
  event: { text: 'Event', icon: <EventIcon height={16} /> },
  guide: { text: 'Guide', icon: <GuideIcon height={16} /> },
  research: { text: 'Research', icon: <SearchIcon height={16} /> },
  customerStory: { text: 'Customer Story', icon: <CustomerIcon height={16} /> },
} as const;

type TagProps = { type: 'blogPost' | 'webinar' | 'event' | 'guide' | 'research' | 'customerStory'; isLight: boolean };
/**
 * Category/Type tag for content hub articles.
 */
const CategoryTag: FC<TagProps> = ({ type, isLight }) => {
  return (
    <Tag isLight={isLight} data-testid="article-type-tag">
      {typeTagsFormat[type].icon}
      <BodySpan type="bodySBold"> {typeTagsFormat[type].text}</BodySpan>
    </Tag>
  );
};

export default CategoryTag;
