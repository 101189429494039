import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { CenteredSliceContentContainer } from '../../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utilityTypes';
import FaqItem from './FaqItem';
import { FaqItem as FaqItemType } from './types';

const FaqItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpointSmall(css`
    flex-direction: row;
  `)}
`;

const FaqItemsColumn = styled.div`
  ${breakpointSmall(css`
    flex-basis: 50%;
  `)}
`;

const FaqItemsColumnLeft = styled(FaqItemsColumn)`
  ${breakpointSmall(css`
    margin-right: ${spacings['12px']};
  `)}
`;

const FaqItemsColumnRight = styled(FaqItemsColumn)`
  ${breakpointSmall(css`
    margin-left: ${spacings['12px']};
  `)}
`;

type FaqsProps = {
  heading: PrismicRichTextType;
  items?: FaqItemType[];
} & WithClassName;

const FaqContainer = styled(CenteredSliceContentContainer)`
  width: 100%;
`;

const getItemsBasedOnIndex = <T,>(items: T[], indexPredicate: (index: number) => boolean) =>
  items.reduce((acc, item, index) => (indexPredicate(index) ? [...acc, item] : acc), [] as typeof items);

const isOdd = (number: number) => number % 2 !== 0;
const isEven = (number: number) => number % 2 === 0;

/**
 * Renders FAQs slice.
 */
const Faqs: FC<FaqsProps> = ({ className, heading, items }) => {
  const dataFirstCol = items && getItemsBasedOnIndex(items, isOdd);
  const dataSecondCol = items && getItemsBasedOnIndex(items, isEven);
  const secondColIndexStart = (dataFirstCol && dataFirstCol.length) || 0;

  return (
    <SliceContainer className={className} background="brand4LightSolidWeelLogo">
      <FaqContainer>
        {heading?.richText && (
          <Spacing bottom={{ base: '24px', breakpointSmall: '48px' }}>
            <RichText textType="heading2" field={heading} />
          </Spacing>
        )}
        <FaqItemsContainer>
          <FaqItemsColumnLeft>
            {dataFirstCol?.map((item, index) => (
              <FaqItem key={index} index={index} heading={item.heading} text={item.textField} />
            ))}
          </FaqItemsColumnLeft>
          <FaqItemsColumnRight>
            {dataSecondCol?.map((item, index) => (
              <FaqItem
                key={secondColIndexStart + index}
                index={secondColIndexStart + index}
                heading={item.heading}
                text={item.textField}
              />
            ))}
          </FaqItemsColumnRight>
        </FaqItemsContainer>
      </FaqContainer>
    </SliceContainer>
  );
};

export default Faqs;
