import React, { FC, forwardRef, ForwardRefRenderFunction } from 'react';
import styled, { css } from 'styled-components';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { getRichTextFieldText } from '../../../components/richText/utils';
import { breakpointMedium } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import Heading2 from '../../../design/typography/Heading2';
import { BodyP } from '../../../design/typography/Typography';
import { QuoteProps } from '../Quote';
import { CtaButtons } from './CtaButtons';

type QuoteTextProps = { quoteText: PrismicRichTextType };
const QuoteText: FC<QuoteTextProps> = ({ quoteText }) => {
  const quoteTextWithQuotesAdded = {
    richText: [{ ...quoteText?.richText[0], text: `“${quoteText?.richText[0].text}”` }],
  };

  return getRichTextFieldText(quoteText).length <= 100 ? (
    <Heading2 textAlign="left">{quoteTextWithQuotesAdded}</Heading2>
  ) : (
    <RichText textAlign="left" textType="quotes" field={quoteTextWithQuotesAdded} />
  );
};

const TextContainer = styled.div`
  padding: ${spacings['48px']} 0;
  ${breakpointMedium(css`
    max-width: 600px;
    padding: ${spacings['100px']} 0;
  `)}
`;

/**
 * Render QuoteText component.
 */
const QuoteTextContainer: FC<Pick<QuoteProps, 'quoteText' | 'quoteAuthor' | 'primaryCta' | 'secondaryCta'>> = (
  { quoteText, quoteAuthor, primaryCta, secondaryCta },
  ref
) => {
  if (!quoteText) return null;

  return (
    <TextContainer ref={ref}>
      <QuoteText quoteText={quoteText} />
      {quoteAuthor && (
        <Spacing top="24px">
          <BodyP type="bodySBold" textAlign="left">
            {quoteAuthor}
          </BodyP>
        </Spacing>
      )}
      <CtaButtons primaryCta={primaryCta} secondaryCta={secondaryCta} />
    </TextContainer>
  );
};

export default forwardRef(
  QuoteTextContainer as ForwardRefRenderFunction<
    unknown,
    Pick<QuoteProps, 'quoteText' | 'quoteAuthor' | 'primaryCta' | 'secondaryCta'>
  >
);
