import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { extractBodyFromArticle, generateArticleSchema } from '../../components/SEO/structuredData/utils';
import { grey200 } from '../../design/colors/greyscale';
import { breakpointMedium } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import PageContainer from '../../pageContainers/PageContainer';
import ImageData from '../../slices/image/data/Image.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import WysiwygData from '../../slices/wysiwyg/data/Wysiwyg.queryData';
import { PrismicImageData } from '../../utilityTypes';
import { objectData, prismicData } from '../../utils/lodashHelpers';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import CustomerQuote from './components/CustomerQuote';
import CustomerStoryHeader from './components/CustomerStoryHeader';
import InfoBox from './components/InfoBox';

const components = {
  wysiwyg: withBottomSpacing(WysiwygData),
  image: withBottomSpacing(ImageData),
};

/**
 * Renders Prismic Customer Story document and maps slice data to be rendered by their components in order.
 */
const CustomerStory: FC<PageProps<Queries.CustomerStoryQuery>> = ({ data }) => {
  const headerId = data.prismicCustomerStory?.data.header?.id || undefined;
  const footerId = data.prismicCustomerStory?.data.footer?.id || undefined;
  const postData = data?.prismicCustomerStory?.data;
  const story = data?.prismicCustomerStory;
  const slices = postData?.slices?.map((slice) => ({ ...slice })) as Queries.PrismicSharedSliceType[];

  const seoData = {
    title: prismicData(story, 'Title'),
    description: prismicData(story, 'description'),
    exclude_from_search_engines: !!story?.data.no_index,
    last_publication_date: story?.last_publication_date,
  };

  const body = extractBodyFromArticle(slices);

  const schema = JSON.stringify(
    generateArticleSchema({
      ...seoData,
      uid: `${objectData(data, 'prismicCustomerStory.url', undefined)}`,
      body,
    })
  );

  const seo = {
    ...seoData,
    schema,
  };

  const challengesArray = prismicData(story, 'challenges', []).map((challenge: Record<'challenge', string>) =>
    objectData(challenge, 'challenge', '')
  );

  const headerInfo = {
    name: prismicData(story, 'client_name'),
    role: prismicData(story, 'client_role'),
    company: prismicData(story, 'company_name'),
    title: prismicData(story, 'title'),
  };

  const infoBox = {
    excerpt: prismicData(story, 'excerpt'),
    companyAbout: prismicData(story, 'company_about'),
    challenges: challengesArray,
    logo: prismicData(story, 'logo') as PrismicImageData,
    location: prismicData(story, 'location'),
    industry: prismicData(story, 'industry'),
  };

  const s24 = spacings['24px'];
  const s36 = spacings['24px'];

  const PageLayout = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${s24};
    align-items: flex-start;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 ${s24};
    ${breakpointMedium(css`
      grid-template-columns: 1fr 2fr;
    `)}
  `;

  const Background = styled.div`
    background: ${grey200};
    border-radius: ${s36};
    width: 100%;
    margin-bottom: ${s24};
    padding-top: ${s24};
    ${breakpointMedium(css`
      padding-top: 0;
    `)}
  `;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <CustomerStoryHeader {...headerInfo} />
      <Background>
        <PageLayout>
          <InfoBox {...infoBox} />
          <ContentWrapper>
            <CustomerQuote excerpt={infoBox.excerpt} />
            <SliceZone slices={slices} components={components} />
          </ContentWrapper>
        </PageLayout>
      </Background>
    </PageContainer>
  );
};

export default withPrismicPreview(CustomerStory);

export const query = graphql`
  query CustomerStory($url: String!) {
    prismicCustomerStory(url: { eq: $url }) {
      url
      _previewable
      last_publication_date
      data {
        ...CustomerStorySeo
        challenges {
          challenge
        }
        date
        client_name
        client_role
        company_name
        company_about
        title {
          ...RichText
        }
        location
        logo {
          ...Image
        }
        industry
        excerpt
        header {
          id
        }
        footer {
          id
        }
        card_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        slices {
          ...Slice
          ...Wysiwyg
          ...ImageSlice
        }
      }
    }
  }
`;
