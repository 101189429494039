/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { GatedButton } from '../../../components/gatedContent/GatedContent';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { flex, grid, padding } from '../../../design/animations/display';
import { blogHeaderShadow } from '../../../design/colors/shadows';
import { Panel } from '../../../design/containers/panel/Panel';
import { createBreakpoint } from '../../../design/responsive/breakpointUtils';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import CategoryTag from '../../../slices/contentHubCards/components/Tags/CategoryTag';
import TopicTags from '../../../slices/contentHubCards/components/Tags/TopicTags';
import { PrismicImageData } from '../../../utilityTypes';
import EventDetails from './EventDetails';
import { EventState, getEventState, shouldRenderButton } from './EventHeader.helpers';

const s48 = spacings['48px'];
const s24 = spacings['24px'];

export const ArticleHeaderContainer = styled.div`
  padding-top: ${s48};
  margin-bottom: ${s24};
`;

export const HeaderContainer = styled(Panel)`
  padding: ${s48} 0;
  ${blogHeaderShadow}
  ${createBreakpoint(850)(
    css`
      padding: 0;
    `
  )}
`;

export const InnerContainer = styled.div`
  ${flex({ gap: '24px', direction: 'column' })}
  ${padding({ x: '24px' })}
  max-width: 1285px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  ${createBreakpoint(850)(css`
    ${grid({ gap: '24px' })}
  `)}
`;

export const Content = styled.article`
  padding: 0;
  overflow: hidden;
  ${flex({ direction: 'column', gap: '24px', justify: 'center' })}
  ${createBreakpoint(850)(css`
    padding: ${s48} 0;
  `)}
`;

const Media = styled.figure`
  width: 100%;
  height: 100%;
  ${flex({ direction: 'column', justify: 'center', align: 'center' })}
`;

const Image = styled(PrismicImage)`
  max-height: 350px;
  ${breakpointSmall(css`
    max-height: 573px;
    max-width: 446px;
  `)}
`;

export type EventHeaderProps = {
  title?: PrismicRichTextType;
  topics?: string[];
  excerpt?: string | null;
  image?: PrismicImageData;
  date?: string | null;
  time?: string | null;
  location?: string;
  recording?: string | null;
  hubspotFormID: string;
  hubspotFormTrackingEventName: string;
  hubspotFormSuccessHeading: string;
  hubspotFormSuccessText: string;
  isWebinar?: boolean | null;
};

/**
 * Renders Events Header.
 */
const EventHeader: FC<EventHeaderProps> = ({
  title,
  topics,
  excerpt,
  image,
  date,
  location,
  time,
  recording,
  isWebinar,
  ...hubspotData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailState, setDetailState] = useState<EventState>();

  useEffect(() => setDetailState(getEventState({ date, hubspotFormID: hubspotData.hubspotFormID, recording })), []);

  const gatedContentProps = {
    ...hubspotData,
    isModalOpen,
    setIsModalOpen,
    downloadLink: recording,
  } as const;

  const eventDetails = { info: { date, location, time }, detailState };

  return (
    <ArticleHeaderContainer>
      <HeaderContainer background="brand3GradientStripedCurve" data-testid="event-research-header">
        <InnerContainer>
          <Content>
            <CategoryTag type={isWebinar ? 'webinar' : 'event'} isLight />
            {title && (
              <RichText
                field={title}
                textType={{ base: 'heading2', breakpointSmall: 'heading3', breakpointLarge: 'heading2' }}
                textAlign="left"
              />
            )}
            {excerpt && <BodyP type="bodyMRegular">{excerpt}</BodyP>}
            {topics && <TopicTags topics={topics} theme="brand3Mid" />}
            <EventDetails {...eventDetails} />
            {detailState && shouldRenderButton(detailState) && (
              <GatedButton text={detailState} {...gatedContentProps} />
            )}
          </Content>
          <Media>{image && <Image image={image} objectFit="contain" />}</Media>
        </InnerContainer>
      </HeaderContainer>
    </ArticleHeaderContainer>
  );
};

export default EventHeader;
