import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import ApplePay from '../ApplePay';
type ApplePayDataProps = SliceProps<'applePay', SliceVariation<'default', Queries.PrismicApplePayDefaultPrimary>> &
  WithClassName;
/**
 * ApplePay data component: specifies the fragment and passes the appropriate data to the ApplePay component.
 */
const ApplePayData: FC<ApplePayDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  return (
    <ApplePay
      className={className}
      leftContent={{
        heading: data?.leftHeading,
        text: data?.leftContent,
      }}
      rightContent={{
        heading: data?.rightHeading,
        text: data?.rightContent,
      }}
      steps={{
        step1: stringData(data.step1),
        step2: stringData(data.step2),
        step3: stringData(data.step3),
      }}
    />
  );
};

export default ApplePayData;

export const query = graphql`
  fragment ApplePay on PrismicApplePayDefault {
    id
    primary {
      leftContent {
        ...RichText
      }
      leftHeading {
        ...RichText
      }
      rightContent {
        ...RichText
      }
      rightHeading {
        ...RichText
      }
      step3
      step2
      step1
    }
  }
`;
