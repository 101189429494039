import React, { ComponentProps, FC } from 'react';
import { Card } from '../../../../design/components/card/Card';
import { Mutable, RecursiveNotEmptyObject, WithClassName } from '../../../../utilityTypes';
import { OneRowCardsContainer, OneRowCardStylesProvider } from './oneRowCards';

type OneRowSmallCardsRightIconCardsProps = {
  cards: RecursiveNotEmptyObject<Mutable<Queries.OneRowSmallCardsRightIconFragment['items']>>;
} & WithClassName;
/**
 * Renders cards for 1RowSmallCardsRightIcon variation.
 */
export const OneRowSmallCardsRightIconCards: FC<OneRowSmallCardsRightIconCardsProps> = ({ className, cards }) => {
  const backgrounds: ComponentProps<typeof Card>['background'][] = [
    'whiteSolid',
    'brand2Solid',
    'brand5Solid',
    'brand3Solid',
  ];

  return (
    <OneRowCardsContainer className={className}>
      {cards.map((card, index) => {
        const background = backgrounds[index % 4];

        return (
          <OneRowCardStylesProvider key={card.text}>
            <Card
              background={background}
              icon={card.prismicIcon || undefined}
              index={index}
              title={card.text || undefined}
              cardType="iconRight"
            />
          </OneRowCardStylesProvider>
        );
      })}
    </OneRowCardsContainer>
  );
};
