import { css } from 'styled-components';
import { successDark, successLight, successMid, successMid2 } from '../../../../../../colors/system';
import { alertDisabledStyles } from '../alert';

/**
 * Generates the success button type style.
 */
export const successTypeStyle = () => css`
  background-color: ${successLight};
  border-color: ${successMid2};
  color: ${successDark};

  &:hover {
    background-color: ${successMid2};
    border-color: ${successMid};
  }

  ${alertDisabledStyles}
`;
