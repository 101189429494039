import React, { FC, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import styled, { css } from 'styled-components';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { brand1DarkAlt, brand2, brand3 } from '../../design/colors/brand';
import { grey800 } from '../../design/colors/greyscale';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodySpan } from '../../design/typography/Typography';
import { WithClassName } from '../../utilityTypes';
type StatDataProp = {
  number?: number;
  text?: PrismicRichTextType;
};

type StatsProps = {
  stat1?: StatDataProp;
  stat2?: StatDataProp;
  stat3?: StatDataProp;
} & WithClassName;

type StatComponentProp = StatDataProp & {
  numberColor: string;
  textColor: string;
};

const StatContainer = styled(OnVisible)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type ColorProps = { color: string };
const StyledRichText = styled(RichText)<ColorProps>`
  color: ${(props) => props.color};
`;

const DisplayNumber = styled(BodySpan).attrs({ type: 'display' })<ColorProps>`
  color: ${(props) => props.color};
  text-align: center;
`;
const Stat = ({ number, text, numberColor, textColor }: StatComponentProp) => {
  const countUpRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleVisible = (isVisible: boolean) => setIsVisible(isVisible);

  const countUpConfig = {
    ref: countUpRef,
    end: number || 1,
    duration: 2.8,
    useEasing: true,
    start: isVisible ? 0 : undefined,
    formattingFn: (number: number) => `${number}%`,
  } as const;

  const stateId = isVisible ? 'mounted' : 'not-mounted';

  const Number = () => {
    useCountUp({ ...countUpConfig });
    return (
      <p data-testid={`stat-number-${stateId}`} ref={countUpRef}>
        {number}
      </p>
    );
  };

  return (
    <StatContainer onChange={(isVisible) => handleVisible(isVisible)}>
      <>
        <Spacing bottom={spacings['24px']}>
          <DisplayNumber color={numberColor} textAlign="center">
            <Number />
          </DisplayNumber>
        </Spacing>
        {text && <StyledRichText color={textColor} textType="bodyLRegular" textAlign="center" field={text} />}
      </>
    </StatContainer>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${spacings['64px']} 0;
  max-width: 1286px;
  row-gap: ${spacings['64px']};
  ${breakpointSmall(css`
    grid-template-columns: 1fr 1fr 1fr;
    padding: ${spacings['48px']} 0;
    column-gap: ${spacings['64px']};
  `)}
`;
/**
 * Stats presentation component.
 */
const Stats: FC<StatsProps> = ({ className, stat1, stat2, stat3 }) => (
  <SliceContainer className={className}>
    <Container data-testid="stats-container">
      {stat1?.number && (
        <Stat number={stat1.number} text={stat1.text} numberColor={brand1DarkAlt} textColor={grey800} />
      )}
      {stat2?.number && <Stat number={stat2.number} text={stat2.text} numberColor={brand2} textColor={grey800} />}
      {stat3?.number && <Stat number={stat3.number} text={stat3.text} numberColor={brand3} textColor={grey800} />}
    </Container>
  </SliceContainer>
);
export default Stats;
