import { FilledLinkToDocumentField } from '@prismicio/types';

export type GuidesDoc = FilledLinkToDocumentField<
  string,
  string,
  Omit<Queries.PrismicGuidesDataType, 'slices' | 'topics'>
>;
const getArticleTypePrefix = (isResearch: boolean | undefined | null) =>
  isResearch ? '/resources/the-weelhouse/research' : '/resources/the-weelhouse/guides';

/**
 * Resolves links to guides.
 */
export const guidesLinkResolver = (doc: GuidesDoc, url: string) =>
  `${getArticleTypePrefix(doc?.data?.is_research)}${url}`;
