import { graphql } from 'gatsby';
import _ from 'lodash';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import VideoEmbed from '../VideoEmbed';

type VideoEmbedDataProps = SliceProps<
  'video_embed',
  | SliceVariation<'default', Queries.VideoEmbedDefaultFragment['primary']>
  | SliceVariation<'withHeader', Queries.VideoEmbedWithHeaderFragment['primary']>
> &
  WithClassName;

/**
 * Video embed data component.
 */
const VideoEmbedData: FC<VideoEmbedDataProps> = ({ className, slice }) => (
  <VideoEmbed
    className={className}
    videoEmbed={_.get(slice, 'primary.videoLink', '')}
    title={(slice.variation === 'withHeader' && slice.primary.title) || null}
  />
);

export default VideoEmbedData;

export const query = graphql`
  fragment VideoEmbedDefault on PrismicVideoEmbedDefault {
    primary {
      videoLink
    }
  }

  fragment VideoEmbedWithHeader on PrismicVideoEmbedWithHeader {
    primary {
      videoLink
      title {
        ...RichText
      }
    }
  }

  fragment VideoEmbed on PrismicSliceType {
    ...VideoEmbedDefault
    ...VideoEmbedWithHeader
  }
`;
