import { siteUrl } from '../../../../config/site';
import { SpeakerProps } from '../../../slices/speakers/speaker/Speaker';
import { RecursiveDefined, RecursiveMutable, RecursiveNotEmptyObject } from '../../../utilityTypes';
import { objectData } from '../../../utils/lodashHelpers';
import schemas, { generateSchemaId } from './schemas';

export type SharedData = {
  headline?: string;
  description?: string;
  uid: string;
  image?: string;
  datePublished?: string;
};

const generateSharedData = ({ headline, description, uid, image, datePublished }: SharedData) => ({
  ...(headline && { headline }),
  ...(description && { description }),
  ...(uid && { url: `${siteUrl}${uid}` }),
  ...(image && { image }),
  ...(datePublished && { datePublished }),
});

const isPartOfWebPage = (uid: string) => ({ isPartOf: { '@id': generateSchemaId('Webpage', uid) } });

const generateWordCountAndAbstract = (body: string) => {
  const words = body.trim().match(/\S+/g);
  const wordCount = words && words.length;
  const abstract = words && `${words.slice(0, 50).join(' ')}...`;
  return { ...(wordCount && { wordCount }), ...(abstract && { abstract }) };
};

/**
 * Generate single string from wysiwyg slice copy.
 */
export const extractBodyFromArticle = (slices: Queries.PrismicSharedSliceType[]) => {
  const wysiwygSlices = slices?.filter(
    (slice) => slice.slice_type === 'wysiwyg'
  ) as unknown as Queries.WysiwygFragment[];
  return wysiwygSlices.map(({ primary: { content } }) => content?.text).join(' ');
};

type GeneratePersonOptions = Omit<SpeakerProps, 'inCarousel'>;
type SpeakerItem = RecursiveDefined<RecursiveNotEmptyObject<RecursiveMutable<Queries.SpeakersFragment['items']>>>;
/**
 * Generate single string from wysiwyg slice copy.
 */
export const extractSpeakersFromEvent = (slices: Queries.PrismicSharedSliceType[]) => {
  const speakerSlices = slices?.filter(
    (slice) => slice.slice_type === 'speakers'
  ) as unknown as Queries.SpeakersFragment[];

  const speakers = speakerSlices
    .flatMap(({ items }) => items as SpeakerItem)
    .map((item) => item.speakers.document.data);

  return speakers.map(
    ({ linkedin: { url: linkedin }, ...speakerData }) => ({ linkedin, ...speakerData } as GeneratePersonOptions)
  );
};

/**
 * Generate Person schema (https://schema.org/Person).
 */
export const generatePersonSchema = (speaker: GeneratePersonOptions) => {
  const { name, role: jobTitle, image, linkedin: sameAs } = speaker;
  const imageUrl = objectData(image, 'gatsbyImageData.images.fallback.src') as string | undefined;
  const names = name?.split(' ');
  return {
    ...(names?.length && { givenName: names[0] }),
    ...(names?.length && { familyName: names[1] }),
    ...(jobTitle && { jobTitle }),
    ...(imageUrl && { image: imageUrl }),
    ...(sameAs && { sameAs }),
    ...schemas.person,
  };
};
/**
 * Generate WebPage schema (https://schema.org/WebPage).
 */
export const generateWebpageSchema = (sharedData: SharedData) => ({
  '@id': generateSchemaId('Webpage', sharedData.uid),
  ...generateSharedData(sharedData),
  ...schemas.webpage,
});

type ArticleSchemaProps = SharedData & { body: string; author?: string };
/**
 * Generate Article schema (https://schema.org/Article).
 */
export const generateArticleSchema = ({ body: articleBody, author, ...sharedData }: ArticleSchemaProps) => ({
  '@context': 'https://schema.org',
  articleBody,
  ...(sharedData.uid && isPartOfWebPage(sharedData.uid)),
  ...(author && { author }),
  ...generateWordCountAndAbstract(articleBody),
  ...generateSharedData(sharedData),
  ...schemas.article,
});

export type FAQItem = { question: string; answer: string };
const generateFaqItem = ({ question, answer }: FAQItem) => ({
  '@type': 'Question',
  name: `${question}`,
  acceptedAnswer: {
    '@type': 'Answer',
    text: `${answer}`,
  },
});

type FAQschemaOptions = { items: FAQItem[] } & SharedData;
/**
 * Generate FAQ schema (https://schema.org/Faq).
 */
export const generateFaqSchema = ({ items, ...sharedData }: FAQschemaOptions) => ({
  '@context': 'https://schema.org',
  mainEntity: items.map((item) => generateFaqItem(item)),
  ...(sharedData.uid && isPartOfWebPage(sharedData.uid)),
  ...generateSharedData(sharedData),
  ...schemas.faqPage,
});

type EventSchemaProps = SharedData & { location?: string; startDate: string; performers?: GeneratePersonOptions[] };
/**
 * Generate FAQ schema (https://schema.org/Faq).
 */
export const generateEventSchema = ({ location, startDate, performers: speakers, ...sharedData }: EventSchemaProps) => {
  const performers = speakers?.map((speaker) => generatePersonSchema(speaker));
  return {
    '@context': 'https://schema.org',
    name: sharedData.headline,
    ...(sharedData?.description && { description: sharedData.description }),
    ...(sharedData?.image && { image: sharedData.image }),
    ...(sharedData.uid && { '@id': generateSchemaId('Event', sharedData.uid) }),
    ...(location && { location }),
    ...(startDate && { startDate }),
    ...(performers && { performers }),
    ...schemas.event,
  };
};

/**
 * Generate combined/nested schema markup.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateMasterSchema = (pageSchema: Record<string, any>) => ({
  '@context': 'https://schema.org',
  '@graph': [pageSchema, schemas.organization, schemas.website],
});
