import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import ContentInfoBlock from '../ContentInfoBlock';

type ContentInfoBlockProps = SliceProps<
  'contentInfoBlock',
  SliceVariation<'default', Queries.PrismicContentInfoBlockDefaultPrimary, Queries.PrismicContentInfoBlockDefaultItem>
> &
  WithClassName;

/**
 * Content info block data component: specifies the fragment and passes the appripriate data to the ContentInfoBlock component.
 */
const ContentInfoBlockData: FC<ContentInfoBlockProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  return (
    <ContentInfoBlock
      className={className}
      heading={data?.heading}
      text={stringData(data?.text)}
      primaryCta={{ text: stringData(data?.primaryCtaButtonText), link: data?.primaryCtaButtonLink }}
      secondaryCta={{ text: stringData(data?.secondaryCTAButtonText), link: data?.secondaryCTAButtonLink }}
      image={data?.image}
      items={slice.items || []}
    />
  );
};

export default ContentInfoBlockData;

export const query = graphql`
  fragment ContentInfoBlock on PrismicContentInfoBlockDefault {
    primary {
      heading {
        ...RichText
      }
      text
      primaryCtaButtonText
      primaryCtaButtonLink {
        ...PrismicLink
      }
      secondaryCTAButtonText
      secondaryCTAButtonLink {
        ...PrismicLink
      }
      image {
        ...Image
      }
    }
    items {
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
  }
`;
