import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { RawImage } from '../../components/image/rawImage/RawImage';
import Link from '../../components/link/Link';
import { flex } from '../../design/animations/display';
import { grow } from '../../design/animations/micro-interactions';
import { grey700 } from '../../design/colors/greyscale';
import { Background } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointMedium } from '../../design/responsive/breakpoints';
import { spacings } from '../../design/spacing/Spacing';
import { Heading4 } from '../../design/typography/Typography';
import c3 from '../../images/icons/C3.svg';
import canva from '../../images/icons/Canva.svg';
import michaelHill from '../../images/icons/Michael hill.svg';
import unitingAgeWell from '../../images/icons/Uniting age well.svg';
import weAreSocial from '../../images/icons/We are social.svg';
import xero from '../../images/icons/Xero.svg';
import { WithClassName } from '../../utilityTypes';
import { IconLinkMapping } from './data/utils';

const Title = styled(Heading4)`
  ${breakpointMedium(css`
    max-width: 328px;
    margin-right: ${spacings['24px']};
  `)}
`;

const ContentContainer = styled.div`
  color: ${grey700};
  max-width: 1286px;
  width: 100%;
  margin: 48px auto;
  ${flex({ gap: '24px', direction: 'column' })}
  ${breakpointMedium(css`
    ${flex({ gap: '24px', direction: 'row', justify: 'center', align: 'center' })}
    margin: 24px auto;
  `)}
`;

type IconsContainerProps = { justify?: 'center' | 'space-between' };
const IconsContainer = styled.div<IconsContainerProps>`
  ${({ justify }) => flex({ justify: justify || 'center', gap: '24px', align: 'center' })}
  flex-wrap: wrap;
  ${breakpointMedium(css`
    flex-wrap: nowrap;
  `)}
`;

const HardcodedIcon = styled(RawImage)`
  && {
    ${grow}
  }
  max-height: ${spacings['48px']};
`;

const ImageIcon = styled(PrismicImage)`
  && {
    ${grow}
  }
`;
ImageIcon.defaultProps = { objectFit: 'contain' };

const defaultHeading = "Trusted by Australia's fastest growing businesses";
const icons = [
  { name: 'Canva', icon: canva },
  { name: 'Michael Hill', icon: michaelHill },
  { name: 'C3', icon: c3 },
  { name: 'We are Social', icon: weAreSocial },
  { name: 'Uniting AgeWell', icon: unitingAgeWell },
  { name: 'Xero', icon: xero },
];

/**
 * Old default icons.
 *
 * @deprecated
 */
const HardCodedIcons = () => (
  <IconsContainer>
    {icons.map((item) => {
      const { name, icon } = item;
      return (
        <Fragment key={name}>
          <HardcodedIcon src={icon} alt={name} />
        </Fragment>
      );
    })}
  </IconsContainer>
);

type CustomIcons = { icons?: IconLinkMapping } & IconsContainerProps & WithClassName;
/**
 * Renders social proof icons.
 */
export const SocialProofIcons: FC<CustomIcons> = ({ className, icons: iconData, ...iconContainerProps }) => (
  <IconsContainer className={className} {...iconContainerProps}>
    {iconData?.length &&
      iconData.map((image) => (
        <Fragment key={image.icon?.alt}>
          {image?.link.url ? (
            <Link key={image.icon?.alt} link={image.link}>
              <ImageIcon image={image.icon} />
            </Link>
          ) : (
            <>
              <ImageIcon image={image.icon} />
            </>
          )}
        </Fragment>
      ))}
  </IconsContainer>
);

type SocialProofProps = {
  background?: Background;
  text?: string;
  icons?: IconLinkMapping;
} & WithClassName;
/**
 * Renders SocialProof slice.
 */
const SocialProof: FC<SocialProofProps> = ({ className, text, icons = [], background = 'whiteSolid' }) => {
  const heading = text || defaultHeading;
  return (
    <SliceContainer className={className} background={background} data-testid="social-proof">
      <ContentContainer>
        <Title textAlign="center">{heading}</Title>
        {icons.length ? <SocialProofIcons icons={icons} /> : <HardCodedIcons />}
      </ContentContainer>
    </SliceContainer>
  );
};

export default SocialProof;
