import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { HubspotProps } from '../../../components/hubspotForm/HubspotForm';
import { HubspotFormData } from '../../../components/hubspotForm/types';
import DemoHubspotForm from '../DemoHubspotForm';
import { ChiliPiperLead } from './types';

const convertFormDataToLead = (formData: HubspotFormData) =>
  formData.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {} as ChiliPiperLead);

/**
 * Renders Chili piper form for demo slice.
 */
const ChiliPiperForm: FC<HubspotProps> = ({ id, link, trackingEvent, referrer }) => {
  const chiliPiperSubmit = useCallback(
    (formData: HubspotFormData) => {
      const cpTenantDomain = 'divipay';
      const cpRouterName = referrer ? `${referrer}_router` : 'inbound_router';

      window.ChiliPiper.submit(cpTenantDomain, cpRouterName, { map: true, lead: convertFormDataToLead(formData) });
    },
    [referrer]
  );

  return (
    <>
      <Helmet>
        <script src="https://js.chilipiper.com/marketing.js" type="text/javascript"></script>
      </Helmet>
      <DemoHubspotForm id={id} link={link} trackingEvent={trackingEvent} onFormSubmit={chiliPiperSubmit} />
    </>
  );
};

export default ChiliPiperForm;
