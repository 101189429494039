import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { Panel } from '../../design/containers/panel/Panel';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { BodyP, Heading2 } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';
import QuoteCard from './QuoteCard/QuoteCard';

const LeftImageDesktop = styled(PrismicImage)`
  && {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    ${breakpointSmall(css`
      width: 45%;
    `)}
  }
`;
LeftImageDesktop.defaultProps = { visibility: { base: 'hide', breakpointSmall: 'show' } };

const LeftImageMobile = styled(PrismicImage)`
  && {
    position: absolute;
    max-height: 500px;
    width: 100%;
    top: 0;
  }
`;

LeftImageMobile.defaultProps = { visibility: { base: 'show', breakpointSmall: 'hide' } };

const BelowRightCardImageDesktop = styled(PrismicImage)`
  max-width: 400px;
`;
BelowRightCardImageDesktop.defaultProps = {
  visibility: { base: 'hide', breakpointSmall: 'show' },
  objectFit: 'contain',
};

const BelowRightCardImageMobile = styled(PrismicImage)``;
BelowRightCardImageMobile.defaultProps = {
  visibility: { base: 'show', breakpointSmall: 'hide' },
  objectFit: 'contain',
};

const StyledSliceContainer = styled(SliceContainer)`
  padding: 0;
  height: 100%;
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['24px']};
  ${breakpointSmall(
    css`
      grid-template-columns: 1fr 1fr;
      margin: auto;
      padding: ${spacings['144px']} 0;
    `
  )};
`;

const CenteredContentContainer = styled.div`
  padding: ${spacings['24px']};
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 1800px;
  width: 100%;
  padding-bottom: 0;
  ${breakpointSmall(css`
    padding-top: 0;
  `)}
`;

const LeftCardOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LeftCardContainer = styled(Panel)`
  padding: ${spacings['24px']};
  margin-top: 350px;
  height: 100% fit-content;
  border-radius: ${spacings['12px']};
  ${breakpointSmall(css`
    padding: ${spacings['48px']};
    margin-top: 0;
    max-width: 630px;
  `)}
`;

LeftCardContainer.defaultProps = { shadow: 'bigShadowStyles' };

type LeftCardProps = Pick<TwoColFloatingContentProps, 'leftCardTitle' | 'leftCardText'>;

const LeftCard = ({ leftCardTitle, leftCardText }: LeftCardProps) => (
  <LeftCardOuterContainer>
    <LeftCardContainer background="whiteSolid">
      <Spacing bottom="24px">
        <Heading2 textAlign="left">{leftCardTitle}</Heading2>
      </Spacing>
      <BodyP type="bodyLRegular">{leftCardText}</BodyP>
    </LeftCardContainer>
  </LeftCardOuterContainer>
);

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpointSmall(
    css`
      align-items: flex-start;
    `
  )}
`;

type TwoColFloatingContentProps = {
  leftImage: PrismicImageData;
  leftImageMobile: PrismicImageData;
  leftCardTitle?: string;
  leftCardText?: string;
  rightCardQuote?: string;
  rightCardAuthor?: string;
  rightCardJobTitle?: string;
  rightCardCompany?: string;
  belowRightCardImage: PrismicImageData;
  belowRightCardImageMobile: PrismicImageData;
} & WithClassName;

/**
 * Two Col Floating Content slice.
 */
const TwoColFloatingContent: FC<TwoColFloatingContentProps> = ({
  className,
  leftImage,
  leftImageMobile,
  leftCardTitle,
  leftCardText,
  rightCardQuote,
  rightCardAuthor,
  rightCardJobTitle,
  rightCardCompany,
  belowRightCardImage,
  belowRightCardImageMobile,
}) => (
  <StyledSliceContainer className={className} background="brand5Solid">
    <CenteredContentContainer>
      <LeftImageDesktop image={leftImage} />
      <LeftImageMobile image={leftImageMobile} />
      <MainContainer>
        <LeftCard leftCardTitle={leftCardTitle} leftCardText={leftCardText} />
        <RightSideContainer>
          <QuoteCard
            background="brand3SolidStripedCurve"
            quote={rightCardQuote}
            author={rightCardAuthor}
            role={rightCardJobTitle}
            company={rightCardCompany}
          />
          {belowRightCardImage && <BelowRightCardImageDesktop image={belowRightCardImage} />}
          {belowRightCardImageMobile && <BelowRightCardImageMobile image={belowRightCardImageMobile} />}
        </RightSideContainer>
      </MainContainer>
    </CenteredContentContainer>
  </StyledSliceContainer>
);

export default TwoColFloatingContent;
