import styled, { css } from 'styled-components';
import { borderRadius, flex, padding, toggleAlignment } from '../../../../../animations/display';
import { brand2, brand2Light } from '../../../../../colors/brand';
import { breakpointLarge, breakpointSmall } from '../../../../../responsive/breakpoints';
import { Button } from '../../../Button';
import { tertiaryTypeStyle } from '../brand/tertiary/tertiary';
import { transparentTypeStyle } from '../brand/transparent/transparent';

const activeStyles = () => css`
  ${tertiaryTypeStyle};
  * {
    stroke: white;
  }
`;

const defaultStyles = () => css`
  height: fit-content;
  width: 100%;
  ${flex({ gap: '12px', justify: 'space-between' })};
  ${padding({ y: '16px', x: '24px' })};
  white-space: nowrap;
`;

const hoverStyles = () => css`
  &:hover {
    background: ${brand2Light};
    border-color: ${brand2Light};
    color: ${brand2};
  }
`;
/**
 * Toggle expand/collapse styles on filters.
 */
export const expandButtonStyles = (expand: boolean) => css`
  ${borderRadius({ all: '100px' })} transition: border-radius 0.3s linear 0.3s;
  ${expand && borderRadius({ top: '32px' })} transition: border-radius 0.1s linear;
`;

const typeFilterStyles = () => css`
  ${toggleAlignment('left')};
  width: 100%;
  ${breakpointLarge(css`
    width: fit-content;
    ${padding({ all: '16px' })};
  `)}
`;

export const DropDownOption = styled(Button)<{
  isActive: boolean;
  isTopic: boolean | undefined;
}>`
  ${defaultStyles};
  ${transparentTypeStyle}
  background: transparent;
  ${({ isActive }) => (isActive ? activeStyles() : hoverStyles())}
  ${({ isTopic }) => (isTopic ? flex({ justify: 'space-between' }) : typeFilterStyles())};
`;

export const ActiveFilterButton = styled(Button)<{ expand: boolean; maxHeight?: number; hideOnDesktop?: boolean }>`
  ${defaultStyles};
  ${({ expand }) => expandButtonStyles(expand)};
  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    breakpointLarge(css`
      display: none;
    `)}
  ${breakpointSmall(css`
    padding: 24px 32px;
  `)}
`;
