import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { ExtractFromArray, Mutable, RecursiveNotEmptyObject, WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { getSocialProofIconsFromPrismicDocumentLinkField } from '../../socialProof/data/utils';
import { SliceProps, SliceVariation } from '../../types';
import Cards from '../Cards';
import { OneRowCardsLeftNumberCards } from './cardLayouts/OneRowCardsLeftNumberCards';
import { OneRowSmallCardsRightIconCards } from './cardLayouts/OneRowSmallCardsRightIcon';

const getBackgroundName = (background: string | null) => {
  const backgroundNameMapping = {
    'Brand 3 Solid': 'brand3Solid',
    'Brand 5 Solid': 'brand5Solid',
    'Brand 3 Gradient Striped Curve': 'brand3GradientStripedCurve',
    'Brand 4 Gradient': 'brand4Gradient',
    'Brand 4 Gradient Wave Midnight Right': 'brand4WaveMidnightRight',
  } as const;

  const firstBackground = Object.values(backgroundNameMapping)[0];
  return backgroundNameMapping[background as keyof typeof backgroundNameMapping] || firstBackground;
};

type OneRowCardsLeftNumberVariation = SliceVariation<
  '1RowCardsLeftNumber',
  Queries.OneRowCardsLeftNumberFragment['primary'],
  ExtractFromArray<Mutable<Queries.OneRowCardsLeftNumberFragment['items']>>
>;
type OneRowSmallCardsRightIconVariation = SliceVariation<
  '1RowSmallCardsRightIcon',
  Queries.OneRowSmallCardsRightIconFragment['primary'],
  RecursiveNotEmptyObject<ExtractFromArray<Mutable<Queries.OneRowSmallCardsRightIconFragment['items']>>>
>;
type CardsProps = SliceProps<'cards', OneRowCardsLeftNumberVariation | OneRowSmallCardsRightIconVariation> &
  WithClassName;
/**
 * Cards data component. Specifies the query for the cards slice and renders the cards component with the correct data.
 */
const CardsData: FC<CardsProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  const cardsPlacement =
    slice.variation === '1RowCardsLeftNumber' ? (
      <OneRowCardsLeftNumberCards cards={slice.items} />
    ) : (
      <OneRowSmallCardsRightIconCards cards={slice.items} />
    );
  return (
    <Cards
      className={className}
      background={getBackgroundName(data.background)}
      heading={data.heading}
      text={data.text}
      socialProofIcons={getSocialProofIconsFromPrismicDocumentLinkField(data.socialProof)}
      primaryCta={{ text: stringData(data.primaryCtaButtonText), link: data.primaryCtaLink }}
      secondaryCta={{ text: stringData(data.secondaryCtaButtonText), link: data.secondaryCtaLink }}
      cardsPlacement={cardsPlacement}
    />
  );
};

export default CardsData;

export const query = graphql`
  fragment OneRowCardsLeftNumber on PrismicCards1RowCardsLeftNumber {
    primary {
      background
      heading {
        ...RichText
      }
      text
      socialProof {
        document {
          ...SocialProofIcons
        }
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
    items {
      image {
        alt
        gatsbyImageData
      }
      heading
      text
      ctaButtonText
      ctaLink {
        ...PrismicLink
      }
    }
  }

  fragment OneRowSmallCardsRightIcon on PrismicCards1RowSmallCardsRightIcon {
    primary {
      background
      heading {
        ...RichText
      }
      text
      socialProof {
        document {
          ...SocialProofIcons
        }
      }
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
    items {
      image {
        alt
        gatsbyImageData
      }
      prismicIcon: icon {
        ...Icon
      }
      text
    }
  }

  fragment Cards on PrismicSliceType {
    ...OneRowCardsLeftNumber
    ...OneRowSmallCardsRightIcon
  }
`;
