import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { headerZindex } from '../../../components/zindex';
import { flex } from '../../../design/animations/display';
import { brand2Mid } from '../../../design/colors/brand';
import { hyperlink } from '../../../design/colors/system';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';

const BannerContainer = styled.div`
  ${flex({ align: 'center' })}
  padding: ${spacings['12px']} ${spacings['16px']};
  z-index: ${headerZindex + 1};
  background-color: #edfafc;
  border: 1px solid ${brand2Mid};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const InlineParagraph = styled(BodyP)`
  display: inline;
`;

const Link = styled.a.attrs({ target: '_blank', href: 'https://discover.letsweel.com/wex' })`
  color: ${hyperlink};
`;
type Ref = HTMLDivElement;

/**
 * Render Banner component.
 */
export const Banner = forwardRef<Ref, unknown>((_, ref) => (
  <BannerContainer ref={ref} data-testid="wex-banner">
    <InlineParagraph type="bodySRegular">
      Are you a <InlineParagraph type="bodySBold">WEX Motorpass</InlineParagraph> Customer? <Link>Click here</Link> for
      the partner offer.
    </InlineParagraph>
  </BannerContainer>
));

Banner.displayName = 'Banner';
