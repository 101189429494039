import React, { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { LinkType } from '../../components/link/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { flex } from '../../design/animations/display';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall, breakpointMedium } from '../../design/responsive/breakpoints';
import { WithClassName } from '../../utilityTypes';
import MaskedImage from './components/MaskedImage';
import QuoteTextContainer from './components/QuoteTextContainer';

export type QuoteProps = {
  quoteText: PrismicRichTextType;
  quoteAuthor?: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  image: ComponentProps<typeof PrismicImage>['image'] | null;
} & WithClassName;

const Container = styled(SliceContainer)`
  position: relative;
  display: block;
`;

const ContentContainer = styled.div`
  ${flex({ direction: 'column-reverse' })};
  max-width: 1500px;
  margin: 0 auto;
  ${breakpointSmall(css`
    overflow: visible;
    display: block;
  `)}
`;

type MaskBg = {
  height?: number | undefined;
};

const MaskShapeContainer = styled.div<MaskBg>`
  position: relative;
  width: 100%;
  height: 250px;
  ${flex({ justify: 'center' })}
  ${breakpointSmall(css`
    display: none;
  `)}
    ${breakpointMedium(css`
    height: 0;
    display: block;
  `)}
`;
/**
 * Renders Quote slice.
 */
const Quote: FC<QuoteProps> = ({ className, quoteAuthor, quoteText, primaryCta, secondaryCta, image }) => {
  const [contentHeight, setContentHeight] = useState<number>();
  const textContainerRef = useRef<HTMLElement>();

  useEffect(() => {
    const contentEl = textContainerRef?.current?.clientHeight;
    setContentHeight(contentEl);
  }, []);

  const handleResize = () => {
    const contentEl = textContainerRef?.current?.clientHeight;
    setContentHeight(contentEl);
  };

  useEffect(() => {
    addEventListener('resize', () => handleResize());
    return removeEventListener('resize', () => handleResize());
  });

  return (
    <Container className={className} background="brand5Solid" data-testid="quote-slice">
      <ContentContainer>
        <MaskShapeContainer height={contentHeight}>
          <MaskedImage image={image} />
        </MaskShapeContainer>
        <QuoteTextContainer
          ref={textContainerRef}
          quoteText={quoteText}
          quoteAuthor={quoteAuthor}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
      </ContentContainer>
    </Container>
  );
};
export default Quote;
