import { faker } from '@faker-js/faker';
import querystring from 'query-string';
import { RateRequestParams, RateResponsePayload } from './types';

const fxRatesUrl = `${process.env.GATSBY_API_URL}/banking/v1/fx-rates`;
export const maxAllowedMoneyAmount = 1000000;

/**
 * Get exchange rates from Weel API.
 */
export const fetchExchangeRates = async (params: RateRequestParams): Promise<RateResponsePayload> =>
  await fetch(`${fxRatesUrl}?${querystring.stringify(params)}`).then((res) => res.json());

/**
 * Mock RateResponsePayload payload for tests.
 */
export const mockResponsePayload = (): RateResponsePayload => ({
  rate: parseFloat(`0.${faker.random.numeric(5)}`),
  destination_amount: parseInt(faker.random.numeric(5)),
  markup: 0.95,
  estimated_saving: parseInt(faker.random.numeric(5)),
  source_amount: parseInt(faker.random.numeric(5)),
});
