import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { UnorderedListItem } from '../../../components/richText/components';
import { flex, margin } from '../../../design/animations/display';
import { white } from '../../../design/colors/greyscale';
import { Panel } from '../../../design/containers/panel/Panel';
import { SliceContainer } from '../../../design/containers/sliceContainer/SliceContainer';
import { breakpointMedium, breakpointXSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading3 } from '../../../design/typography/Typography';
import { PrismicImageData } from '../../../utilityTypes';
import KeyTextInfo from './KeyTextInfo';

const topOffsetAmount = 70;
const s24 = spacings['24px'];
const s32 = spacings['32px'];
const s16 = spacings['16px'];
const s8 = spacings['8px'];

const StyledSliceContainer = styled(SliceContainer)`
  padding: 0;
  ${breakpointMedium(
    css`
      top: -${topOffsetAmount}px;
    `
  )};
`;

const StyledListItem = styled(UnorderedListItem)`
  margin: 0 0 ${s8} 0;
`;

const AboutAndChallenges = styled(Panel)`
  padding: ${s24};
  display: flex;
  gap: ${s16};
  flex-direction: column;
  ${breakpointXSmall(css`
    padding: ${s32};
    gap: ${s24};
  `)}
`;

const Logo = styled(PrismicImage)`
  max-width: 80px;
  flex-shrink: 0;
  ${margin({ left: '16px' })}
  ${breakpointXSmall(css`
    ${margin({ left: '24px' })}
    max-height: 64px;
    max-width: 100px;
  `)}
`;

const SpaceBetween = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })}
`;

const DotPoints = styled.ul`
  color: ${white};
  padding-left: 20px;
`;

type InfoBoxProps = {
  companyAbout?: string | null;
  challenges?: string[];
  logo: PrismicImageData;
  location?: string;
  industry?: string;
};

/**
 * Info Box component for Customer Story Page.
 */
const InfoBox: FC<InfoBoxProps> = ({ companyAbout, challenges, logo, location, industry }) => {
  return (
    <StyledSliceContainer borderRadius="0px">
      <AboutAndChallenges background="brand2Solid">
        <SpaceBetween>
          <Heading3>About</Heading3>
          {logo && <Logo objectFit="contain" image={logo} />}
        </SpaceBetween>
        {(industry || location) && <KeyTextInfo {...{ industry, location }} />}
        {companyAbout && <BodyP type="bodyMRegular">{companyAbout}</BodyP>}
        {challenges && <BodyP type="bodyLBold">Challenges</BodyP>}
        {challenges && (
          <DotPoints>
            {challenges?.length &&
              challenges.map((item) => {
                return (
                  <StyledListItem key={item}>
                    <Spacing left="8px">
                      <BodyP key={item} type="bodyMRegular">
                        {item}
                      </BodyP>
                    </Spacing>
                  </StyledListItem>
                );
              })}
          </DotPoints>
        )}
      </AboutAndChallenges>
    </StyledSliceContainer>
  );
};

export default InfoBox;
