import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { padding, flex, expandClosedStyles, toggleExpand } from '../../../../design/animations/display';
import { white } from '../../../../design/colors/greyscale';
import { bigShadowStyles } from '../../../../design/colors/shadows';
import { DropDownOption } from '../../../../design/components/button/styles/buttonType/blog/blogFilter';
import { breakpointLarge, breakpointSmall } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { BodyP } from '../../../../design/typography/Typography';
import { singleLineEllipsis } from '../../../../design/typography/base/utils';
import RightArrow from '../../../../images/react-icons/ArrowRightLime.inline.svg';
import { filterButtonIcons } from './TypeFilter';

const s32 = spacings['32px'];

export type FilterOptions = { expand: boolean; maxHeight?: number; hideOnDesktop?: boolean };
export const ExpandCollapseContainer = styled.div<FilterOptions>`
  ${expandClosedStyles}
  ${flex({ direction: 'column', gap: '4px' })};
  ${({ expand, maxHeight }) => maxHeight && toggleExpand(expand, maxHeight + 16)};
  ${({ expand }) => expand && padding({ all: '8px' })};
  ${bigShadowStyles};
  position: relative;
  background: ${white};
  border-radius: 0 0 ${s32} ${s32};
  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    breakpointLarge(css`
      display: none;
    `)}
`;

export const FilterContainer = styled.div<{ align: 'left' | 'right' }>`
  position: relative;
  ${({ align }) =>
    align === 'left'
      ? `${breakpointSmall(css`
          left: 0;
          position: absolute;
          top: 0;
        `)}`
      : `${breakpointSmall(css`
          right: 0;
          position: absolute;
          top: 0;
        `)}`}
`;

const StyledBodyP = styled(BodyP)`
  ${singleLineEllipsis}
`;

type ButtonMapOptions = {
  values: string[];
  activeValue: string;
  onClick: (type: string) => void;
  excludeActive: boolean;
  isTopics?: boolean;
  isTypes?: boolean;
};
/**
 * Button map for Types filter.
 */
export const ButtonMap: FC<ButtonMapOptions> = ({ values, activeValue, onClick, excludeActive, isTopics, isTypes }) => {
  if (!values) return null;

  return (
    <>
      {values.map((value) => {
        const isActive = activeValue === value;
        if (isActive && excludeActive) return null;
        return (
          <DropDownOption isActive={isActive} onClick={() => onClick(value)} key={value} isTopic={isTopics}>
            {isTypes && filterButtonIcons[value]}
            <StyledBodyP textAlign="left" type="bodyMRegular">
              {value}
            </StyledBodyP>
            {isTopics && <RightArrow />}
          </DropDownOption>
        );
      })}
    </>
  );
};
