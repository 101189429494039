import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { grey500 } from '../../../design/colors/greyscale';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointMedium, breakpointSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodySpan, BodyP } from '../../../design/typography/Typography';
import { textStyles } from '../../../design/typography/base/Text';
import { WithClassName } from '../../../utilityTypes';
import { PricingItem } from '../types';
import { TwoColumnContainer } from './PricingColumns';

const CardPanel = styled(Panel)`
  padding: ${spacings['48px']};
  position: relative;
  flex: 1;
  align-self: center;
  max-width: 416px;
  min-width: 224px;
  ${breakpointSmall(
    css`
      align-self: stretch;
    `
  )};
`;
CardPanel.defaultProps = { shadow: 'bigShadowStyles', padding: '32px', borderRadius: '12px' };

const PricingPeriod = styled(BodyP).attrs({ type: 'bodyLRegular', textAlign: 'left' })`
  color: ${grey500};
`;

const PriceText = styled(BodyP)`
  ${breakpointSmall(css`
    ${textStyles({
      fontSize: '48pt',
    })}
  `)}
  ${breakpointMedium(css`
    ${textStyles({
      fontSize: '64pt',
    })}
  `)}
`;
PriceText.defaultProps = { type: 'numbersMedium' };

type PriceProps = {
  price: PricingItem['price'];
  pricingPeriod: PricingItem['pricingPeriod'];
  pricingSubtext?: PricingItem['pricingSubtext'];
} & WithClassName;

/**
 * Renders the price and pricing period for the Price slice.
 */
const Price: FC<PriceProps> = ({ price, pricingPeriod, pricingSubtext, className }) => (
  <>
    <Spacing
      top={{ base: '16px', breakpointSmall: '8px', breakpointMedium: '16px', breakpointLarge: '24px' }}
      bottom={{ base: '16px', breakpointSmall: '8px', breakpointMedium: '16px', breakpointLarge: '24px' }}
    >
      <TwoColumnContainer>
        <Spacing right="4px">
          <PriceText>{price}</PriceText>
        </Spacing>
        <PricingPeriod>{pricingPeriod}</PricingPeriod>
      </TwoColumnContainer>
    </Spacing>
    <BodySpan className={className} type="bodyLRegular" textAlign="left">
      {pricingSubtext}
    </BodySpan>
  </>
);

export default Price;
