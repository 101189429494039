import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand2 } from '../../../../design/colors/brand';
import { breakpointMedium } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { GridAreaProvider } from '../../../../design/utils/GridAreaProvider';
import { LinkType } from '../../../link/types';
import { headerCtasZindex } from '../../../zindex';
import { slideUpTransition } from '../menu/styles/slideUpTransition';
import { CtaButton, CtaToUse } from './CtaButton';

export const ctasHeight = '168px';
export type CtasContainerProps = { open?: boolean };
const CtasContainer = styled.div<CtasContainerProps>`
  ${(props) => slideUpTransition(props.open)}
  z-index: ${headerCtasZindex};
  height: ${ctasHeight};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'secondaryCta1 secondaryCta2'
    'primaryCta primaryCta';
  gap: ${spacings['12px']};
  background-color: ${brand2};
  border-radius: 24px 24px 0 0;
  padding: ${spacings['24px']};

  ${breakpointMedium(css`
    display: flex;
    align-items: center;
    height: auto;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    gap: ${spacings['none']};
    padding-left: ${spacings['12px']};
  `)}
`;
export type CtasProps = {
  secondaryCta1: LinkType;
  secondaryCta2: LinkType;
  primaryCta: LinkType;
  tourCtaMobile: LinkType;
};
type Props = CtasContainerProps & CtasProps;
/**
 * Renders the three CTAs on the header.
 */
export const Ctas: FC<Props> = ({ primaryCta, secondaryCta1, secondaryCta2, tourCtaMobile, open }) => {
  return (
    <CtasContainer data-testid="ctas" open={open}>
      {secondaryCta1 && tourCtaMobile && (
        <GridAreaProvider area="secondaryCta1">
          <CtaToUse desktopCta={secondaryCta1} mobileCta={tourCtaMobile} styleType="secondaryTransparent" />
        </GridAreaProvider>
      )}
      <GridAreaProvider area="secondaryCta2">
        <CtaButton styleType="secondaryTransparent" link={secondaryCta2.link} text={secondaryCta2.text} />
      </GridAreaProvider>
      <GridAreaProvider area="primaryCta">
        <CtaButton styleType="primary" link={primaryCta.link} text={primaryCta.text} />
      </GridAreaProvider>
    </CtasContainer>
  );
};
