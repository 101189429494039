import { JSXMapSerializer } from '@prismicio/react';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import linkResolver from '../../../gatsbyHelpers/linkResolver/linkResolver';
import { hyperlink } from '../../design/colors/system';
import { spacings } from '../../design/spacing/Spacing';
import { BodyEm, BodyLink, BodyP, TypographyProps, BodyStrong } from '../../design/typography/Typography';
import Text from '../../design/typography/base/Text';
import bullet from '../../images/icons/LimeBullet.svg';
import { Defined, WithClassName } from '../../utilityTypes';

export const Paragraph = styled(BodyP)`
  & + * {
    margin-top: 1em;
  }
`;

export const Bold = styled(BodyStrong)`
  font-weight: bold;
`;

export const Italic = styled(BodyEm)`
  font-style: italic;
`;

export const Hyperlink = styled(BodyLink)`
  color: ${hyperlink};

  &:hover {
    text-decoration: underline;
  }
`;

export const OrderedListItem = styled(Text)`
  list-style: decimal;
  margin: ${spacings['16px']};
`;
OrderedListItem.defaultProps = { element: 'li' };

export const UnorderedListItem = styled(Text)`
  margin: ${spacings['16px']};
  list-style-image: url(${bullet});
  list-style-type: disc;
`;
UnorderedListItem.defaultProps = { element: 'li' };

type UnresolvedLinkProps = { node: Parameters<Defined<JSXMapSerializer['hyperlink']>>['0']['node'] } & TypographyProps;
/**
 * Resolves the RTLinkNode using the linkResolver and renders a Link.
 */
export const RichTextLink: FC<PropsWithChildren<UnresolvedLinkProps>> = ({ children, node, ...bodyLinkProps }) => {
  const urlProps = node.data.link_type === 'Document' ? { url: linkResolver(node.data) } : { url: node.data.url };
  return (
    <Hyperlink link={{ ...node.data, ...urlProps }} {...bodyLinkProps}>
      {children}
    </Hyperlink>
  );
};

type ConditionalStyleProviderProps = WithClassName;
/**
 * If a className is passed in, it wraps the children in a div and passes the className into the div.
 * Otherwise it just renders the children.
 *
 * This allows rich text elements that do not having any spacing applied to not have an unnecessary div
 * wrapping them.
 */
export const ConditionalStyleProvider: FC<PropsWithChildren<ConditionalStyleProviderProps>> = ({
  className,
  children,
  ...props
}) =>
  className ? (
    <div className={className} {...props}>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
