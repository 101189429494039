import { css } from 'styled-components';
import { brand4 } from '../../../../../../colors/brand';

/**
 * Generates the transparent button type style.
 */
export const transparentTypeStyle = () => css`
  border-color: transparent;
  color: ${brand4};
`;
