import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import RichText from '../../../components/richText/RichText';
import { flex, toggleShow } from '../../../design/animations/display';
import { toggleFadeUp } from '../../../design/animations/micro-interactions';
import { brand3 } from '../../../design/colors/brand';
import { grey200, grey400 } from '../../../design/colors/greyscale';
import { bigShadowStyles } from '../../../design/colors/shadows';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointMedium, breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import { colorBoldText } from '../../../design/typography/base/utils';
import Cross from '../../../images/react-icons/Cross.inline.svg';
import { WithClassName, WithTestId } from '../../../utilityTypes';
import { TooltipType } from '../types';

const Description = styled(RichText)`
  ${colorBoldText({ color: brand3, weight: '500' })};
`;

const Row = styled.div`
  ${flex({ justify: 'space-between' })};
  border-bottom: 1px solid ${grey400};
  padding: ${spacings['8px']};
  &:last-child {
    border-bottom: none;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  ${flex({ justify: 'flex-end' })};
`;

type RowProps = { column1: string; column2: string; isHeadingRow?: boolean };
const RowItem: FC<RowProps> = ({ column1, column2, isHeadingRow }) => {
  return (
    <Row>
      <BodyP type={isHeadingRow ? 'bodySMedium' : 'bodyMRegular'}>{column1}</BodyP>
      <BodyP type={isHeadingRow ? 'bodySMedium' : 'bodyMRegular'}>{column2}</BodyP>
    </Row>
  );
};

const PricingTable = styled.div`
  background: ${grey200};
  border-radius: ${spacings['12px']};
  padding: ${spacings['24px']};
  width: 100%;
  ${breakpointMedium(css`
    padding: ${spacings['32px']};
  `)}
`;

/**
 * Toggle popup visibility .
 */
export const toggleStyles = (show?: boolean) => css`
  ${toggleShow(show)}
  ${toggleFadeUp(show)}
`;

/**
 * Toggle Popup Hover.
 */
export const popUpHoverTrigger = () => css`
  &:hover {
    ${Container} {
      ${toggleStyles(true)}
    }
  }
`;

export const Container = styled(Panel)<Omit<PopUpProps, 'onClose'>>`
  ${(props) => toggleStyles(props.showOnMobile)};
  ${bigShadowStyles};
  ${flex({ direction: 'column', gap: '16px' })}
  position: absolute;
  min-width: 100%;
  left: 0;
  bottom: 0;
  ${breakpointSmall(css`
    bottom: ${spacings['48px']};
    right: ${spacings['48px']};
    left: auto;
    min-width: unset;
    max-width: 350px;
  `)}
  ${breakpointMedium(css`
    max-width: 412px;
  `)}
`;

Container.defaultProps = {
  background: 'whiteSolid',
  padding: { base: '24px', breakpointSmall: '32px' },
  borderRadius: '12px',
};

const CloseButtonElement = styled.button`
  cursor: pointer;
`;
type CloseButtonProps = { onClick?: () => void };
const CloseButton: FC<CloseButtonProps> = ({ onClick }) => (
  <CloseButtonElement onClick={onClick} data-testid="pop-up-close">
    <Cross height={14} width={14} />
  </CloseButtonElement>
);

type PopUpProps = { tooltip?: TooltipType; showOnMobile: boolean; onClose: () => void } & WithTestId & WithClassName;
/**
 * Renders the tool tip Popup.
 */
const PopUp: FC<PopUpProps> = ({ tooltip, showOnMobile, onClose, className }) => {
  const hasColumnTitles = !!(tooltip?.leftColTitle && tooltip?.rightColTitle);
  const hasRows = !!tooltip?.rows.length;
  return (
    <Container data-testid="tool-tip-popup" showOnMobile={showOnMobile} className={className}>
      <ButtonContainer>
        <CloseButton onClick={onClose} />
      </ButtonContainer>
      {tooltip?.heading && (
        <RichText
          field={tooltip.heading}
          textType={{ base: 'heading4', breakpointMedium: 'heading3' }}
          textAlign="left"
        />
      )}
      {tooltip?.description && <Description field={tooltip.description} textType="bodyMRegular" textAlign="left" />}
      <PricingTable>
        {hasColumnTitles && <RowItem column1={tooltip.leftColTitle} column2={tooltip.rightColTitle} isHeadingRow />}
        {hasRows &&
          tooltip.rows.map((item) => {
            const { left_column_entry: leftCol, right_column_entry: rightCol } = item;
            return <RowItem key={leftCol} column1={leftCol} column2={rightCol} />;
          })}
      </PricingTable>
    </Container>
  );
};

export default PopUp;
