import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { LinkType } from '../../components/link/types';
import { AnimationField } from '../../components/lottieAnimation/types';
import { PrismicRichTextType } from '../../components/richText/RichText';
import { ButtonLink } from '../../design/components/button/Button';
import { Background } from '../../design/containers/panel/Panel';
import * as backgrounds from '../../design/containers/panel/backgrounds';
import TwoCtaPlacementContainer from '../../design/containers/placements/TwoCtaPlacementContainer';
import { Side } from '../../design/containers/sliceContainer/SideSliceContentContainer';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing from '../../design/spacing/Spacing';
import Heading2 from '../../design/typography/Heading2';
import { BodyP } from '../../design/typography/Typography';
import { PrismicImageData, WithClassName } from '../../utilityTypes';
import { ResourceCard } from '../contentHubCards/types';
import LinkedDocumentCta from './LinkedDocumentCta';
import ColumnsContainer from './components/ColumnsContainer/ColumnsContainer';
import QuoteCard from './components/QuoteCard/QuoteCard';

const ButtonsPlacement = styled(TwoCtaPlacementContainer)`
  align-self: center;
  ${breakpointSmall(css`
    align-self: flex-start;
  `)}
`;

const TextContainer = styled.div``;

type QuoteCardProps = {
  companyImage?: PrismicImageData;
  quote?: string;
  author?: string;
  jobTitle?: string;
};

export type TwoColumnContentImageProps = {
  heading: PrismicRichTextType;
  text: string;
  primaryCta: LinkType;
  secondaryCta: LinkType;
  desktopImage: PrismicImageData;
  mobileImage: PrismicImageData;
  backgroundType?: Background;
  linkedDocumentCta?: ResourceCard;
  quoteCard: QuoteCardProps;
  contentAlignment: Side;
  animation?: AnimationField;
} & WithClassName;

/**
 * Renders TwoColumnContentImage slice.
 */
const TwoColumnContentImage: FC<TwoColumnContentImageProps> = ({
  className,
  heading,
  text,
  primaryCta,
  secondaryCta,
  desktopImage,
  mobileImage,
  contentAlignment,
  quoteCard,
  animation,
  linkedDocumentCta,
  backgroundType = 'brand3GradientStripedCurve',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const noButtons = !primaryCta.link?.url && !primaryCta.text && !secondaryCta.link?.url && !secondaryCta.text;
  const isDarkBackground = backgrounds[backgroundType].isDark;
  const secondaryButtonStyle = isDarkBackground ? 'reverseSecondary' : 'secondary';

  const isQuoteCardShown = Object.values(quoteCard).some((value) => !!value);
  const horizontalPadding = { base: '24px', breakpointXSmall: '48px' } as const;
  const verticalPadding = { base: 'none' } as const;
  const padding = {
    left: { ...horizontalPadding },
    right: { ...horizontalPadding },
    top: { ...verticalPadding },
    bottom: { ...verticalPadding },
  } as const;

  return (
    <SliceContainer className={className} background={backgroundType} padding={padding}>
      <ColumnsContainer
        contentAlignment={contentAlignment}
        desktopImage={desktopImage}
        animation={animation}
        mobileImage={mobileImage}
      >
        <TextContainer>
          <Heading2 textAlign="left">{heading}</Heading2>
          <Spacing top="24px" bottom="24px">
            <BodyP type="bodyLRegular">{text}</BodyP>
          </Spacing>
          {isQuoteCardShown && (
            <Spacing top="48px">
              <QuoteCard
                background="brand3Solid"
                companyImage={quoteCard.companyImage}
                quote={quoteCard.quote}
                author={quoteCard.author}
                role={quoteCard.jobTitle}
              />
            </Spacing>
          )}
          {linkedDocumentCta && (
            <Spacing top={{ base: 'none', breakpointMedium: '24px' }}>
              <ButtonsPlacement
                cta1Placement={
                  <LinkedDocumentCta
                    linkedDocumentCta={linkedDocumentCta}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                }
                cta2Placement={<></>}
              />
            </Spacing>
          )}
          {!noButtons && (
            <Spacing top={{ base: 'none', breakpointMedium: '24px' }}>
              <ButtonsPlacement
                cta1Placement={
                  <ButtonLink styleType="primary" link={primaryCta.link}>
                    {primaryCta.text}
                  </ButtonLink>
                }
                cta2Placement={
                  <ButtonLink styleType={secondaryButtonStyle} link={secondaryCta.link}>
                    {secondaryCta.text}
                  </ButtonLink>
                }
              />
            </Spacing>
          )}
        </TextContainer>
      </ColumnsContainer>
    </SliceContainer>
  );
};

export default TwoColumnContentImage;
