import React, { ComponentProps, FC } from 'react';
import styled from 'styled-components';
import { AspectRatioContainer, AspectRatioContainerProps } from '../../../design/containers/AspectRatioContainer';
import CloneChild from '../../../design/utils/CloneChild';
import { WithClassName } from '../../../utilityTypes';

const VideoElementStyleProvider = styled(CloneChild)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

type Props = { children: ComponentProps<typeof CloneChild>['children'] } & AspectRatioContainerProps & WithClassName;
/**
 * Aspect ratio container to wrap videos with. The video will be styled
 * to take the full space in the container.
 *
 * @example
 *
 * <AspectRatioVideoContainer heightRatio="56.25">
 *   <video>
 *     <source src="..." />
 *   </video>
 * </AspectRatioVideoContainer>
 */
export const AspectRatioVideoContainer: FC<Props> = ({ className, children, heightRatio }) => (
  <AspectRatioContainer className={className} heightRatio={heightRatio}>
    <VideoElementStyleProvider>{children}</VideoElementStyleProvider>
  </AspectRatioContainer>
);
