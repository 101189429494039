import { css, FlattenSimpleInterpolation } from 'styled-components';
import { Direction, spacings, SpacingType } from '../spacing/Spacing';

/**
 * Generates styles to hide or show element (display: none prevents ability to animate/transition).
 */
export const toggleShow = (showElement?: boolean) =>
  showElement
    ? css`
        opacity: 1;
        height: auto;
        width: auto;
        overflow: visible;
        visibility: visible;
      `
    : css`
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
      `;

const expandOpenStyles = (maxHeight: number) => `
  opacity: 1;
  max-height: ${maxHeight}px;
`;
export const expandClosedStyles = `
  opacity: 0;
  max-height: 0;
  overflow: hidden;
`;
const expandTransitionStyles = `
  transition: opacity 0.7s ease, max-height 0.5s ease;
`;
/**
 * Generates styles to hide or show element for height expand.
 */
export const toggleExpand = (showElement: boolean, maxHeight: number) => css`
  ${showElement ? expandOpenStyles(maxHeight) : expandClosedStyles}
  ${expandTransitionStyles}
`;

/**
 * &:hover helper that accepts styles.
 */
export const onHover = (styles: FlattenSimpleInterpolation) => css`
  &:hover {
    ${styles}
  }
`;

type FlexProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  gap?: keyof typeof spacings;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
};
/**
 * Flex constructor.
 */
export const flex = ({ direction, align, justify, gap, wrap }: FlexProps) =>
  css`
    display: flex;
    ${direction && `flex-direction: ${direction};`}
    ${justify && `justify-content: ${justify};`}
    ${align && `align-items: ${align};`}
    ${gap && ` gap: ${gap};`}
    ${wrap && `flex-wrap: ${wrap};`}
  `;

type GridProps = {
  columns?: number;
  rows?: number;
  gap?: keyof typeof spacings;
  align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';
};
/**
 * Grid constructor.
 */
export const grid = ({ columns = 2, gap, align }: GridProps) => css`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  ${gap && `gap: ${gap}`};
  ${align && `align-items: ${align}`};
`;

type PositionOptions = {
  top?: SpacingType;
  right?: SpacingType;
  bottom?: SpacingType;
  left?: SpacingType;
  x?: SpacingType;
  y?: SpacingType;
  all?: SpacingType;
};

const generatePadding = (size: SpacingType, direction: Direction) => `padding-${direction}: ${spacings[size]};`;
const generateMargin = (size: SpacingType, direction: Direction) => `margin-${direction}: ${spacings[size]};`;
/**
 * Padding helper.
 */
export const padding = ({ top, right, bottom, left, x, y, all }: PositionOptions) => css`
  ${all && `padding: ${spacings[all]};`};
  ${x && !y && `padding: 0 ${spacings[x]};`}};
  ${y && !x && `padding: ${spacings[y]} 0;`}};
  ${x && y && `padding: ${spacings[y]} ${spacings[x]};`};
  ${top && generatePadding(top, 'top')};
  ${right && generatePadding(right, 'right')};
  ${bottom && generatePadding(bottom, 'bottom')};
  ${left && generatePadding(left, 'left')};
`;
/**
 * Padding helper.
 */
export const margin = ({ top, right, bottom, left, x, y, all }: PositionOptions) => css`
  ${all && `margin: ${spacings[all]};`};
  ${x && !y && `margin: 0 ${spacings[x]}`};
  ${y && !x && `margin: ${spacings[y]} 0`};
  ${x && y && `margin: ${spacings[y]} ${spacings[x]}`}
  ${top && generateMargin(top, 'top')};
  ${right && generateMargin(right, 'right')};
  ${bottom && generateMargin(bottom, 'bottom')};
  ${left && generateMargin(left, 'left')};
`;
/**
 * Border-radius helper.
 */
export const borderRadius = ({ top, right, bottom, left, all }: PositionOptions) => css`
  ${all && `border-radius: ${spacings[all]};`};
  ${top && `border-radius: ${spacings[top]} ${spacings[top]} 0 0;`};
  ${right && `border-radius: 0 ${spacings[right]} ${spacings[right]} 0 ;`};
  ${bottom && `border-radius: 0 0 ${spacings[bottom]} ${spacings[bottom]};`};
  ${left && `border-radius: ${spacings[left]} 0 0 ${spacings[left]};`};
`;
/**
 * Flex Alignment Toggle.
 */
export const toggleAlignment = (direction: 'left' | 'right' | 'center') => {
  const justification: Record<string, FlexProps['justify']> = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
  };
  const justify = justification[direction];
  return css`
    ${flex({ justify })}
  `;
};

export const alignCenterHorizontal = css`
  left: 50%;
  transform: translateX(-50%);
`;

export const alignCenterVertical = css`
  top: 50%;
  transform: translateY(-50%);
`;
