import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utilityTypes';
import { SliceProps, SliceVariation } from '../../types';
import SocialProof from '../SocialProof';
import { getSocialProofIconsFromPrismicDocumentLinkField } from './utils';

type SocialProofDataProps = SliceProps<
  'socialProof',
  SliceVariation<'default', Queries.SocialProofDefaultFragment['primary']> | SliceVariation<'grey'>
> &
  WithClassName;
/**
 * SocialProof data component: specifies the fragment and passes the appropriate data to the SocialProof component.
 */
const SocialProofData: FC<SocialProofDataProps> = ({ className, slice }) => {
  if (slice.variation === 'grey') return <SocialProof className={className} background="grey200Solid" />;
  if (!slice.primary) return <SocialProof className={className} background="whiteSolid" />;

  return (
    <SocialProof
      className={className}
      background={(slice.primary.backgroundColor as Background) || undefined}
      text={slice.primary.text || ''}
      icons={getSocialProofIconsFromPrismicDocumentLinkField(slice.primary.icons)}
    />
  );
};

export default SocialProofData;

export const query = graphql`
  fragment SocialProofIcons on PrismicSocialProofIcons {
    data {
      icons {
        icon {
          alt
          gatsbyImageData(height: 48)
        }
        iconLink {
          ...PrismicLink
        }
      }
    }
  }

  fragment SocialProofDefault on PrismicSocialProofDefault {
    primary {
      text
      backgroundColor
      icons {
        document {
          ...SocialProofIcons
        }
      }
    }
  }

  fragment SocialProof on PrismicSliceType {
    ...SocialProofDefault
  }
`;
