import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { PrismicImage } from '../../components/image/prismicImage/PrismicImage';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utilityTypes';

// @todo build these styles into the RichText comp?
type ImageProps = {
  columns?: string;
  items?: Queries.PrismicImageDefaultItem[];
} & WithClassName;

const ImageGrid = styled.div`
  display: grid;
  padding: 0;
  width: 100%;
  &.1-column {
    display: grid;
    grid-template-columns: 1fr;
    color: red;
  }
  &.2-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &.3-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  // @todo come back to this. Cannot understand why not working
  // not nec for launch
`;

const ImageContainer = styled.div`
  border-radius: ${spacings['24px']};
  overflow: hidden;
  margin: ${spacings['12px']} 0;
`;

/**
 * Renders Wysiwyg slice.
 */
const Image: FC<ImageProps> = ({ items, columns }) => (
  <SliceContainer>
    <ImageGrid className={`${columns}-column`} style={{ gridTemplateColumns: `repeat(${columns}), 1fr` }}>
      {items?.map((item, index) => {
        return (
          <Fragment key={`img-${index}`}>
            <ImageContainer>
              <PrismicImage image={item.image} />
            </ImageContainer>
          </Fragment>
        );
      })}
    </ImageGrid>
  </SliceContainer>
);

export default Image;
