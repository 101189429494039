import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicLinkType } from '../../../components/link/data/Link.generateData';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { flex, grid, padding } from '../../../design/animations/display';
import { brand1 } from '../../../design/colors/brand';
import { grey400 } from '../../../design/colors/greyscale';
import { ButtonLink } from '../../../design/components/button/Button';
import {
  breakpointLarge,
  breakpointMedium,
  breakpointSmall,
  breakpointXLarge,
} from '../../../design/responsive/breakpoints';
import Spacing from '../../../design/spacing/Spacing';
import { Heading3, getTypographyStyles } from '../../../design/typography/Typography';
import Text from '../../../design/typography/base/Text';
import { CenteredContainer, FeaturePricingComparisonProps } from '../FeaturePricingComparison';
import { formatPlanPrice } from '../utils';
import { Tier } from './FeatureRow';

const responsiveLayout = () => css`
  ${flex({ direction: 'column', align: 'center' })}
  ${breakpointSmall(
    css`
      ${grid({ columns: 4, gap: '16px' })}
    `
  )};
  ${breakpointXLarge(
    css`
      ${grid({ columns: 4, gap: '72px' })}
    `
  )};
`;

const responsiveTypography = () => css`
  ${getTypographyStyles('heading3')}
  &:after {
    ${getTypographyStyles('bodySRegular')};
  }
  ${breakpointMedium(
    css`
      ${getTypographyStyles('heading1Mobile')}
      &:after {
        ${getTypographyStyles('bodyMRegular')};
      }
    `
  )};
  ${breakpointLarge(
    css`
      ${getTypographyStyles('heading1Mobile')}
      &:after {
        ${getTypographyStyles('bodyLRegular')};
      }
    `
  )};
`;

const PricingRowContainer = styled.div<{ isSticking?: boolean }>`
  ${padding({ bottom: '24px' })}
  ${flex({ direction: 'column', align: 'center' })}
  ${responsiveLayout()};
  ${({ isSticking }) =>
    isSticking
      ? css`
          border-bottom: none;
        `
      : css`
          border-bottom: 1px solid ${grey400};
        `}
`;

const Price = styled(Text)`
  position: relative;
  ${responsiveTypography()}
  &:after {
    content: 'AUD / month';
    position: relative;
    white-space: nowrap;
    bottom: 0;
    opacity: 0.5;
  }
`;

const showHideStyles = (showOnMobile?: boolean) =>
  showOnMobile
    ? css`
        ${flex({ direction: 'column', align: 'center', justify: 'center', gap: '4px' })}
        h3 {
          display: none;
        }
        ${breakpointSmall(
          css`
            h3 {
              display: block;
            }
          `
        )};
      `
    : css`
        display: none;
      `;

const CellContainer = styled.div<{ showOnMobile?: boolean }>`
  ${({ showOnMobile }) => showHideStyles(showOnMobile)}
  ${breakpointSmall(
    css`
      ${flex({ direction: 'column', align: 'center', justify: 'center', gap: '4px' })}
    `
  )};
`;

const HeadingCell: FC<{ title: string; price: string; showOnMobile?: boolean; link: PrismicLinkType }> = ({
  title,
  price,
  showOnMobile,
  link,
}) => (
  <CellContainer data-testid={`${title.toLowerCase()}-header`} showOnMobile={showOnMobile}>
    <Heading3 color={brand1} type={{ base: 'heading4', breakpointLarge: 'heading3' }}>
      {title}
    </Heading3>
    <Price>{price}</Price>
    <Spacing top="8px">
      <ButtonLink styleType="primary" link={link} size="small">
        Learn more
      </ButtonLink>
    </Spacing>
  </CellContainer>
);

const SliceTitle = styled(RichText).attrs({ textAlign: 'left', textType: 'heading3' })`
  ${padding({ y: '24px' })}
  align-self: baseline;
  width: 0;
  height: 0;
  opacity: 0;
  ${breakpointMedium(
    css`
      height: auto;
      width: auto;
      opacity: 1;
    `
  )};
`;

/**
 * Heading Row for Feature Comparison slice.
 */
export const FeatureHeaderRow: FC<{
  mobileActiveTier?: Tier;
  links: FeaturePricingComparisonProps['links'];
  isSticking?: boolean;
  prices: Record<Tier, number>;
  heading: PrismicRichTextType;
}> = ({ mobileActiveTier, links, isSticking, prices, heading }) => {
  const headings = [
    { title: 'Basic', price: prices.basic },
    { title: 'Premium', price: prices.premium },
    { title: 'Enterprise', price: prices.enterprise },
  ];

  return (
    <CenteredContainer>
      <PricingRowContainer isSticking={isSticking}>
        <SliceTitle field={heading} />
        {headings.map(({ title, price }) => (
          <HeadingCell
            key={title}
            title={title}
            price={formatPlanPrice(price)}
            showOnMobile={mobileActiveTier === title.toLowerCase()}
            link={links[title.toLowerCase() as Tier]}
          />
        ))}
      </PricingRowContainer>
    </CenteredContainer>
  );
};
