import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { flex, onHover } from '../../../design/animations/display';
import { brand1 } from '../../../design/colors/brand';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodySpan, Heading3 } from '../../../design/typography/Typography';
import LinkedinLogo from '../../../images/react-icons/Linkedin.inline.svg';
import MaskRoundedBorder from '../../../images/react-icons/MaskImageRounded.inline.svg';
import { PrismicImageData } from '../../../utilityTypes';

const StyledRoleText = styled(BodySpan)`
  color: ${brand1};
  margin-bottom: ${spacings['4px']};
`;

const SpeakerContainer = styled.div<{ inCarousel?: boolean }>`
  ${flex({ direction: 'column', align: 'center' })}
  ${({ inCarousel }) => (inCarousel ? 'max-width: unset;' : 'max-width: 330px;')}
  width: 100%;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 330px;
`;

const StyledImage = styled(PrismicImage)`
  && {
    position: absolute;
    top: 0;
    clip-path: circle(40%);
  }
`;
StyledImage.defaultProps = { objectFit: 'contain' };

const StyledLinkedinLogo = styled(LinkedinLogo)`
  ${onHover(
    css`
      cursor: pointer;
      * {
        fill: ${brand1};
        transition: fill 0.3s ease;
      }
    `
  )}
`;
export type SpeakerProps = {
  company?: string;
  name?: string;
  role?: string;
  image?: PrismicImageData;
  linkedin?: string;
  inCarousel?: boolean;
};
/**
 * Speaker presentation comp.
 */
const Speaker: FC<SpeakerProps> = ({ name, company, role, image, linkedin, inCarousel }) => (
  <SpeakerContainer inCarousel={inCarousel}>
    <Spacing bottom="24px">
      <ImageContainer>
        <MaskRoundedBorder />
        {image && <StyledImage image={image} />}
      </ImageContainer>
    </Spacing>
    <Spacing bottom="4px">
      <Heading3 type={{ base: 'heading3', breakpointSmall: 'heading4', breakpointMedium: 'heading3' }}>{name}</Heading3>
    </Spacing>
    <Spacing bottom="4px">
      <StyledRoleText type="bodyMBold"> {role}</StyledRoleText>
    </Spacing>
    <BodySpan type="bodyMRegular">{company}</BodySpan>
    {linkedin && (
      <Spacing top="12px">
        <StyledLinkedinLogo onClick={() => window.open(linkedin)} />
      </Spacing>
    )}
  </SpeakerContainer>
);

export default Speaker;
