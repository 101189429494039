import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { breakpointMedium } from '../../../../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../../../../design/spacing/Spacing';
import { Heading4 } from '../../../../../../design/typography/Typography';
import { HeaderMegaDropdownSection } from '../../../../types';
import { HiddenOnMobile } from '../../../../utils/Hidden';
import { SectionSubText } from '../SectionSubText';
import { SectionItem } from '../sectionItem/SectionItem';

type SectionItemsContainerProps = { sectionItemColumns?: '1' | '2' };
const SectionItemsContainer = styled.div<SectionItemsContainerProps>`
  ${(props) =>
    breakpointMedium(css`
      display: grid;
      grid-template-columns: ${props.sectionItemColumns === '1' ? '1fr' : '1fr 1fr'};
      column-gap: ${spacings['24px']};
      row-gap: ${spacings['16px']};
    `)}
`;

type SectionContentProps = { section: HeaderMegaDropdownSection } & SectionItemsContainerProps;
/**
 * Renders a nav dropdown section.
 */
export const Section: FC<SectionContentProps> = ({ section, sectionItemColumns = '1' }) => (
  <>
    <HiddenOnMobile>
      <Heading4 type="heading4Bold">{section?.heading}</Heading4>
    </HiddenOnMobile>
    <Spacing top="4px">
      <HiddenOnMobile>
        <SectionSubText>{section?.text}</SectionSubText>
      </HiddenOnMobile>
    </Spacing>
    <Spacing top={{ breakpointMedium: '32px' }}>
      <SectionItemsContainer sectionItemColumns={sectionItemColumns} data-testid="section-items-container">
        {section?.items.map((item) => (
          <SectionItem key={item.heading} item={item} />
        ))}
      </SectionItemsContainer>
    </Spacing>
  </>
);
