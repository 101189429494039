/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { Resource } from '../slices/relatedResources/RelatedResources.queryData';
import { stringData } from './normaliseData';

/**
 * Accepts prismic ref to document object
 * Gets key off 'document'
 * Will work with chaining e.g 'title.text'
 * '' = default fallback value.
 */
export const prismicDoc = (document: any, key: string, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, `document.${key}`, fallbackValue);
};
/**
 * Accepts prismic ref to document object
 * Gets key off 'document.data'
 * Will work with chaining e.g 'title.text'
 * '' = default fallback value.
 */
export const prismicDocData = (document: any, key: string, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, `document.data.${key}`, fallbackValue);
};
/**
 * Accepts prismic author ref
 * Gets value off 'document.data.name'
 * '' = default fallback value.
 */
export const prismicAuthor = (document: any, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, 'document.data.name', fallbackValue);
};
/**
 * Accepts prismic document with author relationship
 * Gets value from 'document.data.author.document.data.name'
 * '' = default fallback value.
 */
export const prismicAuthorRef = (document: any, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, 'document.data.author.document.data.name', fallbackValue);
};
/**
 * Accepts prismic document
 * Gets key off 'data'
 * Will work with chaining e.g 'title.text'
 * '' = default fallback value.
 */
export const prismicData = (document: any, key: string, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, `data.${key}`, fallbackValue);
};
/**
 * Accepts any object
= * Will work with chaining e.g 'title.text'
 * '' = default fallback value.
 */
export const objectData = (document: any, key: string, fallback?: any) => {
  const fallbackValue = stringData(fallback);
  return _.get(document, `${key}`, fallbackValue);
};

/**
 * Given a prismic document that can be a guide, blog post, or event,
 * returns a string regarding what article type it is.
 */
export const getArticleType = (prismicDocument: any) => {
  const prismicType = _.get(prismicDocument, 'type', '');
  const prismicData = _.get(prismicDocument, 'data', '');

  if (prismicType === 'blog_post') {
    const prismicArticleType = _.get(prismicData, 'article_type', '');
    if (prismicArticleType.includes('Customer')) return 'customerStory';
    return 'blogPost';
  }

  if (prismicType === 'events') {
    const isWebinar = _.get(prismicData, 'is_webinar', false);
    if (isWebinar) return 'webinar';
    return 'event';
  }

  if (prismicType === 'guides') {
    const isResearch = _.get(prismicData, 'is_research', false);
    if (isResearch) return 'research';
    return 'guide';
  }

  if (prismicType === 'customer_story') {
    return 'customerStory';
  }
  return 'blogPost';
};

/**
 * Given a prismic topics document, iterates through it and filters those that exist.
 */
export const getDocumentTopics = (prismicTopics: string[]) =>
  prismicTopics
    .map((prismicTopic: string) => {
      const topic = _.get(prismicTopic, 'topic.document.data.topic', '');
      return topic;
    })
    .filter((topic: string) => topic !== '');

/**
 * Given a prismic document, returns the correct image based on the document type.
 */
export const getCardImage = (prismicDocument: Resource) => {
  const type = _.get(prismicDocument, 'type', null);
  if (type === 'blog_post') return _.get(prismicDocument, 'data.feature_image');
  return _.get(prismicDocument, 'data.card_image');
};
