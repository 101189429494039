import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImage } from '../../../../components/image/prismicImage/PrismicImage';
import Lottie from '../../../../components/lottieAnimation/Lottie';
import { grid, flex } from '../../../../design/animations/display';
import { breakpointSmall, breakpointMedium } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { TwoColumnContentImageProps } from '../../TwoColumnContentImage';

type TwoColumnsProps = { alignment: 'left' | 'right' };
type AlignProps = Pick<TwoColumnsProps, 'alignment'>;
const gridAlignment = ({ alignment }: TwoColumnsProps) => css`
  ${grid({ columns: 1, align: 'center' })}
  grid-template-areas:
    'content'
    'media';
  ${breakpointSmall(
    css`
      ${grid({ columns: 2, gap: '32px', align: 'center' })}
      ${alignment === 'left' ? "grid-template-areas: 'content media'" : "grid-template-areas: 'media content'"};
    `
  )}
`;
const columnAlignment = ({ alignment }: AlignProps) => css`
  ${breakpointSmall(
    css`
      ${alignment === 'left' ? 'justify-self: flex-start' : 'justify-self: flex-end'};
    `
  )}
`;

const TwoColumns = styled.section<TwoColumnsProps>`
  width: 100%;
  ${({ alignment }) => gridAlignment({ alignment })}
  ${breakpointSmall(
    css`
      max-width: 1150px;
    `
  )};
  ${breakpointMedium(
    css`
      gap: 48px;
      max-width: 1285px;
    `
  )};
`;
const ContentContainer = styled.article<AlignProps>`
  grid-area: content;
  justify-self: center;
  padding: ${spacings['48px']} 0;
  ${({ alignment }) => columnAlignment({ alignment })}
  ${breakpointSmall(
    css`
      max-width: 550px;
      padding: ${spacings['64px']} 0;
      ${flex({ align: 'center' })}
    `
  )};
`;
const MediaContainer = styled.div<AlignProps>`
  grid-area: media;
`;

type ColumnsContainerProps = { children: React.ReactNode } & Pick<
  TwoColumnContentImageProps,
  'contentAlignment' | 'desktopImage' | 'animation' | 'mobileImage'
>;

/**
 * Columns Container for .
 */
const ColumnsContainer: FC<ColumnsContainerProps> = ({
  contentAlignment,
  desktopImage,
  children,
  animation,
  mobileImage,
}) => {
  const lottie = animation?.document?.data && <Lottie animation={animation} />;
  const image = desktopImage?.gatsbyImageData && (
    <PrismicImage objectFit="contain" visibility={{ base: 'hide', breakpointSmall: 'show' }} image={desktopImage} />
  );
  const smallImage = mobileImage?.gatsbyImageData && (
    <PrismicImage objectFit="contain" visibility={{ base: 'show', breakpointSmall: 'hide' }} image={mobileImage} />
  );

  const media = lottie || (
    <>
      {image}
      {smallImage}
    </>
  );

  return (
    <TwoColumns alignment={contentAlignment} data-testid="two-column-container">
      <ContentContainer alignment={contentAlignment}>{children}</ContentContainer>
      {media && <MediaContainer alignment={contentAlignment}>{media}</MediaContainer>}
    </TwoColumns>
  );
};
export default ColumnsContainer;
