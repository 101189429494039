import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { GatedButton } from '../../../components/gatedContent/GatedContent';
import { PrismicImage } from '../../../components/image/prismicImage/PrismicImage';
import { PrismicLinkType } from '../../../components/link/data/Link.generateData';
import RichText, { PrismicRichTextType } from '../../../components/richText/RichText';
import { flex, grid, padding } from '../../../design/animations/display';
import { blogHeaderShadow } from '../../../design/colors/shadows';
import { ButtonLink } from '../../../design/components/button/Button';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import CategoryTag from '../../../slices/contentHubCards/components/Tags/CategoryTag';
import TopicTags from '../../../slices/contentHubCards/components/Tags/TopicTags';
import { PrismicImageData } from '../../../utilityTypes';

const ArticleHeaderContainer = styled.div`
  padding-top: 48px;
  margin-bottom: ${spacings['24px']};
`;

const HeaderContainer = styled(Panel)`
  padding: 48px 0;
  ${blogHeaderShadow}
  ${breakpointSmall(
    css`
      padding: 0;
    `
  )}
`;

const InnerContainer = styled.div`
  ${flex({ gap: '24px', direction: 'column' })}
  ${padding({ x: '24px' })}
  max-width: 1285px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  ${breakpointSmall(css`
    ${grid({ gap: '24px' })}
  `)}
`;

const Cta = styled(ButtonLink)`
  max-width: fit-content;
`;

const Content = styled.div`
  padding: 0;
  ${flex({ direction: 'column', gap: '24px', justify: 'center' })}
  ${breakpointSmall(css`
    padding: 48px 0;
  `)}
`;

const Media = styled.figure`
  width: 100%;
  ${flex({ direction: 'column', justify: 'flex-end', align: 'center' })}
  ${breakpointSmall(css`
    ${flex({ direction: 'column', align: 'center' })}
    height: 100%;
  `)}
`;

const Image = styled(PrismicImage)`
  max-height: 400px;
  ${breakpointSmall(css`
    max-height: 573px;
    max-width: 446px;
    top: 5em;
  `)}
`;

type GuideHeaderProps = {
  researchType?: boolean | null | undefined;
  title?: PrismicRichTextType;
  topics?: string[];
  excerpt?: string | null;
  image?: PrismicImageData;
  downloadLink?: PrismicLinkType | null;
  hubspotFormID: string;
  hubspotFormTrackingEventName: string;
  hubspotFormSuccessHeading: string;
  hubspotFormSuccessText: string;
};

/**
 * Renders Blog Post Header.
 */
const GuideHeader: FC<GuideHeaderProps> = ({
  researchType,
  title,
  topics,
  excerpt,
  downloadLink,
  image,
  hubspotFormID,
  hubspotFormTrackingEventName,
  hubspotFormSuccessHeading,
  hubspotFormSuccessText,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const gatedButtonProps = {
    hubspotFormID: hubspotFormID,
    hubspotFormTrackingEventName: hubspotFormTrackingEventName,
    hubspotFormSuccessHeading: hubspotFormSuccessHeading,
    hubspotFormSuccessText: hubspotFormSuccessText,
    downloadLink: downloadLink?.url,
    isModalOpen: isModalOpen,
    setIsModalOpen: setIsModalOpen,
  } as const;

  return (
    <ArticleHeaderContainer>
      <HeaderContainer background="brand4WaveMidnightRight" data-testid="guide-research-header">
        <InnerContainer>
          <Content>
            <CategoryTag type={researchType ? 'research' : 'guide'} isLight />
            {title && <RichText field={title} textType="heading2" textAlign="left" />}
            {excerpt && <BodyP type="bodyMRegular">{excerpt}</BodyP>}
            {topics && <TopicTags topics={topics} theme="brand2" />}
            {downloadLink && hubspotFormID ? (
              <GatedButton text="Download Now" data-testid="guide-download-gated-button" {...gatedButtonProps} />
            ) : (
              downloadLink && (
                <Cta data-testid="guide-download-cta" link={downloadLink}>
                  Download now
                </Cta>
              )
            )}
          </Content>
          <Media>{image && <Image image={image} objectFit="contain" />}</Media>
        </InnerContainer>
      </HeaderContainer>
    </ArticleHeaderContainer>
  );
};

export default GuideHeader;
