import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Background } from '../../../design/containers/panel/Panel';
import { WithClassName } from '../../../utilityTypes';
import { stringData } from '../../../utils/normaliseData';
import { SliceProps, SliceVariation } from '../../types';
import BottomCta from '../BottomCta';

const getBackground = (backgroundColor: BottomCtaDataProps['slice']['primary']['backgroundColor']) => {
  const backgroundMap: Record<string, Background> = {
    whiteSolid: 'whiteSolid',
    grey200Solid: 'grey200Solid',
    brand2Solid: 'brand2Solid',
    brand3Solid: 'brand3Solid',
    brand5Solid: 'brand5Solid',
  };
  return backgroundColor ? backgroundMap[backgroundColor] : 'whiteSolid';
};

type BottomCtaDataProps = SliceProps<'bottomCta', SliceVariation<'default', Queries.PrismicBottomCtaDefaultPrimary>> &
  WithClassName;
/**
 * BottomCta data component: specifies the fragment and passes the appropriate data to the BottomCta component.
 */
const BottomCtaData: FC<BottomCtaDataProps> = ({ className, slice }) => {
  const data = { ...slice.primary };
  return (
    <BottomCta
      className={className}
      background={getBackground(slice.primary.backgroundColor)}
      heading={data.heading}
      text={stringData(data.text)}
      subtext={stringData(data.subtext)}
      primaryCta={{ text: stringData(data.primaryCtaButtonText), link: data.primaryCtaLink }}
      secondaryCta={{ text: stringData(data.secondaryCtaButtonText), link: data.secondaryCtaLink }}
    />
  );
};

export default BottomCtaData;

export const query = graphql`
  fragment BottomCta on PrismicBottomCtaDefault {
    primary {
      backgroundColor
      heading {
        ...RichText
      }
      text
      subtext
      primaryCtaButtonText
      primaryCtaLink {
        ...PrismicLink
      }
      secondaryCtaButtonText
      secondaryCtaLink {
        ...PrismicLink
      }
    }
  }
`;
