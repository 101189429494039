import { css } from 'styled-components';
import { errorDark, errorLight, errorMid, errorMid2 } from '../../../../../../colors/system';
import { alertDisabledStyles } from '../alert';

/**
 * Generates the error button type style.
 */
export const errorTypeStyle = () => css`
  background-color: ${errorLight};
  border-color: ${errorMid2};
  color: ${errorDark};

  &:hover {
    background-color: ${errorMid2};
    border-color: ${errorMid};
  }

  ${alertDisabledStyles}
`;
