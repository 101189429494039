import { css, FlattenSimpleInterpolation } from 'styled-components';
import * as breakpoints from './breakpoints';

const breakpointsIncludingBase = { base: (style: FlattenSimpleInterpolation) => style, ...breakpoints };

type Breakpoint = keyof typeof breakpointsIncludingBase;
type GenerateStyles<T> = (arg: T) => FlattenSimpleInterpolation | string;
export type BreakpointMap<T> = Partial<Record<Breakpoint, T>>;
export type ResponsiveValue<T> = BreakpointMap<T> | T;

/**
 * Narrows type to whether it's a concerte type or breakpoint map of type.
 */
export const isBreakpointMap = <T>(value: ResponsiveValue<T>): value is BreakpointMap<T> => {
  if (!value || typeof value !== 'object') return false;
  const keys = Object.keys(value);
  const breakpointNames = Object.keys(breakpointsIncludingBase);
  return !!(keys.length && keys.some((key) => breakpointNames.includes(key)));
};

/**
 * Generates responsive styles.
 */
const generateResponsiveStyles = <T>(value: ResponsiveValue<T>, generateStyles: GenerateStyles<T>) => {
  if (!isBreakpointMap(value)) return generateStyles(value);

  const valueBreakpointNames = Object.keys(value) as (keyof typeof value)[];
  return valueBreakpointNames.map((breakpoint) => {
    const generateBreakpoint = breakpointsIncludingBase[breakpoint];
    const breakpointValue = value[breakpoint];
    return (
      breakpointValue &&
      generateBreakpoint(
        css`
          ${generateStyles(breakpointValue)}
        `
      )
    );
  });
};

export default generateResponsiveStyles;
